import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { authSelector, setAuth } from "../features/auth/authSlice";
import { keycloakConfig } from "../utils/keycloak.config";
import { useNavigate } from "react-router";
import Keycloak from 'keycloak-js';
import jwtDecode from "jwt-decode";
import '../styles/Pages.sass';
import { Modal, Typography } from "antd";
import Lottie from "lottie-react";
import SuccessAnimation from "../utils/success.json";

const { baseURL, realms, clientId, responseType } = keycloakConfig;

function Login() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(authSelector);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);


  let initOptions = {
    url: baseURL,
    realm: realms,
    clientId: clientId,
    onLoad: 'login-required', // check-sso | login-required
    KeycloakResponseType: responseType,
  }

  let kc = new Keycloak(initOptions);

  const login = () => {
    kc.init({
      onLoad: initOptions.onLoad,
      KeycloakResponseType: 'code',
      silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
      checkLoginIframe: false,
      pkceMethod: 'S256'
    }).then((auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        let token = jwtDecode(kc.token);
        console.log("token", token)
        let roles = token.realm_access.roles;

        let isAdmin, isMainAdmin, isClient, isFinance = false
        if(roles.includes("MAIN_ADMIN")){
          isMainAdmin = roles.includes("MAIN_ADMIN");
        } else if(roles.includes("MAT_ADMIN")){
          isAdmin = roles.includes("MAT_ADMIN");
        }
        else if(roles.includes("MAT_FINANCE")){
          isFinance = roles.includes("MAT_FINANCE");
        } 
        else {
          isClient = roles.includes("NEW_CLIENT") || roles.some(role => role.includes("-ADMIN")) || roles.some(role => role.includes("_ADMIN"));
        }        

        dispatch(setAuth({ accessToken: kc.token, refreshToken: kc.refreshToken, isAdmin, isMainAdmin,isFinance, isClient, isAuth: kc.authenticated }));
        handleSuccess(isFinance)
      }
    }, () => {
      console.error("Authenticated Failed");
    });
  }

  const handleSuccess = (isFinance) => {
    let secondsToGo = 3;

    setModalOpen(true)

    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setModalOpen(false);
      console.log('authxxx', isFinance)
      isFinance ? navigate("/dashboard/joint-ticket") : navigate("/dashboard/confirmation");
    }, secondsToGo * 1000);
  }

  useEffect(() => {
    if (auth.isAuth) {
      auth.isFinance ? navigate("/dashboard/joint-ticket") : navigate("/dashboard/confirmation");
      console.log("redirect")
    }
    login();
  }, [])
  return (
    <>
      <Modal
        closable={true}
        open={modalOpen}
        centered
        footer={null}
        bodyStyle={{ paddingTop: 20, display: "flex", justifyContent: "center" }}
        onCancel={() => setModalOpen(false)}
        closeIcon={<></>}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Lottie loop={false} style={{ width: 198 }} animationData={SuccessAnimation} />
          <Typography.Title style={{ textAlign: "center", margin: 0, fontWeight: 500 }} level={3}>
            You Successfully Signed In</Typography.Title>
        </div>
      </Modal>
    </>
  )
}

export default Login