import axios from "axios";
import { store } from "../app/store";
import { message, notification } from "antd";
import { getRefreshedToken } from "../APIs/auth";

export const instance = axios.create({
    baseURL: `${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}`,
    headers: {
        "Authorization": `Bearer `,
        "Content-Type": "application/json"
    }
});

instance.interceptors.request.use((config) => {
    const token = store.getState().auth.accessToken;
    config.headers['Authorization'] = `Bearer ${token}`;

    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, async (error) => {
    const url = error?.response?.data?.apiPath?.split("=")[1]
    console.log("error url", url)
    const foundUrl = url?.includes("/excel/nominate")
    console.log("found url", foundUrl)
    const method = error?.config?.method
    console.log("header method", method)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error?.response) {
        const { status, statusText } = error.response;
        if ((status === 401 || statusText === "Unauthorized") && !error.config._retry) {
            const originalRequest = error.config;
            originalRequest._retry = true;
            const accessToken = await getRefreshedToken();
            originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
            return axios.request(originalRequest);
        }
        // For forbidden, then redirecting to logout page 
        else if (status === 403 || statusText === "Forbidden") {
            notification.error({
                message: 'Forbidden',
                description:
                    '403'
            });
            // SignOut()
        }

        else if (status === 404) {
            if(error.response.data.errorMessage){
                message.error(error.response.data.errorMessage)
            } else {
                message.error("404 not found")
            }
        }
    

        else if(status === 400){
            if(error.response.data.errorMessage){
                if(typeof error.response.data.errorMessage === "string"){
                    message.error(error.response.data.errorMessage)
                } else {
                    if(!((method==="put" && url.includes("/nominate/details/") || (method==="post" && url.includes("/nominate"))))) {
                        Object.keys(error.response.data.errorMessage).forEach(function(key, index) {
                            message.error(key+": "+error.response.data.errorMessage[key])
                        }); 
                    }
                }
            } 
            else if(error.response.data.detail) {
                message.error(error.response.data.detail)
            }
            else {
                Object.keys(error.response.data).forEach(function(key, index) {
                    message.error(key+": "+error.response.data[key])
                });
            }
            
        } else if(status >= 500){
            if(error.response.data.message){
                message.error(error.response.data.message)
            } 
            else if(error.response.data.error) {
                message.error(error.response.data.error)
            } else if(error.response.data.errorMessage) {
                !foundUrl && message.error(error.response.data.errorMessage)
            }
        } 
        
        else {
            message.error(error.response.data.errorMessage)
        }
        console.log(error)

    }

    return Promise.reject(error);
});
