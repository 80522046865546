import { Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { NominationStatus, NominationStatusDisplay, NotificationStatus, NotificationStatusColors, NotificationStatusDisplay, nominationStatusColors } from '../../utils/healpers/constants';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

export interface Item {
    id: 1,
    name: string,
    contractId: string,
    dcq: number,
    totalDcq: number,
    duration: string,
    nominatedQty: number,
    confirmedQty: number,
    notification: Boolean,
    gasDay: string,
    status: Boolean,
    clientId: number,
    client: string,
    startDate: string,
    endDate: string,
    notificationStatus: string,
    nominationStatus: string,
    firstNotificationDate: string | null,
    nomination: any,
    deliveryId: number,
    lastNotificationDate: string
}


export const columns: ColumnsType<Item> = [
    {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        align: "center",
        render: (client: string) => <Tooltip title={client}><Typography.Text ellipsis style={{ width: 100 }}>
            {client}</Typography.Text >
        </Tooltip>,
        // sorter: true
    },
    {
        title: 'Client Code',
        dataIndex: 'clientCode',
        key: 'clientCode',
        render: (clientCode) => <div style={{width: 90, display: "inline-block"}}>{clientCode}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: 'Contract ID',
        dataIndex: 'contractId',
        key: 'contractId',
        align: "center",
        render: (contractId: string) => <div style={{ width: 80, display: "inline-block" }}>{contractId}</div>,
        // sorter: true,
    },
    {
        title: 'IGX Trade ID',
        dataIndex: 'tradeId',
        key: 'tradeId',
        render: (tradeId) => <div style={{width: 100, display: "inline-block"}}>{tradeId}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: 'DCQ',
        dataIndex: 'totalDcq',
        key: 'totalDcq',
        render: (totalDcq: string) => <div style={{ width: 80, display: "inline-block" }}>{totalDcq}</div>,
        align: "center",
        // sorter: true
    },
    {
        title: 'Duration',
        key: 'startDate',
        render: (record) => 
        <div style={{ 
            width: 160,
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2px"
        }}>
            {dayjs(record.startDate, "DD/MM/YYYY").format("DD MMM")}            
            <img width={15} src="/assets/date-line.svg" />
            {dayjs(record.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}
        </div>,
        align: "center",
        sorter: true,
    },
    {
        title: <>Nominated Qty<br/>(MMBTU)</>,
        dataIndex: 'nominatedQty',
        key: 'nominatedQty',
        render: (nominatedQty: string) => <div style={{ width: 150, display: "inline-block" }}>{nominatedQty}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: <>Tentative Schedule<br/>Qty (MMBTU)</>,
        dataIndex: 'confirmedQty',
        key: 'confirmedQty',
        render: (value) => <div style={{ width: 140, display: "inline-block" }}>{value ? value : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: 'Notification',
        dataIndex: 'notificationStatus',
        key: 'notificationStatus',
        render: (notificationStatus:
            NotificationStatus
        ) => {
            const displayValue = NotificationStatusDisplay[notificationStatus];
            return <Tag style={{ fontSize: 14, display: "inline-block" }} color={NotificationStatusColors[notificationStatus]}>{displayValue}</Tag>
        },
        align: "center",
        // sorter: true
    },
    {
        title: 'Status',
        dataIndex: 'nominationStatus',
        key: 'nominationStatus',
        render: (nominationStatus:
            NominationStatus
        ) => {
            const displayValue = NominationStatusDisplay[nominationStatus];
            return <div style={{minWidth: 100, display: "inline-block",}}>
                <Tag style={{ fontSize: 14, textAlign: "center" }} color={nominationStatusColors[nominationStatus]}>{displayValue.split(" ")[0]}<br/>{displayValue.split(" ")[1]}{displayValue.split(" ")[2] && " "+displayValue.split(" ")[2]}</Tag>
            </div>
        },
        align: "center",
        // sorter: true
    },
    {
        title: 'Action',
        key: 'action',
        render: (record) => <Link style={{textDecoration: "underline"}} to={`/dashboard/confirmation/${record.id}`}>View Details</Link>,
        align: "center",
        ellipsis: true
    },
];