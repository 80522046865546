import { Button, Col, Divider, Form, Input, Modal, Row, Skeleton, Space, Typography, message } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import theme from '../../../utils/theme';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import AddressUpdateModal from './AddressUpdateModal';
import UpdateStatusLabel from './UpdateStatusLabel';
import { stopScroll } from '../../../utils/common';
import _ from 'lodash';


type FieldType = {
    email?: string;
    name?: string;
    mobileNo?: number;
    telephoneNo?: number;
    registeredOfficeAddressId: number | null;
    registeredOfficeAddressLine1: string;
    registeredOfficeAddressLine2: string;
    registeredOfficeAddressCity: string;
    registeredOfficeAddressState: string;
    registeredOfficeAddressPinCode: string;
    plantOfficeAddressId: number | null;
    registeredOfficeCountry: string;
    plantOfficeAddressLine1: string;
    plantOfficeAddressLine2: string;
    plantOfficeAddressCity: string;
    plantOfficeAddressState: string;
    plantOfficeAddressPinCode: string;
    plantOfficeCountry: string;
};

type PropType = { id: string | undefined, clientData: any | undefined, setClientData: Dispatch<any>, getClientData: any, fetchingDetails: boolean, setDefaulttab: Dispatch<SetStateAction<any | null>>, approveOrReject: any, updateStatus: string, setInProgrss?: any, tabStates?: any }

function ClientDetails({ clientData, setClientData, fetchingDetails, setDefaulttab, getClientData, id, approveOrReject, updateStatus, setInProgrss, tabStates }: PropType) {
    const [loading, setLoading] = useState(false)
    const [enableReset, setEnableReset] = useState(false)
    const [enableEdit, setEnableEdit] = useState(!id ? true : false)
    const auth = useAppSelector(authSelector);
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        if (setInProgrss) {
            setInProgrss(enableEdit)
        }
    }, [])

    useEffect(() => {
        if (setInProgrss) {
            setInProgrss(enableEdit)
        }
    }, [enableEdit])


    const navigate = useNavigate()
    const [form] = Form.useForm();

    const showModal = (record: any) => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const updateClient = async (values: any) => {
        setLoading(true);
        try {
            values.name = values.name.replace(/\s+/g, ' ').trim();
            let formData = {
                "email": values.email,
                "name": values.name,
                "mobileNo": values.mobileNo,
                "telephoneNo": values.telephoneNo,
                "addressDtoList": [
                    {
                        "id": values.registeredOfficeAddressId ? values.registeredOfficeAddressId : null,
                        "addressLine1": values.registeredOfficeAddressLine1,
                        "addressLine2": values.registeredOfficeAddressLine2,
                        "city": values.registeredOfficeAddressCity,
                        "state": values.registeredOfficeAddressState,
                        "pinCode": values.registeredOfficeAddressPinCode,
                        "country": values.registeredOfficeCountry,
                        "addressType": "REGISTERED_OFFICE_ADDRESS",
                    },
                    {
                        "id": values.plantOfficeAddressId ? values.plantOfficeAddressId : null,
                        "addressLine1": values.plantOfficeAddressLine1,
                        "addressLine2": values.plantOfficeAddressLine2,
                        "city": values.plantOfficeAddressCity,
                        "state": values.plantOfficeAddressState,
                        "pinCode": values.plantOfficeAddressPinCode,
                        "country": values.plantOfficeCountry,
                        "addressType": "PLANT_OFFICE_ADDRESS"
                    }
                ]
            }
            if (id) {

                if (clientData?.registrationStatus == "ADMIN_ONBOARDING_COMPLETED" || clientData?.registrationStatus == "APPROVED") {

                    Modal.confirm({
                        title: <div>
                            Are you sure you want to submit these changes?
                        </div>,
                        // content: 'Once deleted changes cannot be reverted',
                        okText: 'Yes',
                        okType: 'danger',
                        centered: true,
                        cancelText: 'No',
                        icon: null,
                        onOk: async () => {
                            const registeredOffice = clientData?.addressList?.find((item: any) => {
                                return item.addressType === "REGISTERED_OFFICE_ADDRESS"
                            })

                            const plantOffice = clientData?.addressList?.find((item: any) => {
                                return item.addressType === "PLANT_OFFICE_ADDRESS"
                            })

                            let originalData: any = {
                                "email": clientData?.email,
                                "name": clientData?.name,
                                "mobileNo": clientData?.mobileNo,
                                "telephoneNo": clientData?.telephoneNo,
                                "addressDtoList": [
                                    {
                                        "id": registeredOffice?.id ? registeredOffice?.id : null,
                                        "addressLine1": registeredOffice?.addressLine1,
                                        "addressLine2": registeredOffice?.addressLine2,
                                        "city": registeredOffice?.city,
                                        "state": registeredOffice?.state,
                                        "pinCode": registeredOffice?.pinCode,
                                        "country": registeredOffice?.country,
                                        "addressType": "REGISTERED_OFFICE_ADDRESS",
                                    },
                                    {
                                        "id": plantOffice?.id ? plantOffice?.id : null,
                                        "addressLine1": plantOffice?.addressLine1,
                                        "addressLine2": plantOffice?.addressLine2,
                                        "city": plantOffice?.city,
                                        "state": plantOffice?.state,
                                        "pinCode": plantOffice?.pinCode,
                                        "country": plantOffice?.country,
                                        "addressType": "PLANT_OFFICE_ADDRESS"
                                    }
                                ]
                            }

                            // Compare formData and originalData and only keep the differences
                            const diff: any = {
                                email: formData.email !== originalData.email ? formData.email : undefined,
                                name: formData.name !== originalData.name ? formData.name : undefined,
                                mobileNo: formData.mobileNo !== originalData.mobileNo ? formData.mobileNo : undefined,
                                telephoneNo: formData.telephoneNo !== originalData.telephoneNo ? formData.telephoneNo : undefined,
                                addressDtoList: formData.addressDtoList.map((newAddr, index) => {
                                    const originalAddr = originalData.addressDtoList[index];
                                    const addrDiff = {
                                        id: newAddr.id,
                                        addressType: newAddr.addressType,
                                        ...(_.isEqual(newAddr.addressLine1, originalAddr.addressLine1) ? {} : { addressLine1: newAddr.addressLine1 }),
                                        ...(_.isEqual(newAddr.addressLine2, originalAddr.addressLine2) ? {} : { addressLine2: newAddr.addressLine2 }),
                                        ...(_.isEqual(newAddr.city, originalAddr.city) ? {} : { city: newAddr.city }),
                                        ...(_.isEqual(newAddr.state, originalAddr.state) ? {} : { state: newAddr.state }),
                                        ...(_.isEqual(newAddr.pinCode, originalAddr.pinCode) ? {} : { pinCode: newAddr.pinCode }),
                                        ...(_.isEqual(newAddr.country, originalAddr.country) ? {} : { country: newAddr.country })
                                    };
                                    return _.omitBy(addrDiff, _.isUndefined);
                                }).filter(item => Object.keys(item).length > 2)  // Ensure there's more than just 'id' and 'addressType'
                            }

                            // Remove undefined values from diff
                            Object.keys(diff).forEach(key => diff[key] === undefined && delete diff[key]);
                            Object.keys(diff).forEach(key => diff[key] === undefined && delete diff[key]);

                            console.log("diff", diff)

                            diff.id = id

                            try{
                                await instance.put(`clientservice/clients/edit-profile`, diff)
                                message.success("request created successfully")
                                getClientData()
                                setEnableReset(false)
                                setEnableEdit(false)
                                setDefaulttab(null)
                            } catch(e){
                                console.log(e)
                            }


                        },
                        bodyStyle: {
                            textAlign: "center",
                        },
                        okButtonProps: {
                            size: "large",
                            type: "primary",
                            style: {background: theme.token.colorPrimary, width: 140, fontWeight: 600}
                        },
                        cancelButtonProps: {
                            size: "large",
                            type: "default",
                            style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary}
                        }
                    })


                } else {
                    Modal.confirm({
                        title: <div>
                            Are you sure you want to submit these changes?
                        </div>,
                        // content: 'Once deleted changes cannot be reverted',
                        okText: 'Yes',
                        okType: 'danger',
                        centered: true,
                        cancelText: 'No',
                        icon: null,
                        onOk: async () => {
                            try{
                                await instance.put(`clientservice/clients/${id}`, formData)
                                message.success("client updated successfully")
                                getClientData()
                                setEnableReset(false)
                                setEnableEdit(false)
                                setDefaulttab(null)
                            } catch(e){
                                console.log(e)
                            }
                        },
                        bodyStyle: {
                            textAlign: "center",
                        },
                        okButtonProps: {
                            size: "large",
                            type: "primary",
                            style: {background: theme.token.colorPrimary, width: 140, fontWeight: 600}
                        },
                        cancelButtonProps: {
                            size: "large",
                            type: "default",
                            style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary}
                        }
                    })
                    
                }
            } else {
                const res = await instance.post("clientservice/clients", formData)
                message.success("client created successfully")
                setEnableReset(false)
                setEnableEdit(false)
                if (res.data.id) {
                    window.location.href = `/dashboard/profile/${res.data.id}?tab=client-details-2`
                }
                // setDefaulttab(null)
            }

        } catch (e) {
            console.log(e)
        }
        setLoading(false);
    };

    useEffect(() => {
        setFormValues()
    }, [clientData])

    const setFormValues = () => {
        const registeredOffice = clientData?.addressList?.find((item: any) => {
            return item.addressType === "REGISTERED_OFFICE_ADDRESS"
        })

        const plantOffice = clientData?.addressList?.find((item: any) => {
            return item.addressType === "PLANT_OFFICE_ADDRESS"
        })
        form.setFieldsValue({
            "email": clientData?.email,
            "name": clientData?.name,
            "mobileNo": clientData?.mobileNo,
            "telephoneNo": clientData?.telephoneNo,
            "registeredOfficeAddressId": registeredOffice?.id ? registeredOffice?.id : null,
            "registeredOfficeAddressLine1": registeredOffice?.addressLine1,
            "registeredOfficeAddressLine2": registeredOffice?.addressLine2,
            "registeredOfficeAddressCity": registeredOffice?.city,
            "registeredOfficeAddressState": registeredOffice?.state,
            "registeredOfficeAddressPinCode": registeredOffice?.pinCode,
            "registeredOfficeCountry": registeredOffice?.country,
            "plantOfficeAddressId": plantOffice?.id ? plantOffice?.id : null,
            "plantOfficeAddressLine1": plantOffice?.addressLine1,
            "plantOfficeAddressLine2": plantOffice?.addressLine2,
            "plantOfficeAddressCity": plantOffice?.city,
            "plantOfficeAddressState": plantOffice?.state,
            "plantOfficeAddressPinCode": plantOffice?.pinCode,
            "plantOfficeCountry": plantOffice?.country,

        })
        setEnableReset(false)
    }

    const handleFormChange = () => {
        // const field = e.target.getAttribute('id');
        // const name = field.split("_")[1]

        const registeredOffice = clientData?.addressList?.find((item: any) => {
            return item.addressType === "REGISTERED_OFFICE_ADDRESS"
        })

        const plantOffice = clientData?.addressList?.find((item: any) => {
            return item.addressType === "PLANT_OFFICE_ADDRESS"
        })

        let originalData: any = {
            "name": clientData?.name,
            "email": clientData?.email,
            "mobileNo": clientData?.mobileNo,
            "telephoneNo": clientData?.telephoneNo,
            "registeredOfficeAddressId": registeredOffice?.id ? registeredOffice?.id : null,
            "registeredOfficeAddressLine1": registeredOffice?.addressLine1,
            "registeredOfficeAddressLine2": registeredOffice?.addressLine2,
            "registeredOfficeAddressCity": registeredOffice?.city,
            "registeredOfficeAddressState": registeredOffice?.state,
            "registeredOfficeAddressPinCode": registeredOffice?.pinCode,
            "registeredOfficeCountry": registeredOffice?.country,
            "plantOfficeAddressId": plantOffice?.id ? plantOffice?.id : null,
            "plantOfficeAddressLine1": plantOffice?.addressLine1,
            "plantOfficeAddressLine2": plantOffice?.addressLine2,
            "plantOfficeAddressCity": plantOffice?.city,
            "plantOfficeAddressState": plantOffice?.state,
            "plantOfficeAddressPinCode": plantOffice?.pinCode,
            "plantOfficeCountry": plantOffice?.country,
               
        }

        const obj1String = JSON.stringify(originalData);
        const obj2String = JSON.stringify(form.getFieldsValue());

        console.log("obj2String",form.getFieldsValue())


        if (obj1String === obj2String) {
            setEnableReset(false)

        } else {
            setEnableReset(true)
        }

    }

    return (
        <React.Fragment>

            {(clientData?.clientHistory || clientData?.addressHistoryList) && <UpdateStatusLabel label="Profile" showModal={showModal} updateStatus={updateStatus} />}

            {!fetchingDetails ? <Form
                scrollToFirstError
                onChange={() => setTimeout(() => { handleFormChange(); }, 500)}
                form={form}
                disabled={loading}
                name="client-details"
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={updateClient}
                autoComplete="off"
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Company name"
                            name="name"
                            rules={[{ required: true }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Email ID"
                            name="email"
                            rules={[{
                                validator: async (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error("Please input email!"));
                                    }
                                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    if (!emailRegex.test(value)) {
                                        return Promise.reject(new Error("Invalid email format"));
                                    }
                                }
                            }]}
                        >
                            <Input type='email' disabled={!enableEdit || !!id} size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Mobile No."
                            name="mobileNo"
                            rules={[{ required: true, len: 10 }]}
                        >
                            <Input disabled={!enableEdit} onFocus={stopScroll} type="number" size="large" style={{ width: "100%" }} prefix={<div style={{ display: "flex", alignItems: "center", gap: 8 }}><span>+91-</span><span style={{ height: 26, width: 1, background: "#DADADA" }}></span></div>} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Telephone No."
                            name="telephoneNo"
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold" }} orientation="left">Registered Office Address</Divider>
                        </div>
                    </Col>
                    <Col style={{ display: "none" }} span={12}>
                        <Form.Item<FieldType>
                            label="reg id"
                            name="registeredOfficeAddressId"
                            rules={[{ required: false }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Address Line 1"
                            name="registeredOfficeAddressLine1"
                            rules={[{ required: true }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Address Line 2"
                            name="registeredOfficeAddressLine2"
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>

                            label="City"
                            name="registeredOfficeAddressCity"
                            rules={[{
                                validator: async (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error("Please input City"));

                                    }
                                    if (!/^[A-Za-z ]+$/.test(value)) {
                                        return Promise.reject(new Error('Only letters allowed'));
                                    }
                                },
                            }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="State"
                            name="registeredOfficeAddressState"
                            rules={[{
                                validator: async (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error("Please input State"));

                                    }
                                    if (!/^[A-Za-z ]+$/.test(value)) {
                                        return Promise.reject(new Error('Only letters allowed'));
                                    }
                                },
                            }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Pin-Code"
                            name="registeredOfficeAddressPinCode"
                            rules={[{
                                validator: async (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error("Please input Pin-Code"));
                                    }
                                    if (isNaN(value) || parseInt(value) < 0 || value.includes('.')) {
                                        return Promise.reject(new Error('only numbers allowed'));
                                    }

                                }
                            }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Country"
                            name="registeredOfficeCountry"
                            rules={[{
                                validator: async (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error("Please input Country"));

                                    }
                                    if (!/^[A-Za-z ]+$/.test(value)) {
                                        return Promise.reject(new Error('Only letters allowed'));
                                    }
                                },
                            }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold" }} orientation="left">Plant Office Address</Divider>
                        </div>
                    </Col>
                    <Col style={{ display: "none" }} span={12}>
                        <Form.Item<FieldType>
                            label="plant id"
                            name="plantOfficeAddressId"
                            rules={[{ required: false }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Address Line 1"
                            name="plantOfficeAddressLine1"
                            rules={[{ required: true }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Address Line 2"
                            name="plantOfficeAddressLine2"
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="City"
                            name="plantOfficeAddressCity"
                            rules={[{
                                validator: async (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error("Please input City"));

                                    }
                                    if (!/^[A-Za-z ]+$/.test(value)) {
                                        return Promise.reject(new Error('Only letters allowed'));
                                    }
                                },
                            }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="State"
                            name="plantOfficeAddressState"
                            rules={[{
                                validator: async (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error("Please input State"));

                                    }
                                    if (!/^[A-Za-z ]+$/.test(value)) {
                                        return Promise.reject(new Error('Only letters allowed'));
                                    }
                                },
                            }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Pin-Code"
                            name="plantOfficeAddressPinCode"
                            rules={[{
                                validator: async (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error("Please input Pin-Code"));
                                    }
                                    if (isNaN(value) || parseInt(value) < 0 || value.includes('.')) {
                                        return Promise.reject(new Error('only numbers allowed'));
                                    }
                                }
                            }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Country"
                            name="plantOfficeCountry"
                            rules={[{
                                validator: async (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error("Please input Country"));

                                    }
                                    if (!/^[A-Za-z ]+$/.test(value)) {
                                        return Promise.reject(new Error('Only letters allowed'));
                                    }
                                },
                            }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                </Row>


                <div className="hide-me" style={{ display: "flex", justifyContent: "center" }}>
                    <Form.Item>
                        {!enableEdit ? <Space size="large">
                            {/* {!id && <Button style={{ width: 200 }} disabled={id ? !(id && enableReset) : false} size='large' loading={loading} type="primary" htmlType="submit">
                                {id ? "Update" : "Create"}
                            </Button>} */}
                            {((auth.isClient && clientData?.addressUpdateStatus !== "UPDATED_BY_ADMIN") || ((auth.isAdmin || auth.isMainAdmin) && clientData?.addressUpdateStatus !== "UPDATED")) && <>
                                {id && <Button style={{ width: 200 }} size="large" onClick={() => setEnableEdit(true)}>
                                    Edit
                                </Button>}
                            </>}
                            {id && <Button onClick={setDefaulttab} style={{ width: 200 }} size='large' type='primary'>Next</Button>}

                        </Space> : <Space size="large">
                            <Button style={{ width: 200 }} size='large' disabled={loading} onClick={() => { setFormValues(); setEnableEdit(false) }} type="default">Cancel</Button>
                            <Button style={{ width: 200 }} disabled={id ? !(id && enableReset) : false} type="primary" htmlType="submit" loading={loading} size='large'>Save & Next</Button>
                        </Space>}
                    </Form.Item>
                </div>

            </Form> : <>
                <Skeleton />
            </>}

            <Modal destroyOnClose width={869} footer={null} style={{ textAlign: "center" }} bodyStyle={{ paddingBottom: 16 }} title={<div style={{ marginTop: 16, color: theme.token.colorPrimary }}>
                {(clientData?.addressUpdateStatus === "UPDATED_BY_ADMIN" && (auth.isClient)) && "Admin has edited your Profile details, please review the information."}
                {(clientData?.addressUpdateStatus === "UPDATED_BY_ADMIN" && (auth.isAdmin || auth.isMainAdmin)) && "Profile details has been updated."}


                {(clientData?.addressUpdateStatus === "UPDATED" && (auth.isClient)) && "Profile details has been updated."}
                {(clientData?.addressUpdateStatus === "UPDATED" && (auth.isAdmin || auth.isMainAdmin)) && "Client has edited your Profile details, please review the information."}

            </div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <AddressUpdateModal approveOrReject={(type: any) => { approveOrReject(type); setIsModalOpen(false) }} clientData={clientData} addressUpdateData={clientData?.addressHistoryList} />
            </Modal>
        </React.Fragment>
    )

};

export default ClientDetails;
