import { Breadcrumb, Button, Card, Form, Input, Modal, Select, Skeleton, Space, Spin, Table, Tag, Tooltip, Typography, message } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type { FormInstance } from 'antd/es/form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { instance } from '../../../utils/api';
import dayjs from 'dayjs';
import { NotificationStatus, NotificationStatusDisplay, NotificationStatusColors, NominationStatus, NominationStatusDisplay, nominationStatusColors } from '../../../utils/healpers/constants';
import { fetchStats, handleMenuItemClick, showSuccess, stopScroll } from '../../../utils/common';
import useNavigationWithUnfinishedTasks from '../../../utils/hooks/useUnfinishedTaskAlert';
import { dashboardSelector, setPendingConfirmationCount, setUnSavedData } from '../../../features/auth/dashboardSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import theme from '../../../utils/theme';
import _isEqual from 'lodash/isEqual';
import { ExclamationCircleOutlined, UpOutlined } from '@ant-design/icons';
import { flatMap } from 'lodash';
import { authSelector } from '../../../features/auth/authSlice';
const _ = require('lodash');


const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

interface Item {
    id: number;
    gasDay: string,
    dcq: number,
    nominatedQty: number,
    confirmedQty: number | null,
    deliveryEndPoint: string,
    deliveryId: string,
    sourceId: string,
    pipelineId: string,
    status: string,
    notification: string,
    lastUpdatedBy: string,
    nominationDetails: any
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
    parentRecord: any,
    setErrors: any
    errors: any;
    dcq: number;
    contractId: string;
    contract: any;
    setContract: any;
    disableAll: boolean
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    parentRecord,
    setErrors,
    errors,
    dcq,
    contractId,
    contract,
    setContract,
    disableAll,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext)!;
    const [fetchingContract, setFetchingContract] = useState(false)

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = async () => {
        setEditing(!editing);
        if (!contract && dataIndex === "deliveryEndPoint") {
            setFetchingContract(true)
            try {
                const response = await instance.get(`/clientservice/clients/contract?id=${contractId}`);
                setContract(response.data)
                form.setFieldsValue({ [dataIndex]: record[dataIndex] });
            } catch (error) {
                console.error('Error fetching dbdata:', error);
            }
            setFetchingContract(false)
        } else {
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }
    };

    const save = async (e: any, value?: any) => {
        if (e) {
            if ((isNaN(e?.target?.value) || parseInt(e?.target?.value) < 0) && !["sourceId", "pipelineId"].includes(dataIndex)) {
                setErrors((prevErr: any) => {
                    const existingErrorIndex = prevErr.findIndex((err: any) => err.dataIndex === dataIndex && record.id === err.id);

                    if (existingErrorIndex !== -1) {
                        // Error already exists, update the message
                        const newErr = [...prevErr];
                        newErr[existingErrorIndex].error = "only numbers allowed";
                        return newErr;
                    } else {
                        // Error doesn't exist, add a new error object
                        return [...prevErr, { dataIndex: dataIndex, id: record.id, error: "only numbers allowed", gasDay: record.gasDay }];
                    }
                });
            } else if((!/^\d+(\.\d{1,3})?$/.test(e?.target?.value) && e?.target?.value) && !["sourceId", "pipelineId"].includes(dataIndex)) {
                setErrors((prevErr: any) => {
                    const existingErrorIndex = prevErr.findIndex((err: any) => err.dataIndex === dataIndex && record.id === err.id);

                    if (existingErrorIndex !== -1) {
                        // Error already exists, update the message
                        const newErr = [...prevErr];
                        newErr[existingErrorIndex].error = "3 decimal places allowed";
                        return newErr;
                    } else {
                        // Error doesn't exist, add a new error object
                        return [...prevErr, { dataIndex: dataIndex, id: record.id, error: "3 decimal places allowed", gasDay: record.gasDay }];
                    }
                });
            }
            else if (!record.nominatedQty) {
                setErrors((prevErr: any) => {
                    const existingErrorIndex = prevErr.findIndex((err: any) => err.dataIndex === dataIndex && record.id === err.id);

                    if (existingErrorIndex !== -1) {
                        // Error already exists, update the message
                        const newErr = [...prevErr];
                        newErr[existingErrorIndex].error = "nomination not done";
                        return newErr;
                    } else {
                        // Error doesn't exist, add a new error object
                        return [...prevErr, { dataIndex: dataIndex, id: record.id, error: "nomination not done", record: record.gasDay }];
                    }
                });
            }
            else {
                setErrors((prevErr: any) => {
                    const newErr = prevErr.filter((err: any) => !(err.dataIndex === dataIndex && err.id === record.id));
                    return newErr;
                });
            }
        }
        try {
            let values = await form.validateFields();
            if(dataIndex === "deliveryEndPoint"){
                values.deliveryId = contract?.deliveryEndPoints?.find((i:any)=>
                    i.deliveryEndPoint === value
                ).id
            }
            // values.confirmedQty = isNaN(e.target.value) || !e.target.value ? values.confirmedQty : parseInt(values.confirmedQty)
            // values.confirmedQty = e.target.value === "" ? null : values.confirmedQty
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    const disableDeliveryOption = (id:any) => {
        if(!record.nominationDetails) {
            const parentData = parentRecord.find((item:any)=>{
                return item.gasDay === record.gasDay
           })
           console.log("parent data", parentData)
           const deliveryIds = parentData.nominationDetails?.map((item:any)=> item.deliveryId)
           return deliveryIds?.includes(id)
        } else {
            return false
        }
    }

    let childNode = children;

    if (editable && (record.nominationDetails?.length < 2 || !record.nominationDetails)) {
        childNode = <div id={`nomination-entry-${record.gasDay}`} style={{ display: "inline-block"}}>{editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
            >
                {dataIndex === "sourceId" || dataIndex === "pipelineId" || dataIndex === "deliveryId"
                    ?
                    <Input.TextArea disabled={dataIndex === "deliveryId"} className="hide-scroll" style={{ width: 173, whiteSpace: "nowrap" }} autoFocus ref={inputRef} onChange={save} onBlur={(e: any) => { toggleEdit() }} />
                    :
                    dataIndex === "deliveryEndPoint" ? <Select suffixIcon={fetchingContract ? <Spin size='small'/> : <UpOutlined />} defaultOpen={true} autoFocus style={{ width: 173 }} ref={inputRef} onChange={(value) => save(null, value)} onBlur={(e: any) => { toggleEdit() }}>
                        {contract?.deliveryEndPoints?.map((i: any) => {
                            return <Select.Option className="nomination-client-rounded" disabled={disableDeliveryOption(i.id)} key={i.id} value={i.deliveryEndPoint}>{i.deliveryEndPoint}</Select.Option>
                        })}

                    </Select> : <Input onFocus={stopScroll} style={{ width: 173, display: "inline-block", }} ref={inputRef} onChange={save} onBlur={(e: any) => { toggleEdit() }} />}
            </Form.Item>
        ) : (
            <div 
                style={{ position:"relative", width: 173,border: "solid 1px lightgray", padding: "5px 0px", borderRadius: 12, background: dayjs(record.gasDay, "DD/MM/YYYY").isAfter(dayjs()) ? "white" : "#E9E9E9", height: 32 }} 
                onClick={() => dayjs(record.gasDay, "DD/MM/YYYY").isAfter(dayjs()) && !disableAll && dataIndex !== "deliveryEndPoint" ? toggleEdit() : {}}
                onFocus={() => dayjs(record.gasDay, "DD/MM/YYYY").isAfter(dayjs()) && !disableAll && dataIndex !== "deliveryEndPoint" ? toggleEdit() : {}}
                tabIndex={0}
            >
                {children}
            {/* {dataIndex== "deliveryEndPoint" &&  <DownOutlined style={{fontSize: 12, position: "absolute", right: 10, top: 0, bottom: 0}} />} */}

            </div>
        )}
            {errors.map((error: any) => {
                return error.dataIndex === dataIndex && record.id === error.id && <span style={{ position: "absolute", color: "red", right: 0, left: 0, display: "block", fontSize: 10 }}>
                    <ExclamationCircleOutlined />{" "}{error.error}
                </span>
            })}
        </div>
    }

    return <td {...restProps}>
        {record?.nominationDetails?.length > 1 && dataIndex!="confirmedQty" ? <div style={{width: 173, display: "inline-block",}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div> : <>{childNode}</>}
    </td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function ConfirmationDetails() {
    let { id } = useParams();
    const [modal, modalContex] = Modal.useModal();
    const [data, setData] = useState<Item[] | []>([]);
    const [originalData, setOriginalData] = useState<Item[] | []>([]);
    const [clientData, setClientData] = useState<any>();
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [approvingAll, setApprovingAll] = useState(false);
    const [notifyingAll, setNotifyingAll] = useState(false)
    const [openNotify, setOpenNotify] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [editedItems, setEditedItems] = useState<any>([]);
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [loadingClientData, setLoadingClientDetails] = useState(false)
    const checkUnfinishedTasks = useNavigationWithUnfinishedTasks(saveEnabled)
    const dispatch = useAppDispatch();
    const dashboard: any = useAppSelector(dashboardSelector)
    const [messageApi, contextHolder] = message.useMessage();
    const [cancelled, setCancelled] = useState(false)
    const [contract, setContract] = useState<any>(null)
    const [errors, setErrors] = useState<any>([])
    const location = useLocation();
    const auth = useAppSelector(authSelector);



    useEffect(() => {
        if (saveEnabled) {
            dispatch(setUnSavedData(true))
        } else {
            dispatch(setUnSavedData(false))
        }

        return () => {
            dispatch(setUnSavedData(false))
        }
    }, [saveEnabled])

    const navigate = useNavigate();

    useEffect(() => {
        fetchDetails()
        fetchClientData();
    }, []);

    const handleReset = () => {
        fetchDetails()
        fetchClientData()
        setEditedItems([])
    }

    const handleCancel = () => {
        setData(originalData);
        setSaveEnabled(false)
        setSelectedRowKeys([]);

        setEditedItems([])
    }

    const fetchClientData = async () => {
        setLoadingClientDetails(true)
        try {
            const response = await instance.get(`orderservice/nominate/${id}`);
            setClientData(response.data);
        } catch (e) {
            console.log(e)
        }
        setLoadingClientDetails(false)
    }


    const fetchDetails = async () => {
        const params = {
            sort: "gasDay|ASC&auditInfo.creationDate|ASC",
            size: 10000,
            filterAnd: `${dayjs().isBefore(dayjs().date(15), 'day') || dayjs().isSame(dayjs().date(15), 'day')
                ? "gasDay|gted|" + dayjs().date(1).format("DD/MM/YYYY") :
                "gasDay|gted|" + dayjs().date(16).format("DD/MM/YYYY")}&nomination.id|eq|${id}`
        }
        setLoadingDetails(true)
        try {
            const res = await instance.get(`orderservice/nominate/details`, { params });
            setData(res.data.items)
            setOriginalData(res.data.items)
            if (res.data.items.length > 0) {
                const totalItems = res.data.items.length
                const pendingItems = res.data.items.filter((i: any) => i.confirmedQty === null).length
                if (pendingItems > 0) {
                    dispatch(setPendingConfirmationCount(`${pendingItems}/${totalItems}`))
                }
            }

            setSaveEnabled(false)
        }
        catch (e) {
            console.log(e)
        }
        setLoadingDetails(false)
    }



    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Gas Day',
            dataIndex: 'gasDay',
            key: 'gasDay',
            align: "center",
            render: (gasDay) => <div style={{ width: 90, display: "inline-block", position: "relative", height: 22 }}>
                <span style={{ position: "absolute", width: "100%", left: 0, zIndex: 1 }}>{dayjs(gasDay, "DD/MM/YYYY").format("DD MMM YYYY")}</span>
            </div>
        },
        {
            title: 'DCQ',
            dataIndex: 'dcq',
            key: 'dcq',
            align: "center",
            render: () => <div style={{ width: 100, display: "inline-block", position: "relative", height: 22 }}>
                <span style={{ position: "absolute", width: "100%", left: 0, zIndex: 1 }}>
                    {clientData?.dcq}
                </span>
            </div>
        },
        {
            title: <>Nominated Qty.<br />(MMBTU)</>,
            dataIndex: 'nominatedQty',
            align: "center",
            render: (nominatedQty) => <div style={{ minWidth: 150, display: "inline-block", }}>{nominatedQty}</div>
        },
        {
            title: <>Tentative Schedule.<br />Qty. (MMBTU)</>,
            dataIndex: 'confirmedQty',
            key: 'confirmedQty',
            editable: true,
            align: "center",
            render: (confirmedQty, record) => <div style={{ minWidth: 173, display: "inline-block" }}>{confirmedQty}</div>
        },
        {
            title: 'Source ID',
            dataIndex: 'sourceId',
            editable: true,
            align: "center",
            render: (sourceId, record) => {
                if(record?.nominationDetails?.length > 1){
                    return <div style={{ minWidth: 173, display: "inline-block", }}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
                } else {
                    return <div style={{ minWidth: 173, display: "inline-block", }}>{sourceId}</div>
                }
            }
        },
        {
            title: 'Pipeline ID',
            dataIndex: 'pipelineId',
            editable: true,
            align: "center",
            render: (pipelineId, record) => {
                if(record?.nominationDetails?.length > 1){
                    return <div style={{ minWidth: 173, display: "inline-block", }}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
                } else {
                    return <div style={{ minWidth: 173, display: "inline-block", }}>{pipelineId}</div>
                }
            }
        },
        {
            title: 'Delivery end point',
            dataIndex: 'deliveryEndPoint',
            editable: true,
            align: "center",
            render: (deliveryEndPoint:
                any
            , record: any) => {
                if(record?.nominationDetails?.length > 1){
                    return <div style={{minWidth: 173, display: "inline-block",}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
                } else {
                    return <Tooltip title={deliveryEndPoint}
                    ><Typography.Text style={{width: 140, display: "inline-block",}} ellipsis>
                                                    {deliveryEndPoint}
                                                </Typography.Text></Tooltip>
                }
            },
        },
        {
            title: 'Notification',
            dataIndex: 'notificationStatus',
            key: 'notificationStatus',
            render: (notificationStatus:
                NotificationStatus, record:any
            ) => {
                if(record?.nominationDetails?.length > 1){
                    return <div style={{width: 100, display: "inline-block",}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
                } else {
                    const displayValue = NotificationStatusDisplay[notificationStatus];
                    return <div style={{minWidth: 100, display: "inline-block",}}>
                    <Tag style={{ fontSize: 14 }} color={NotificationStatusColors[notificationStatus]}>{displayValue}</Tag>
                    </div>
                }
                
            },
            align: "center"
        },
        {
            title: 'Status',
            dataIndex: 'nominationStatus',
            key: 'nominationStatus',
            render: (nominationStatus:
                NominationStatus
            , record: any) => {
                if(record?.nominationDetails?.length > 1){
                    return <div style={{width: 100, display: "inline-block",}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
                } else {
                    const displayValue = NominationStatusDisplay[nominationStatus];
                    return <Tag style={{ fontSize: 14, textAlign: "center" }} color={nominationStatusColors[nominationStatus]}>{displayValue.split(" ")[0]}<br />{displayValue.split(" ")[1]}</Tag>
                }
            },
            align: "center"
        },
        {
            title: 'Last updated by',
            dataIndex: 'auditInfo',
            render: (auditInfo) => { return <div style={{minWidth: 120, display: "inline-block",}}>{!auditInfo?.updatedBy ? auditInfo?.createdBy : auditInfo?.updatedBy}</div> },
            align: "center"
        },
        {
            dataIndex: 'deliveryId',
            editable: true,
            align: "center",
            render:()=> null
        },
    ];

    const handleSave = (row: Item) => {
        // const existingDEIndex = data.find((item:any)=>item.gasDay === row.gasDay)?.nominationDetails?.findIndex((item:any)=>item.deliveryId === row.deliveryId && item.id !== row.id)
        // console.log(existingDEIndex, "existingDEIndex")
        // if (existingDEIndex && existingDEIndex !== -1) {
        //     setErrors((prevErr: any) => {
        //         return [...prevErr, { dataIndex: "deliveryEndPoint", id: row.id, error: "cannot be duplicate", gasDay: row.gasDay }];
        //     });
        // }  else {
        //     setErrors((prevErr: any) => {
        //         const newErr = prevErr.filter((err: any) => !(err.dataIndex === "deliveryEndPoint" && err.gasDay === row.gasDay));
        //         return newErr;
        //     });
        // }
        
        console.log(row, "row data")
        console.log(originalData, "originalData")
        // if (Number(row.confirmedQty) != (row.hasOwnProperty('nominationDetails') ? Number(data.filter(item => item.id === row.id)[0].confirmedQty) : Number(data.filter((i)=>i.gasDay === row.gasDay)[0].nominationDetails.filter((j:any)=>j.id === row.id)[0].confirmedQty))) {
        const editedIndex = editedItems.findIndex((x: any) => x.id === row.id)

        if (editedIndex != -1) {
            let newItems = [...editedItems]
            newItems[editedIndex] = row
            setEditedItems(newItems)
        } else {
            setEditedItems([...editedItems, row])
        }
        const newData = [...data];
        if (row.hasOwnProperty('nominationDetails')) {
            const index = newData.findIndex((item) => row.id === item.id);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            console.log("newData", newData)
            setData(newData);
        } else {
            const gasDayItem = newData.filter((i) => i.gasDay === row.gasDay)[0]
            const nominationDetails = gasDayItem.nominationDetails
            const updatedNominationDetails = nominationDetails.map((i: any) => {
                if (i.id === row.id) {
                    return row
                } else {
                    return i
                }
            })
            const updatedGasDayItem = { ...gasDayItem, nominationDetails: updatedNominationDetails }
            const index = newData.findIndex((item) => row.gasDay === item.gasDay);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...updatedGasDayItem,
            });
            console.log("newData", newData)
            setData(newData);
        }

        // }
    };

    useEffect(() => {
        let originalNomination = flatMap(originalData, (item:any)=>{
            return item.nominationDetails
        })

        originalNomination = originalNomination.map((item:any)=>{
            let newItem = {...item, confirmedQty: !item?.confirmedQty ? null : parseInt(item?.confirmedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId} 

            return newItem
        })

        let dataNomination = flatMap(data, (item:any)=>{
            return item.nominationDetails
        })

        dataNomination.map((item:any)=>{
            let newItem = {...item, confirmedQty: !item?.confirmedQty ? null : parseInt(item?.confirmedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId} 

            return newItem
        })
        const areArraysEqual = _isEqual(originalNomination, dataNomination);

        const newEditedItems = editedItems.filter((item:any)=>{
            const originalItem = originalNomination.find((i)=>i.id === item.id)
            let newItem = {...item, confirmedQty: !item?.confirmedQty ? null : parseInt(item?.confirmedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId}


            console.log(originalItem, newItem, "itemzz", (_isEqual(originalItem, newItem)))
            return !(_isEqual(originalItem, newItem))
        })

        if (areArraysEqual || errors.length > 0) {
            setSaveEnabled(false)
        } else {
            const emptyItems = newEditedItems.filter((item:any) => !item?.confirmedQty || item?.confirmedQty === "" )
            if(emptyItems.length>0){
                setSaveEnabled(false)
            } else {
                console.log("newEditedItems",newEditedItems)
                if(newEditedItems.length>0){
                    setSaveEnabled(true)
                } else {
                    setSaveEnabled(false)
                }
            }
        }
    }, [data])

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const exppandedCols = (record: Item, index: any, col:any, notifying?: boolean)  => {
        if ((col.dataIndex === "gasDay" || col.dataIndex === "dcq") && !(record.hasOwnProperty('nominationDetails')) && data.filter((i) => i.gasDay === record.gasDay)[0].nominationDetails?.[0].id === record.id) {
            return {
                rowSpan: data.filter((i) => i.gasDay === record.gasDay)[0].nominationDetails.length,
                className: col.dataIndex === "dcq" ? "child-border" : ""
            };
        } else if ((col.dataIndex === "gasDay" || col.dataIndex === "dcq") && !(record.hasOwnProperty('nominationDetails')) && data.filter((i) => i.gasDay === record.gasDay)[0].nominationDetails?.[0].id != record.id) {

            return {
                rowSpan: 0,
            };
        } else {
            return {
                rowSpan: 1
            };
        }

    }

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return {
                ...col,
                // onCell: (record: Item) => ({
                //     rowSpan: col.dataIndex === "gasDay" && !record.nominationDetails && data.filter((i)=>i.gasDay === record.gasDay)[0].nominationDetails[0].id === record.id ? data.filter((i)=>i.gasDay === record.gasDay)[0].nominationDetails.length : 1
                // }),
                onCell: (record: Item, index: any) => exppandedCols(record, index,col)
            }
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
                parentRecord: data,
                setErrors,
                errors,
                disableAll: openNotify,
                dcq: clientData?.dcq,
                contractId: clientData?.contractId,
                contract: contract,
                hidden: col.dataIndex === "deliveryId",
                setContract: setContract
            }),
        };
    });

    const updateConfirmedQty = async () => {
        Modal.destroyAll();

        let originalNomination = flatMap(originalData, (item:any)=>{
            return item.nominationDetails
        })

        originalNomination = originalNomination.map((item:any)=>{
            let newItem = {...item, confirmedQty: !item?.confirmedQty ? null : parseInt(item?.confirmedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId} 

            return newItem
        })

        const newEditedItems = editedItems.filter((item:any)=>{
            const originalItem = originalNomination.find((i)=>i.id === item.id)
            let newItem = {...item, confirmedQty: !item?.confirmedQty ? null : parseInt(item?.confirmedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId}


            console.log(originalItem, newItem, "itemzz", (_isEqual(originalItem, newItem)))
            return !(_isEqual(originalItem, newItem))
        })

        let itemsToCheck = originalNomination.filter((item:any)=>{
            return newEditedItems.find((i:any)=>{
                return i.gasDay === item.gasDay
            })
        })

        itemsToCheck = itemsToCheck.map((item:any) => {
            return newEditedItems.find((i:any) => i.id === item.id) ? newEditedItems.find((i:any) => i.id === item.id) : item
        })

        const aggregatedArr = Object.entries(itemsToCheck.reduce((acc:any, {gasDay, confirmedQty}:any) => {
            
                acc[gasDay] = acc[gasDay] ? acc[gasDay] + Number(confirmedQty) : Number(confirmedQty);
            
            return acc;
        }, {})).map(([gasDay, totalConfirmedQty]) => ({ gasDay, totalConfirmedQty }));

        console.log("aggregatedArr", aggregatedArr)
        
        if (aggregatedArr.some((item:any) => item.totalConfirmedQty > (clientData?.dcq*2)) && !auth.isClient) {
            // Notify the user about the error and exit the function
            Modal.confirm({
                // show success message along with image
                icon: null,
                width: 430,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 15 }}>
                        <img width={96} src="/assets/warning-icon.png" />
                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 10, marginTop: 20, fontSize: 16 }}>DCQ MMBTU Limit Exceeding</Typography.Text>

                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 20, color: "#707070" }}>Confirmed quantity for Gas day {aggregatedArr.filter((item:any) => item.totalConfirmedQty > (clientData?.dcq*2)).map((i:any, key:number)=> <b style={{color: "black"}}>{`${dayjs(i.gasDay, "DD/MM/YYYY").format("DD MMM YYYY")}${key<aggregatedArr.length-1 ? ", " : ""}`}</b>)} is exceeding DCQ of {clientData?.dcq} MMBTU by <span style={{color: "red"}}>200%</span>. Do you wish to continue?</Typography.Text>
                    </div>
                ),
                okText: 'Yes',
                cancelText: 'No, edit',
                onOk: async () => {
                    await finalUpdateConfirmedQty(newEditedItems, true)
                },
                onCancel: () => {
                    document?.getElementById(`nomination-entry-${aggregatedArr[0].gasDay}`)?.scrollIntoView({ behavior: 'auto' /*or smooth*/, block: 'center' });
                },
                okButtonProps: {
                    size: "large",
                    type: "primary",
                    style: {background: theme.token.colorPrimary, width: 140, fontWeight: 600}
                },
                cancelButtonProps: {
                    size: "large",
                    type: "default",
                    style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary}
                }
            });
        } else {
            finalUpdateConfirmedQty(newEditedItems)
        }

    }

    const finalUpdateConfirmedQty = async (newEditedItems:any, dontConfirm?: boolean) => {
        if(!dontConfirm){
            modal.confirm({
                icon: null,
                content: <div style={{ fontSize: 16, marginBottom: 50 }}>Do you want to save confirmation with changed quantity?</div>,
                okText: 'Yes',
                cancelText: 'No',
                onOk: async () => await updateNominationApi(newEditedItems),
                onCancel() {
                    console.log('Cancel');
                },
                centered: true,
                bodyStyle: {
                    textAlign: "center",
                    padding: 10
                },
                okButtonProps: {
                    size: "large",
                    style: { width: 140 }
                },
                cancelButtonProps: {
                    size: "large",
                    style: { width: 140 }
                }
            });
        } else {
            updateNominationApi(newEditedItems)
        }
    }
    

    const updateNominationApi = async (newEditedItems: any) => {
        setUpdating(true)
        let formData: {
            orders: any[]
        } = { orders: [] };
        formData.orders = newEditedItems.filter((i: any) => dayjs(i.confirmedQty && i.gasDay, "DD/MM/YYYY").isAfter(dayjs())).map((o: any) => Object.fromEntries(
            ["id", "confirmedQty", "gasDay", "deliveryEndPoint", "deliveryId", "sourceId", "pipelineId"].map((k: any) => [k, o[k]])
        ));
        console.log("formDataxxx", formData)

        try {
            await instance.put(`/orderservice/nominate/${id}/details/confirmation`, formData)
            fetchStats()
            showSuccess(<>Confirmation updated successfully!</>, modal)
            handleReset()
        } catch (error) {
            console.log(error)
            handleReset()
        }
        setUpdating(false)
    }

    const approveAll = () => {
        modal.confirm({
            icon: null,
            content: <div style={{ fontSize: 16, marginBottom: 50 }}>Do you want to approve Confirmed quantity for all dates?</div>,
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                let formData: {
                    orders: any[]
                } = { orders: [] };
                const newData = data.flatMap((i: any) => i.nominationDetails.map((j: any) => j));
                formData.orders = newData.filter((i) => dayjs(i.nominatedQty && i.gasDay, "DD/MM/YYYY").isAfter(dayjs()) && i.confirmedQty === null).map((o: any) => Object.fromEntries(
                    ["id", "confirmedQty", "gasDay", "deliveryEndPoint", "deliveryId", "sourceId", "pipelineId"].map((k: any) => {
                        if (k === 'confirmedQty') {
                            return [k, o.nominatedQty]
                        } else {
                            return [k, o[k]]
                        }
                    })
                ));
                try {
                    setApprovingAll(true)
                    await instance.put(`/orderservice/nominate/${id}/details/confirmation`, formData)
                    fetchStats()
                    showSuccess(<>Approved all successfully!</>, modal)
                    handleReset()
                } catch (error) {
                    console.log(error)
                }
                setApprovingAll(false)

            },
            onCancel() {
                console.log('Cancel');
            },
            centered: true,
            bodyStyle: {
                textAlign: "center",
                padding: 10
            },
            okButtonProps: {
                size: "large",
                style: { width: 140 }
            },
            cancelButtonProps: {
                size: "large",
                style: { width: 140 }
            }
        });

    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    // };

    const rowSelection = {
        getCheckboxProps: (record: any) => {
            console.log("record", record.scheduledQty)
            return {
                disabled: !record.confirmedQty ? true : false
            };
        },
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const notifyClients = async () => {
        modal.confirm({
            icon: null,
            content: <div style={{ fontSize: 16, marginBottom: 50 }}>Do you want to proceed and send Confirmation to the client?</div>,
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                const resultArray = selectedRowKeys.map((item:any) => item.replace(/\D/g, ''));
                const uniqueArr =  _.uniq(resultArray)

                setNotifyingAll(true)
                try {
                    await instance.post(`orderservice/nominate/details/notify?orderType=CONFIRMATION&ids=${uniqueArr.join(',')}`);
                    showSuccess(<>Thanks !!<br />
                        The confirmation has been sent to the client.</>, modal)
                    setOpenNotify(false)
                    setSelectedRowKeys([]);
                    handleReset()
                } catch (error) {
                    console.log(error)
                }
                setNotifyingAll(false)
            },
            onCancel() {
                console.log('Cancel');
            },
            centered: true,
            bodyStyle: {
                textAlign: "center",
                padding: 10
            },
            okButtonProps: {
                size: "large",
                style: { width: 140 }
            },
            cancelButtonProps: {
                size: "large",
                style: { width: 140 }
            }
        });
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <Breadcrumb separator=">" style={{ marginBottom: 0, cursor: "pointer" }}>
                <Breadcrumb.Item
                    onClick={_ => handleMenuItemClick(`/dashboard/confirmation`, dashboard, modal, navigate, messageApi, false, location, cancelled, setCancelled)}
                >
                    <span style={{ fontWeight: 600 }}>
                        Tentative Schedule
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ fontWeight: 600 }}>
                        View Details
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>
            {modalContex}
            {contextHolder}

            {!openNotify ? <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Space>
                    <Button onClick={approveAll} loading={approvingAll} disabled={updating || approvingAll || notifyingAll} type="primary" size='large'>Approve All</Button>
                    <Button onClick={() => { handleCancel(); setOpenNotify(true) }} loading={notifyingAll} disabled={updating || approvingAll || notifyingAll} type="primary" size='large'>Notify</Button>
                </Space>
                <Space>
                    <Button loading={updating} onClick={updateConfirmedQty} disabled={!saveEnabled || updating} type="primary" size="large">Save</Button>
                    {saveEnabled && <Button onClick={handleCancel} disabled={updating || approvingAll || notifyingAll} type="default" size="large">Cancel</Button>}
                </Space>
            </div>
                :
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <Space>
                        <Button loading={notifyingAll} disabled={!(selectedRowKeys.length > 0)} onClick={notifyClients} type="primary" size='large'>Send Email</Button>
                        <Button onClick={() => setOpenNotify(false)} type="default" size='large'>Cancel</Button>
                    </Space>
                </div>}
            <Card>
                {loadingClientData ? <Skeleton active /> : <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <div style={{display: "flex", alignItems: "center", gap: 5}}>
                            <Typography.Text type="secondary">Client :</Typography.Text>
                            <Typography.Title style={{ fontWeight: 500, margin: 0 }} level={3}>{clientData?.client}</Typography.Title>
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: 5, marginTop: 8}}>
                            <Typography.Text type="secondary">Client Code :</Typography.Text>
                            <Typography.Title style={{ fontWeight: 500, margin: 0 }} level={5}>{clientData?.clientCode}</Typography.Title>
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: 5, marginTop: 4}}>
                            <Typography.Text type="secondary">IGX Trade ID :</Typography.Text>
                            <Typography.Title style={{ fontWeight: 500, margin: 0 }} level={5}>{clientData?.tradeId}</Typography.Title>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Typography.Text style={{ fontSize: 16 }} type="secondary">Contract ID: </Typography.Text>
                            <Typography.Text style={{ fontSize: 16 }}>{clientData?.contractId}</Typography.Text>

                        </div>
                        <div>
                            <Typography.Text style={{ fontSize: 16 }} type="secondary">Status: </Typography.Text>
                            <Typography.Text style={{ fontSize: 16 }}>{NominationStatusDisplay[clientData?.nominationStatus as NominationStatus]}</Typography.Text>
                        </div>
                    </div>
                </div>}
            </Card>
            <Table
                loading={loadingDetails}
                components={components}
                rowClassName={(record) => `table-row ${record.hasOwnProperty('nominationDetails') ? "parent-row" : "child-row"} ${record.nominationDetails?.length > 1 ? "mg-expandable-row" : ""}`}
                rowSelection={openNotify ? { ...rowSelection, checkStrictly: false } : undefined}
                columns={openNotify ? columns.map((i: any) => { return { ...i, onCell: (record: Item, index: any) => exppandedCols(record, index,i, true) } }) : columns as ColumnTypes}
                dataSource={data.map((item: any) => {
                    return { ...item, nominationDetails: item.nominationDetails?.length > 1 ? item.nominationDetails : null }
                })
                }
                pagination={false}
                rowKey={(record) => { return record.hasOwnProperty('nominationDetails') ? record.id + "parent" : record.id + "child" }}
                scroll={{ x: true, y: "55vh" }}
                expandable={{
                    childrenColumnName: "nominationDetails",
                    rowExpandable: (record) => record.nominationDetails.length > 1,
                    indentSize: 0,
                    expandIcon: () => null,
                    onExpand: (expanded, record) => {
                        const rowItem = document.querySelectorAll(`[data-row-key="${record.id}parent"]`);
                        // add class name to rowItem
                        if (rowItem.length > 0) {
                            // Iterate through each rowItem and add the class name
                            console.log("rowItem", rowItem)
                            rowItem.forEach(item => {
                                console.log("expanded", expanded)
                                if (expanded) {
                                    item.classList.add("mg-expandabled-row")
                                } else {
                                    item.classList.remove("mg-expandabled-row")
                                }
                            });
                        }

                    },
                    expandRowByClick: true,
                }}
            />
        </div>
    )

};

export default ConfirmationDetails;