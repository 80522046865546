import { Button, Col, Divider, Form, Input, Row, Skeleton, Space, message } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';

type FieldType = {
    email?: string;
    name?: string;
    mobileNo?: number;
    telephoneNo?: number;
    registeredOfficeAddressId: number | null;
    registeredOfficeAddressLine1: string;
    registeredOfficeAddressLine2: string;
    registeredOfficeAddressCity: string;
    registeredOfficeAddressState: string;
    registeredOfficeAddressPinCode: string;
    plantOfficeAddressId: number | null;
    registeredOfficeCountry: string;
    plantOfficeAddressLine1: string;
    plantOfficeAddressLine2: string;
    plantOfficeAddressCity: string;
    plantOfficeAddressState: string;
    plantOfficeAddressPinCode: string;
    plantOfficeCountry: string;
};

type PropType = { clientData: any | undefined, setClientData: Dispatch<any>, getClientData: any, fetchingDetails: boolean, setDefaulttab: Dispatch<SetStateAction<any | null>> }

function ClientDetails({ clientData, fetchingDetails, setDefaulttab, getClientData }: PropType) {
    let { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [enableReset, setEnableReset] = useState(false)
    const [enableEdit, setEnableEdit] = useState(!id ? true : false)

    const navigate = useNavigate()
    const [form] = Form.useForm();

    const createClient = async (values: any) => {
        setLoading(true);
        try {
            values.name = values.name.replace(/\s+/g, ' ').trim();
            let formData = {
                "email": values.email,
                "name": values.name,
                "mobileNo": values.mobileNo,
                "telephoneNo": values.telephoneNo,
                "addressDtoList": [
                  {
                    "id": null,
                    "addressLine1": values.registeredOfficeAddressLine1,
                    "addressLine2": values.registeredOfficeAddressLine2,
                    "city": values.registeredOfficeAddressCity,
                    "state": values.registeredOfficeAddressState,
                    "pinCode": values.registeredOfficeAddressPinCode,
                    "country": values.registeredOfficeCountry,
                    "addressType": "REGISTERED_OFFICE_ADDRESS",
                  },
                  {
                    "id": null,
                    "addressLine1": values.plantOfficeAddressLine1,
                    "addressLine2": values.plantOfficeAddressLine2,
                    "city": values.plantOfficeAddressCity,
                    "state": values.plantOfficeAddressState,
                    "pinCode": values.plantOfficeAddressPinCode,
                    "country": values.plantOfficeCountry,
                    "addressType": "PLANT_OFFICE_ADDRESS"
                  }
                ]
              }
            const res = await instance.post("clientservice/clients", formData)
            message.success("client created successfully")
            setEnableReset(false)
            setEnableEdit(false)
            if (res.data.id) {
                navigate(`/dashboard/client-details/${res.data.id}?tab=client-details-2`)
            }
            // router("/dashboard/create-client/1", {replace: true})
        } catch (e) {
            console.log(e)
        }
        setLoading(false);
    };

    const updateClient = async (values: any) => {
        setLoading(true);
        try {
            values.name = values.name.replace(/\s+/g, ' ').trim();
            let formData = {
                "email": values.email,
                "name": values.name,
                "mobileNo": values.mobileNo,
                "telephoneNo": values.telephoneNo,
                "addressDtoList": [
                  {
                    "id": values.registeredOfficeAddressId ? values.registeredOfficeAddressId : null,
                    "addressLine1": values.registeredOfficeAddressLine1,
                    "addressLine2": values.registeredOfficeAddressLine2,
                    "city": values.registeredOfficeAddressCity,
                    "state": values.registeredOfficeAddressState,
                    "pinCode": values.registeredOfficeAddressPinCode,
                    "country": values.registeredOfficeCountry,
                    "addressType": "REGISTERED_OFFICE_ADDRESS",
                  },
                  {
                    "id": values.plantOfficeAddressId ? values.plantOfficeAddressId : null,
                    "addressLine1": values.plantOfficeAddressLine1,
                    "addressLine2": values.plantOfficeAddressLine2,
                    "city": values.plantOfficeAddressCity,
                    "state": values.plantOfficeAddressState,
                    "pinCode": values.plantOfficeAddressPinCode,
                    "country": values.plantOfficeCountry,
                    "addressType": "PLANT_OFFICE_ADDRESS"
                  }
                ]
              }
            await instance.put(`clientservice/clients/${id}`, formData)
            message.success("client updated successfully")
            getClientData()
            setEnableReset(false)
            setEnableEdit(false)
        } catch (e) {
            console.log(e)
        }
        setLoading(false);
    };

    useEffect(() => {

        const registeredOffice = clientData?.addressList?.find((item:any)=>{
            return item.addressType === "REGISTERED_OFFICE_ADDRESS"
        })

        const plantOffice = clientData?.addressList?.find((item:any)=>{
            return item.addressType === "PLANT_OFFICE_ADDRESS"
        })

        console.log("registeredOffice",registeredOffice, "plantOffice", plantOffice)


        form.setFieldsValue({
            "email": clientData?.email,
            "name": clientData?.name,
            "mobileNo": clientData?.mobileNo,
            "telephoneNo": clientData?.telephoneNo,
            "registeredOfficeAddressId": registeredOffice?.id ? registeredOffice?.id : null,
            "registeredOfficeAddressLine1": registeredOffice?.addressLine1,
            "registeredOfficeAddressLine2": registeredOffice?.addressLine2,
            "registeredOfficeAddressCity": registeredOffice?.city,
            "registeredOfficeAddressState": registeredOffice?.state,
            "registeredOfficeAddressPinCode": registeredOffice?.pinCode,
            "registeredOfficeCountry": registeredOffice?.country,
            "plantOfficeAddressId": plantOffice?.id ? plantOffice?.id : null,
            "plantOfficeAddressLine1": plantOffice?.addressLine1,
            "plantOfficeAddressLine2": plantOffice?.addressLine2,
            "plantOfficeAddressCity": plantOffice?.city,
            "plantOfficeAddressState": plantOffice?.state,
            "plantOfficeAddressPinCode": plantOffice?.pinCode,
            "plantOfficeCountry": plantOffice?.country,

          })
    }, [clientData])

    const setFormValues = () => {
        form.setFieldsValue({
            email: clientData?.email,
            name: clientData?.name,
        })
        setEnableReset(false)
    }

    const handleFormChange = () => {
        // const field = e.target.getAttribute('id');
        // const name = field.split("_")[1]
        const originalData: any = {
            email: clientData?.email,
            name: clientData?.name,
        }
        const obj1String = JSON.stringify(originalData);
        const obj2String = JSON.stringify(form.getFieldsValue());


        if (obj1String === obj2String) {
            setEnableReset(false)

        } else {
            setEnableReset(true)
        }

    }

    return (
        <React.Fragment>
            {!fetchingDetails ? <Form
                onChange={handleFormChange}
                form={form}
                disabled={loading}
                name="client-details"
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={!id ? createClient : updateClient}
                autoComplete="off"
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input client email!' }]}
                        >
                            <Input type='email' disabled={id || !enableEdit ? true : false} size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    
                    
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="mobileNo"
                            name="mobileNo"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="telephoneNo"
                            name="telephoneNo"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold" }} orientation="left">Registered Office Address</Divider>
                        </div>
                    </Col>
                    <Col style={{display: "none"}} span={12}>
                        <Form.Item<FieldType>
                            label="reg id"
                            name="registeredOfficeAddressId"
                            rules={[{ required: false, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Address Line 1"
                            name="registeredOfficeAddressLine1"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Address Line 2"
                            name="registeredOfficeAddressLine2"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            
                            label="City"
                            name="registeredOfficeAddressCity"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="State"
                            name="registeredOfficeAddressState"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Pin-Code"
                            name="registeredOfficeAddressPinCode"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Country"
                            name="registeredOfficeCountry"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold" }} orientation="left">Plant Office Address</Divider>
                        </div>
                    </Col>
                    <Col style={{display: "none"}} span={12}>
                        <Form.Item<FieldType>
                            label="plant id"
                            name="plantOfficeAddressId"
                            rules={[{ required: false, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Address Line 1"
                            name="plantOfficeAddressLine1"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Address Line 2"
                            name="plantOfficeAddressLine2"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="City"
                            name="plantOfficeAddressCity"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="State"
                            name="plantOfficeAddressState"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Pin-Code"
                            name="plantOfficeAddressPinCode"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<FieldType>
                            label="Country"
                            name="plantOfficeCountry"
                            rules={[{ required: true, message: 'Please input client name!' }]}
                        >
                            <Input disabled={!enableEdit} size="large" />

                        </Form.Item>
                    </Col>
                </Row>


                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Form.Item>
                        {!enableEdit ? <Space size="large">
                            {/* {!id && <Button style={{ width: 200 }} disabled={id ? !(id && enableReset) : false} size='large' loading={loading} type="primary" htmlType="submit">
                                {id ? "Update" : "Create"}
                            </Button>} */}
                            {id && <Button style={{ width: 200 }} size="large" onClick={() => setEnableEdit(true)}>
                                Edit
                            </Button>}
                            {id && <Button onClick={setDefaulttab} style={{ width: 200 }} size='large' type='primary'>Next</Button>}

                        </Space> : <Space size="large">
                            <Button style={{ width: 200 }} size='large' disabled={loading} onClick={() => { setFormValues(); setEnableEdit(false) }} type="default">Cancel</Button>
                            <Button style={{ width: 200 }} disabled={id ? !(id && enableReset) : false} type="primary" htmlType="submit" loading={loading} size='large'>Save{!id && ` & Next`}</Button>
                        </Space>}
                    </Form.Item>
                </div>

            </Form> : <>
                <Skeleton />
            </>}
        </React.Fragment>
    )

};

export default ClientDetails;