import { useEffect } from 'react';

const useNavigationWithUnfinishedTasks = (checkUnfinishedTasks:boolean) => {

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (checkUnfinishedTasks) {
        const message = 'You have unfinished tasks. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [checkUnfinishedTasks]);

};

export default useNavigationWithUnfinishedTasks;
