import { Breadcrumb, Card, Space, Table, Typography } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPaginationCountApi } from "../../../utils/common";
import theme from "../../../utils/theme";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import { columns } from "../../../interfaces/TableColumns/NominationHistoryDetails";
import { TableDataType } from "../../../interfaces/TableData";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import FilterSelect from "../../../components/FilterSelect";
import { Item } from "../../../interfaces/TableColumns/Confirmation";

export interface DataType extends TableDataType {
    items: Item[] | [];
}

export default function NominationHistoryDetails() {

    let { id } = useParams();
    let { state } = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState<any | null>(null);

    const { tableParams, onFilerSearch, handleSort, onSearch } = useTableFilters("auditInfo.creationDate|DESC", '', true ,false,true);
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState(null)

    useEffect(() => {
        fetchData();
    }, [tableParams])

    const fetchData = async () => {
        const params = {
            ...tableParams,
            // filter and contract endDate is less than current date
            filterAnd: `clientId|eq|${id}` + `${tableParams.filterAnd ? `&${tableParams.filterAnd}` : ""}`
        }
        setLoading(true)
        try {
            const res = await instance.get("orderservice/nominate", { params })
            setData(res.data);
            if(!options){
                setOptions(res?.data?.items)
            }
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setLoading(false)
    };


    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <Breadcrumb separator=">" style={{ marginBottom: 0, cursor: "pointer" }}>
                <Breadcrumb.Item
                    onClick={_ => navigate(`/dashboard/nomination-history`)}
                >
                    <span style={{ fontWeight: 600 }}>
                        Nomination History
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ fontWeight: 600}}>
                        {state?.name} - View Details
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "baseline" }}>
                    
                    <Space>
                        <FilterSelect onFilerSearch={onFilerSearch} options={options} onSearch={onSearch} confirmationList={true} />
                        {/* <Button 
                            onClick={()=>navigate(`/dashboard/client-details/${id}?tab=client-details-4`)} 
                            size="large" 
                            type="primary">
                                <PlusOutlined />
                                New Contract
                        </Button> */}
                    </Space>
                </div>

            <Card>
                <div>
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        <Typography.Title style={{ color: theme.token.colorPrimary, margin: 0 }} level={3}>{state?.name}</Typography.Title>
                    </div>
                </div>
                
            </Card>

            {id && <Table
                loading={loading}
                onChange={handleSort}
                rowClassName="table-row"
                columns={columns}
                dataSource={data?.items}
                pagination={{
                    pageSize: tableParams.size,
                    total: data?.totalItems,
                    current: tableParams.page,
                    showSizeChanger: false,
                    position: ["bottomCenter"]
                }}
                footer={()=><div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                rowKey={(record) => record.id}
                scroll={{ x: true, y: "65vh" }}
            />}


        </div>
    )
}