import { Navigate } from "react-router-dom";

export type ProtectedRouteProps = {
    isAuthenticated: boolean;
    outlet: JSX.Element;
    canAccess: boolean
  };
  
export default function ProtectedRoute({isAuthenticated, canAccess, outlet}: ProtectedRouteProps) {
    if(isAuthenticated) {
        if(canAccess){
            return outlet;
        } else {
            return <Navigate to={{ pathname: "/dashboard/404" }} />;
        }
    } else {
        return <Navigate to={{ pathname: "/" }} />;
    }
};