import {Button, Typography} from "antd";
import { useNavigate } from "react-router-dom";

export default function EmptyEnquiry() {

    const nav = useNavigate()
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 40 }}>
            <img width={312} height={312} src={`${__dirname}assets/contract-signing.png`} alt="" />
            <Typography.Text style={{fontSize: 20, fontWeight: 500}}>To Process further please fill raise an enquiry</Typography.Text>
            <Button onClick={()=>nav("/dashboard/create-enquiry")} size="large" type="primary" style={{width: 312}}>Proceed</Button>
        </div>
    )
}