import { Tag, Tooltip, Typography } from "antd";
import { ColumnTypes } from "./NominationDetail";
import { Link } from "react-router-dom";

export const clientColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
        title: 'Client',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => <Tooltip title={name}><Typography.Text ellipsis style={{ width: 100 }}>
            {name}</Typography.Text >
        </Tooltip>,
        align: "center"
    },
    {
        title: "Client Code",
        dataIndex: "clientCode",
        key: "clientCode",
        render: (clientCode) => <div style={{ display: "inline-block"}}>{clientCode}</div>,
        align: "center"
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        align: "center"

    },
    {
        title: 'Status',
        dataIndex: 'registrationStatus',
        key: 'registrationStatus',
        align: "center",
        render: (registrationStatus, record) => {
            if(record.registrationStatusWithUpdate == "ACTION_REQUIRED"){
                return <Tag style={{ fontSize: 14, textAlign: "center" }} color="#fa8c16">ACTION REQUIRED</Tag>
            }
            else if (registrationStatus === "ADMIN_ONBOARDING_COMPLETED") {
                return <Tag style={{ fontSize: 14, textAlign: "center" }} color="pink">ONBOARDING COMPLETED</Tag>
            } else if (registrationStatus === "KYC_PENDING") {
                return <Tag style={{ fontSize: 14, textAlign: "center" }} color="blue">KYC PENDING</Tag>
            } else if (registrationStatus === "SIGNED_DOCUMENT_PENDING") {
                return <Tag style={{ fontSize: 14, textAlign: "center" }} color="orange">SIGNED DOCUMENT PENDING</Tag>
            } else if (registrationStatus === "SIGNED_DOCUMENT_PENDING_ADMIN") {
                return <Tag style={{ fontSize: 14, textAlign: "center" }} color="orange">SIGNED DOCUMENT PENDING ADMIN</Tag>
            } else if (registrationStatus === "APPROVAL_PENDING") {
                return <Tag style={{ fontSize: 14, textAlign: "center" }} color="green">APPROVAL PENDING</Tag>
            } else if (registrationStatus === "ADMIN_ONBOARDING") {
                return <Tag style={{ fontSize: 14, textAlign: "center" }} color="yellow">ADMIN ONBOARDING</Tag>
            } 
            else {
                return <Tag style={{ fontSize: 14, textAlign: "center" }} color="cyan">{registrationStatus}</Tag>
            }
        },
        filters: [
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color="#fa8c16">ACTION REQUIRED</Tag>,
                value: "ACTION_REQUIRED",
            },
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color="pink">ONBOARDING COMPLETED</Tag>,
                value: "ADMIN_ONBOARDING_COMPLETED",
            },
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color="blue">KYC PENDING</Tag>,
                value: "KYC_PENDING",
            },
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color="orange">SIGNED DOCUMENT PENDING</Tag>,
                value: "SIGNED_DOCUMENT_PENDING",
            },
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color="orange">SIGNED DOCUMENT PENDING ADMIN</Tag>,
                value: "SIGNED_DOCUMENT_PENDING_ADMIN",
            },
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color="green">APPROVAL PENDING</Tag>,
                value: "APPROVAL_PENDING",
            },
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color="cyan">APPROVED</Tag>,
                value: "APPROVED",
            },
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color="yellow">ADMIN ONBOARDING</Tag>,
                value: "ADMIN_ONBOARDING",
            },
        ],
        onFilter: (value, record) => {
            if (value === "ACTION_REQUIRED") {
                return record.registrationStatusWithUpdate === value;
            }
            return value === record.registrationStatus;
        }
    },

    {
        title: 'Action',
        dataIndex: 'id',
        key: "id",
        render: (id) => <Link
            style={{ textDecoration: "underline" }}
            state={{
                fromRegistration: true
            }}
            to={`/dashboard/profile/${id}`}>View Details
        </Link>,
        align: "center"
    }
];