import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import { Button, Col, Divider, Form, Input, Modal, Row, Space, Typography, Upload, message } from 'antd';
import theme from '../../../utils/theme';
import { CloudUploadOutlined, UploadOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import { Link } from 'react-router-dom';
import BankUpdateModal from './BankUpdateModal';
import UpdateStatusLabel from './UpdateStatusLabel';
import ViewPDF from '../../../components/ViewPDF';
import { beforeUpload } from '../../../utils/common';
import _ from 'lodash';

function BankDetails({ id, clientData, bankDetails, setDefaulttab, getClientData, approveOrReject, updateStatus, setInProgrss, tabStates }: { id: any | undefined, bankDetails: any, setDefaulttab: Dispatch<SetStateAction<any | null>>, getClientData: any, clientData: any, updateStatus: string, approveOrReject: any, setInProgrss?: any, tabStates?: any }) {
    const [form] = Form.useForm()
    const [editing, setEditing] = useState(false)
    const auth = useAppSelector(authSelector);
    const [modal, modalContex] = Modal.useModal();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [enableReset, setEnableReset] = useState(false);
    const [submitting, setSubmitting] = useState(false)




    const onCreate = async (values: any) => {
        setSubmitting(true)
        const formData = new FormData();

        formData.append("file", values["file"]);
        console.log("files", values["file"])
        
        // if(!values["file"]){
        //     setSubmitting(false)
        //     form.setFields([
        //         {
        //             name: `file`,
        //             errors: [`Please upload the updated Cancelled Cheque`]
        //         },
        //     ])
        //     return
        // }

        const temp = {
            "clientId": id,
            "accountNumber": values.accountNumber,
            "accountName": values.accountName,
            "bankName": values.bankName,
            "branchName": values.branchName,
            "ifscCode": values.ifscCode
        }

        const originalData: any = {
            "clientId": id,
            "accountName": bankDetails[0]?.accountName,
            "accountNumber": bankDetails[0]?.accountNumber,
            "bankName": bankDetails[0]?.bankName,
            "branchName": bankDetails[0]?.branchName,
            "ifscCode": bankDetails[0]?.ifscCode,
        }

        const diff = _.reduce(temp, (result: any, value, key) => {
            if (!_.isEqual(value, originalData[key])) {
                result[key] = value;
            }
            return result;
        }, {});


        try {
            if (clientData?.registrationStatus == "ADMIN_ONBOARDING_COMPLETED" || clientData?.registrationStatus == "APPROVED") {

                Modal.confirm({
                    title: <div>
                        Are you sure you want to submit these changes?
                    </div>,
                    // content: 'Once deleted changes cannot be reverted',
                    okText: 'Yes',
                    okType: 'danger',
                    centered: true,
                    cancelText: 'No',
                    icon: null,
                    onOk: async () => {
                        diff.clientId = id;
                        formData.append('details', new Blob([JSON.stringify(bankDetails.length > 0 ? { ...diff, id: bankDetails?.[0]?.id } : diff)], { type: 'application/json' }));
                        try {
                            await instance.put(`clientservice/clients/bank/edit-profile`, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            message.success("Bank details update request created successfully!")
                            getClientData()
                            setEditing(false)
                            setDefaulttab("client-details-4")
                        } catch (e) {
                            console.log(e)
                        }

                    },
                    bodyStyle: {
                        textAlign: "center",
                    },
                    okButtonProps: {
                        size: "large",
                        type: "primary",
                        style: { background: theme.token.colorPrimary, width: 140, fontWeight: 600 }
                    },
                    cancelButtonProps: {
                        size: "large",
                        type: "default",
                        style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary }
                    }
                })

            } else {
                if (bankDetails.length == 0) {
                    formData.append('details', new Blob([JSON.stringify(bankDetails.length > 0 ? { ...temp, id: bankDetails?.[0]?.id } : temp)], { type: 'application/json' }));
                    try {
                        await instance.post(`clientservice/clients/bank-details`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        message.success("Bank details created successfully!");
                        getClientData()
                        setEditing(false)
                        setDefaulttab("client-details-4")
                    } catch(e){
                        console.log(e)
                    }
                    
                } else {
                    Modal.confirm({
                        title: <div>
                            Are you sure you want to submit these changes?
                        </div>,
                        // content: 'Once deleted changes cannot be reverted',
                        okText: 'Yes',
                        okType: 'danger',
                        centered: true,
                        cancelText: 'No',
                        icon: null,
                        onOk: async () => {
                            try{
                                formData.append('details', new Blob([JSON.stringify(bankDetails.length > 0 ? { ...temp, id: bankDetails?.[0]?.id } : temp)], { type: 'application/json' }));
                                await instance.post(`clientservice/clients/bank-details`, formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                })
                                message.success("Bank details updated successfully!");
                                getClientData()
                                setEditing(false)
                                setDefaulttab("client-details-4")
                            } catch(e){
                                console.log(e)
                            }
                            
                        },
                        bodyStyle: {
                            textAlign: "center",
                        },
                        okButtonProps: {
                            size: "large",
                            type: "primary",
                            style: { background: theme.token.colorPrimary, width: 140, fontWeight: 600 }
                        },
                        cancelButtonProps: {
                            size: "large",
                            type: "default",
                            style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary }
                        }
                    })
    
                }
            }
            // document?.querySelector(`#contract${res.data.id}`)?.scrollIntoView();
        } catch (e) {
            console.log(e)
        }
        setSubmitting(false)
    }

    useEffect(() => {
        if (setInProgrss) {
            if (!tabStates?.isBankDetailsCompleted) {
                setInProgrss(false)
            } else {
                setInProgrss(true)
            }
        }
        setFormValues()
    }, [bankDetails])

    useEffect(() => {
        if (setInProgrss) {
            if (!bankDetails[0]) {
                setInProgrss(true)
            } else {
                setInProgrss(editing)
            }
        }

    }, [editing, bankDetails])

    const setFormValues = () => {
        form.setFieldsValue({
            "accountName": bankDetails[0]?.accountName,
            "accountNumber": bankDetails[0]?.accountNumber,
            "bankName": bankDetails[0]?.bankName,
            "branchName": bankDetails[0]?.branchName,
            "ifscCode": bankDetails[0]?.ifscCode,
        })
    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleFormChange = () => {

        let originalData: any = {
            "accountName": bankDetails[0]?.accountName,
            "accountNumber": bankDetails[0]?.accountNumber,
            "bankName": bankDetails[0]?.bankName,
            "branchName": bankDetails[0]?.branchName,
            "ifscCode": bankDetails[0]?.ifscCode,
        }

        const obj1String = JSON.stringify(originalData);
        const obj2String = JSON.stringify(form.getFieldsValue());

        console.log("obj2String",form.getFieldsValue())


        if (obj1String === obj2String) {
            setEnableReset(false)

        } else {
            setEnableReset(true)
        }

    }



    return (
        <React.Fragment>
            <UpdateStatusLabel label="Bank Account" showModal={() => setIsModalOpen(true)} updateStatus={updateStatus} />

            <Form
                scrollToFirstError
                disabled={!editing && bankDetails.length > 0}
                onChange={() => setTimeout(() => { handleFormChange(); }, 500)}
                onFinish={onCreate}
                name={"bank-details"}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                form={form}
                encType='multipart/form-data'
                // onChange={() => setEnableReset(true)}
            >

                <Row gutter={24}>
                    <Col span={24}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold", color: theme.token.colorPrimary }} orientation="left">Bank Account details</Divider>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="Account Name"
                                        name="accountName"
                                        rules={[{
                                            required: true, validator: async (_, value) => {
                                                if (!(/^[a-zA-Z ]+$/.test(value)) || !value?.trim()) {
                                                    return Promise.reject(new Error('Enter a valid name'));
                                                }

                                            }
                                        }]} >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="Account Number"
                                        name="accountNumber"
                                        rules={[{
                                            validator: async (_, value) => {
                                                if (!value) {
                                                    return Promise.reject(new Error("Please enter Account Number"));
                                                }
                                                if (isNaN(value) || parseInt(value) < 0 || value.includes('.')) {
                                                    return Promise.reject(new Error('only numbers allowed'));
                                                }
                                            }
                                        }]}
                                    >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                            </Row>


                        </div>
                    </Col>

                    <Col span={24}>
                        <div style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="Bank Name"
                                        name="bankName"
                                        rules={[{ required: true }]} >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="Branch Name"
                                        name="branchName"
                                        rules={[{ required: true }]} >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                            </Row>


                        </div>
                    </Col>

                    <Col span={24}>
                        <div style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="IFSC Code"
                                        name="ifscCode"
                                        rules={[{ required: true }]} >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col span={24}>
                        <div style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }}>
                            <Row gutter={24}>
                                <Col span={16} style={{ display: "flex", alignItems: "center" }}>
                                    Cancelled Cheque <span style={{ color: "red", fontSize: 10 }}>&nbsp;*</span>
                                </Col>
                                <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                                    <div>
                                        {!editing && bankDetails.length > 0 ?

                                            <>
                                                <div className='hide-me'>
                                                    <ViewPDF name="Cancelled Cheque" url={bankDetails[0]?.cancelledCheque} />
                                                </div>
                                                <div style={{ display: "none", marginLeft: -40 }} className='show-me'>{bankDetails[0]?.cancelledCheque}</div>
                                            </>
                                            : <><Form.Item
                                                style={{ margin: 0 }}
                                                name="file"
                                                getValueFromEvent={({ file }) => { return file.originFileObj }}
                                            >

                                                <Upload
                                                    name='file'
                                                    accept="application/pdf"
                                                    maxCount={1}
                                                    listType="text"
                                                    customRequest={dummyRequest}
                                                    beforeUpload={(file: any) => beforeUpload(file, () => form.resetFields(["file"]))}
                                                >
                                                    <Button style={{ display: "flex", alignItems: "center", height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Cancelled Cheque<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                                </Upload>


                                            </Form.Item>

                                                {bankDetails[0]?.cancelledCheque &&
                                                    <div style={{ width: 200, marginTop: 8 }}>
                                                        <Link target="_blank" to={bankDetails[0]?.cancelledCheque}>
                                                            <Typography.Text style={{ color: theme.token.colorPrimary }} ellipsis>{bankDetails[0]?.cancelledCheque}</Typography.Text>
                                                        </Link>
                                                    </div>
                                                }
                                            </>}
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Form>
            <div className="hide-me" style={{ display: "flex", justifyContent: "center", marginTop: 36 }}>
                {bankDetails.length > 0 && !editing ? <Space size="large">
                    {((auth.isClient && clientData?.bankUpdateStatus !== "UPDATED_BY_ADMIN") || ((auth.isAdmin || auth.isMainAdmin) && clientData?.bankUpdateStatus !== "UPDATED")) && <Button onClick={() => setEditing(true)} style={{ width: 200 }} size="large">Edit</Button>}


                    <Button type="primary" style={{ width: 200 }} size='large' onClick={() => setDefaulttab("client-details-4")}>Next</Button>

                </Space> : <Space size="large">
                    {<Button onClick={() => { setFormValues(); setEditing(false); setEnableReset(false) }} style={{ width: 200 }} size='large' type="default">
                        Cancel
                    </Button>}
                    <Button disabled={!enableReset} loading={submitting} style={{ width: 200 }} onClick={() => form.submit()} size='large' type="primary" htmlType="submit">
                        Save & Next
                    </Button>

                </Space>}
            </div>
            {modalContex}

            <Modal destroyOnClose width={869} footer={null} style={{ textAlign: "center" }} bodyStyle={{ paddingBottom: 16 }} title={
                <div style={{ marginTop: 16, color: theme.token.colorPrimary }}>
                    {(clientData?.bankUpdateStatus === "UPDATED_BY_ADMIN" && (auth.isClient)) && "Admin has edited your Bank details, please review the information."}
                    {(clientData?.bankUpdateStatus === "UPDATED_BY_ADMIN" && (auth.isAdmin || auth.isMainAdmin)) && "Bank Account details has been updated."}


                    {(clientData?.bankUpdateStatus === "UPDATED" && (auth.isClient)) && "Bank Account details has been updated."}
                    {(clientData?.bankUpdateStatus === "UPDATED" && (auth.isAdmin || auth.isMainAdmin)) && "Client has edited your Bank details, please review the information."}



                </div>
            } open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>
                <BankUpdateModal approveOrReject={(type: any) => { approveOrReject(type); setIsModalOpen(false) }} clientData={clientData} />
            </Modal>
        </React.Fragment>
    )

};

export default BankDetails;