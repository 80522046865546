import { Divider, Row, Col, Input, InputNumber, Button, Form, message, Space, Modal } from "antd";
import { Dispatch, useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import theme from "../../../utils/theme";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { stopScroll } from "../../../utils/common";

type FieldType = any;

function KycForm({ id, data, setPocDetails, setDefaulttab }: { id: string | undefined, data?: any, itemKey?: number, setPocDetails: Dispatch<any>, setDefaulttab: Dispatch<any> }) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [modal, modalContex] = Modal.useModal();
    const [enableReset, setEnableReset] = useState(false)
    const [editing, setEditing] = useState(data.length < 2)
    const [addingNewPoc, setAddingNewPoc] = useState(false)
    const [enableSave, setEnableSave] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const updatePoc = async (values: any) => {
        try {
            setLoading(true)
            const res = await instance.post(`clientservice/clients/${id}/poc`, values)
            const formValues = form.getFieldValue("createPocDtoList")

            let arrIds = res.data.ids.filter((id: number) => !formValues.some((item:any) => item.id === id)).reverse();
            console.log(arrIds, "arrIds")
            const updatedData = formValues.map((item: any) => {
                if (!item.id) {
                    // If not, assign an id from arrIds and remove it from arrIds
                    item.id = arrIds.pop();
                }
                return item;
            })
            console.log(updatedData, "updatedData")


            // If you want to update the state with the modified data, use setData
            setPocDetails(updatedData);
            // form.setFieldsValue(
            //     {
            //         email: res.data.email,
            //         name: res.data.name,
            //         state: res.data.state,
            //         designation: res.data.designation,
            //         phone: res.data.phone
            //     }
            // )
            message.success("client poc updated successfully!")
            setEnableReset(false)
            setEditing(false);
            setEnableSave(false)
            setAddingNewPoc(false)
        } catch (e: any) {
            console.log(e);
        }
        setLoading(false);
    };

    const setFormValues = () => {
        setEnableReset(false)
        form.setFieldsValue(data.length < 2 ? {
            createPocDtoList: [{
                id: data[0]?.id,
                email: data[0]?.email,
                name: data[0]?.name,
                state: data[0]?.state,
                designation: data[0]?.designation,
                phone: data[0]?.phone
            },
            // {
            //     id: data[1]?.id,
            //     email: data[1]?.email,
            //     name: data[1]?.name,
            //     state: data[1]?.state,
            //     designation: data[1]?.designation,
            //     phone: data[1]?.phone
            // }
            ]
        } : {
            createPocDtoList: data
        })
    }

    const deletPoc = (deletePocId: number) => {
        modal.confirm({
            title: <>Are you sure to delete?<br/> Changes cannot be reverted.</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            icon: null,
            onOk: async () => {
                try {
                    setDeleting(true)
                    await instance.delete(`clientservice/clients/poc/${deletePocId}`)
                    message.success("client poc deleted successfully!")
                    setPocDetails((prev: any) => prev.filter((item: any) => item.id != deletePocId))
                } catch (e: any) {
                    console.log(e);
                }
                setDeleting(false);
                setEditing(false);
                setEnableSave(false)
                setAddingNewPoc(false)
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })

    }

    useEffect(() => {
        setFormValues()
    }, [data])


    const handleFormChange = () => {
        const formData = form.getFieldsValue().createPocDtoList;

        const formDataString = JSON.stringify(formData);
        const dataString = JSON.stringify(data);

        if(formData.length === data.length){
            if(formDataString === dataString){
                setEnableSave(false)
            } else {
                setEnableSave(true)
            }
        } else {
            setEnableSave(true)
        }
    }

    return <>
        <div id={`poc_${data?.id}`} style={{ marginBottom: 50 }}>
            {modalContex}
            <Form
                onChange={handleFormChange}
                // onChange={handleFormChange}
                form={form}
                name={"poc-details"}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={updatePoc}
                autoComplete="off"
                initialValues={data.length < 2 ? {
                    createPocDtoList: [{
                        id: data[0]?.id,
                        email: data[0]?.email,
                        name: data[0]?.name,
                        state: data[0]?.state,
                        designation: data[0]?.designation,
                        phone: data[0]?.phone
                    },
                    // {
                    //     id: data[1]?.id,
                    //     email: data[1]?.email,
                    //     name: data[1]?.name,
                    //     state: data[1]?.state,
                    //     designation: data[1]?.designation,
                    //     phone: data[1]?.phone
                    // }
                    ]
                } : {
                    createPocDtoList: data
                }}
            >

                <Form.List name="createPocDtoList">
                    {(fields, fieldOpt) => (
                        <div>
                            {fields.map((field) => (
                                <Row key={field.key} gutter={24}>
                                    <Col span={24}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Divider orientationMargin="0" style={{ color: theme.token.colorPrimary, minWidth: "unset" }} orientation="left">Contact Person {field.name + 1}</Divider>

                                            {field.name > 1 &&
                                                <Button onClick={() => {
                                                    const idFieldValue = form.getFieldValue(['createPocDtoList', field.name, 'id']);
                                                    console.log('Email Field Value:', idFieldValue);
                                                    if (idFieldValue) {
                                                        deletPoc(idFieldValue)
                                                    } else {
                                                        fieldOpt.remove(field.name);
                                                    }


                                                }} icon={<DeleteOutlined />} loading={deleting} type="default" danger>
                                                    Delete
                                                </Button>}
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            name={[field.name, "id"]}
                                            hidden={true}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>

                                        <Form.Item<FieldType>
                                            label="Email"
                                            name={[field.name, "email"]}
                                            rules={[{ required: true, message: 'Please input client poc email!', type: 'email' }]}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            label="Name"
                                            name={[field.name, "name"]}
                                            rules={[{ required: true, message: 'Please input client poc name!' }]}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            label="State"
                                            name={[field.name, "state"]}
                                            rules={[{ required: true, message: 'Please input client poc state!' }]}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            label="Designation"
                                            name={[field.name, "designation"]}
                                            rules={[{ required: true, message: 'Please input client poc designation!' }]}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            label="Mobile Number"
                                            name={[field.name, "phone"]}
                                            rules={[{ required: true, message: 'Please input client poc mobile!' }]}
                                        >
                                            <InputNumber onFocus={stopScroll} type='number' disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} style={{ width: "100%" }} maxLength={10} size="large" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Divider orientationMargin="0" style={{ color: theme.token.colorPrimary, minWidth: "unset" }} orientation="left">New Contact Person</Divider>

                                <Button size="large" style={{color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary}} onClick={() => {fieldOpt.add();setAddingNewPoc(true)}} icon={<PlusOutlined />} type="default">
                                    New Contact Person
                                </Button>
                                
                            </div>
                        </div>
                    )}
                </Form.List>

                {(data.length < 1 || editing || addingNewPoc) && <div style={{ display: "flex", justifyContent: "center", marginTop: 36 }}>
                    <Space size="large">
                        <Button disabled={data.length < 2} style={{ width: 200 }} onClick={() => {setEnableSave(false);setEditing(false);setAddingNewPoc(false);setFormValues()}} size="large">
                            Cancel
                        </Button>
                        <Button disabled={!enableSave} style={{ width: 200 }} size="large" loading={loading} type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Space>
                </div>}
            </Form>
            <div style={{ display: "flex", justifyContent: "center", marginTop: 36 }}>
                {(data.length >= 1 && !editing && !addingNewPoc) &&
                    <Space size="large">
                        <Button onClick={() => setEditing(true)} style={{ width: 200 }} size="large">Edit</Button>
                        {/* {data?.id && <Button style={{ width: 200 }} disabled={!enableReset || loading} onClick={setFormValues} size='large' type="default">
                                Cancel
                            </Button>} */}
                        {/* <Button style={{ width: 200 }} size='large' disabled={data?.id ? !(data?.id && enableReset) : false} loading={loading} type="primary" htmlType="submit">
                                {data?.id ? `Update` : `Add new POC`}
                            </Button> */}
                        <Button type="primary" style={{ width: 200 }} size='large' onClick={() => setDefaulttab("client-details-3")}>Next</Button>
                    </Space>}
            </div>
        </div>
    </>
}

export default KycForm;