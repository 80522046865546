import { Divider, Row, Col, Input, Button, Form, message, Space, Modal } from "antd";
import { Dispatch, useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import theme from "../../../utils/theme";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { showSuccess, stopScroll } from "../../../utils/common";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';

type FieldType = any;

function KycForm({ id, data, setPocDetails, setDefaulttab, clientData, getClientDetails, setTabStates, setInProgrss, getPocDetails }: { id: any | undefined, data?: any, itemKey?: number, setPocDetails: Dispatch<any>, setDefaulttab: Dispatch<any>, clientData: any, getClientDetails: any, setTabStates: any, setInProgrss?: any, getPocDetails: any }) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const auth = useAppSelector(authSelector);
    const [modal, modalContex] = Modal.useModal();
    const [enableReset, setEnableReset] = useState(false)
    const [editing, setEditing] = useState(data.length < 2)
    const [addingNewPoc, setAddingNewPoc] = useState(false)
    const [enableSave, setEnableSave] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (setInProgrss) {
            setInProgrss(editing)
        }
    }, [editing])

    const getUpdatedValues = (values: any, originalData: any) => {
        const originalList = originalData;
        const updatedList = values.createPocDtoList.map((newItem: any) => {
            if (newItem.id) {
                const originalItem = originalList.find((item: any) => item.id === newItem.id);
                if (originalItem) {
                    const itemDiff = _.reduce(newItem, (result: any, value, key) => {
                        if (key === 'id' || !_.isEqual(value, originalItem[key])) {
                            result[key] = value;
                        }
                        return result;
                    }, {});

                    return _.omitBy(itemDiff, (value, key) => key !== 'id' && _.isEqual(value, originalItem[key]));
                }
            }
            return newItem; // Include new items without id
        }).filter((item: any) => item && Object.keys(item).length > 1); // Filter out unchanged items

        return { createPocDtoList: updatedList };
    };


    const updatePoc = async (values: any) => {
        console.log('values', values, data)
        const diff: any = getUpdatedValues(values, data);
        console.log("diff", diff)

        try {
            setLoading(true)
            if (clientData?.registrationStatus == "ADMIN_ONBOARDING_COMPLETED" || clientData?.registrationStatus == "APPROVED") {
                Modal.confirm({
                    title: <div>
                        Are you sure you want to submit these changes?
                    </div>,
                    // content: 'Once deleted changes cannot be reverted',
                    okText: 'Yes',
                    okType: 'danger',
                    centered: true,
                    cancelText: 'No',
                    icon: null,
                    onOk: async () => {
                        try{
                            const res = await instance.put(`clientservice/clients/poc/edit-profile?clientId=${id}`, diff)
                            getClientDetails()
                            getPocDetails()
                            message.success("client poc update request created successfully!")
                            if (!((auth.isAdmin || auth.isMainAdmin) && clientData.registrationStatus === "APPROVAL_PENDING") && clientData.registrationStatus !== "ADMIN_ONBOARDING_COMPLETED" && clientData.registrationStatus !== "APPROVED") {
                                setDefaulttab("client-details-4")
                            }
                            setEnableReset(false)
                            setEditing(false);
                            setEnableSave(false)
                            setAddingNewPoc(false)
                        } catch(e){
                            console.log(e)
                        }
                    },
                    bodyStyle: {
                        textAlign: "center",
                    },
                    okButtonProps: {
                        size: "large",
                        type: "primary",
                        style: { background: theme.token.colorPrimary, width: 140, fontWeight: 600 }
                    },
                    cancelButtonProps: {
                        size: "large",
                        type: "default",
                        style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary }
                    }
                })

            } else {
                if(data.length == 0){
                    const res = await instance.post(`clientservice/clients/${id}/poc`, values)
                    getClientDetails()
                    getPocDetails();
                    message.success("client poc updated successfully!")
                    if (!((auth.isAdmin || auth.isMainAdmin) && clientData.registrationStatus === "APPROVAL_PENDING") && clientData.registrationStatus !== "ADMIN_ONBOARDING_COMPLETED" && clientData.registrationStatus !== "APPROVED") {
                        setDefaulttab("client-details-4")
                    }
                    setEnableReset(false)
                    setEditing(false);
                    setEnableSave(false)
                    setAddingNewPoc(false)
                } else {
                    Modal.confirm({
                        title: <div>
                            Are you sure you want to submit these changes?
                        </div>,
                        // content: 'Once deleted changes cannot be reverted',
                        okText: 'Yes',
                        okType: 'danger',
                        centered: true,
                        cancelText: 'No',
                        icon: null,
                        onOk: async () => {
                            try{
                                const res = await instance.post(`clientservice/clients/${id}/poc`, values)
                                getClientDetails()
                                getPocDetails();
                                message.success("client poc updated successfully!")
                                if (!((auth.isAdmin || auth.isMainAdmin) && clientData.registrationStatus === "APPROVAL_PENDING") && clientData.registrationStatus !== "ADMIN_ONBOARDING_COMPLETED" && clientData.registrationStatus !== "APPROVED") {
                                    setDefaulttab("client-details-4")
                                }
                                setEnableReset(false)
                                setEditing(false);
                                setEnableSave(false)
                                setAddingNewPoc(false)
                            } catch(e){
                                console.log(e)
                            }
                        },
                        bodyStyle: {
                            textAlign: "center",
                        },
                        okButtonProps: {
                            size: "large",
                            type: "primary",
                            style: { background: theme.token.colorPrimary, width: 140, fontWeight: 600 }
                        },
                        cancelButtonProps: {
                            size: "large",
                            type: "default",
                            style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary }
                        }
                    })
    
                }
            }




            // form.setFieldsValue(
            //     {
            //         email: res.data.email,
            //         name: res.data.name,
            //         state: res.data.state,
            //         designation: res.data.designation,
            //         phone: res.data.phone
            //     }
            // )
            
        } catch (e: any) {
            console.log(e);
        }
        setLoading(false);
    };

    const setFormValues = () => {
        setEnableReset(false)
        form.setFieldsValue(data.length < 2 ? {
            createPocDtoList: [{
                id: data[0]?.id,
                email: data[0]?.email,
                name: data[0]?.name,
                department: data[0]?.department,
                designation: data[0]?.designation,
                phone: data[0]?.phone
            },
            {
                id: data[1]?.id,
                email: data[1]?.email,
                name: data[1]?.name,
                department: data[1]?.department,
                designation: data[1]?.designation,
                phone: data[1]?.phone
            }
            ]
        } : {
            createPocDtoList: data
        })
    }

    const deletPoc = (deletePocId: number) => {
        modal.confirm({
            title: <>Are you sure to delete?<br /> Changes cannot be reverted.</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            icon: null,
            onOk: async () => {
                try {
                    setDeleting(true)
                    await instance.delete(`clientservice/clients/poc/${deletePocId}`)
                    message.success("client poc deleted successfully!")
                    setPocDetails((prev: any) => prev.filter((item: any) => item.id != deletePocId))
                } catch (e: any) {
                    console.log(e);
                }
                setDeleting(false);
                setEditing(false);
                setEnableSave(false)
                setAddingNewPoc(false)
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })

    }

    useEffect(() => {
        setFormValues()
    }, [data])


    const handleFormChange = () => {
        const formData = form.getFieldsValue().createPocDtoList;

        const formDataString = JSON.stringify(formData);
        const dataString = JSON.stringify(data);
        
        if (formData.length === data.length) {
            if (formDataString === dataString) {
                setEnableSave(false)
            } else {
                setEnableSave(true)
            }
        } else {
            setEnableSave(true)
        }
    }

    const approve = () => {
        modal.confirm({
            title: <>Do you want to proceed and save KYC details of client?</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            icon: null,
            onOk: async () => {
                try {
                    const formData = new FormData();

                    formData.append("clientId", id)

                    // setLoading(true)
                    await instance.post(`clientservice/clients/approve?clientId=${id}`)
                    showSuccess(<>Details successfully updated</>, Modal)
                    navigate("/dashboard/registrations?tab=KYC")

                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })
    }

    const checkDuplicates = () => {
        const formValues = form.getFieldsValue()
        console.log("formValues", formValues)
        const errrorArrray = formValues.createPocDtoList.map((item: any, index: any) => {
            const hasDuplicate = formValues.createPocDtoList.filter((value: any, key: any) => {
                return value?.email === item?.email && item?.email
            })
            console.log("hasDuplicate", hasDuplicate)
            if (hasDuplicate?.length > 1) {
                return {
                    name: ['createPocDtoList', index, 'email'],
                    errors: ['email cannot be duplicate'],
                }
            } else {
                return {
                    name: ['createPocDtoList', index, 'email'],
                    errors: [],
                }
            }
        })
        form.setFields(errrorArrray);

        const phoneErrorArrray = formValues.createPocDtoList.map((item: any, index: any) => {
            const hasDuplicate = formValues.createPocDtoList.filter((value: any, key: any) => {
                return value?.phone === item?.phone && item?.phone
            })
            console.log("hasDuplicate", hasDuplicate)
            if (hasDuplicate?.length > 1) {
                return {
                    name: ['createPocDtoList', index, 'phone'],
                    errors: ['mobile cannot be duplicate'],
                }
            } else {
                return {
                    name: ['createPocDtoList', index, 'phone'],
                    errors: [],
                }
            }
        })
        form.setFields(phoneErrorArrray);


    }

    return <>
        <div id={`poc_${data?.id}`} style={{ marginBottom: 50 }}>
            {modalContex}
            <Form
                scrollToFirstError
                onChange={handleFormChange}
                // onChange={handleFormChange}
                form={form}
                name={"poc-details"}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={updatePoc}
                autoComplete="off"
                initialValues={data.length < 2 ? {
                    createPocDtoList: [{
                        id: data[0]?.id,
                        email: data[0]?.email,
                        name: data[0]?.name,
                        department: data[0]?.department,
                        designation: data[0]?.designation,
                        phone: data[0]?.phone
                    },
                    {
                        id: data[1]?.id,
                        email: data[1]?.email,
                        name: data[1]?.name,
                        department: data[1]?.department,
                        designation: data[1]?.designation,
                        phone: data[1]?.phone
                    }
                    ]
                } : {
                    createPocDtoList: data
                }}
            >

                <Form.List name="createPocDtoList">
                    {(fields, fieldOpt) => (
                        <div>
                            {fields.map((field) => (
                                <Row key={field.key} gutter={24}>
                                    <Col span={24}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Divider orientationMargin="0" style={{ color: theme.token.colorPrimary, minWidth: "unset" }} orientation="left">Contact Person-{field.name + 1}</Divider>

                                            {field.name > 1 &&
                                                <Button onClick={() => {
                                                    setTimeout(() => { handleFormChange(); }, 500)
                                                    const idFieldValue = form.getFieldValue(['createPocDtoList', field.name, 'id']);
                                                    console.log('Email Field Value:', idFieldValue);
                                                    if (idFieldValue) {
                                                        deletPoc(idFieldValue)
                                                    } else {
                                                        fieldOpt.remove(field.name);
                                                    }


                                                }} icon={<DeleteOutlined />} loading={deleting} type="default" danger disabled={!editing}>
                                                    Delete
                                                </Button>}
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            label="Name"
                                            name={[field.name, "name"]}
                                            rules={[{ required: true, message: 'Please input client poc name!' }]}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            label="Designation"
                                            name={[field.name, "designation"]}
                                            rules={[{ required: true, message: 'Please input client poc designation!' }]}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>
                                    </Col>


                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            label="Department"
                                            name={[field.name, "department"]}
                                            rules={[{
                                                required: true,
                                            }]}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            name={[field.name, "id"]}
                                            hidden={true}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>

                                        <Form.Item<FieldType>
                                            label="Email"
                                            name={[field.name, "email"]}
                                            rules={[{
                                                validator: async (_, value) => {
                                                    const formValues = form.getFieldValue("createPocDtoList")
                                                    const itemsToCheck = formValues.filter((item: any, index: any) => index !== field.name)
                                                    const findDuplicates = itemsToCheck.filter((item: any, index: any) => item?.email === value && item?.email)
                                                    if (!value) {
                                                        return Promise.reject(new Error("Please input client poc email!"));
                                                    }
                                                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                    if (!emailRegex.test(value)) {
                                                        return Promise.reject(new Error("Invalid email format"));
                                                    }
                                                    if (findDuplicates && findDuplicates.length > 0) {
                                                        return Promise.reject(new Error("email cannot be duplicate"));
                                                    } else {
                                                        checkDuplicates()
                                                    }
                                                }
                                            }]}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} size="large" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item<FieldType>
                                            label="Mobile Number"
                                            name={[field.name, "phone"]}
                                            rules={[{
                                                len: 10,
                                                validator: async (_, value) => {
                                                    const formValues = form.getFieldValue("createPocDtoList")
                                                    const itemsToCheck = formValues.filter((item: any, index: any) => index !== field.name)
                                                    const findDuplicates = itemsToCheck.filter((item: any, index: any) => item?.phone === value && item?.phone)
                                                    if (!value) {
                                                        return Promise.reject(new Error("Please input client mobile no!"));
                                                    }
                                                    if (value.length !== 10) {
                                                        return Promise.reject(new Error("Mobile no. has to be 10 digits!"))
                                                    }
                                                    if (findDuplicates && findDuplicates.length > 0) {
                                                        return Promise.reject(new Error("mobile cannot be duplicate"));
                                                    } else {
                                                        checkDuplicates()
                                                    }
                                                }
                                            }]}
                                        >
                                            <Input disabled={editing ? false : form.getFieldValue(['createPocDtoList', field.name, 'id'])} onFocus={stopScroll} type="number" size="large" style={{ width: "100%" }} prefix={<div style={{ display: "flex", alignItems: "center", gap: 8 }}><span>+91-</span><span style={{ height: 26, width: 1, background: "#DADADA" }}></span></div>} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                            {((auth.isClient && clientData?.pocUpdateStatus !== "UPDATED_BY_ADMIN") || ((auth.isAdmin || auth.isMainAdmin) && clientData?.pocUpdateStatus !== "UPDATED")) && <div className="hide-me" style={{ display: "flex", alignItems: "center" }}>
                                <Divider orientationMargin="0" style={{ color: theme.token.colorPrimary, minWidth: "unset" }} orientation="left">Contact Person-{fields.length + 1}</Divider>

                                <Button size="large" style={{ color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary }} onClick={() => { setEditing(true); fieldOpt.add(); setAddingNewPoc(true) }} icon={<PlusOutlined />} type="default">
                                    New Contact Person
                                </Button>

                            </div>}
                        </div>
                    )}
                </Form.List>

                {(data.length < 2 || editing || addingNewPoc) && <div style={{ display: "flex", justifyContent: "center", marginTop: 36 }}>
                    <Space size="large">
                        <Button style={{ width: 200 }} onClick={() => { setEnableSave(false); setEditing(false); setAddingNewPoc(false); setFormValues() }} size="large">
                            Cancel
                        </Button>
                        <Button disabled={!enableSave} style={{ width: 200 }} size="large" loading={loading} type="primary" htmlType="submit">
                            Save {!((auth.isAdmin || auth.isMainAdmin) && clientData.registrationStatus === "APPROVAL_PENDING") && clientData.registrationStatus !== "ADMIN_ONBOARDING_COMPLETED" && clientData.registrationStatus !== "APPROVED" && `& Next`}
                        </Button>
                    </Space>
                </div>}
            </Form>
            <div className="hide-me" style={{ display: "flex", justifyContent: "center", marginTop: 36 }}>
                {(data.length > 1 && !editing && !addingNewPoc) &&
                    <Space size="large">
                        {((auth.isClient && clientData?.pocUpdateStatus !== "UPDATED_BY_ADMIN") || ((auth.isAdmin || auth.isMainAdmin) && clientData?.pocUpdateStatus !== "UPDATED")) && <Button onClick={() => setEditing(true)} style={{ width: 200 }} size="large">Edit</Button>}
                        {((auth.isClient && clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING") || ((auth.isAdmin || auth.isMainAdmin) && clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING_ADMIN")) && <Button type="primary" style={{ width: 200 }} size='large' onClick={() => setDefaulttab("client-details-4")}>Next</Button>}

                        {/* {data?.id && <Button style={{ width: 200 }} disabled={!enableReset || loading} onClick={setFormValues} size='large' type="default">
                                Cancel
                            </Button>} */}
                        {/* <Button style={{ width: 200 }} size='large' disabled={data?.id ? !(data?.id && enableReset) : false} loading={loading} type="primary" htmlType="submit">
                                {data?.id ? `Update` : `Add new POC`}
                            </Button> */}
                        {(auth.isAdmin || auth.isMainAdmin) && clientData.registrationStatus === "APPROVAL_PENDING" && <Button onClick={approve} style={{ width: 200 }} size="large" type="primary">
                            Approve
                        </Button>}
                    </Space>}
            </div>
        </div>
    </>
}

export default KycForm;