import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { instance } from "../../../utils/api"
import { Link } from "react-router-dom"
import { EyeOutlined } from "@ant-design/icons"
import { Modal, Typography } from "antd"
import UpdateQtyModal from "./UpdateQtyModal"
import ProposalHistoryModal from "./ProposalHistoryModal"
import { useAppSelector } from "../../../app/hooks"
import { authSelector } from "../../../features/auth/authSlice"
import ContractHistoryModal from "./ContactHistoryModal"

export default function ContractDetailsModal({data}:any) {

    interface itemType {
    
        "additionalVAT": string,
        "applicableTaxes": string,
        "contractUpdateStatus": string,
        "creditDetail": string,
        "deliveryPoint": string,
        "description": string,
        "details": string,
        "endDate": string,
        "enquiryId": string,
        "exchangeRate": string,
        "exchangeRateDef": string,
        "flexibility": string,
        "id": string,
        "invoiceCycle": string,
        "linkageDef": string,
        "linkagePercent": string,
        "linkagePrice": string,
        "linkageType": string,
        "linkageValue": string,
        "maxContractFlex": string,
        "maxSupplyFlex": string,
        "minContractFlex": string,
        "minSupplyFlex": string,
        "newQuantity": string,
        "oldQuantity": string,
        "paymentDueDate": string,
        "paymentSecurity": string,
        "price": string,
        "startDate": string,
        "supplyOrPayLevel": string,
        "takeOrPayLevel": string,
        "tariffPath": string,
        "tariffZone": string,
        "taxOnTariff": string,
        "totalQuantity": string,
        "tradeId": string,
        "transportationTariff": string,
        "url": string,
        "contractHistory": string
    }

    const [fetching, setFetchingDetails] = useState(true)
    const [contractData, setContractDetails] = useState<itemType | null>(null)
    const [isUpdateQtyModalOpen, setIsUpdateQtyModalOpen] = useState(false) 
    const [isProposalsOpen, setIsProposalsOpen] = useState(false)
    const [isHistoryOpen, setIsHistoryOpen] = useState(false)
    const auth = useAppSelector(authSelector);


    const getTenure = (startDate:string, endDate:string) => {
        const differencence = dayjs(endDate, "DD/MM/YYYY").endOf("day").diff(dayjs(startDate, "DD/MM/YYYY"), 'day') + 1
       return <>{`${differencence ? differencence : 0} Days`}</>
    }

    useEffect(()=>{
        getContractDetails()
    }, [])

    const getContractDetails = async () => {
        setFetchingDetails(true)
        try {
            const res = await instance.get(`clientservice/clients/contract?id=${data.contractId}`);
            setContractDetails(res.data)
        } catch (e) {
            console.log(e);
        }
        setFetchingDetails(false)
    }

    const showHistoryModal = () => {
        setIsHistoryOpen(true)
    }

    const handleHistoryOk = () => {
        setIsHistoryOpen(false)
    }

    const handleHistoryCancel = () => {
        setIsHistoryOpen(false)
    }

    const showProposalModal = () => {
        setIsProposalsOpen(true)
    }

    const handleProposalOk = () => {
        setIsProposalsOpen(false)
    }

    const handleProposalCancel = () => {
        setIsProposalsOpen(false)
    }


    const showUpdateQtyModal = () => {
        setIsUpdateQtyModalOpen(true);
    };


    const handleOk = () => {
        setIsUpdateQtyModalOpen(false);
    };

    const handleCancel = () => {
        setIsUpdateQtyModalOpen(false);
    };

    const handleQtyChange = () => {
        handleCancel();
        getContractDetails();
    }

    return (
        <div style={{display: "flex", gap: 8, flexDirection: "column", alignItems: "center", marginTop: 40}}>
            <div style={itemStyle}>
                <div>
                    Tenure
                </div>
                <div>
                    {!data?.startDate ? "NA" :`${dayjs(data?.startDate, "DD/MM/YYYY").format("DD MMM")} - ${dayjs(data?.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}`}
                </div>
            </div>

            <div style={itemStyle}>
                <div>
                    Contract No. of days
                </div>
                <div>
                    {getTenure(data.startDate, data.endDate)}
                </div>
            </div>
            <div style={itemStyle}>
                <div>
                    Price
                </div>
                <div>
                    {contractData?.price}
                </div>
            </div>
            <div style={itemStyle}>
                <div>
                    Linkage Formula
                </div>
                <div>
                    {contractData?.linkagePercent && `${contractData?.linkagePercent} of ${contractData?.linkageType}`}
                </div>
            </div>
            <div style={itemStyle}>
                <div>
                    Flexibility
                </div>
                <div>
                    {contractData?.flexibility}
                </div>
            </div>
            <div style={itemStyle}>
                <div>
                    Take/supplies
                </div>
                {contractData?.totalQuantity && data?.contractStatus !== "Completed" && !auth.isClient && <div onClick={()=>showUpdateQtyModal()} style={{display: "flex", alignItems: "center", gap: 4, color: "#2C66E3", textDecoration: "underline", cursor: "pointer"}}>
                    <img src="/assets/edit-icon.png" width={18} /> {contractData?.totalQuantity} MMBTU
                </div>}

                {auth.isClient && `${contractData?.totalQuantity} MMBTU` }
            </div>
            <div style={itemStyle}>
                <div>
                    Due Date
                </div>
                <div>
                    {contractData?.paymentDueDate}
                </div>
            </div>
            <div style={itemStyle}>
                <div>
                    Delivery Point
                </div>
                <div>
                    {contractData?.deliveryPoint}
                </div>
            </div>
            <div style={itemStyle}>
                <div>
                    Credit Detail
                </div>
                <div>
                    {contractData?.creditDetail}
                </div>
            </div>
            <div style={itemStyle}>
                <div>
                    Signed Proposals
                </div>
                <div>
                    <Link onClick={()=>showProposalModal()} style={{color: "#2C66E3"}} to="">
                        <EyeOutlined style={{marginRight: 8}} />
                        View History
                    </Link>
                </div>
            </div>
            <div style={itemStyle}>
                <div>
                    Contract History
                </div>
                <div>
                    <Link onClick={()=>showHistoryModal()} style={{color: "#2C66E3"}} to="">
                        <EyeOutlined style={{marginRight: 8}} />
                        View History
                    </Link>
                </div>
            </div>
            <div style={itemStyle}>
                <div>
                    Signed Contract
                </div>
                <div>
                    <Link style={{color: "#2C66E3"}} target="_blank" to={contractData?.url as any}>
                        <EyeOutlined style={{marginRight: 8}} />
                        PDF - <Typography.Text ellipsis style={{color: "inherit"}}>{contractData?.url?.substring(contractData?.url.lastIndexOf('/') + 1).split('.pdf')[0]}</Typography.Text>
                    </Link>
                </div>
            </div>

            <Modal destroyOnClose width={1000} centered={true} footer={null} style={{textAlign: "center"}} bodyStyle={{paddingBottom: 16}} title={<div style={{marginTop: 16, fontSize: 24}}>Contract Quantity Update</div>} open={isUpdateQtyModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <UpdateQtyModal handleQtyChange={handleQtyChange} contractId={data.contractId} totalQuantity={contractData?.totalQuantity} />
            </Modal>

            <Modal destroyOnClose width={1000} centered={true} footer={null} style={{textAlign: "center"}} bodyStyle={{paddingBottom: 16}} title={<div style={{marginTop: 16, fontSize: 24}}>Proposal History</div>} open={isProposalsOpen} onOk={handleProposalOk} onCancel={handleProposalCancel}>
                <ProposalHistoryModal id={data?.enquiryId} />
            </Modal>

            <Modal destroyOnClose width={1000} centered={true} footer={null} style={{textAlign: "center"}} bodyStyle={{paddingBottom: 16}} title={<div style={{marginTop: 16, fontSize: 24}}>Contract History</div>} open={isHistoryOpen} onOk={handleHistoryOk} onCancel={handleHistoryCancel}>
                <ContractHistoryModal contractHistory={contractData?.contractHistory} />
            </Modal>

        </div>
    )
}
const itemStyle: React.CSSProperties = {
    padding: "12px 22px 12px 22px",
    background: "#F8FAFF",
    fontSize: 14,
    display: "flex",
    justifyContent: "space-between",
    width: 568
}