import { Modal } from "antd"
import IntraDayForm from "./IntraDayForm";
import UploadExcelForm from "./UploadExcelForm";

export default function ImportModal({ nominationType, uploadType, setUploadType, isModalOpen, handleCancel, fetchData }: any) {

    return (
        <Modal
            footer={null}
            destroyOnClose
            okButtonProps={{
                size: "large"
            }
            }
            bodyStyle={{
                display: "flex",
                justifyContent: "center"
            }}
            width="auto"
            cancelButtonProps={{
                size: "large"
            }} centered={true} title={<div style={{textAlign: "center", fontSize: 20}}>{uploadType=="excel" ? "Please upload an excel document" : "Create New Entry"}</div>} open={isModalOpen} onCancel={handleCancel} closeIcon={false} >

            {uploadType === "form" && <IntraDayForm closeModal={handleCancel} fetchData={fetchData} />}
            {uploadType === "excel" && <UploadExcelForm fetchDetails={fetchData} nominationType={nominationType} closeModal={handleCancel} />}


        </Modal >
    )
}