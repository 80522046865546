import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';



function EmptyList({customMessage, hideMessage}:any) {

    const navigate = useNavigate()

    return (
        <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ borderRadius: 17, background: 'white', display: "flex", padding: "30px 60px", flexDirection: "column", alignItems: "center", gap: "27px" }}>
                <img src={`${__dirname}assets/no-records.svg`} style={{ width: 336, height: 265 }} />
                {!hideMessage && <div style={{ width: 418, textAlign: 'center', lineHeight: '24px', color: "black" }}>
                    {!customMessage ? `No Nomination found.` : customMessage}<br />
                    {!customMessage && `You can see the nominations placed by clients here, also you can place nominations for them.`}
                </div>}
                {!customMessage && <Button onClick={()=>navigate('/dashboard/confirmation/nomination-entry')} icon={<PlusOutlined />} type='primary' style={{ width: '90%' }} size="large">Nomination Entry</Button>}
            </div>
        </div>
    )
}

export default EmptyList