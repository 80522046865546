import { Breadcrumb, Space, Table, Typography } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import theme from "../../../utils/theme";
import { columns } from "../../../interfaces/TableColumns/NominationHistoryContractDetails";
import { getPaginationCountApi } from "../../../utils/common";
import { useEffect, useState } from "react";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { instance } from "../../../utils/api";
import { EyeOutlined } from "@ant-design/icons";
import { UploadInvoiceDropdown } from "./UploadInvoice";

export default function ContractDetails() {
    let { state, pathname } = useLocation();

    const navigate = useNavigate();

    const [data, setData] = useState<any | null>(null);

    const { tableParams, handleSort } = useTableFilters("", ``, false, false, true, true);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchData();
    }, [tableParams])

    const fetchData = async () => {
        const params = {
            ...tableParams,
            // filter and contract endDate is less than current date
            filterAnd: `${tableParams.filterAnd ? `${tableParams.filterAnd}` : ""}`
        }
        params.startDate = state?.startDate;
        params.endDate = state?.endDate;
        params.nominationId = state?.nominationId
        setLoading(true)
        try {
            const res = await instance.get("orderservice/nominate/fortnight/details/invoice", { params })
            setData(res.data);
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setLoading(false)
    };

    return (<div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <Breadcrumb separator=">" style={{ marginBottom: 0, cursor: "pointer" }}>
            {pathname.split("/")[2] === "joint-ticket" ? <Breadcrumb.Item
                onClick={_ => navigate(`/dashboard/joint-ticket`)}
            >
                <span style={{ fontWeight: 600 }}>
                    Invoice
                </span>
            </Breadcrumb.Item> : <Breadcrumb.Item
                onClick={_ => navigate(`/dashboard/nomination-history`)}
            >
                <span style={{ fontWeight: 600 }}>
                    Nomination History
                </span>
            </Breadcrumb.Item>}
            {pathname.split("/")[2] != "joint-ticket" && <Breadcrumb.Item>
                <Link to={`/dashboard/nomination-history/${state.clientId}`} state={{ name: state.name }}>
                    <span style={{ fontWeight: 600 }}>
                        {state.name} - View Details
                    </span>
                </Link>
            </Breadcrumb.Item>}
            <Breadcrumb.Item>
                <span style={{ fontWeight: 600 }}>
                    {state.name} Details- {state.contractId}
                </span>
            </Breadcrumb.Item>
        </Breadcrumb>

        <Space direction="horizontal" size="middle">
            <div className="info-card">
                <div>
                    <Typography.Text type="secondary">Client</Typography.Text>
                </div>
                <div>
                    <Typography.Title ellipsis={{
                        tooltip: true
                    }} style={{ color: theme.token.colorPrimary, margin: 0, maxWidth: 200 }} level={4}>{state?.name}</Typography.Title>
                </div>
            </div>

            <div className="info-card">
                <div>
                    <Typography.Text type="secondary">Status</Typography.Text>
                </div>
                <div>
                    <Typography.Title style={{ color: "#1B78DF", margin: 0, textTransform: "uppercase" }} level={4}>{state?.contractStatus}</Typography.Title>
                </div>
            </div>

            <div className="info-card">
                <div>
                    <Typography.Text type="secondary">Client Code</Typography.Text>
                </div>
                <div>
                    <Typography.Title style={{ margin: 0 }} level={4}>{state?.clientCode !== undefined || state?.clientCode !== null ? state?.clientCode : "----"}</Typography.Title>
                </div>
            </div>

            <div className="info-card">
                <div>
                    <Typography.Text type="secondary">IGX Trade ID</Typography.Text>
                </div>
                <div>
                    <Typography.Title style={{ margin: 0 }} level={4}>{state?.tradeId ? state?.tradeId : "----"}</Typography.Title>
                </div>
            </div>

            <div className="info-card">
                <div>
                    <Typography.Text type="secondary">Contract ID</Typography.Text>
                </div>
                <div>
                    <Typography.Title style={{ margin: 0 }} level={4}>{state?.contractId}</Typography.Title>
                </div>
            </div>

            <div className="info-card">
                <div>
                    <Typography.Text type="secondary">Days Left</Typography.Text>
                </div>
                <div>
                    <Typography.Title style={{ margin: 0 }} level={4}>{state?.daysLeft < 0 ? 0 : state?.daysLeft}</Typography.Title>
                </div>
            </div>
        </Space>

        <Table
            loading={loading}
            onChange={handleSort}
            rowClassName="table-row"
            columns={[...columns, {
                title: "Action",
                key: "action",
                render: (record) => <Space size="large">
                    <Link to={`${state?.nominationId}`} state={{
                        clientId: state.clientId,
                        clientName: state.name,
                        startDate: state?.startDate,
                        endDate: state?.endDate,
                        contractId: state.contractId,
                        contractStatus: state.contractStatus,
                        clientCode: state.clientCode,
                        tradeId: state?.tradeId,
                        record: record
                    }}>
                        <div style={{ textAlign: "center" }}>
                            <EyeOutlined style={{ color: "#1B78DF" }} />
                            <div style={{ color: "#1B78DF", fontSize: 12, width: "max-content" }}>
                                View Details
                            </div>
                        </div>
                    </Link>

                    <UploadInvoiceDropdown
                        icon={true}
                        record={record}
                        contractId={state.contractId}
                        startDate={state.startDate}
                        endDate={state.endDate}
                        fetchData={fetchData}
                        dropDownType="invoice"
                    />
                    <UploadInvoiceDropdown
                        icon={true}
                        record={record}
                        contractId={state.contractId}
                        startDate={state.startDate}
                        endDate={state.endDate}
                        fetchData={fetchData}
                        dropDownType="ticket"
                    />
                </Space>,
                align: "center",

            }]}
            dataSource={data?.items}
            pagination={{
                pageSize: tableParams.size,
                total: data?.totalItems,
                current: tableParams.page,
                showSizeChanger: false,
                position: ["bottomCenter"]
            }}
            footer={() => <div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
            rowKey={(record) => record.id}
            scroll={{ x: true, y: "65vh" }}
        />

    </div>)
}