import { useState } from "react";
import { EditableCell, EditableRow } from "../../components/EditableCell";
import { Item } from "../../interfaces/TableColumns/Confirmation";
import { ColumnGroupType, ColumnType } from "antd/es/table";
import { AnyObject } from "antd/es/_util/type";

export function useEditableCell(defaultColumns:((ColumnGroupType<AnyObject> | ColumnType<AnyObject>) & {
    editable?: boolean | undefined;
    dataIndex: string;
})[]) {
    const [data, setData] = useState<any>([]);
    const [originalData, setOriginalData] = useState<any>([]);
    const [editedItems, setEditedItems] = useState<any>([]);
    const [errors, setErrors] = useState<any>([])
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const expandedCols = (record: any, index: any, col:any) => {
        if (["gasDay", "dcq", "client", "clientCode", "contractId", "tradeId"].includes(col.dataIndex) && !(record.hasOwnProperty('nominationDetails')) && data?.items?.filter((i:any) => i.nomination.id === record.nomination.id)[0].nominationDetails?.[0].id === record.id) {
            return {
                rowSpan: data?.items?.filter((i:any) => i.nomination.id === record.nomination.id)[0].nominationDetails.length,
                className: col.dataIndex === "dcq" ? "child-border" : ""
            };
        } else if (["gasDay", "dcq", "client", "clientCode", "contractId", "tradeId"].includes(col.dataIndex) && !(record.hasOwnProperty('nominationDetails')) && data?.items?.filter((i:any) => i.nomination.id === record.nomination.id)[0].nominationDetails?.[0].id != record.id) {

            return {
                rowSpan: 0,
            };
        } else {
            return {
                
                rowSpan: 1
            };
        }

    }

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return {
                ...col,
                // onCell: (record: Item) => ({
                //     rowSpan: col.dataIndex === "gasDay" && !record.nominationDetails && data.filter((i)=>i.gasDay === record.gasDay)[0].nominationDetails[0].id === record.id ? data.filter((i)=>i.gasDay === record.gasDay)[0].nominationDetails.length : 1
                // }),
                onCell: (record: Item, index: any) => expandedCols(record, index,col)
            }
        }
        return {
            ...col,
            onCell: (record: any) =>({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
                setErrors,
                hidden: col.dataIndex === "deliveryId",
                parentRecord: data.items,
                errors
            }),
            
        };
    });

    const resetData = () => {
        setData(originalData);
        setEditedItems([]);
        setErrors([])
    }


    const handleSave = (row: Item) => {
        // const existingDEIndex = data.items.find((item:any)=>item.nomination.id === row.nomination.id)?.nominationDetails?.findIndex((item:any)=>item.deliveryId === row.deliveryId && item.id !== row.id)
        // if (existingDEIndex && existingDEIndex !== -1) {
        //     setErrors((prevErr: any) => {
        //         return [...prevErr, { dataIndex: "deliveryEndPoint", id: row.id, error: "cannot be duplicate", gasDay: row.gasDay }];
        //     });
        // }  else {
        //     setErrors((prevErr: any) => {
        //         const newErr = prevErr.filter((err: any) => !(err.dataIndex === "deliveryEndPoint" && err.gasDay === row.gasDay));
        //         return newErr;
        //     });
        // }
        const editedIndex = editedItems.findIndex((x: any) => x.id === row.id)
        if (editedIndex != -1) {
            let newItems = [...editedItems]
            newItems[editedIndex] = row
            setEditedItems(newItems)
        } else {
            setEditedItems([...editedItems, row])
        }
        const newData = [...data.items];

        if (row.hasOwnProperty('nominationDetails')) {
            const index = newData.findIndex((item) => row.id === item.id);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setData({...data, items: newData});
        } else {
            const gasDayItem = newData.filter((i) => i.nomination.id === row.nomination.id)[0]
            const nominationDetails = gasDayItem.nominationDetails
            const updatedNominationDetails = nominationDetails.map((i: any) => {
                if (i.id === row.id) {
                    return row
                } else {
                    return i
                }
            })
            const updatedGasDayItem = { ...gasDayItem, nominationDetails: updatedNominationDetails }
            const index = newData.findIndex((item) => row.nomination.id === item.nomination.id);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...updatedGasDayItem,
            });
            setData({...data, items: newData});
        }        
    };

    const setTableData = (data: any) => {
        setData(data)
        setOriginalData(data)
    }

    const getErrors = (errors:any) => {
        const result = Object.keys(errors).map((key) => {
            const match = key.match(/orders\[(\d+)\]\.(.+)/);
            if (match) {
                const index = parseInt(match[1], 10);
                const dataIndex = match[2];
                return {
                    id: editedItems[index]?.id || null,
                    error: errors[key],
                    dataIndex: dataIndex,
                    gasDay: editedItems[index]?.gasDay || null,
                };
            } 
            // else {
            //     const idMatch = key.match(/\b\d+\b/);  
            //     if(idMatch) {
            //         console.log(idMatch, "xxxxxvv", {
            //             id: key,
            //             error: errors[key],
            //             dataIndex: "deliveryEndPoint",
            //             gasDay: editedItems.find((item:any)=>item.id === 44)
            //         })
            //         return {
            //             id: key,
            //             error: errors[key],
            //             dataIndex: "deliveryEndPoint",
            //             gasDay: editedItems.find((item:any)=>item.id === key)?.gasDay || null,
            //         };
            //     }

            // }
            return null;
        });

        // Remove null results
        const filteredResult = result.filter((item) => item !== null);
        return filteredResult
    }

    return {
        data,
        setOriginalData,
        originalData,
        editedItems,
        columns,
        components,
        setTableData,
        resetData,
        setEditedItems,
        setErrors,
        getErrors,
        errors,
        expandedCols
    }
}