import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select, Space, Table, Typography, Upload, message } from "antd";
import theme from "../../../utils/theme";
import dayjs from "dayjs";
import { CalendarOutlined, CheckCircleFilled, DownOutlined, DownloadOutlined, EyeOutlined, FilePdfOutlined, PlusSquareFilled, ReloadOutlined, UpOutlined, UploadOutlined } from "@ant-design/icons";
import { beforeUpload, hasMoreThanThreeDecimals, showSuccess, stopScroll } from "../../../utils/common";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { cardStyle, statusStyle, tagStyle } from "../Client-Enquiry/EnuiryDetails";
import { columns } from "../../../interfaces/TableColumns/AdminProposalHistory";
import { EnquiryStatus, ProposalStatus } from "../../../utils/healpers/constants";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";

export default function CreateEnquiry({ setDefaultTab, state }: any) {
    // let { state, pathname } = useLocation();
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [modal, modalContex] = Modal.useModal();
    const [submitting, setSubmitting] = useState(false)
    let { id } = useParams();
    const [proposals, setProposals] = useState<any>(null);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [current, setCurrent] = useState(0);
    const [enableSubmit, setEnableSubmit] = useState(false)
    const { tableParams, handleSort } = useTableFilters("auditInfo.creationDate|DESC", '', true, false, true);
    const [submitEnable, setSubmitEnable] = useState(false)
    const [duplicates, setDuplicates] = useState<number[] | []>([])
    const [contractTypeState, setContractType] = useState("")



    // const onChange = (value: number) => {
    //     console.log('onChange:', value);
    //     setCurrent(value);
    // };

    useEffect(() => {
        fetchProposals()
    }, [tableParams]);

    const fetchProposals = async () => {
        const params = {
            ...tableParams,
            sort: tableParams.sort != undefined ? tableParams.sort : "auditInfo.creationDate|DESC",
            filterAnd: `enquiry.id|eq|${id}`,
        }

        try {
            const res = await instance.get("/clientservice/clients/proposal", { params })
            setProposals({ ...res.data, items: res.data.items })
            form.setFieldValue("remarks", proposals?.items[0].remarks)
        } catch (e) {
            console.log(e)
        }
    }

    const handleDownload = async (e: any, urlString: string, downloadType: string = "proposal") => {
        e.preventDefault();
        e.stopPropagation();

        await fetch(urlString).then((response) => {
            response.blob().then((blob) => {

                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = downloadType + "_" + id;
                alink.click();
            });
        });
    };

    useEffect(() => {
        if (id) {
            form.setFieldsValue({
                "startDate": dayjs(state?.startDate, "DD/MM/YYYY"),
                "endDate": dayjs(state?.endDate, "DD/MM/YYYY"),
                // "tenure": state.tenure,
                "totalQuantity": state?.totalQuantity,
                // "dcq": "400.000",
                "createDeliveryDtoList": state?.createDeliveryDtoList?.map((item: any) => {
                    return item.deliveryEndPoint
                }),

                "description": state?.description
            })
            getTenure()
            getTotalQunatity()
        }
    }, [])



    const getTenure = () => {
        const endDate = form.getFieldValue("endDate")
        const startDate = form.getFieldValue("startDate")
        const differencence = dayjs(endDate, "DD/MM/YYYY").endOf("day").diff(dayjs(startDate, "DD/MM/YYYY"), 'day') + 1
        console.log(differencence, startDate, endDate)
        form.setFieldsValue({
            tenure: differencence ? differencence + " Days" : 0 + " Days"
        })
    }

    const getDcq = () => {
        const dcq = form.getFieldValue("dcq");
        const tenure = form.getFieldValue("tenure")
        const tenureValue = parseInt(tenure.match(/\d+/)[0])
        const totalQuantity = dcq * tenureValue;
        console.log("totalQuantity.toFixed(3)", totalQuantity.toFixed(3))
        form.setFieldsValue({
            totalQuantity: totalQuantity ? totalQuantity.toFixed(3) : ""
        })
    }

    const getTotalQunatity = () => {
        const totalQuantity = form.getFieldValue("totalQuantity");
        const tenure = form.getFieldValue("tenure")
        const tenureValue = parseInt(tenure.match(/\d+/)[0])

        const dcq = totalQuantity / tenureValue
        const decimal = hasMoreThanThreeDecimals(dcq)

        if(decimal){
            form.setFieldsValue({
                dcq: dcq.toFixed(3)
            })
        } else {
            form.setFieldsValue({
                dcq: dcq
            }) 
        }
    }

    // const getPrice = () => {
    //     const linkageFormula = form.getFieldValue("linkagePercent")
    //     const linkageValue = form.getFieldValue("linkageValue")
    //     const linkagePrice = (linkageFormula / 100) * linkageValue
    //     form.setFieldValue("linkagePrice", linkagePrice)
    // }


    const createEnquiry = async (values: any) => {

        modal.confirm({
            title: <>Please confirm to submit the<br /> proposal form </>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            icon: null,
            centered: true,
            onOk: async () => {
                setSubmitting(true)

                const formData = new FormData();

                formData.append("file", values["file"]);
                const temp: any = {}

                temp.date = dayjs().format('DD/MM/YYYY')
                temp.remarks = ""
                temp.enquiryId = id

                console.log("temp", temp)

                formData.append('proposal', new Blob([JSON.stringify(temp)], { type: 'application/json' }));
                try {
                    await instance.post(`clientservice/clients/proposal/create`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    showSuccess(<>Proposal sent successfully!</>, Modal)
                    navigate(-1)
                    // document?.querySelector(`#contract${res.data.id}`)?.scrollIntoView();
                    form.resetFields()
                } catch (e) {
                    console.log(e)
                }
                setSubmitting(false)
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })


    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };



    const resendConract = async () => {
        modal.confirm({
            title: <>Do you want to proceed and<br />rensend the contract?</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            centered: true,
            cancelText: 'No',
            icon: null,
            onOk: async () => {
                try {
                    // setLoading(true)
                    await instance.post(`clientservice/clients/enquiry/send-contract-email?enquiryId=${id}`)
                    showSuccess(<>Contract successfully sent, please wait for the client to verify the same.</>, Modal)

                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })
    }

    const createContract = async (values: any) => {
        setSubmitting(true)
        const { createDeliveryDtoList,contractType, ...temp } = values;

        if (contractType !== "EXCHANGE") {
            temp.contractType = contractType;
        }
        temp.createDeliveryDtoList = values.createDeliveryDtoList.map((item: any) => {
            const existingItem = state?.createDeliveryDtoList?.find((i: any) => i.deliveryEndPoint === item)
            return existingItem ? { deliveryEndPoint: item, id: existingItem.id } : { deliveryEndPoint: item }
        })

        temp.startDate = dayjs(values.startDate).format('DD/MM/YYYY')
        temp.endDate = dayjs(values.endDate).format('DD/MM/YYYY')
        // temp.paymentDueDate = dayjs(values.paymentDueDate).format('DD/MM/YYYY')
        temp.existingContractUrl = state?.signedContractUrl
        temp.enquiryId = id

        let idsToDelete = []
        const finalIds = temp.createDeliveryDtoList.map((item: any) => item.deliveryEndPoint);


        const deletedIds = state?.createDeliveryDtoList
            .filter((item: any) => !finalIds.includes(item.deliveryEndPoint))
            .map((item: any) => item.id);

        console.log(deletedIds);
        if (deletedIds.length > 0) {
            idsToDelete = deletedIds
        }

        console.log("temp dataxx", temp)

        try {
            await instance.post(`clientservice/clients/enquiry/create-contract?${idsToDelete.length > 0 ? 'deleteIds=' + idsToDelete.join(',') : ''}`, temp)
            showSuccess(<>Contract successfully created, please wait for the client to verify the same.</>, Modal)
            navigate(-1)
            setSubmitting(false)

            // document?.querySelector(`#contract${res.data.id}`)?.scrollIntoView();
            form.resetFields()
        } catch (e) {
            console.log(e)
            setSubmitting(false)
        }

    }

    const handleFormChange = () => {
        const formValues = form.getFieldsValue()
        console.log(formValues)
        if (
            !!formValues.file
        ) {
            setEnableSubmit(true)
        } else {
            setEnableSubmit(false)
        }

    }

    const findDuplicateIndexes = (arr: string[]) => {
        const seen: Map<string, number[]> = new Map();

        arr.forEach((item, index) => {
            if (seen.has(item)) {
                seen.get(item)!.push(index);
            } else {
                seen.set(item, [index]);
            }
        });

        let duplicateIndexes: number[] = [];
        seen.forEach((indexes) => {
            if (indexes.length > 1) {
                duplicateIndexes = duplicateIndexes.concat(indexes);
            }
        });

        console.log(duplicateIndexes, "findDuplicateIndexes", arr);


        arr.map((i: any, index: number) => {
            const element = document.getElementById(`delivery-end-point-${index}`);
            if (element) {
                if (duplicateIndexes.includes(index)) {
                    element.style.borderColor = 'red'; // Change to the desired color
                } else {
                    element.style.borderColor = '#d9d9d9';
                }
            }
        })

        setDuplicates(duplicateIndexes)

    };

    const handleCreateContactFormChange = (values: any) => {
        const formData = form.getFieldsValue()
        const errorCount = form.getFieldsError([
            "additionalVAT",
            "applicableTaxes",
            "contractType",
            "createDeliveryDtoList",
            "creditDetail",
            "dcq",
            "deliveryPoint",
            "description",
            "endDate",
            "exchangeRate",
            "exchangeRateDef",
            "flexibility",
            "invoiceCycle",
            "linkageDef",
            // "linkagePercent",
            "linkagePrice",
            "linkageFormula",
            "linkageType",
            "linkageValue",
            "maxContractFlex",
            "maxSupplyFlex",
            "minContractFlex",
            "minSupplyFlex",
            "paymentDueDate",
            "paymentSecurity",
            "price",
            "startDate",
            "supplyOrPayLevel",
            "takeOrPayLevel",
            "tariffPath",
            "tariffZone",
            "taxOnTariff",
            "tenure",
            "totalQuantity",
            "transportationTariff"
          ]).filter(({ errors }) => errors.length).length

        console.log("formData", formData)
        let submitEnable = true
        const formContractType = form.getFieldValue("contractType")
        for (const key in formData) {
            if(formContractType !== "EXCHANGE"){
                if (!formData[key] && key !== "description" && key !== "tradeId") {
                    submitEnable = false;
                }
            } else {
                if (!formData[key] && key !== "description") {
                    submitEnable = false;
                }
            }
        }
        const deliveryEPs = form.getFieldValue("createDeliveryDtoList")

        let hasDuplicate = deliveryEPs.some((val: any, i: any) => deliveryEPs.indexOf(val) !== i)

        findDuplicateIndexes(deliveryEPs)

        setSubmitEnable(submitEnable && !hasDuplicate && errorCount === 0)
    }



    return (
        <>

            <div style={{color: "#6B6B6B"}}>
                <Typography.Title style={{fontWeight: 600 }} level={5}><span style={{color: theme.token.colorPrimary}}>Enquiry ID:</span> {id}</Typography.Title>
            </div>
            <br />
            <br />


            {(state?.status === "PROPOSAL_TO_BE_SENT" || state?.status === "REMARKS_RECEIVED" || state?.status === EnquiryStatus.ENQUIRY_CANCELLED) &&
                <>
                    {state?.status === "REMARKS_RECEIVED" && <div style={{ position: "relative", paddingBottom: 80 }}>
                        <div style={{ ...statusStyle, background: "rgba(27, 120, 223, 0.08)", border: "solid 1px #E2E2E2" }}>
                            <div style={{ fontSize: 18, display: "flex", gap: 20 }}>
                                <span style={{ color: "rgba(27, 120, 223, 1)" }}>Proposal Remark</span>
                            </div>
                        </div>
                    </div>}
                    <Form
                        className="contract-form"
                        form={form}
                        onFinish={createEnquiry}
                        name="contract-details"
                        layout={undefined}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        encType='multipart/form-data'
                        autoComplete="off"
                    >

                        <div style={{ display: "flex", justifyContent: "center" }}>

                            <div style={{ width: "85%", }}>
                                {state?.status === "REMARKS_RECEIVED" && <Link target="_blank" to={proposals?.items[0]?.proposalUrl} style={{ padding: "7px 14px 7px 14px", display: "flex", justifyContent: "center", alignItems: "center", gap: 14, border: "solid 1px #E2E2E2", borderRadius: 11, borderColor: "#E2E2E2", background: "white", width: "fit-content", marginBottom: 28, cursor: "pointer" }}>
                                    <FilePdfOutlined style={{ color: "#71A84D" }} />
                                    <Typography.Text style={{ color: "#71A84D" }} >View Proposal ID-{proposals?.items[0]?.id}</Typography.Text>
                                </Link>}
                                {state?.status === "REMARKS_RECEIVED" && <>
                                    <Typography.Text style={{ fontSize: 14, marginLeft: 20, display: "block", marginBottom: 8 }}>Remark</Typography.Text>
                                    <Input.TextArea disabled={true} value={proposals?.items[0]?.remarks} rows={4} style={{ marginBottom: 28 }} />
                                </>}
                                <Typography.Text style={{ marginLeft: 20, display: "block" }}>Tenure <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                                <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10 }}>


                                    <Row gutter={40} style={{ alignItems: "center" }}>
                                        <Col span={6}>
                                            <Form.Item label="From"
                                                name="startDate"
                                                rules={[{ required: true, message: 'Please input contract start date!', type: 'date' }]} >
                                                <DatePicker allowClear={false} disabled={id ? true : false} suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: theme.token.colorPrimary }} />} disabledDate={(date) => {
                                                    return date && date.valueOf() < dayjs().valueOf()
                                                }}
                                                    onChange={() => { form.resetFields(["endDate"]); getTenure() }}
                                                    size='large' style={{ width: "100%", border: `solid 1px ${theme.token.colorPrimary}` }} format='DD/MM/YYYY' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={1} style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 10, paddingLeft: "0px", paddingRight: "0px" }}>
                                            <img width="90%" src="/assets/date-line-long.png" />
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="To"
                                                name="endDate"
                                                rules={[{ required: true, message: 'Please input contract end date!', type: 'date' }]} >
                                                <DatePicker allowClear={false} disabled={id ? true : false} onChange={getTenure} suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: theme.token.colorPrimary }} />} disabledDate={(date) => {
                                                    return date && date.valueOf() < dayjs(form.getFieldValue("startDate"), "DD/MM/YYYY").add(1, "day").valueOf()
                                                }} size='large' style={{ width: "100%", border: `solid 1px ${theme.token.colorPrimary}` }} format='DD/MM/YYYY' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item label="Tenure"
                                                name="tenure"
                                                rules={[{ required: false }]} >
                                                <Input disabled size="large" />
                                            </Form.Item>
                                        </Col>
                                    </Row>



                                </div>

                                <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Quantity <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                                <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10 }}>


                                    <Row gutter={40} style={{ alignItems: "center" }}>

                                        <Col span={11}>
                                            <Form.Item label="Daily Quantity (MMBTU)"
                                                name="dcq"
                                                rules={[
                                                    { required: true, message: 'Please input dcq!' },
                                                    () => ({
                                                      validator(_, value) {
                                                        if (isNaN(value) || parseInt(value) < 0) {
                                                            return Promise.reject(new Error('only numbers allowed'));
                                                        }
                                                        if (value && !/^\d+(\.\d{1,3})?$/.test(value.toString())) {
                                                          return Promise.reject(new Error('3 decimal places allowed'));
                                                        }
                                                        return Promise.resolve();
                                                      }
                                                    })
                                                  ]}
                                                >
                                                <Input disabled={id ? true : false}  onChange={getDcq} onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 10, paddingLeft: "0px", paddingRight: "0px" }}>
                                            <img width="90%" src="/assets/dcq-line-long.png" />
                                        </Col>
                                        <Col span={11}>
                                            
                                            <Form.Item label="Total Contract Quantity MMBTU"
                                                name="totalQuantity">
                                                <InputNumber disabled onFocus={stopScroll} type='number' style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>

                                <div style={{ marginTop: 30 }}>
                                    <Row gutter={40} style={{ alignItems: "center" }}>

                                        <Col span={16}>
                                            <Form.List
                                                name="createDeliveryDtoList"
                                                initialValue={[undefined]}
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 1) {
                                                                return Promise.reject(new Error('At least 1 delivery-endpoint'));
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Form.Item
                                                                label={index === 0 ? 'Delivery End Points' : ''}
                                                                required={true}
                                                                key={field.key}
                                                            >
                                                                <Form.Item
                                                                    {...field}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         whitespace: true,
                                                                    //         message: "Please input delivery-endpoint",
                                                                    //     },
                                                                    // ]}
                                                                    noStyle
                                                                >
                                                                    {/* <Input onChange={()=>form.getFieldsValue()} size="large" placeholder="delivery endpoint" style={{ width: '60%' }} /> */}
                                                                    <Input disabled={id ? true : false} placeholder="Enter Delivery Point" style={{ width: 400, background: "#f8f8f8" }} size="large" />
                                                                </Form.Item>
                                                                {" "}
                                                                {/* {fields.length - 1 === index ? (
                                                                    <PlusSquareFilled
                                                                        style={{ color: "#2C66E3" }}
                                                                        onClick={() => add()}
                                                                    />
                                                                ) : null}
                                                                {" "}
                                                                {fields.length > 1 && index !== 0 ? (
                                                                    <img width={16} src="/assets/delete.png"
                                                                        style={{ color: "#FF3B30", position: "absolute", marginTop: 10, marginLeft: 4, cursor: "pointer" }}
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null} */}

                                                            </Form.Item>
                                                        ))}
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                    </Row>
                                </div>

                                <div style={{ marginTop: 30 }}>
                                    <Row gutter={40} style={{ alignItems: "center" }}>

                                        <Col span={24}>
                                            <Form.Item label={<Typography.Text style={{ marginLeft: 20 }}>Description</Typography.Text>}
                                                name="description"
                                                rules={[{ required: false }]} >
                                                <Input.TextArea disabled={id ? true : false} rows={6} placeholder="Add your description here..." style={{ width: "100%", background: "#f8f8f8", border: "none" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>

                                {state?.status !== EnquiryStatus.ENQUIRY_CANCELLED && <div style={{ marginTop: 30 }}>
                                    <Row gutter={40}>

                                        <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                                            Upload signed Proposal Details
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                style={{ margin: 0 }}
                                                name="file"
                                                rules={[{ required: true, message: "Please enter file (less than 5MB)" }]}
                                                getValueFromEvent={({ file }) => { return file.originFileObj }}
                                            >

                                                <Upload
                                                    name='file'
                                                    accept="application/pdf"
                                                    maxCount={1}
                                                    listType="text"
                                                    onChange={handleFormChange}
                                                    customRequest={dummyRequest}
                                                    beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields(["file"]))}
                                                >
                                                    <Button
                                                        style={{ display: "flex", alignItems: "center", gap: 26, height: "auto", width: "100%" }}
                                                        type="dashed" size="large"
                                                        icon={<img width="95%" src="/assets/upload.png" />}
                                                    >
                                                        <span style={{ textAlign: "left", marginBlock: 10 }}>Upload Proposal<br />
                                                            <Typography.Text type="secondary">File type: PDF</Typography.Text >
                                                        </span>
                                                        <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} />
                                                    </Button>
                                                </Upload>

                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>}

                                {state?.status !== EnquiryStatus.ENQUIRY_CANCELLED && <div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                                    <Space size="large">
                                        <Button loading={submitting} disabled={submitting || !enableSubmit} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Space>
                                </div>}

                            </div>
                        </div>

                    </Form>
                </>}

            {state?.status === "CONTRACT_NOT_SENT" &&
                <>
                    <div style={{ position: "relative" }}>
                        <div style={{ ...statusStyle, background: "rgba(113, 168, 77, 0.08)", border: "solid 1px #E2E2E2" }}>
                            <div style={{ fontSize: 18, display: "flex", gap: 20 }}>
                                <CheckCircleFilled style={{ color: "#71A84D", fontSize: 24 }} />

                                <span style={{ color: "#71A84D" }}>Proposal Approved</span>
                            </div>
                        </div>
                    </div>


                    <div style={{ display: "flex", alignItems: "center", paddingTop: 100, flexDirection: "column" }}>
                        <div style={{ width: "85%", marginTop: 10 }}>
                            <div style={cardStyle}>
                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                    <img src="/assets/enquiry-icon.png" />
                                    <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>View Proposal ID-{proposals?.items[0].id}</Typography.Text>
                                </div>

                                <div style={{ display: "flex", gap: 28 }}>
                                    <div onClick={(e: any) => { handleDownload(e, proposals?.items[0].proposalUrl) }} style={{ textAlign: "center", color: theme.token.colorPrimary, cursor: "pointer" }}>
                                        <DownloadOutlined style={{ fontSize: 24 }} /><br />
                                        Download
                                    </div>
                                    <Link target="_blank" to={proposals?.items[0].proposalUrl} style={{ textAlign: "center", color: "rgba(27, 120, 223, 1)", cursor: "pointer" }}>
                                        <EyeOutlined style={{ fontSize: 24 }} /><br />
                                        View File
                                    </Link>
                                </div>
                            </div>

                            <div style={{ marginTop: 40 }}>
                                <Typography.Text style={{ fontSize: 14, marginLeft: 20, display: "block", marginBottom: 8 }}>Remark</Typography.Text>
                                <Input.TextArea disabled={true} value={proposals?.items.filter((item: any) => item.adminStatus !== ProposalStatus.SIGNED)[0]?.remarks} rows={4} style={{ marginBottom: 28 }} />
                            </div>
                        </div>

                        <Button onClick={() => setDefaultTab("client-details-6")} style={{ width: 200, marginTop: 50 }} size="large" type="primary">Send Contract</Button>
                    </div>

                </>}

            {/* {state?.status === "CONTRACT_NOT_SENT" && showUploader &&
                <div style={{ ...cardStyle, border: "none", display: "block" }}>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        <Typography.Title level={3}>
                            Please upload a signed copy of the signed contract in PDF format.
                        </Typography.Title>
                        <Divider />
                    </div>


                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 40px" }}>
                        <div>
                            <Typography.Text style={{ fontSize: 18 }}>Upload signed <br />Stamped Contract Details</Typography.Text>
                        </div>
                        <div>
                            <Form form={form} layout="vertical">
                                <Form.Item
                                    style={{ margin: 0 }}
                                    name="file"
                                    rules={[{ required: true }]}
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        onChange={handleFormChange}
                                        name='file'
                                        accept="application/pdf"
                                        maxCount={1}
                                        listType="text"
                                        customRequest={dummyRequest}
                                    >
                                        <Button
                                            style={{ display: "flex", alignItems: "center", gap: 26, height: "auto", justifyContent: "space-between" }}
                                            type="dashed" size="large"
                                            icon={<img src="/assets/upload.png" width="95%" />}
                                        >
                                            <span style={{ textAlign: "left", marginBlock: 10 }}>Upload Contract<br />
                                                <Typography.Text type="secondary">File type: PDF</Typography.Text >
                                            </span>
                                            <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} />
                                        </Button>
                                    </Upload>

                                </Form.Item>
                            </Form>
                        </div>
                    </div>

                    <div style={{ textAlign: "center", marginTop: 48, display: "flex", justifyContent: "center", gap: 20 }}>
                        <Button style={{ borderColor: theme.token.colorPrimary, color: theme.token.colorPrimary, width: 200 }} onClick={() => setShowUploader(false)} size="large" type="default">
                            Back
                        </Button>
                        <Button disabled={!enableSubmit} style={{ width: 200 }} size="large" type="primary" onClick={() => sendConract()}>
                            Submit
                        </Button>
                    </div>

                </div>
            } */}


            {state?.status === "CONTRACT_REGISTRATION_PENDING" &&
                <>
                    <div style={{ position: "relative" }}>
                        <div style={{ ...statusStyle, background: "rgba(113, 168, 77, 0.08)", border: "solid 1px #E2E2E2" }}>
                            <div style={{ fontSize: 18, display: "flex", gap: 20 }}>
                                <CheckCircleFilled style={{ color: "#71A84D", fontSize: 24 }} />

                                <span style={{ color: "#71A84D" }}>Signed Contract Received </span>
                            </div>
                        </div>
                    </div>


                    <div style={{ display: "flex", alignItems: "center", paddingTop: 100, flexDirection: "column" }}>
                        <div style={{ width: "85%", marginTop: 10 }}>
                            <div style={cardStyle}>
                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                    <img src="/assets/enquiry-icon.png" />
                                    <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>Signed Contract <br />from client</Typography.Text>
                                </div>

                                <div style={{ display: "flex", gap: 28 }}>
                                    <div onClick={(e: any) => { handleDownload(e, state?.signedContractUrl, "contract") }} style={{ textAlign: "center", color: theme.token.colorPrimary, cursor: "pointer" }}>
                                        <DownloadOutlined style={{ fontSize: 24 }} /><br />
                                        Download
                                    </div>
                                    <Link target="_blank" to={state?.signedContractUrl} style={{ textAlign: "center", color: "rgba(27, 120, 223, 1)", cursor: "pointer" }}>
                                        <EyeOutlined style={{ fontSize: 24 }} /><br />
                                        View File
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Form
                        form={form}
                        onFinish={createContract}
                        scrollToFirstError
                        name="contract-details"
                        className="contract-form"
                        onValuesChange={handleCreateContactFormChange}
                        layout={undefined}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        encType='multipart/form-data'
                        autoComplete="off"
                        style={{ marginTop: 40 }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>

                            <div style={{ width: "85%" }}>
                                <div>
                                    <Row gutter={40} style={{ alignItems: "center" }}>

                                        <Col span={6}>
                                            <Form.Item label="Contract Type"
                                                name="contractType"
                                                rules={[{ required: true }]} >
                                                <Select onChange={(value)=>{
                                                    setContractType(value)
                                                    form.setFields([
                                                        {
                                                            name: 'tradeId',
                                                            errors: [],
                                                        },
                                                    ]);
                                                }} size="large">
                                                    <Select.Option value="BILATERAL">
                                                        BILATERAL
                                                    </Select.Option>
                                                    <Select.Option value="TENDER">
                                                        TENDER
                                                    </Select.Option>
                                                    <Select.Option value="EXCHANGE">
                                                        EXCHANGE
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <Typography.Text style={{ marginLeft: 20, display: "block" }}>Tenure <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                                <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>


                                    <Row gutter={40} style={{ alignItems: "center" }}>
                                        <Col span={6}>
                                            <Form.Item label="From"
                                                name="startDate"
                                                rules={[{ required: true, message: 'Please input contract start date!', type: 'date' }]} >
                                                <DatePicker allowClear={false} suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: theme.token.colorPrimary }} />} disabledDate={(date) => {
                                                    return date && date.valueOf() < dayjs().valueOf()
                                                }}
                                                    onChange={() => { form.resetFields(["endDate"]); getTenure(); getDcq() }}
                                                    size='large' style={{ width: "100%", border: `solid 1px ${theme.token.colorPrimary}` }} format='DD/MM/YYYY' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={1} style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 10, paddingLeft: "0px", paddingRight: "0px" }}>
                                            <img width="90%" src="/assets/date-line-long.png" />
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="To"
                                                name="endDate"
                                                rules={[{ required: true, message: 'Please input contract end date!', type: 'date' }]} >
                                                <DatePicker allowClear={false} onChange={() => { getTenure(); getDcq() }} suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: theme.token.colorPrimary }} />} disabledDate={(date) => {
                                                    return date && date.valueOf() < dayjs(form.getFieldValue("startDate"), "DD/MM/YYYY").add(1, "day").valueOf()
                                                }} size='large' style={{ width: "100%", border: `solid 1px ${theme.token.colorPrimary}` }} format='DD/MM/YYYY' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item label="Tenure"
                                                name="tenure"
                                                rules={[{ required: false }]} >
                                                <Input disabled size="large" />
                                            </Form.Item>
                                        </Col>
                                    </Row>



                                </div>

                                <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Quantity <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                                <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>


                                    <Row gutter={40} style={{ alignItems: "center" }}>

                                        <Col span={11}>
                                            <Form.Item label="Daily Quantity (MMBTU)"
                                                name="dcq"
                                                rules={[
                                                    { required: true, message: 'Please input dcq!' },
                                                    () => ({
                                                      validator(_, value) {
                                                        if (isNaN(value) || parseInt(value) < 0) {
                                                            return Promise.reject(new Error('only numbers allowed'));
                                                        }
                                                        if (value !== null && !/^\d+(\.\d{1,3})?$/.test(value.toString())) {
                                                          return Promise.reject(new Error('3 decimal places allowed'));
                                                        }
                                                        return Promise.resolve();
                                                      }
                                                    })
                                                  ]}
                                                >
                                                <Input onChange={getDcq} onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 10, paddingLeft: "0px", paddingRight: "0px" }}>
                                            <img width="90%" src="/assets/dcq-line-long.png" />
                                        </Col>
                                        <Col span={11}>
                                            
                                            <Form.Item label="Total Contract Quantity MMBTU"
                                                name="totalQuantity">
                                                <Input disabled onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>

                                <div style={{ marginTop: 30 }}>
                                    <Row gutter={40} style={{ alignItems: "center" }}>

                                        <Col span={16}>
                                            <Form.List
                                                name="createDeliveryDtoList"
                                                initialValue={[undefined]}
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 1) {
                                                                return Promise.reject(new Error('At least 1 delivery-endpoint'));
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Form.Item
                                                                label={index === 0 ? 'Delivery End Points' : ''}
                                                                required={true}
                                                                key={field.key}
                                                            >
                                                                <Form.Item
                                                                    {...field}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            // whitespace: true,
                                                                            validator: async (_, value) => {
                                                                                if (!value) {
                                                                                    return Promise.reject(new Error("Please input delivery-endpoint"));
                                                                                }
                                                                            }
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    {/* <Input onChange={()=>form.getFieldsValue()} size="large" placeholder="delivery endpoint" style={{ width: '60%' }} /> */}
                                                                    <Input onInput={(e:any) => e.target.value = e.target.value.toUpperCase()} id={`delivery-end-point-${index}`} placeholder="Enter Delivery Point" style={{ width: 400 }} size="large" />
                                                                </Form.Item>
                                                                {" "}
                                                                {fields.length - 1 === index && index < 4 ? (
                                                                    <PlusSquareFilled
                                                                        style={{ color: "#2C66E3", marginLeft: 4 }}
                                                                        onClick={() => add()}
                                                                    />
                                                                ) : null}
                                                                {" "}
                                                                {fields.length > 1 && index !== 0 && index < 5 ? (
                                                                    <img width={16} src="/assets/delete2.png"
                                                                        style={{ color: "#FF3B30", position: "absolute", marginTop: 11, marginLeft: 4, cursor: "pointer" }}
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null}

                                                            </Form.Item>
                                                        ))}
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                    </Row>
                                    {duplicates.length > 0 && <span style={{color: "red"}} >Delivery end points must be unique</span>}
                                </div>

                                <div style={{ marginTop: 30 }}>
                                    <Row gutter={40} style={{ alignItems: "center" }}>

                                        <Col span={16}>
                                            <Form.Item label="Description"
                                                name="description"
                                                rules={[{ required: false }]} >
                                                <Input.TextArea rows={4} placeholder="Add your description here..." style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>

                                <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Linkage <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                                <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>
                                    <Row gutter={10} style={{ alignItems: "center" }}>
                                        <Col span={11}>
                                            <Form.Item rules={[{
                                                required: true
                                            }]} name="linkageFormula" label="Linkage Formula">
                                                <Input placeholder="e.g., 16.80%" style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        {/* <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#979797" }}>
                                            of
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item rules={[{ required: false }]} name="linkageType" label={<></>}>
                                                <Input placeholder="e.g., Brent" style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col> */}

                                        <Col span={11}>
                                            <Form.Item rules={[{ required: true }]} name="linkageDef" label="Linkage Def">
                                                <Input placeholder="e.g., 3 months Brent Avg." style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#979797" }}>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item rules={[{
                                                required: true, validator: async (_, value) => {
                                                    if (isNaN(value) || parseInt(value) < 0) {
                                                        return Promise.reject(new Error('only numbers allowed'));
                                                    }

                                                }
                                            }]} name="linkageValue" label="Linkage Value">
                                                <Input placeholder="e.g., 84" onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item rules={[{
                                                required: true
                                            }]} name="linkagePrice" label="Linkage Price">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>

                                <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Applicable Exchange Rate<span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                                <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>
                                    <Row gutter={20} style={{ alignItems: "center" }}>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="exchangeRateDef" label="Exchange Rate Def">
                                                <Input placeholder="e.g., Monthly or Fortnightly" style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="exchangeRate" label="Exchange Rate">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </div>

                                <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Flexibility Range<span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                                <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>
                                    <Typography.Text style={{ fontSize: 14, color: "blue", paddingBottom: 16, display: "block" }}>Contract Limit</Typography.Text>

                                    <Row gutter={20} style={{ alignItems: "center" }}>
                                        <Col span={12}>
                                            <Form.Item rules={[{
                                                required: true, validator: async (_, value) => {
                                                    if (isNaN(value) || parseInt(value) < 0) {
                                                        return Promise.reject(new Error('only numbers allowed'));
                                                    }
                                                    if (value > 100) {
                                                        return Promise.reject(new Error('enter a value less than or equal to 100'));
                                                    }

                                                }
                                            }]} name="minContractFlex" label="Lower Limit" >
                                                <Input onChange={(e) => {form.setFieldValue("minSupplyFlex", e.target.value)
                                                    form.validateFields(["minSupplyFlex"])
                                                }} onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{
                                                required: true, validator: async (_, value) => {
                                                    if (isNaN(value) || parseInt(value) < 0) {
                                                        return Promise.reject(new Error('only numbers allowed'));
                                                    }
                                                    if (value > 100) {
                                                        return Promise.reject(new Error('enter a value less than or equal to 100'));
                                                    }

                                                }
                                            }]} name="maxContractFlex" label="Upper Limit">
                                                <Input onChange={(e) => {form.setFieldValue("maxSupplyFlex", e.target.value);
                                                    form.validateFields(["maxSupplyFlex"])
                                                }} onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </div>

                                <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>
                                    <Typography.Text style={{ fontSize: 14, color: "blue", paddingBottom: 16, display: "block" }}>Supply Limit</Typography.Text>

                                    <Row gutter={20} style={{ alignItems: "center" }}>
                                        <Col span={12}>
                                            <Form.Item rules={[{
                                                required: true, validator: async (_, value) => {
                                                    if (isNaN(value) || parseInt(value) < 0) {
                                                        return Promise.reject(new Error('only numbers allowed'));
                                                    }
                                                    if (value > 100) {
                                                        return Promise.reject(new Error('enter a value less than or equal to 100'));
                                                    }

                                                }
                                            }]} name="minSupplyFlex" label="Lower Limit">
                                                <Input onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{
                                                required: true, validator: async (_, value) => {
                                                    if (isNaN(value) || parseInt(value) < 0) {
                                                        return Promise.reject(new Error('only numbers allowed'));
                                                    }
                                                    if (value > 100) {
                                                        return Promise.reject(new Error('enter a value less than or equal to 100'));
                                                    }

                                                }
                                            }]} name="maxSupplyFlex" label="Upper Limit">
                                                <Input onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </div>

                                <div style={{ marginTop: 30 }}>
                                    <Row gutter={40}>

                                        <Col span={24}>
                                            <Form.Item rules={[{ required: contractTypeState === "EXCHANGE" }]} name="tradeId" label="Trade ID">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="tariffPath" label="Tariff Path">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="tariffZone" label="Tariff Zone">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="transportationTariff" label="Transportation Tariff">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="taxOnTariff" label="Tax On Transportation Tariff">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="applicableTaxes" label="Applicable Taxes">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="additionalVAT" label="Additional Surcharge on VAT">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="takeOrPayLevel" label="Take Or Pay Level">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="supplyOrPayLevel" label="Supply Or Pay Level">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="paymentSecurity" label="Payment Security">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="invoiceCycle" label="Invoice Cycle">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Payment Due Date"
                                                name="paymentDueDate"
                                                rules={[{ required: true, message: 'Please input contract paymentDueDate !' }]} >
                                                <Input size='large' style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="creditDetail" label="Credit Detail">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="deliveryPoint" label="Delivery Point">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="flexibility" label="Flexibility">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item rules={[{ required: true }]} name="price" label="Price">
                                                <Input style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>

                                    </Row>


                                </div>


                                {<div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                                    <Space size="large">
                                        <Button loading={submitting} disabled={submitting || !submitEnable} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Space>
                                </div>}

                            </div>
                        </div>

                    </Form >
                </>
            }

            {state?.status === EnquiryStatus.SIGNED_DOCUMENT_PENDING &&
                <>
                    <div style={{ position: "relative" }}>
                        <div style={{ ...statusStyle, background: "rgba(113, 168, 77, 0.08)", border: "solid 1px #E2E2E2" }}>
                            <div style={{ fontSize: 18, display: "flex", gap: 20 }}>
                                <CheckCircleFilled style={{ color: "#58d34d", fontSize: 24 }} />

                                <span style={{ color: "#58d34d" }}>Contract Sent</span>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", paddingTop: 100, flexDirection: "column" }}>
                        <div style={{ width: "90%", marginTop: 10 }}>
                            <div style={{ ...cardStyle, padding: 16 }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <img src="/assets/enquiry-icon.png" />
                                    <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>Contract Proposal</Typography.Text>

                                    <Link target="_blank" to={proposals?.items[0].proposalUrl} style={{ padding: 10, border: "dashed #E2E2E2 1px", borderRadius: 16, background: "#F8FAFF", marginLeft: 8, cursor: "pointer" }}>
                                        <Typography.Text style={{ fontSize: 16 }}>
                                            Contract Proposal
                                        </Typography.Text>
                                        <Typography.Text style={{ fontSize: 12, fontWeight: 400, color: "#B6B6B6", display: "block" }}>
                                            PDF - {proposals?.items[0]?.id} <span style={{ marginLeft: 20 }}>2mb</span>
                                        </Typography.Text>
                                    </Link>
                                </div>



                                <div style={{ display: "flex", gap: 20 }}>
                                    <div onClick={(e: any) => { handleDownload(e, state?.contractUrl) }} style={{ textAlign: "center", color: theme.token.colorPrimary, cursor: "pointer" }}>
                                        <DownloadOutlined style={{ fontSize: 24 }} /><br />
                                        Download
                                    </div>
                                    <Link target="_blank" to={state?.contractUrl} style={{ textAlign: "center", color: "rgba(27, 120, 223, 1)", cursor: "pointer" }}>
                                        <EyeOutlined style={{ fontSize: 24 }} /><br />
                                        View File
                                    </Link>
                                    <div onClick={resendConract} style={{ textAlign: "center", color: "#F2923A", cursor: "pointer" }}>
                                        <ReloadOutlined style={{ fontSize: 24 }} /><br />
                                        Resend
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ width: "90%", marginTop: 40 }}>
                            <div style={{ ...cardStyle, justifyContent: "center", gap: 32 }}>
                                <img width="85px" src="/assets/signed-document-pending.png" />
                                <Typography.Text style={{ fontSize: 18, fontWeight: 600 }}>Waiting for the signed Document from client</Typography.Text>
                            </div>
                        </div>

                    </div>
                </>

            }

            {(state?.status === "PROPOSAL_SENT" || state?.status === EnquiryStatus.SIGNED_DOCUMENT_PENDING || state?.status === EnquiryStatus.CONTRACT_NOT_SENT || state?.status === EnquiryStatus.REMARKS_RECEIVED) &&
                <>
                    {state?.status != "PROPOSAL_SENT" ? <div style={{ position: "relative", marginBottom: 125, marginTop: 48 }}>
                        <div style={{ ...statusStyle, background: "rgba(232, 247, 249, 1)" }} onClick={() => setHistoryOpen(!historyOpen)}>
                            <div style={{ ...tagStyle, color: "rgba(27, 120, 223, 1", background: "transparent" }}>
                                Proposal History
                                {historyOpen ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
                            </div>
                        </div>
                    </div> : <Divider orientationMargin="0" style={{ minWidth: "unset" }} orientation="left"> Proposal History</Divider>}
                    {(historyOpen || state?.status === "PROPOSAL_SENT") && state?.satus != EnquiryStatus.CONTRACT_NOT_SENT && <Table
                        rowClassName="table-row"
                        columns={columns as any}
                        onChange={handleSort}
                        dataSource={proposals?.items}
                        rowKey={(record) => record.id}
                        pagination={false}
                    />}
                </>
            }



            {modalContex}
        </>

    )
}