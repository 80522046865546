import { Typography } from "antd";
import ClientDetails from "./ClientDetails";
import KycDetails from "./KycDetails";
import BankDetails from "./BankDetails";
import PocDetails from "./PocDetails";
import { instance } from "../../../utils/api";
import { useEffect } from "react";

export default function RegistrationSummary({ setDefaultTab, approveOrReject, id, selectedUser, fetchingDetails, clientData, setClietData, bankDetails, setTabStates, setInProgrss, tabStates }: any) {

    useEffect(() => {
        getClientDetails()
    }, [])

    const getClientDetails = async () => {
        try {
            const res = await instance.get(`clientservice/clients/${id ? id : selectedUser.clientId}`);
            setClietData(res.data)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <img src="/assets/matrix-logo.png" style={{marginBottom: 20, marginTop: 20}} />
            <Typography.Title level={5} style={{ margin: 0, padding: 0, marginBottom: 40 }}>Registration Summary</Typography.Title>

            <ClientDetails tabStates={tabStates} updateStatus={clientData?.addressUpdateStatus} approveOrReject={approveOrReject} id={id ? id : selectedUser?.clientId} getClientData={getClientDetails} fetchingDetails={fetchingDetails} clientData={clientData} setClientData={setClietData} setDefaulttab={() => setDefaultTab("client-details-2")} />
            <br/>
            <KycDetails tabStates={tabStates} approveOrReject={approveOrReject} updateStatus={clientData?.kycUpdateStatus} clientData={clientData} registeredName={clientData?.companyRegisteredId ? {
                "label": clientData.companyRegisteredName,
                "value": clientData.companyRegisteredId,
                "key": clientData.companyRegisteredId
            } : null} getClientData={getClientDetails} id={id ? id : selectedUser?.clientId} setDefaulttab={() => setDefaultTab("client-details-3")} />
            <br/>

            <BankDetails tabStates={tabStates} approveOrReject={approveOrReject} updateStatus={clientData?.bankUpdateStatus} clientData={clientData} bankDetails={bankDetails} getClientData={getClientDetails} id={id ? id : selectedUser?.clientId} setDefaulttab={() => setDefaultTab("client-details-4")} />
            <br/>

            <PocDetails tabStates={tabStates} approveOrReject={approveOrReject} updateStatus={clientData?.pocUpdateStatus} setTabStates={setTabStates} getClientDetails={getClientDetails} clientData={clientData} id={id ? id : selectedUser?.clientId} setDefaulttab={() => setDefaultTab("client-details-5")} />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                    <Typography.Text>Signature</Typography.Text>
                    <div style={{ width: 200, height: 70, border: "solid 1px" }}>

                    </div>
                </div>


            </div>
        </>
    )
}