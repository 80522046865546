import { Button, Col, Form, Input, Row, Typography } from "antd";
import theme from "../../../utils/theme";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";

export default function PocUpdateModal({ approveOrReject, clientData, pocDetails }: any) {
    const auth = useAppSelector(authSelector);
    console.log("pocDetails", pocDetails)
    return (
        <>
            <Form
                style={{ marginTop: "20px" }}
                // disabled={loading}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                {clientData?.pocHistoryList.map((item: any, key: number) => {
                    return <Row gutter={24}>
                        {item && <Col span={24} style={{textAlign: "left", marginBottom: 10}}>
                            <Typography.Text style={{color: theme.token.colorPrimary}}>
                                Contact Person {pocDetails.findIndex((poc:any)=>poc.id == item.id) !== -1 ? pocDetails.findIndex((poc:any)=>poc.id == item.id)+1 : "New"}
                            </Typography.Text>
                        </Col>}

                        {Object.entries(clientData?.pocHistoryList?.[key] || {}).map(([objectKey, value]: [string, any]) => (
                           objectKey !== "requestId" && objectKey !== "id"  && <Col span={12}>
                                <Form.Item
                                    label={<span style={{textTransform: "capitalize"}}>{objectKey}</span>}
                                >
                                    <Input value={value} disabled size="large" />
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                })}

            </Form>

            {((auth.isClient && clientData?.pocUpdateStatus === "UPDATED_BY_ADMIN") || ((auth.isMainAdmin || auth.isAdmin) && clientData?.pocUpdateStatus === "UPDATED"))
            && <div style={{ display: "flex", gap: 40, justifyContent: "center", marginTop: 20 }}>
                <Button onClick={() => approveOrReject("POC_REJECTED")} className="reset-button-calendar" size="large" type="default">Reject</Button>

                <Button onClick={() => approveOrReject("POC_APPROVED")} style={{ width: 120 }} size="large" type="primary">Approve</Button>
            </div>}
        </>
    )
}