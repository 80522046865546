import axios from "axios";
import { keycloakConfig } from "./keycloak.config";
import { store } from "../app/store";
import { setUser } from "../features/auth/userSlice";
import { setAuth } from "../features/auth/authSlice";
import { instance } from "./api";
import { setDashboard } from "../features/auth/dashboardSlice";
import Lottie from "lottie-react";
import { Typography, message } from "antd";
import SuccessAnimation from "../utils/success.json";
const _ = require('lodash');

const { clientId, baseURL } = keycloakConfig;

export const SignOut = async () => {
    const refreshToken = store.getState().auth.refreshToken;
    try {
        await axios.post(`${baseURL}/realms/Matrix/protocol/openid-connect/logout`, {
          client_id: clientId,
          refresh_token: refreshToken,
          // redirect_uri: process.env.REACT_APP_REDIRECT_ENDPOINT,
        },{
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(res => {
          if(res.status === 204) {
            store.dispatch(setUser({
              name: "",
              email: "",
            }));
            store.dispatch(setAuth({
              isAdmin: false,
              isMainAdmin: false,
              isAuth: false,
              isFinance: false,
              isClient: false,
              accessToken: "",
              refreshToken: "",
              idToken: "",
            }));
          }
          // window.open(process.env.REACT_APP_ENDPOINT, "_self");
        });
      } catch (err) {
        console.log("Error: ", err);
      }
}

export const fetchStats = async () => {
  try {
    const res = await instance.get("orderservice/nominate/details/count-null-qty")
    console.log(res.data, "stats")
    store.dispatch(setDashboard({
      stats: res.data,
    }))
  } catch (e) {
    console.log(e)
  }

}

export const stopScroll = (e:any) => {e.target.addEventListener("wheel", function (e:any) { e.preventDefault() }, { passive: false })}

export const getPaginationCountApi = (data:any, pagintion?: any) => {
  if(pagintion){
    const firstItem = (pagintion.current - 1) * pagintion.pageSize + 1;
    let lastItem = Math.min(pagintion.current * pagintion.pageSize, data?.items?.length);

    // Format the pagination string
    return lastItem === 0 ? ``:`${firstItem}-${lastItem} of ${data?.items?.length}`;
  } else {
    const firstItem = (((data?.currentPage ? data?.currentPage : 0)-1)*15)+1
    const lastItem = firstItem + (data?.items?.length ? data?.items?.length-1 : 0)

    return data?.totalItems === 0 || !data?.items ? `` : `${firstItem}-${lastItem} of ${data?.totalItems}`
  }
}

export const getPaginationCountClient = (data:any, pagination: any) => {
  const lastPage = Math.ceil(data.length / 15)
  const firstItem = ((pagination.current-1)*15)+1
  const lastItem = lastPage === pagination.current ? data.length : firstItem + 14

  return data.length === 0 ? `` : `${firstItem}-${lastItem} of ${data.length}`
}

export const handleMenuItemClick = (navigateTo: string, dashboard:any, modal:any,navigate:any,messageApi:any,setAllowNavigation:any, location:any, cancelled?:any, setCancelled?: any) => {
  
  let count;
  let totalCount
  // get current url
  const url = location.pathname.split("/")[2];
  if(dashboard.stats?.unSavedData && (url === "scheduled-list" || url === "allocation" || url === "intra-day" || url === "confirmation" || url === "nomination-entry" || url === "enquiry")){
    modal.confirm({
      title: "You have unsaved changes do you wish to proceed?",
      okText: 'Yes',
      cancelText: 'No',
      icon: null,
      centered: true,
      bodyStyle: {
          textAlign: "center"
      },
      okButtonProps: { size: "large", style: { width: 140, marginTop: 10 } },
      cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 } },
      onOk:()=>{
        // navigate(e.key.toString());
        navigate(navigateTo)
      }
    })
  } 
  else {
    // switch (url) {
    //   case "scheduled-list":
    //     count = dashboard.stats?.countScheduledQty;
    //     totalCount = dashboard.stats?.totalScheduledCount
    //     break;
    //   case "allocation":
    //     count = dashboard.stats?.countAllocatedQty;
    //     totalCount = dashboard.stats?.totalAllocatedCount
    //     break;
    //   case "intra-day":
    //     count = dashboard.stats?.countIntraDayQty;
    //     totalCount = dashboard.stats?.totalIntraDayCount
    //     break;
    //   case "confirmation": 
    //     count = location.pathname.split("/").length === 4 && dashboard.stats?.pendingConfirmationCount
    //     break;
    //   default:
    //     break; 
    // }
    // console.log({url})
    // if (count && count !== null && count !== 0 && !cancelled) {
    //   // Show alert if count is not zero
    //   messageApi.destroy();
    //   messageApi.open({
    //     type: 'info',
    //     icon: <></>,
    //     content: <div>You have {typeof(count) === "string" ? count : `${count}/${totalCount}`} entries left.
    //       <span 
    //         onClick={()=>{setCancelled(true);messageApi.destroy()}}
    //         style={{color: theme.token.colorPrimary, textDecoration: "underline", cursor: "pointer", fontWeight: "bold", marginLeft: 100}}>
    //           Cancel
    //       </span>
    //       </div>,
    //     duration: 0,
    //     style:{
    //       marginTop: "80vh",
    //       marginLeft: 210
    //     }
    //   });
      
    //   setAllowNavigation && setAllowNavigation(false);
    // } else {
    //   // Proceed with navigation if count is zero or undefined
    //   setCancelled(false)
    //   navigate(navigateTo);
    //   setAllowNavigation && setAllowNavigation(true);
    // }

    navigate(navigateTo);

  }
  
};


export const showSuccess = (msg: any,modal: any) => {

  let secondsToGo = 2;

  const instance = modal.success({
      // show success message along with image
      mask: true,
      maskClosable: true,
      closeIcon:<></>,
      closable: true,
      content: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Lottie loop={false} style={{ width: 198 }} animationData={SuccessAnimation} />
              <Typography.Title style={{ textAlign: "center", margin: 0, fontWeight: "normal", fontFamily: "'Poppins', sans-serif" }} level={3}>{msg}</Typography.Title>
          </div>
      ),
      footer: null,
      icon: null,
      bodyStyle: {paddingTop: 20, display: "flex", justifyContent: "center"}
  });

  const timer = setInterval(() => {
  secondsToGo -= 1;
  }, 1000);

  setTimeout(() => {
  clearInterval(timer);
  instance.destroy();
  }, secondsToGo * 1000);
}

export const hasDuplicates = (a:any[]) => {
  return _.uniq(a).length !== a.length; 
}

export const beforeUpload = (file:any, reset:any) => {
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    message.error('File must be smaller than 3MB!');
    reset()
  }
  return isLt5M;
};

export const hasMoreThanThreeDecimals = (value:any) => {
  // Convert value to string to handle both numbers and strings
  const valueStr = String(value);

  // Regular expression to match a number with more than 3 decimal places
  const regex = /^-?\d+\.\d{4,}$/;

  return regex.test(valueStr);
};