import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { columns } from "../../../interfaces/TableColumns/ContractHistory";

import { Table } from "antd";

export default function ContractHistoryModal({ contractHistory }: any) {
    const { handleSort } = useTableFilters("auditInfo.creationDate|DESC", '', true, false, true);


    console.log("contractHistory",contractHistory)

    return (
        <>
            <Table
                style={{marginTop: 28}}
                rowClassName="table-row"
                columns={(columns as any)}
                dataSource={contractHistory}
                rowKey={(record) => record.id}
                pagination={false}
                onChange={handleSort}
                className="white-header"
            />
        </>
    )
}