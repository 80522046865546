import { Button, Card, Modal, Space, Table, Typography } from 'antd';
import { instance } from '../../../utils/api';
import dayjs from 'dayjs';
import theme from '../../../utils/theme';
import { useNotify } from '../../../utils/hooks/useNotify';
import { useTableFilters } from '../../../utils/hooks/useTableFilters';
import { defaultColumns } from '../../../interfaces/TableColumns/Schedule';
import { ColumnTypes } from '../../../interfaces/TableColumns/NominationDetail';
import { useEditableCell } from '../../../utils/hooks/useEditableCell';
import { useEffect, useState } from 'react';

import { fetchStats, getPaginationCountApi, showSuccess } from '../../../utils/common';
import FilterSelect from '../../../components/FilterSelect';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setTotalScheduledCount, setUnSavedData } from '../../../features/auth/dashboardSlice';
import _isEqual from 'lodash/isEqual';

import EmptyList from './EmptyList';
import { flatMap } from 'lodash';
import { authSelector } from '../../../features/auth/authSlice';
const fitlerString = `nominatedQty|neq|null&gasDay|eqd|${dayjs().add(1, "day").format("DD/MM/YYYY")}`
function ScheduledList() {
    const [modal, modalContex] = Modal.useModal();
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch();
    const auth = useAppSelector(authSelector);



    const { tableParams, onFilerSearch, handleSort, onSearch , pagination} = useTableFilters("auditInfo.creationDate|ASC", fitlerString);
    const { data, originalData, editedItems, columns, components, setTableData, resetData, setEditedItems, setErrors, getErrors, errors,expandedCols } = useEditableCell(defaultColumns)
    const { notifyClients, rowSelection, hasSelected, sendingNotifications, openNotify, closeNotify, setOpenNotify } = useNotify("SCHEDULING",true, setTableData, "scheduledQty")
    const [hasErrors, setHasErrors] = useState(false)
    const [options, setOptions] = useState(null)

    useEffect(() => {
        fetchDetails()
    }, [tableParams])

    useEffect(()=>{
        const checkHasError = errors.find((item:any)=>item.error)
        setHasErrors(checkHasError ? true : false)
    }, [errors])

        // useEffect(()=>{
        //     data?.items?.map((item:any, index:number)=>{
        //      ["scheduledQty"].map((checkingIndex:string)=>{
        //       if(!item[checkingIndex] && originalData.items[index][checkingIndex]){
        //           setErrors((prevErr:any) => {
        //               const existingErrorIndex = prevErr.findIndex((err:any) => err.contractId === originalData.items[index].nomination.contractId && err.dataIndex === checkingIndex);
                
        //               if (existingErrorIndex !== -1) {
        //                 // Error already exists, update the message
        //                 const newErr = [...prevErr];
        //                 newErr[existingErrorIndex].error = `Fill the ${checkingIndex.replace(/([A-Z])/g, ' $1').trim()}.`;
        //                 return newErr;
        //               } else {
        //                 // Error doesn't exist, add a new error object
        //                 return [...prevErr, { contractId: originalData.items[index].nomination.contractId, error: `Fill the ${checkingIndex.replace(/([A-Z])/g, ' $1').trim()}.`, dataIndex: checkingIndex }];
        //               }
        //           }); 
        //          }  else {
        //           // Delete the specific key (record.gasDay)
        //           setErrors((prevErr:any) => {
        //               const newErr = prevErr.filter((err:any) => !(err.contractId === originalData.items[index].nomination.contractId && err.dataIndex === checkingIndex && err.error !== "only numbers allowed"));
        //               return newErr;
        //             });
        //          }  
        //      })
        //     })  
        //   },[data])

    useEffect(()=>{
        if(editedItems.length != 0){
            dispatch(setUnSavedData(true))
        } else {
            dispatch(setUnSavedData(false))
        }
    
        return () => {
            dispatch(setUnSavedData(false))
        }
    },[editedItems])

    const fetchDetails = async () => {
        setLoading(true)
        const params = {
            ...tableParams,
            filterAnd: fitlerString + `${tableParams.filterAnd ? `&${tableParams.filterAnd}` : ""}`
        }
        // &scheduledQty|neq|null
        try {
            const response = await instance.get(`orderservice/nominate/details`, { params });
            setTableData(response.data)
            if(!options){
                setOptions(response?.data?.items)
            }
            dispatch(setTotalScheduledCount(response.data.totalItems))
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const saveData = () => {
        Modal.destroyAll();
        let originalItems = flatMap(originalData?.items, (item:any)=>{
            return item.nominationDetails
        })
        originalItems = originalItems.map((item:any)=>{
            let newItem = {...item, scheduledQty: !item?.scheduledQty ? null : parseInt(item?.scheduledQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId} 

            return newItem
        })

        const newEditedItems = editedItems.filter((item:any)=>{
            const originalItem = originalItems.find((i)=>i.id === item.id)
            let newItem = {...item, scheduledQty: !item?.scheduledQty ? null : parseInt(item?.scheduledQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId}


            console.log(originalItem, newItem, "itemzz", (_isEqual(originalItem, newItem)))
            return !(_isEqual(originalItem, newItem))
        })

        let itemsToCheck = originalItems.filter((item:any)=>{
            return newEditedItems.find((i:any)=>i.gasDay === item.gasDay && i.contractId === item.contractId)
        })

        itemsToCheck = itemsToCheck.map((item:any) => {
            return newEditedItems.find((i:any) => i.id === item.id) ? newEditedItems.find((i:any) => i.id === item.id) : item
        })

        const aggregatedArr = Object.entries(itemsToCheck.reduce((acc:any, { gasDay, contractId, scheduledQty, nomination }:any) => {
            const key = `${gasDay}_${contractId}`;
            acc[key] = {
                gasDay,
                contractId,
                totalScheduledQty: acc[key]?.totalScheduledQty + Number(scheduledQty) || Number(scheduledQty),
                totalDCQ: nomination.dcq
            };
            return acc;
        }, {})).map(([, { gasDay, contractId, totalScheduledQty, totalDCQ }]:any) => ({
            gasDay,
            contractId,
            totalScheduledQty,
            totalDCQ
        }));

        console.log(aggregatedArr, "aggregatedArr")

        setErrors([])
        if (aggregatedArr.some((item:any) => item.totalScheduledQty > (item.totalDCQ*2)) && !auth.isClient) {
            // Notify the user about the error and exit the function
            Modal.confirm({
                // show success message along with image
                icon: null,
                width: 430,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 15 }}>
                        <img width={96} src="/assets/warning-icon.png" />
                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 10, marginTop: 20, fontSize: 16 }}>DCQ MMBTU Limit Exceeding</Typography.Text>

                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 20, color: "#707070" }}>Scheduled quantity for Contract ID {aggregatedArr.filter((item:any) => item.totalScheduledQty > (item.totalDCQ*2)).map((i:any, key:number)=> <b style={{color: "black"}}>{`${i.contractId}${key<aggregatedArr.length-1 ? ", " : ""}`}</b>)} is exceeding DCQ by <span style={{color: "red"}}>200%</span>. Do you wish to continue?</Typography.Text>
                    </div>
                ),
                okText: 'Yes',
                cancelText: 'No, edit',
                onOk: async () => {
                    await updateNomination(newEditedItems, true)
                },
                onCancel: () => {
                    const errorItems = aggregatedArr.filter((item:any) => item.totalScheduledQty > (item.totalDCQ*2))

                    document?.getElementById(`nomination-entry-${errorItems[0].contractId}`)?.scrollIntoView({ behavior: 'auto' /*or smooth*/, block: 'center' });
                },
                okButtonProps: {
                    size: "large",
                    type: "primary",
                    style: {background: theme.token.colorPrimary, width: 140, fontWeight: 600}
                },
                cancelButtonProps: {
                    size: "large",
                    type: "default",
                    style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary}
                }
            });
        } else {
            updateNomination(newEditedItems)
        }
        
    }

    const updateNomination = async (newEditedItems:any, dontConfirm?: boolean) => {
        if(!dontConfirm){
            modal.confirm({
                icon: null,
                title: "Do you want to proceed and send Schedule to the client?",
                okText: 'Yes',
                cancelText: 'No',
                onOk: async () => {
                    await updateNominationApi(newEditedItems)
                },
                onCancel() {
                    resetData();
                },
                centered: true,
                bodyStyle: {
                    textAlign: "center"
                },
                okButtonProps: { size: "large", style: { width: 140, marginTop: 10 } },
                cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 } }
            });
        } else {
            updateNominationApi(newEditedItems)
        }
        
    }

    const updateNominationApi = async (newEditedItems:any) => {
        const formData: any = [];
        newEditedItems.forEach((item: any) => {
            formData.push({
                "id": item.id,
                "gasDay": item.gasDay,
                "scheduledQty": item?.scheduledQty,
                "sourceId": item?.sourceId,
                "pipelineId": item?.pipelineId,
                "deliveryEndPoint": item?.deliveryEndPoint,
                "deliveryId": item?.deliveryId,
            });
        })
        try {
            await instance.put(`/orderservice/nominate/details/schedule`, { orders: formData })
            fetchStats()
            showSuccess(<>Scheduled saved Successfully.</>, modal)
            setEditedItems([])
            setErrors([])
            fetchDetails()
        } catch (error: any) {
            const errors = error?.response?.data?.errorMessage
            if (errors) {
                const filteredResult = getErrors(errors)
                setErrors(filteredResult)
            }
            console.log(error)
        }
    }



    const handleNotify = () => {
        modal.confirm({
            centered: true,
            icon: null,
            title: 'Do you want to proceed and send Confirmation to the client?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                notifyClients(fetchDetails)
            },
            bodyStyle: {
                textAlign: "center"
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 } },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 } }
        });
    }

    const saveDisabled = () => {
        let emptyItems = editedItems.filter((item:any)=>{
            return (!item?.scheduledQty)
        })
        let originalItems = flatMap(originalData?.items, (item:any)=>{
            return item.nominationDetails
        })
        originalItems = originalItems.map((item:any)=>{
            let newItem = {...item, scheduledQty: !item?.scheduledQty ? null : parseInt(item?.scheduledQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId} 

            return newItem
        })
        emptyItems = emptyItems.filter((item:any)=>{
            const originalItem = originalItems.find((i)=>i.id === item.id)
            let newItem = {...item, scheduledQty: !item?.scheduledQty ? null : parseInt(item?.scheduledQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId}


            console.log(originalItem, newItem, "itemxx", (_isEqual(originalItem, newItem)))
            return !(_isEqual(originalItem, newItem))
        })
        const newEditedItems = editedItems.filter((item:any)=>{
            const originalItem = originalItems.find((i)=>i.id === item.id)
            let newItem = {...item, scheduledQty: !item?.scheduledQty ? null : parseInt(item?.scheduledQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId}


            console.log(originalItem, newItem, "itemzz", (_isEqual(originalItem, newItem)))
            return !(_isEqual(originalItem, newItem))
        })
        console.log("empty items", emptyItems)

        if (emptyItems.length > 0 || newEditedItems.length === 0) {
            return true
        }
        return false
    }
    

    return (
        <>
            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, marginTop: 0 }}>Scheduled List</Typography.Title>

            <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
                {modalContex}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <Space>
                        <Card style={{ border: "1px solid #71A84D" }} size="small">
                            <Typography.Text style={{ fontSize: 16, display: "block" }} type="secondary">Gas Day</Typography.Text>
                            <Typography.Text style={{ fontSize: 18 }}>{dayjs().add(1, "day").format("Do MMM' YY")}</Typography.Text>
                        </Card>
                        <Card style={{ border: "1px solid #71A84D" }} size="small">
                            <Typography.Text style={{ fontSize: 16, display: "block" }} type="secondary">Total Nominations for Tomorrow:</Typography.Text>
                            <Typography.Text style={{ fontSize: 18 }}>{data.totalItems}</Typography.Text>
                        </Card>
                    </Space>
                    <Space style={{ display: openNotify ? "none" : "flex" }}>
                        <FilterSelect onFilerSearch={onFilerSearch} options={options} onSearch={onSearch} />
                        <Space>
                            {(editedItems.length === 0) && <Button disabled={!originalData?.items?.length} onClick={() => { resetData(); setOpenNotify(true) }} size='large' type="primary">
                                Notify
                            </Button>}
                            <Button onClick={saveData} disabled={saveDisabled() || hasErrors} size='large' type="primary">
                                Save
                            </Button>
                            {editedItems.length !== 0 && <Button onClick={resetData} disabled={editedItems.length === 0} size='large'>
                                Cancel
                            </Button>}
                        </Space>
                    </Space>
                    <Space style={{ display: !openNotify ? "none" : "flex" }}>
                        <Button loading={sendingNotifications} onClick={handleNotify} size='large' type="primary" disabled={!hasSelected}>Send Email</Button>
                        <Button size='large' type="default" onClick={() => closeNotify()}>Cancel</Button>
                    </Space>
                </div>

                <div>
                    <Table
                        loading={loading}
                        onChange={handleSort}
                        components={components}
                        rowClassName={(record) => `${errors.find((i: any) => i.contractId === record.nomination.contractId) ? "table-row error-bg" : "table-row"} ${record.hasOwnProperty('nominationDetails') ? "parent-row" : "child-row"} ${record.nominationDetails?.length > 1 ? "mg-expandable-row" : ""}`}
                        columns={openNotify ? columns.map((i: any) => { return { ...i, onCell: (record: any, index: any) => expandedCols(record, index,i) } }) : columns as ColumnTypes}
                        dataSource={data?.items?.map((item: any) => {
                            return { ...item, nominationDetails: item.nominationDetails?.length > 1 ? item.nominationDetails : null }
                        })
                        }
                        pagination={{
                            pageSize: 15,
                            // total: data.totalItems,
                            // current: tableParams.page,
                            showPrevNextJumpers: false,
                            showSizeChanger: false,
                            position: ["bottomCenter"]
                        }}
                        rowSelection={openNotify ? { ...rowSelection, checkStrictly: false } : undefined}
                        rowKey={(record) => { return record.hasOwnProperty('nominationDetails') ? record.id + "parent" : record.id + "child" }}
                        footer={()=><div className='footer-pagination'>{data && getPaginationCountApi(data, pagination)}</div>}
                        scroll={{ x: true, 
                            // y: data?.totalItems > 0 ? "65vh" : undefined 
                        }}
                        showHeader={!data?.items || data?.items?.length === 0 ? false : true}
                        locale={loading ? undefined : { emptyText: <EmptyList customMessage={<>No Schedule Found</>}/> }}
                        expandable={{
                            childrenColumnName: "nominationDetails",
                            rowExpandable: (record) => record.nominationDetails.length > 1,
                            indentSize: 0,
                            expandIcon: () => null,
                            onExpand: (expanded, record) => {
                                const rowItem = document.querySelectorAll(`[data-row-key="${record.id}parent"]`);
                                // add class name to rowItem
                                if (rowItem.length > 0) {
                                    // Iterate through each rowItem and add the class name
                                    rowItem.forEach(item => {
                                        if (expanded) {
                                            item.classList.add("mg-expandabled-row")
                                        } else {
                                            item.classList.remove("mg-expandabled-row")
                                        }
                                    });
                                }
        
                            },
                            expandRowByClick: true,
                        }}
                    />
                </div>
            </div>
        </>
    )
};

export default ScheduledList;