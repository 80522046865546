import { Button, Col, Form, Input, Row } from "antd";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import ViewPDF from "../../../components/ViewPDF";

export default function BankUpdateModal({ approveOrReject, clientData }: any) {
    const auth = useAppSelector(authSelector);

    const renderLabel = (key: string) => {
        switch (key) {
            case "accountNumber":
                return "Account Number";
            case "bankName":
                return "Bank Name";
            case "branchName":
                return "Branch Name";
            case "accountName":
                return "Account Name";
            case "ifscCode":
                return "Account Name";
            // case "cancelledCheque":
            //     return "Cancelled Cheque";
            default:
                return key;
        }
    };

    return <>
        <Form
            style={{ marginTop: "20px" }}
            // disabled={loading}
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
        >
            <Row gutter={24}>
                {Object.entries(clientData?.bankDetailHistoryList?.[0] || {}).map(([key, value]: [string, any]) => 
                    key !== "cancelledCheque" && key !== "requestId"  && key !== "id" && <Col span={12} key={key}>
                        <Form.Item
                            label={`${renderLabel(key)}`}
                        >
                            <Input value={value} disabled size="large" />
                        </Form.Item>
                    </Col>
                )}
                {clientData?.bankDetailHistoryList?.[0]?.cancelledCheque && <Col span={12}>
                    <ViewPDF name="Cancelled Cheque" url={clientData?.bankDetailHistoryList?.[0]?.cancelledCheque} />
                </Col>}

            </Row>

        </Form>
        {((auth.isClient && clientData?.bankUpdateStatus === "UPDATED_BY_ADMIN") || ((auth.isMainAdmin || auth.isAdmin) && clientData?.bankUpdateStatus === "UPDATED"))
            && <div style={{ display: "flex", gap: 40, justifyContent: "center", marginTop: 20 }}>
                <Button onClick={() => approveOrReject("BANK_REJECTED")} className="reset-button-calendar" size="large" type="default">Reject</Button>

                <Button onClick={() => approveOrReject("BANK_APPROVED")} style={{ width: 120 }} size="large" type="primary">Approve</Button>
            </div>}
    </>
}