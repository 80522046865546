import { Tag, Tooltip, Typography } from 'antd';
import { NominationStatus, NominationStatusDisplay, nominationStatusColors } from '../../utils/healpers/constants';
import dayjs from 'dayjs';
import { ColumnTypes } from './NominationDetail';

export interface Item {
    id: number,
    gasDay: string,
    pipelineId: string,
    deliveryEndPoint: string,
    startDate?: string,
    endDate?: string,
    nominatedQty: number,
    scheduledQty: number,
    allocaedQty: number,
    dcq: number,
    deviation: string,
    status: string,
}


export const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex?: string })[] = [
    {
        title: 'S. no.',
        key: "s_no",
        align: "center",
        render: (text, record, index) => {
            if(record?.nominationDetails?.length >= 1){
                return         <div style={{ width: 50, display: "inline-block" }}>{index + 1}</div>

            } else {
                return <div style={{ width: 50, display: "inline-block" }}>{index + 1}</div>
            }
    }
        
    },
    {
        title: 'Gas Day',
        dataIndex: 'gasDay',
        key: 'gasDay',
        align: "center",
        render: (gasDay) => <div style={{ width: 90, display: "inline-block", position: "relative", height: 22 }}>
            <span style={{ position: "absolute", width: "100%", left: 0, zIndex: 1 }}>{dayjs(gasDay, "DD/MM/YYYY").format("DD MMM YYYY")}</span>
        </div>
    },
    {
        title: 'DCQ',
        dataIndex: 'dcq',
        key: 'dcq',
        align: "center",
        render: (item, record) => <div style={{ width: 100, display: "inline-block", position: "relative", height: 22 }}>
            <span style={{ position: "absolute", width: "100%", left: 0, zIndex: 1 }}>
                {record.nomination?.dcq}
            </span>
        </div>
    },
    {
        title: <>Total Supply<br />req. (MMBTU)</>,
        dataIndex: 'scheduledQty',
        align: "center",
        render: (scheduledQty) => <div style={{ minWidth: 150, display: "inline-block" }}>{scheduledQty}</div>
    },
    {
        title: <>Total Allocated<br />Qty. (MMBTU)</>,
        dataIndex: 'allocatedQty',
        key: 'allocatedQty',
        align: "center",
        render: (allocatedQty, record) => <div style={{ minWidth: 173, display: "inline-block" }}>{allocatedQty}</div>
    },
    {
        title: 'Deviation',
        dataIndex: 'deviation',
        key: 'deviation',
        render: (deviation) => <div style={{ width: 80, display: "inline-block" }}>{deviation ? deviation.toFixed(2) + "%" : "----"}</div>,
        align: "center",
    },
    
    {
        title: 'Pipeline ID',
        dataIndex: 'pipelineId',
        align: "center",
        render: (pipelineId, record) => {
            if(record?.nominationDetails?.length > 1){
                return <div style={{ minWidth: 173, display: "inline-block" }}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
            } else {
                return <div style={{ minWidth: 173, display: "inline-block" }}>{pipelineId}</div>
            }
        }
    },
    {
        title: 'Delivery end point',
        dataIndex: 'deliveryEndPoint',
        align: "center",
        render: (deliveryEndPoint:
            any
        , record: any) => {
            if(record?.nominationDetails?.length > 1){
                return <div style={{minWidth: 173, display: "inline-block"}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
            } else {
                return <Tooltip title={deliveryEndPoint}
                ><Typography.Text style={{width: 140}} ellipsis>
                                                {deliveryEndPoint}
                                            </Typography.Text></Tooltip>
            }
        },
    },
    {
        title: 'Status',
        dataIndex: 'nominationStatus',
        key: 'nominationStatus',
        render: (nominationStatus:
            NominationStatus
        , record: any) => {
            if(record?.nominationDetails?.length > 1){
                return <div style={{width: 100, display: "inline-block"}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
            } else {
                const displayValue = NominationStatusDisplay[nominationStatus];
                return <Tag style={{ fontSize: 14, textAlign: "center" }} color={nominationStatusColors[nominationStatus]}>{displayValue.split(" ")[0]}<br />{displayValue.split(" ")[1]}</Tag>
            }
        },
        align: "center"
    },
    // {
    //     dataIndex: 'deliveryId',
    //     align: "center",
    //     render:()=> null
    // },
];