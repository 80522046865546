import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Space, Typography } from "antd"
import { instance } from "../../../utils/api";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { showSuccess, stopScroll } from "../../../utils/common";
import { PlusSquareFilled } from "@ant-design/icons";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import theme from "../../../utils/theme";


function IntraDayForm({closeModal, fetchData}:any) {


    const [clients, setClients] = useState([]);
    const [form] = Form.useForm();
    const [contracts, setContracts] = useState([]);
    const [selectedClient, setSelectedClient] = useState<any>(null)
    const [selectedContract, setSelectedContract]= useState<any>(null)
    const [fetchingClients, setFetchingClients] = useState(false)
    const [fetchingContracts, setFetchingContracts] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [enableForm, setEnableForm] = useState(false)
    const [selectedDeliveries, setSelectedDeliveries] = useState<any>([])
    const auth = useAppSelector(authSelector);

    useEffect(() => {
        getClients();
    }, [])

    useEffect(() => {
        selectedClient && fetchContracts()
    }, [selectedClient])

    const getClients = async () => {
        setFetchingClients(true)
        try {
            const response = await instance.get(`/clientservice/clients/not-expired-contracts`);
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setFetchingClients(false)
    }

    const fetchContracts = async () => {
        // const endDate = form.getFieldValue("date-range") ? dayjs().format('DD/MM/YYYY') : null
        const params = {
            filterAnd: `endDate|gted|${dayjs().format("DD/MM/YYYY")}&startDate|lted|${dayjs().format("DD/MM/YYYY")}`
        } 
        try {
            setFetchingContracts(true)
            const response = await instance.get(`/clientservice/clients/${selectedClient?.id}/contract`, { params });
            setContracts(response.data)
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setFetchingContracts(false)
    }

    const handleClientChange = (value: any) => {
        handleReset()
        setSelectedClient(clients.filter((item: any) => item.id === value)[0])
        form.setFieldValue("client", value)
    }

    const handleReset = () => {
        form.resetFields();
        setContracts([]);
        setSelectedClient(null)
        fetchData()
    }


    const handleSubmit = async(values: any) => {
        Modal.destroyAll()

        const selectedContract = contracts.filter((i:any)=>i.id === values.contractID) as any
        let formData = {
            "clientId": values.client,
            "client": selectedClient.name,
            "contractId": values.contractID,
            "clientCode": selectedClient.clientCode,
            "tradeId": selectedContract[0].tradeId,
            "totalContractQty": selectedContract[0].totalQuantity,
            "dcq": values.dcq,
            "startDate": selectedContract[0]["startDate"],
            "endDate": selectedContract[0]["endDate"],
            "createIntradayNominationDetailDtoList": values.scheduledItems.map((item:any, key:any)=>{
                return {
                        "gasDay": dayjs().format("DD/MM/YYYY"),
                        "nominatedQty": values.nominatedItems[key].nominatedQty,
                        "confirmedQty": values.nominatedItems[key].confirmedQty,
                        "scheduledQty": item.scheduledQty,
                        "sourceId": values.sourceDetails[key].sourceId,
                        "pipelineId": values.sourceDetails[key].pipelineId,
                        "deliveryEndPoint": item.deliveryEndPoint,
                        "deliveryId": selectedContract[0].deliveryEndPoints.find((i:any)=>i.deliveryEndPoint === item.deliveryEndPoint).id
                    }
            })
        }


        const aggregatedArr = Object.entries(formData.createIntradayNominationDetailDtoList.reduce((acc:any, {gasDay, scheduledQty}:any) => {
            
            acc[gasDay] = acc[gasDay] ? acc[gasDay] + Number(scheduledQty) : Number(scheduledQty);
        
            return acc;
        }, {})).map(([gasDay, totalScheduledQty]) => ({ gasDay, totalScheduledQty }));

        console.log(aggregatedArr, "aggregatedArr")

        if (aggregatedArr.some((item:any) => item.totalScheduledQty > (formData.dcq*2)) && !auth.isClient) {
            // Notify the user about the error and exit the function
            Modal.confirm({
                // show success message along with image
                icon: null,
                width: 430,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 15 }}>
                        <img width={96} src="/assets/warning-icon.png" />
                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 10, marginTop: 20, fontSize: 16 }}>DCQ MMBTU Limit Exceeding</Typography.Text>

                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 20, color: "#707070" }}>Scheduled quantity is exceeding DCQ by <span style={{color: "red"}}>200%</span>. Do you wish to continue?</Typography.Text>
                    </div>
                ),
                okText: 'Yes',
                cancelText: 'No, edit',
                onOk: async () => {
                    addNomination(formData)
                },
                okButtonProps: {
                    size: "large",
                    type: "primary",
                    style: {background: theme.token.colorPrimary, width: 140, fontWeight: 600}
                },
                cancelButtonProps: {
                    size: "large",
                    type: "default",
                    style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary}
                }
            });
        } else {
            addNomination(formData)
        }
                
    }

    const addNomination = async (formData:any) => {
        setSubmitting(true)

        try {
            const res = await instance.post(`/orderservice/nominate/details/intraday`, formData)
            handleReset()
            closeModal()
            showSuccess(<>The new entry Successfully registered and updated!</>, Modal)
        } catch(e){
            console.log("e", e)
        }
        setSubmitting(false)

    }

    useEffect(()=>{
        // form.resetFields()
        form.setFieldValue("dcq", selectedContract?.dcq)
        form.setFieldValue("clientCode", selectedClient?.clientCode)
        form.setFieldValue("tradeId", selectedContract?.tradeId)
        form.setFieldValue("scheduledItems", [{
            scheduledQty: null,
            deliveryEndPoint: null
        }])
        form.setFieldValue("nominatedItems", [{
            nominatedQty: null,
            confirmedQty: null
        }])
        form.setFieldValue("sourceDetails", [{
            pipelineId: null,
            sourceId: null
        }])
    },[selectedContract])

    const checkFormFilled = () => {
        form.validateFields().then((values:any) => {
            setEnableForm(true)
        }).catch((err)=>{
            setEnableForm(false)
        })
        // if(formValues.client &&
        // formValues.confirmedQty &&
        // formValues.contractID &&
        // formValues.dcq &&
        // formValues.deliveryEndPoint &&
        // formValues.nominatedQty &&
        // formValues.pipelineId &&
        // formValues.scheduledQty &&
        // formValues.sourceId){
        //     setEnableForm(true)
        // } else {
        //     setEnableForm(false)
        // }
    }


    return (
        <div id="intraday-form-container" style={{width: 700}}>
            <Form
                name="intraday-form"
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                style={{ marginTop: 20 }}
                form={form}
                onFinish={handleSubmit}
                onChange={checkFormFilled}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Client"
                            name="client"
                            rules={[{ required: true, message: 'Please input client!' }]}
                        >
                            <Select disabled={fetchingClients} loading={fetchingClients} size="large" onChange={handleClientChange} placeholder="Company">
                                {clients.map((item: any, key) => {
                                    return <Select.Option className="nomination-client-rounded" key={key} value={item.id}>{item.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Contract ID"
                            name="contractID"
                            rules={[{ required: true, message: 'Please input contract ID!' }]}
                        >
                            <Select disabled={fetchingContracts} loading={fetchingContracts} onChange={(value)=>setSelectedContract(contracts.filter((i:any)=>i.id === value)[0])} size="large" placeholder="Contract">
                                {contracts.map((item: any, key) => {
                                    return <Select.Option className="nomination-client-rounded" key={key} value={item.id}>Contract-{item.id} {item.totalQuantity} MMBTU</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Client Code"
                            name="clientCode"
                            rules={[{ required: true, message: 'Please input client code!' }]}
                        >
                            <Input disabled style={{width: "100%"}} size="large" />
                        </Form.Item>
                    </Col>
                    {selectedContract?.tradeId && <Col span={12}>
                        <Form.Item
                            label="IGX Trade ID"
                            name="tradeId"
                            rules={[{ required: true, message: 'Please input tradeId!' }]}
                        >
                            <Input disabled style={{width: "100%"}} size="large" />
                        </Form.Item>
                    </Col>}
                    <Col span={24}>
                        <Divider orientationMargin="0" style={{ minWidth: "unset" }} orientation="left">{<b>Order Information</b>}</Divider>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="DCQ"
                            name="dcq"
                            rules={[{ required: true, message: 'Please input dcq!' },{ type: "number", min: 1 }]}
                        >
                            <InputNumber onFocus={stopScroll} type='number' disabled style={{width: "100%"}} size="large" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.List initialValue={[{
                            scheduledQty: null,
                            deliveryEndPoint: null
                        }]} name="scheduledItems">
                            {(fields, fieldOpt) => (
                                <div style={{padding: 20, borderRadius: 16, background: "aliceblue"}}>
                                    {fields.map((field, index) => (
                                        <Row key={field.key} gutter={10}>
                                            <Col span={11}>
                                                <Form.Item
                                                    className="scheduledQty"
                                                    label={index === 0 ? "Scheduled Qty" : null}
                                                    name={[field.name, "scheduledQty"]}
                                                    rules={[
                                                        { required: true, message: 'Please input scheduled qty!' },
                                                        () => ({
                                                          validator(_, value) {
                                                            if (value !== null && !/^\d+(\.\d{1,3})?$/.test(value.toString())) {
                                                              return Promise.reject(new Error('3 decimal places allowed'));
                                                            }
                                                            return Promise.resolve();
                                                          }
                                                        })
                                                      ]}
                                                >
                                                    <InputNumber onFocus={stopScroll} type='number' style={{width: "100%"}}  onChange={(value)=>{
                                                        const nominatedItems = form.getFieldValue("nominatedItems")
                                                        console.log("nominatedItems", nominatedItems)
                                                        const newNominatedItems = nominatedItems.map((i:any, key:any)=>{
                                                            if(key === field.name){
                                                                return {nominatedQty: value, confirmedQty: value}
                                                            } else {
                                                                return i
                                                            }
                                                        })
                                                        console.log("newNominatedItems", newNominatedItems)
                                                        form.setFieldValue("nominatedItems", newNominatedItems)
                                                    }} size="large" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2} style={{display: "flex", height: index === 0 ? "unset" : 40, alignItems: "center"}}>
                                                {fields.length-1 === index && index < 4 &&
                                                    index < selectedContract?.deliveryEndPoints?.length-1 &&
                                                    <PlusSquareFilled
                                                    style={{color: "#2C66E3", marginRight: 4}}
                                                    onClick={() => {
                                                        let nominatedItems = form.getFieldValue("nominatedItems")
                                                        nominatedItems.push({
                                                            nominatedQty: null,
                                                            confirmedQty: null
                                                        })
                                                        let sourceDetails = form.getFieldValue("sourceDetails")
                                                        sourceDetails.push({
                                                            pipelineId: null,
                                                            sourceId: null
                                                        })
                                                        form.setFieldValue("nominatedItems", nominatedItems)
                                                        form.setFieldValue("sourceDetails", sourceDetails)

                                                        fieldOpt.add()}}
                                                />
                                                }
                                                {index !=0 && 
                                                <div style={{marginTop: 3}}>
                                                    <img width={16} src="/assets/delete.png" onClick={() => {
                                                            let nominatedItems = form.getFieldValue("nominatedItems")
                                                            let newNominatedItems = nominatedItems.filter((i:any,key:any)=>
                                                                key !== field.name
                                                            )
                                                            let sourceDetails = form.getFieldValue("sourceDetails")
                                                            let newSourceDetails = sourceDetails.filter((i:any,key:any)=>
                                                                key !== field.name
                                                            )
                                                            form.setFieldValue("nominatedItems", newNominatedItems)
                                                            form.setFieldValue("sourceDetails", newSourceDetails)
                                                            fieldOpt.remove(field.name)
                                                            setSelectedDeliveries(form.getFieldValue("scheduledItems")?.map((i:any)=>i?.deliveryEndPoint))
                                                        }
                                                    } />
                                                </div>
                                                }
                                                
                                            </Col>

                                            <Col span={11}>
                                                <Form.Item
                                                    label={index === 0 ? "Delivery End Point" : null}
                                                    name={[field.name, "deliveryEndPoint"]}
                                                    rules={[{ required: true, message: 'Please input delivery end point!' }]}
                                                >
                                                    <Select size="large" style={{ width: "100%" }} onChange={()=>{
                                                        setSelectedDeliveries(form.getFieldValue("scheduledItems")?.map((i:any)=>i?.deliveryEndPoint))
                                                        console.log(`form.getFieldValue("scheduledItems")`,form.getFieldValue("scheduledItems"))
                                                    }}>
                                                    {selectedContract?.deliveryEndPoints?.map((item:any)=>{
                                                        return <Select.Option 
                                                            className="nomination-client-rounded"
                                                            disabled={selectedDeliveries.includes(item.deliveryEndPoint)} 
                                                            key={item.id} 
                                                            value={item.deliveryEndPoint}>
                                                                {item.deliveryEndPoint}
                                                            </Select.Option>
                                                    })}

                                                </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}

                                        
                                </div>
                            )}
                        </Form.List>
                    </Col>

                    <Col span={24}>
                        <Form.List initialValue={[{
                            nominatedQty: null,
                            confirmedQty: null
                        }]} name="nominatedItems">
                            {(fields, fieldOpt) => (
                                <div style={{padding: 20, marginTop: 20, borderRadius: 16, background: "aliceblue"}}>
                                    {fields.map((field, index) => (
                                        <Row key={field.key} gutter={10}>
                                            <Col span={11}>
                                                <Form.Item
                                                    label={index === 0 ? "Nominated Qty" : null}
                                                    name={[field.name, "nominatedQty"]}
                                                    rules={[{ required: true, message: 'Please input nominated qty!' }]}
                                                >
                                                    <InputNumber onFocus={stopScroll} type='number' style={{width: "100%"}} disabled size="large" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2} style={{display: "flex", height: index === 0 ? "unset" : 40, alignItems: "center"}}>
                                            </Col>

                                            <Col span={11}>
                                                <Form.Item
                                                    label={index === 0 ? "Tentative Schedule Qty" : null}
                                                    name={[field.name, "confirmedQty"]}
                                                    rules={[{ required: true, message: 'Please input Tentative Schedule Qty!' }]}
                                                >
                                                    <InputNumber onFocus={stopScroll} type='number' style={{width: "100%"}} disabled size="large" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}

                                        
                                </div>
                            )}
                        </Form.List>
                    </Col>
                    
                    <Col span={24}>
                        <Form.List initialValue={[{
                            pipelineId: null,
                            sourceId: null
                        }]} name="sourceDetails">
                            {(fields, fieldOpt) => (
                                <div style={{padding: 20, marginTop: 20, borderRadius: 16, background: "aliceblue"}}>
                                    {fields.map((field, index) => (
                                        <Row key={field.key} gutter={10}>
                                            <Col span={11}>
                                                <Form.Item
                                                    label={index === 0 ? "Pipleline ID" : null}
                                                    name={[field.name, "pipelineId"]}
                                                    rules={[{ required: true, message: 'Please input pipeline id!' }]}
                                                >
                                                    <Input size="large" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2} style={{display: "flex", height: index === 0 ? "unset" : 40, alignItems: "center"}}>
                                            </Col>

                                            <Col span={11}>
                                                <Form.Item
                                                    label={index === 0 ? "Source Id" : null}
                                                    name={[field.name, "sourceId"]}
                                                    rules={[{ required: true, message: 'Please input source id!' }]}
                                                >
                                                    <Input size="large" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}

                                        
                                </div>
                            )}
                        </Form.List>
                    </Col>
                </Row>

                <Space style={{ width: "100%", justifyContent: "center", marginTop: 20 }}>
                    <Button onClick={closeModal} size="large">Cancel</Button>
                    <Button loading={submitting} disabled={submitting || !enableForm} type="primary" size="large" htmlType="submit">Submit</Button>
                </Space>
            </Form>
        </div>
    )
}

export default IntraDayForm