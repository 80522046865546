
import { Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Item } from './Confirmation';
import { EnquiryStatus, enquiryStatusColors, enquiryStatusDisplay } from '../../utils/healpers/constants';

export const columns: ColumnsType<Item> = [
    {
        title: <>Application<br />Date</>,
        dataIndex: 'auditInfo',
        key: 'auditInfo.creationDate',
        render: (auditInfo) => { return <div style={{ display: "inline-block", width: 100 }}>{dayjs(auditInfo.creationDate).format("DD MMM YYYY")}</div> },
        align: "center",
        sorter: true,
    },
    {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        align: "center",
        render: (client: any) => <Tooltip title={client.name}><Typography.Text ellipsis style={{ width: 100 }}>
            {client.name}</Typography.Text >
        </Tooltip>,
    },
    {
        title: "Client Code",
        dataIndex: "client",
        render: (client) => <div style={{ display: "inline-block", width: 100 }}>{client.clientCode}</div>,
        align: "center"
    },
    {
        title: "State",
        dataIndex: "client",
        render: (client) => <div style={{ display: "inline-block", width: 80 }}>{client?.state}</div>,
        align: "center"
    },
    {
        title: 'Enquiry Id',
        dataIndex: 'id',
        key: 'id',
        align: "center",
        render: (id: string) => <div style={{ display: "inline-block" }}>{id}</div>,
    },
    {
        title: <>Daily Quantity<br />(MMBTU)</>,
        dataIndex: 'dcq',
        key: 'dcq',
        render: (dcq: string) => <div style={{ display: "inline-block", width: 100 }}>{dcq}</div>,
        align: "center"
        // sorter: true
    },
    {
        title: 'Contract Duration',
        key: 'startDate',
        render: (record) =>
            <div style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
                minWidth: 200
            }}>
                {dayjs(record.startDate, "DD/MM/YYYY").format("DD MMM")}
                <img width={15} src="/assets/date-line.svg" />
                {dayjs(record.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}
            </div>,
        align: "center",
    },
    {
        title: <>Status</>,
        dataIndex: "adminStatus",
        key: 'adminStatus',
        render: (adminStatus: EnquiryStatus) => {
            return <Tag style={{ fontSize: 14, textAlign: "center" }} color={enquiryStatusColors[adminStatus]}>{enquiryStatusDisplay[adminStatus]}</Tag>
        },
        align: "center",
        filters: [
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color={enquiryStatusColors.CONTRACT_NOT_SENT}>{enquiryStatusDisplay.CONTRACT_NOT_SENT}</Tag>,
                value: "PROPOSAL_ACCEPTED",
            },
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color={enquiryStatusColors.SIGNED_DOCUMENT_PENDING}>{enquiryStatusDisplay.SIGNED_DOCUMENT_PENDING}</Tag>,
                value: "CONTRACT_RECEIVED",
            },
            {
                text: <Tag style={{ fontSize: 14, textAlign: "center" }} color={enquiryStatusColors.CONTRACT_REGISTRATION_PENDING}>{enquiryStatusDisplay.CONTRACT_REGISTRATION_PENDING}</Tag>,
                value: "CONTRACT_REGISTRATION_PENDING",
            },
        ]
    },
    {
        title: 'Action',
        key: 'action',
        render: (record) =>
            <Link
                style={{ textDecoration: "underline" }}
                to={`/dashboard/registrations/${record.id}`}
                state={{
                    "startDate": record.startDate,
                    "endDate": record.endDate,
                    "totalQuantity": record.totalQuantity,
                    "description": record.description,
                    "createDeliveryDtoList": record.deliveryEndPoints,
                    "dcq": record.dcq,
                    "status": record.adminStatus,
                    "signedContractUrl": record.signedContractUrl,
                    "contractUrl": record.contractUrl,
                    "name": record.client.name,
                    "clientCode": record.client.clientCode,
                    "clientId": record.client.id
                }}
            >
                View Details
            </Link>,
        align: "center",
        ellipsis: true
    }
];