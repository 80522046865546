import { Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Item } from './Confirmation';

export const columns: ColumnsType<Item> = [
    {
        title: 'Contract ID',
        dataIndex: 'contractId',
        key: 'contractId',
        align: "center",
        render: (contractId: string) => <div style={{ width: 100, display: "inline-block" }}>{contractId}</div>,
        sorter: true,
    },
    {
        title: 'Contract Duration',
        key: 'startDate',
        render: (record) =>
            <div style={{
                width: 160,
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px"
            }}>
                {dayjs(record.startDate, "DD/MM/YYYY").format("DD MMM")}
                <img width={15} src="/assets/date-line.svg" />
                {dayjs(record.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}
            </div>,
        align: "center",
        sorter: true,
    },
    {
        title: 'DCQ',
        dataIndex: 'totalDcq',
        key: 'totalDcq',
        align: "center"
        // sorter: true
    },
    {
        title: <>Total Supply <br />req. (MMBTU)</>,
        dataIndex: 'totalNominatedQty',
        key: 'totalNominatedQty',
        render: (totalNominatedQty: string) => <div style={{ width: 150, display: "inline-block" }}>{totalNominatedQty ? totalNominatedQty : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: <>Total Allocated<br />Qty.(MMBTU)</>,
        dataIndex: 'totalAllocatedQty',
        key: 'totalAllocatedQty',
        render: (value) => <div style={{ width: 140, display: "inline-block" }}>{value ? value : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    // {
    //     title: 'Deviation',
    //     dataIndex: 'deviation',
    //     key: 'deviation',
    //     render: (deviation) => <div style={{ width: 80, display: "inline-block" }}>{deviation ? deviation.toFixed(2) + "%" : "----"}</div>,
    //     align: "center",
    // },
    {
        title: <>Status</>,
        dataIndex: "contractStatus",
        key: 'endDate',
        render: (contractStatus) => {
            return <Tag style={{ width: 120, display: "inline-block",fontSize: 14, textAlign: "center", textTransform: "uppercase" }} color={contractStatus === "Completed" ? "green" : "orange"}>{contractStatus}</Tag>
        },
        align: "center",
        sorter: true
    },
    {
        title: 'Action',
        key: 'action',
        render: (record) => 
        <Link 
            style={{ textDecoration: "underline" }} 
            to={`/dashboard/workflow-report/${record.clientId}/${record.contractId.replace(/\//g, "-")}`} 
            state={{ 
                clientId: record.clientId,
                name: record.client, 
                contractStatus: record.contractStatus, 
                contractId: record.contractId, 
                daysLeft: dayjs(record.endDate, "DD/MM/YYYY").isAfter(dayjs(), 'day') || dayjs(record.endDate, "DD/MM/YYYY").isSame(dayjs(), 'day') ?
                            dayjs(record.endDate, "DD/MM/YYYY").endOf("day").diff(dayjs(), 'day') + 1 : 0,
                startDate: record.startDate,
                endDate: record.endDate,
                nominationId: record.id,
                clientCode: record.clientCode,
                tradeId: record?.tradeId,
            }}
        >
            View Details
        </Link>,
        align: "center",
        ellipsis: true
    }
];