import axios from "axios";
import { keycloakConfig } from "../utils/keycloak.config";
import { store } from "../app/store";
import { setAuth } from "../features/auth/authSlice";
import { notification } from "antd";
import { SignOut } from "../utils/common";
const { clientId, baseURL } = keycloakConfig;

export const getRefreshedToken = async () => {
    const refreshToken = store.getState().auth.refreshToken;

    const accessToken = await axios.post(`${baseURL}/realms/Matrix/protocol/openid-connect/token`, {
        client_id: clientId,
        refresh_token: refreshToken,
        grant_type: "refresh_token"
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then(response =>{    
        store.dispatch(setAuth({
            isAdmin: store.getState().auth.isAdmin,
            isMainAdmin: store.getState().auth.isMainAdmin,
            isFinance: store.getState().auth.isFinance,
            isClient: store.getState().auth.isClient,
            isAuth: store.getState().auth.isAuth,
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
            idToken: response.data.id_token,
        }))

        return response.data.access_token
    }).catch((e)=>{
        notification.error({
            message: 'Session Timeout',
            description:
                'Redirecting to login screen'
        });
        SignOut()
    });

    return accessToken;
    
}

