import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import DashboardLayout from './pages/Dashboard/DashboardLayout';
import Login from './pages/Login';
import { useAppSelector } from './app/hooks';
import { authSelector } from './features/auth/authSlice';
import EditUser from "./pages/Dashboard/IAM/EditUser";
import Dashboard from './pages/Dashboard/Dashboard';
import UserList from './pages/Dashboard/IAM/UserList';
import './styles/App.sass'
import CreateRole from './pages/Dashboard/IAM/CreateRole';
import CreateUser from './pages/Dashboard/IAM/CreateUser';
import CreateClient from './pages/Dashboard/Client-Onboarding/CreateClient';
import ClientList from './pages/Dashboard/Client-Onboarding/ClientList';
import NominationEntry from './pages/Dashboard/GasOperations/NominationEntry';
import ConfirmationDetails from './pages/Dashboard/GasOperations/ConfirmantionDetails';
import IntraDay from './pages/Dashboard/GasOperations/IntradDay';
import ScheduledList from './pages/Dashboard/GasOperations/ScheduledList';
import Allocation from './pages/Dashboard/GasOperations/Allocation';
import ConfirmationList from './pages/Dashboard/GasOperations/ConfirmationList';
import NominationHistory from './pages/Dashboard/NominationHistory';
import NominationHistoryDetails from './pages/Dashboard/NominationHistory/Details';
import ContractDetails from './pages/Dashboard/NominationHistory/ContractDetails';
import FortnightDetails from './pages/Dashboard/NominationHistory/FortnightDetails';
import JointTicket from './pages/Dashboard/NominationHistory/JumpToJointTicket';
import NotFound from './pages/404';
import NominationWorkflow from './pages/Dashboard/Client-Nomination/NominationWorkflow';
import WorkflowReport from './pages/Dashboard/Client-Nomination/WorkflowReport';
import WorkflowReportDetails from './pages/Dashboard/Client-Nomination/WorkflowReportDetails';
import WorkflowReportFortnightDetials from './pages/Dashboard/Client-Nomination/WorkflowReportFortnightDetail';
import ClientEnquiry from './pages/Dashboard/Client-Enquiry';
import CreateEnquiry from './pages/Dashboard/Client-Enquiry/CreateEnquiry';
import EnquiryDetails from './pages/Dashboard/Client-Enquiry/EnuiryDetails';
import Registrations from './pages/Dashboard/Registrations';
import RegistrationsDetails from './pages/Dashboard/Registrations/RegistrationsDetails';
import RunningContracts from './pages/Dashboard/Client-Enquiry/RunningContracts';
import ClientRunningContracts from './pages/Dashboard/Client-Enquiry/ClientRunningContracts';
import UpdateQty from './pages/Dashboard/Client-Enquiry/UpdateQty';
import SignUp from './pages/Registration/SignUp';
import ClientProfile from './pages/Dashboard/Client-Profile/CreateClient';
import KYCPending from './pages/Dashboard/KYCPending';
import ClientListAdmin from './pages/Dashboard/ClientDetails';
import ClientListDetailsAdmin from './pages/Dashboard/ClientDetails/ClientContract';
import CreateContract from './pages/Dashboard/ClientDetails/CreateContract';
import AdminProfile from './pages/Dashboard/AdminProfile';

function App() {
  const auth = useAppSelector(authSelector);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/dashboard" element={<ProtectedRoute canAccess={true} isAuthenticated={auth.isAuth} outlet={<DashboardLayout/>}/>}>
          <Route path="*" element={<ProtectedRoute canAccess={true} isAuthenticated={auth.isAuth} outlet={<NotFound/>}/>} />
          <Route path="" element={<ProtectedRoute canAccess={true} isAuthenticated={auth.isAuth} outlet={<Dashboard />}/>}/>
          <Route path="users" element={<ProtectedRoute canAccess={auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<UserList/>}/>}/>
          <Route path="users/:username" element={<ProtectedRoute canAccess={auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<EditUser/>}/>}/>
          <Route path="add-role" element={<ProtectedRoute canAccess={auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<CreateRole/>}/>}/>
          <Route path="users/create-user" element={<ProtectedRoute canAccess={auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<CreateUser/>}/>}/>

          {/* gas operatons */}
          <Route path="confirmation" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<ConfirmationList/>}/>}/>
          <Route path="confirmation/:id" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<ConfirmationDetails/>}/>}/>
          <Route path="confirmation/nomination-entry" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<NominationEntry/>}/>}/>
          <Route path="intra-day" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<IntraDay/>}/>}/>
          <Route path="scheduled-list" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<ScheduledList/>}/>}/>
          <Route path="allocation" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<Allocation/>}/>}/>

          {/* client nomination */}
          <Route path="nomination-workflow" element={<ProtectedRoute canAccess={auth.isClient || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<NominationWorkflow />}/>}/>
          <Route path="workflow-report" element={<ProtectedRoute canAccess={auth.isClient || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<WorkflowReport />}/>}/>
          <Route path="workflow-report/:id/:contractId" element={<ProtectedRoute canAccess={auth.isClient || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<WorkflowReportDetails/>}/>}/>
          <Route path="workflow-report/:id/:contractId/:nominationId" element={<ProtectedRoute canAccess={auth.isClient || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<WorkflowReportFortnightDetials />}/>}/>

          {/* nomination history */}
          <Route path="nomination-history" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin} isAuthenticated={auth.isAuth} outlet={<NominationHistory/>}/>}/>
          <Route path="nomination-history/:id" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin} isAuthenticated={auth.isAuth} outlet={<NominationHistoryDetails/>}/>}/>
          <Route path="nomination-history/:id/:contractId" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin} isAuthenticated={auth.isAuth} outlet={<ContractDetails/>}/>}/>
          <Route path="nomination-history/:id/:contractId/:nominationId" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin} isAuthenticated={auth.isAuth} outlet={<FortnightDetails />}/>}/>

          {/* client enquiry */}
          <Route path="enquiry" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<ClientEnquiry/>}/>}/>
          <Route path="enquiry/:id" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<EnquiryDetails />}/>}/>
          <Route path="create-enquiry/:id" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<CreateEnquiry/>}/>}/>
          <Route path="create-enquiry" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<CreateEnquiry/>}/>}/>

          {/* registration  */}
          <Route path="registrations" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin} isAuthenticated={auth.isAuth} outlet={<Registrations/>}/>}/>
          <Route path="registrations/:id" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin} isAuthenticated={auth.isAuth} outlet={<RegistrationsDetails/>}/>}/>
          <Route path="contract-history" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<ClientRunningContracts />}/>}/>

          <Route path="running-contracts" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<RunningContracts />}/>}/>
          <Route path="update-qty" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<UpdateQty/>}/>}/>


          {/* nomination history */}
          <Route path="joint-ticket" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin || auth.isFinance} isAuthenticated={auth.isAuth} outlet={<JointTicket/>}/>}/>
          <Route path="joint-ticket/:id/:contractId" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin || auth.isFinance} isAuthenticated={auth.isAuth} outlet={<ContractDetails/>}/>}/>
          <Route path="joint-ticket/:id/:contractId/:nominationId" element={<ProtectedRoute canAccess={auth.isMainAdmin || auth.isAdmin || auth.isFinance} isAuthenticated={auth.isAuth} outlet={<FortnightDetails />}/>}/>

          {/* client onboarding operations */}
          <Route path="client-list" element={<ProtectedRoute canAccess={auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<ClientList/>}/>}/>
          <Route path="create-client" element={<ProtectedRoute canAccess={auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<CreateClient/>}/>}/>
          <Route path="client-details/:id" element={<ProtectedRoute canAccess={auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<CreateClient/>}/>}/>

          {/* profile */}
          <Route path="profile" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<ClientProfile/>}/>}/>
          <Route path="profile/:id" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<ClientProfile/>}/>}/>

          <Route path="admin-profile" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<AdminProfile/>}/>}/>

          <Route path="kyc-pending" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin || auth.isClient} isAuthenticated={auth.isAuth} outlet={<KYCPending/>}/>}/>

          <Route path="client-details" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<ClientListAdmin/>}/>}/>
          <Route path="client-details/client-contract-details/:id" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<ClientListDetailsAdmin/>}/>}/>
          <Route path="create-contract/:id" element={<ProtectedRoute canAccess={auth.isAdmin || auth.isMainAdmin} isAuthenticated={auth.isAuth} outlet={<CreateContract/>}/>}/>




        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
