import { DownOutlined, DownloadOutlined, EyeOutlined, ProfileOutlined, ReloadOutlined, UpOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal } from "antd"
import { useState } from "react";
import UploadInvoiceForm from "./UploadInvoiceForm";
import theme from "../../../utils/theme";
import { instance } from "../../../utils/api";
import { showSuccess } from "../../../utils/common";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";


export const UploadInvoiceDropdown = ({ icon, record, contractId, startDate, endDate, fetchData, setUrlState, dropDownType }: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadType, setUploadType] = useState("")
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [modal, modalContex] = Modal.useModal();
    const auth = useAppSelector(authSelector);

    const handleDownload = async (e: any, urlString: string, downloadType: string) => {
        e.preventDefault();
        e.stopPropagation();

        await fetch(urlString).then((response) => {
            response.blob().then((blob) => {

                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = downloadType + "_" + contractId + "_" + startDate + "_" + endDate;
                alink.click();
            });
        });
    };

    const handleModalClose = () => {
        setUploadType("")
        setIsModalOpen(false)
    }

    const resend = async (id: number, type: string) => {
        modal.confirm({
            title: <>Are you sure?<br /> Resend <span style={{textTransform: "capitalize"}}>{type}</span></>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            icon: null,
            onOk: async () => {
                try {
                    // setLoading(true)
                    await instance.post(`orderservice/nominate/fortnight/re-send-${type}?id=${id}`)
                    showSuccess(<>The {type} has been successfully shared with client.</>, Modal)

                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })

    }
    return <>
        <Dropdown
            onOpenChange={(open)=>setDropDownOpen(open)}
            menu={
                {
                    items: [
                        {
                            key: '1',
                            label: 'View Invoice',
                            onClick: (_: any) => window.open(record.invoice, '_blank',),
                            disabled: !record.invoice,
                            icon: <EyeOutlined style={{ fontSize: 16, color: theme.token.colorPrimary }} />,
                        },
                        {
                            key: '2',
                            label: 'Download Invoice',
                            onClick: (e: any) => { handleDownload(e.domEvent, record.invoice, "invoice") },
                            disabled: !record.invoice,
                            icon: <DownloadOutlined style={{ fontSize: 16, color: theme.token.colorPrimary }} />,
                        },
                        {
                            key: '3',
                            label: 'Re-send Invoice',
                            onClick: (_: any) => resend(record.id, "invoice"),
                            disabled: !record.invoice,
                            icon: <ReloadOutlined style={{ fontSize: 16, color: theme.token.colorPrimary }} />,
                        },
                        {
                            key: '4',
                            label: 'Upload Invoice',
                            onClick: (_: any) => {
                                setUploadType("invoice");
                                setIsModalOpen(true)
                            },
                            icon: <UploadOutlined style={{ fontSize: 16, color: theme.token.colorPrimary }} />,
                        },
                        {
                            key: '5',
                            label: 'View Ticket',
                            onClick: (_: any) => window.open(record.jointTicket, '_blank',),
                            disabled: !record.jointTicket,
                            icon: <EyeOutlined style={{ fontSize: 16, color: theme.token.colorPrimary }} />,
                        },

                        {
                            key: '6',
                            label: 'Download Ticket',
                            onClick: (e: any) => { handleDownload(e.domEvent, record.jointTicket, "ticket") },
                            disabled: !record.jointTicket,
                            icon: <DownloadOutlined style={{ fontSize: 16, color: theme.token.colorPrimary }} />,
                        },

                        {
                            key: '7',
                            label: 'Re-send Ticket',
                            onClick: (_: any) => resend(record.id, "joint-ticket"),
                            disabled: !record.jointTicket,
                            icon: <ReloadOutlined style={{ fontSize: 16, color: theme.token.colorPrimary }} />,
                        },

                        {
                            key: '8',
                            label: 'Upload Ticket',
                            onClick: (_: any) => {
                                setUploadType("ticket");
                                setIsModalOpen(true)
                            },
                            icon: <UploadOutlined style={{ fontSize: 16, color: theme.token.colorPrimary }} />,
                        },
                    ].slice(dropDownType === "invoice" ? 0 : dropDownType === "ticket" ? 4 : 0, dropDownType === "invoice" ? 4 : 8).filter((i)=>auth.isClient ? ["1","2",,"5","6"].includes(i.key)  : i)
                }
            }
            placement="bottom"
        >
            {icon ? <div style={{ textAlign: "center" }}>
                {dropDownType === "invoice" ? <><ProfileOutlined style={{ color: "#F2923A" }} />
                    <div style={{ color: "#F2923A", fontSize: 12 }}>
                        Invoice
                    </div></> : <><img width={14} className="active" src="/assets/ticket-icon.svg" />
                    <div style={{ color: "#9747FF", fontSize: 12, width: 80 }}>
                        Joint Tickets
                    </div></>}
            </div> : <Button size='large' type="primary">
                Invoice & Ticket
                {dropDownOpen ? <UpOutlined style={{ fontSize: 16 }} /> : <DownOutlined style={{ fontSize: 16 }} />}
            </Button>}
        </Dropdown>
        <Modal
            footer={null}
            destroyOnClose
            okButtonProps={{
                size: "large"
            }
            }
            bodyStyle={{
                display: "flex",
                justifyContent: "center"
            }}
            width="auto"
            cancelButtonProps={{
                size: "large"
            }}
            centered={true}
            title={<div style={{ textAlign: "center", fontSize: 20 }}>Please upload an {uploadType === "invoice" ? "Invoice" : "Ticket"} document</div>}
            open={isModalOpen}
            onCancel={handleModalClose}
            closeIcon={false}
        >
            <UploadInvoiceForm
                setUrlState={setUrlState}
                uploadType={uploadType}
                closeModal={handleModalClose}
                fetchData={fetchData}
                startDate={record.startDateFortnight}
                endDate={record.endDateFortnight}
                contractId={contractId}
                id={record.id}
            />
        </Modal >
        {modalContex}
    </>
}