import React, { useState, useEffect } from 'react';
import { Table, Typography } from 'antd';
import { instance } from '../../../utils/api';
import theme from '../../../utils/theme';
import { useTableFilters } from '../../../utils/hooks/useTableFilters';
import { columns } from '../../../interfaces/TableColumns/NominationHistory';
import { TableDataType } from '../../../interfaces/TableData';
import dayjs from 'dayjs';
import { getPaginationCountApi } from '../../../utils/common';
import { Item } from '../../../interfaces/TableColumns/Confirmation';

export interface DataType extends TableDataType {
    items: Item[] | [];
}

// const filterString = `endDate|gted|${dayjs().format("DD/MM/YYYY")}`


function ConfirmationList() {

    const [data, setData] = useState<DataType | null>(null);

    const { tableParams, onFilerSearch, handleSort, options, onSearch } = useTableFilters("auditInfo.creationDate|DESC", '', true ,false,true);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchData();
    }, [tableParams])

    const fetchData = async () => {
        const params = {
            ...tableParams,
            // filter and contract endDate is less than current date
            filterAnd: `${tableParams.filterAnd ? `&${tableParams.filterAnd}` : ""}`
        }
        setLoading(true)
        try {
            const res = await instance.get("orderservice/nominate", { params })
            setData(res.data);
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setLoading(false)
    };


    

    return (
        <React.Fragment>
            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, margin: 0 }}>Nomination History</Typography.Title>
            

            <Table
                style={{marginTop: 20}}
                loading={loading}
                onChange={handleSort}
                rowClassName="table-row"
                columns={columns}
                dataSource={data?.items}
                pagination={{
                    pageSize: tableParams.size,
                    total: data?.totalItems,
                    current: tableParams.page,
                    showSizeChanger: false,
                    position: ["bottomCenter"]
                }}
                footer={()=><div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                rowKey={(record) => record.id}
                scroll={{ x: true, y: "65vh" }}
            />

        </React.Fragment>
    )

};

export default ConfirmationList;