import React, { useEffect, useState } from 'react';
import { Button, Select, Typography } from 'antd';
import theme from '../../../utils/theme';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { instance } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';



// const filterString = `endDate|gted|${dayjs().format("DD/MM/YYYY")}`


function JointTicket() {

    const [selectBoxOpen, setSelectBoxOpen] = useState(true);
    const [contractSelectBoxOpen, setContractSelectBoxOpen] = useState(false);

    const [selectedClient, setSelectedClient] = useState<any>(null)
    const [fetchingClients, setFetchingClients] = useState(false)
    const [selectedContract, setSelectedContract] = useState<any>(null)
    const [fetchingContracts, setFetchingContracts] = useState(false)

    const [contracts, setContracts] = useState([]);
    const [clients, setClients] = useState([]);

    const navigate = useNavigate()

    useEffect(() => {
        getClients();
    }, [])

    const getClients = async () => {
        setFetchingClients(true)
        try {
            const response = await instance.get(`/orderservice/nominate/distinct-client`);
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setFetchingClients(false)
    }

    const handleClientChange = (value: any) => {
        handleReset()
        setSelectedClient(clients.filter((item: any) => item.clientId === value)[0])
    }

    const handleReset = () => {
        setContracts([]);
        setSelectedContract(null)
    }

    useEffect(() => {
        selectedClient && fetchContracts()
    }, [selectedClient])

    const fetchContracts = async () => {
        setFetchingContracts(true)

        const params = {
            filterAnd: `clientId|eq|${selectedClient.clientId}`
        }
        try {
            const response = await instance.get("orderservice/nominate", { params })

            setContractSelectBoxOpen(true)
            setContracts(response.data.items)
            setFetchingContracts(false)
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
    }

    const handleContractChange = (value: any) => {
        const contract: any = contracts.find((item: any) => item.contractId === value)
        navigate(`/dashboard/joint-ticket/${selectedClient.clientId}/${value.replace(/\//g, "-")}`, {
            state: {
                name: selectedClient.client,
                contractStatus: contract?.contractStatus,
                contractId: contract?.contractId,
                daysLeft: dayjs(contract.endDate, "DD/MM/YYYY").isAfter(dayjs(), 'day') || dayjs(contract.endDate, "DD/MM/YYYY").isSame(dayjs(), 'day') ?
                            dayjs(contract.endDate, "DD/MM/YYYY").endOf("day").diff(dayjs(), 'day') + 1 : 0,
                startDate: contract?.startDate,
                endDate: contract?.endDate,
                nominationId: contract?.id,
                clientCode: selectedClient.clientCode,
                tradeId: contract?.tradeId,
            }
        })
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        console.log(e.key)
        if (e.key === 'Enter') {
            e.stopPropagation();
        }
    };

    return (
        <React.Fragment>
            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, margin: 0 }}>Invoice & Joint Ticket</Typography.Title>

            <div style={{ display: 'flex', gap: 10, marginTop: 40 }}>

                <div>
                    <Button onClick={() => setSelectBoxOpen(!selectBoxOpen)} type="primary" size="large">Select Client Name or Client Code {selectBoxOpen ? <UpOutlined className="ant-select-suffix" style={{ color: "white", fontSize: 16 }} /> : <DownOutlined className="ant-select-suffix" style={{ color: selectedClient ? "white" : "unset", fontSize: 16 }} />}</Button>
                    <div style={{ display: !selectBoxOpen ? "none" : "flex", justifyContent: "center", background: "white", height: 260, minWidth: 280, boxShadow: "2px 4px 14px 0px #00000014", border: "1px solid #E2E2E2", borderRadius: 16, position: "relative", paddingTop: 5, marginTop: 5 }}>
                        <Select
                            onInputKeyDown={handleKeyPress}
                            optionFilterProp="title"
                            labelInValue={false}
                            showSearch
                            listHeight={200}
                            dropdownStyle={{ padding: 0, boxShadow: "none" }}
                            loading={fetchingClients}
                            open={selectBoxOpen}
                            size="large"
                            style={{ height: "fit-content", width: 268 }}
                            onChange={handleClientChange}
                            placeholder="Enter Client Name or Client Code"
                            popupClassName='joint-ticket-dropdown'
                        >
                            {clients.map((item: any, key) => {
                                return <Select.Option className="nomination-client" key={key} value={item.clientId} title={item.client + item.clientCode}>{item.client}<div style={{ fontSize: 10, color: "#707070" }}>Client Code: {item.clientCode}</div></Select.Option>
                            })}
                        </Select>
                    </div>

                </div>


                <div>
                    <Button disabled={!selectedClient} onClick={() => setContractSelectBoxOpen(!contractSelectBoxOpen)} type="primary" size="large">Contract ID {contractSelectBoxOpen ? <UpOutlined className="ant-select-suffix" style={{ color: "white", fontSize: 16 }} /> : <DownOutlined className="ant-select-suffix" style={{ color: "white", fontSize: 16 }} />}</Button>
                    <div style={{ display: !contractSelectBoxOpen ? "none" : "flex", justifyContent: "center", background: "white", height: 260, minWidth: 280, boxShadow: "2px 4px 14px 0px #00000014", border: "1px solid #E2E2E2", borderRadius: 16, position: "relative", paddingTop: 5, marginTop: 5 }}>
                        <Select
                            onInputKeyDown={handleKeyPress}
                            optionFilterProp="title"
                            labelInValue={false}
                            showSearch
                            listHeight={200}
                            dropdownStyle={{ padding: 0, boxShadow: "none" }}
                            loading={fetchingContracts}
                            open={contractSelectBoxOpen}
                            size="large"
                            onChange={handleContractChange}
                            style={{ height: "fit-content", width: 268 }}
                            placeholder="Enter Contract ID"
                            popupClassName='joint-ticket-dropdown'
                        >
                            {contracts.map((item: any, key) => {
                                return <Select.Option className="nomination-client" key={key} value={item.contractId} title={item.contractId}>{item.contractId}</Select.Option>
                            })}
                        </Select>
                    </div>
                </div>

                {/* {dataSubmitted && <Button disabled style={{ marginLeft: "auto" }}>Delivery End Point: Haryana City Gas, Gurgaon</Button>} */}
            </div>

        </React.Fragment>
    )

};

export default JointTicket;