import { Button, Typography } from "antd";
import { useEffect } from "react"
import theme from "../../utils/theme";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { dashboardSelector } from "../../features/auth/dashboardSlice";

export default function KYCPending() {

    const navigate = useNavigate()
    const dashboard: any = useAppSelector(dashboardSelector)

    useEffect(() => {
        const layoutElement = document.querySelector('.ant-layout-content') as HTMLElement;
        if (layoutElement) {
            // Apply CSS styles to the element
            layoutElement.style.background = 'white';
            layoutElement.style.border = 'none';
        }

        return () => {
            // Undo the same changes when the component unmounts
            layoutElement.style.background = '#F5FFFB';
                layoutElement.style.border = '1px solid #E2E2E2';
        }
    }, [])

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <div style={{ textAlign: "center" }}>
                <img width={247} src="/assets/kyc-pending.png" />
                <br />
                {dashboard?.stats?.registrationStatus == "KYC_PENDING" && <Typography.Text style={{ fontSize: 20 }}>To Process further please fill KYC </Typography.Text>}
                {dashboard?.stats?.registrationStatus == "SIGNED_DOCUMENT_PENDING" && <Typography.Text style={{ fontSize: 20 }}>To Process further please upload Registration Summary </Typography.Text>}
                {dashboard?.stats?.registrationStatus == "APPROVAL_PENDING" && <Typography.Text style={{ fontSize: 20 }}>Please wait for the Admin to verify your Registration Details</Typography.Text>}

                {dashboard?.stats?.registrationStatus == "KYC_PENDING" && <div style={{ background: "rgba(113, 168, 77, 0.08)", width: 800, padding: 8, marginTop: 40 }}>
                    <div style={{ fontSize: 14, marginBottom: 12 }}>
                        <span style={{ color: theme.token.colorPrimary }}>Note:</span>
                        <span style={{ marginLeft: 4 }}>Please be prepared with documents to upload:</span>
                    </div>
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>
                        <span style={{ marginRight: 20, display: "inline-block" }}>1. CIN Document</span>
                        <span style={{ marginRight: 20, display: "inline-block" }}>2. Pan Card</span>
                        <span style={{ marginRight: 20, display: "inline-block" }}>3. VAT Card</span>
                        <span style={{ marginRight: 20, display: "inline-block" }}>4. CST Document</span>
                        <br />
                        <span style={{ marginRight: 20, marginTop: 8, display: "inline-block" }}>5. GST Document</span>
                        <span style={{ marginRight: 20, marginTop: 8, display: "inline-block" }}>6. Cancelled Cheque</span>
                    </div>
                </div>}

                <div>
                <Button onClick={() => navigate("/dashboard/profile")} size="large" type="primary" style={{ width: 300, marginTop: 20 }}>{dashboard?.stats?.registrationStatus == "APPROVAL_PENDING" ? "View Profile" : "Proceed"}</Button>
                </div>
            </div>
        </div>
    )
}