
import { Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Item } from './Confirmation';
import { EnquiryStatus, enquiryStatusColors, enquiryStatusDisplay } from '../../utils/healpers/constants';

export const columns: ColumnsType<Item> = [
    {
        title: 'Enquiry ID',
        dataIndex: 'id',
        key: 'id',
        align: "center",
        render: (id: string) => <div style={{ display: "inline-block" }}>{id}</div>,
    },
    {
        title: <>Application Date</>,
        dataIndex: 'auditInfo',
        key: 'auditInfo.creationDate',
        render: (auditInfo) => {return <div style={{display: "inline-block",width: 120}}>{dayjs(auditInfo.creationDate).format("DD MMM YYYY")}</div>},
        align: "center",
    },
    {
        title: 'Duration',
        key: 'startDate',
        render: (record) =>
            <div style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
                minWidth: 170
            }}>
                {dayjs(record.startDate, "DD/MM/YYYY").format("DD MMM")}
                <img width={15} src="/assets/date-line.svg" />
                {dayjs(record.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}
            </div>,
        align: "center",
        sorter: true,
    },
    {
        title: 'Daily Qty.',
        dataIndex: 'dcq',
        key: 'dcq',
        align: "center",
        render: (dcq:any) => {
            return <div style={{display: "inline-block",width: 100}}>{dcq}</div>
        },
        // sorter: true
    },
    {
        title: <>Status</>,
        dataIndex: "status",
        key: 'status',
        render: (status:EnquiryStatus) => {
            return <Tag style={{ fontSize: 14, textAlign: "center", padding: "4px 16px" }} color={enquiryStatusColors[status]}>{enquiryStatusDisplay[status]}</Tag>
        },
        align: "center",
    },
    {
        title: 'Action',
        key: 'action',
        render: (record) =>
            <Link
                style={{ textDecoration: "underline" }}
                to={record.status === "ACTION_PENDING" || record.status === "ENQUIRY_CANCELLED" ? `/dashboard/create-enquiry/${record.id}` :  `/dashboard/enquiry/${record.id}`}
                // state={{
                //     "startDate": record.startDate,
                //     "endDate": record.endDate,
                //     "totalQuantity": record.totalQuantity,
                //     "description": record.description,
                //     "createDeliveryDtoList": record.deliveryEndPoints,
                //     "dcq": record.dcq,
                //     "status": record.status,
                //     "contractUrl": record.contractUrl,
                //     "signedContractUrl": record.signedContractUrl,
                //     "remark": record.remark
                // }}
            >
                View Details
            </Link>,
        align: "center",
        ellipsis: true
    }
];