import { useState } from 'react';
import { instance } from '../api';

export function useTableFilters(defaultSortKey: string, fitlerString?: string, isConfirmationList?: boolean, isIntraDayList?: boolean, paginateOnBackend?: boolean, newFilter?: boolean) {
    const [tableParams, setTableParams] = useState<any>({
        sort: `${defaultSortKey}`,
        size: paginateOnBackend ? 15 : 1000000,
    });
    const [options,setOptions] = useState<any>([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15
    })

    const resetTableParams =()=> {
        setTableParams({
            sort: `${defaultSortKey}`,
            size: paginateOnBackend ? 15 : 1000000,
        })
    }

    const onFilerSearch = (value: string, key:string) => {
        // let numberArray = value.split(",").map(Number);
        // const updatedOptions = options.filter((option:any) => numberArray.includes(option?.nomination?.contractId || option?.contractId));
        // setOptions(updatedOptions);
        if(value) {
            setTableParams({
                ...tableParams, filterAnd: `${key}|in|${value}`, page: 1,
            })
        } else {
            setTableParams({
                ...tableParams,
                filterAnd: ``,
                page: 1,
            })
        }
    }

    const onSearch = async (value: string, key:string, selectValue: any) => {
            const params = {
                ...tableParams,
                filterAnd: fitlerString,
                size: 100000,
            }
            if(isIntraDayList){
                params.filterOr = `scheduledQty|neq|null&confirmedQty|neq|null`
            }
            // &scheduledQty|neq|null
            try {
                const response = await instance.get(isConfirmationList ? `orderservice/nominate` : `orderservice/nominate/details`, { params });
                setOptions(response.data.items);
            } catch (error) {
                console.log(error)
            }
    }

    const handleSort = (pagination: any, filters: any, sorter: any, extra: any) => {
        if(!(extra.action === "paginate" && !paginateOnBackend)){
            setTableParams(paginateOnBackend ? { 
                    ...tableParams, 
                    sort: sorter.order ? `${sorter.column.key}${newFilter ? " " : "|"}${sorter.order === "descend" ? "DESC" : "ASC"}` : `${defaultSortKey}`,
                    page: pagination.current,
            } : { 
                ...tableParams, 
                sort: sorter.order ? `${sorter.column.key}|${sorter.order === "descend" ? "DESC" : "ASC"}` : `${defaultSortKey}`,
                // page: pagination.current,
            })
        } else {
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize
            })
        }
    }

    return {
        tableParams,
        onFilerSearch,
        handleSort,
        onSearch,
        options,
        pagination,
        resetTableParams
        // setOptions,
    };
}