import { ArrowDownOutlined, ArrowLeftOutlined, DownloadOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Divider, Form, Input, Modal, Space, Steps, Tag, Typography, Upload } from "antd"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import theme from "../../../utils/theme";
import { instance } from "../../../utils/api";
import { statusStyle } from "./EnuiryDetails";
import { beforeUpload, showSuccess } from "../../../utils/common";

export default function UpdateQty() {

    let { state } = useLocation();
    const [uploaderOpen, setUploaderOpen] = useState(false)
    const [current, setCurrent] = useState(0);
    const [disableAcceptProposalButton, setDisableAcceptProposalButton] = useState(true);
    const [form] = Form.useForm()
    const [modal, modalContex] = Modal.useModal();
    const [remarks, setRemarks] = useState("")
    const [sendRemarksEnabled, setSendRemarksEnabled] = useState(false)
    const [fetchingDetails, setFetchingDetails] = useState(true)
    const [contractDetails, setContractDetails] = useState<any>({})
    const navigate = useNavigate()

    const onChange = (value: number) => {
        console.log('onChange:', value);
        setCurrent(value);
    };

    useEffect(() => {
        getContractDetails()
    }, [])

    const getContractDetails = async () => {
        setFetchingDetails(true)
        try {
            const res = await instance.get(`clientservice/clients/contract?id=${state.id}`);
            setContractDetails(res.data)
        } catch (e) {
            console.log(e);
        }
        setFetchingDetails(false)
    }

    const handleDownload = async (e: any, urlString: string, downloadType: string = "proposal") => {
        e.preventDefault();
        e.stopPropagation();

        await fetch(urlString).then((response) => {
            response.blob().then((blob) => {

                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = downloadType + "_" + contractDetails?.id;
                alink.click();
            });
        });
    };

    const handleAcceptProposalFormChange = (values: any) => {
        if (values?.file) {
            setDisableAcceptProposalButton(false)
        } else {
            setDisableAcceptProposalButton(true)
        }
    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleProposalRemarkFormChanges = (values: any) => {
        console.log(values.remarks === "")
        if (values.remarks && values.remarks != "") {
            setSendRemarksEnabled(true)
        } else {
            setSendRemarksEnabled(false)
        }
    }

    const acceptProposal = async () => {

        console.log("remarks", remarks)
        modal.confirm({
            title: <>Are you sure<br />you want to accept the updated quantity?</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            icon: null,
            onOk: async () => {
                try {
                    const formValues = form.getFieldsValue();
                    console.log(formValues);

                    const formData = new FormData();

                    formData.append("file", formValues["file"]);
                    formData.append("contractId", contractDetails?.id)
                    formData.append("remark", remarks)


                    // setLoading(true)
                    await instance.put(`clientservice/clients/signed-contract-after-edit-quantity`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    showSuccess(<>Contract successfully updated</>, Modal)
                    navigate("/dashboard/contract-history")

                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })
    }


    return (
        <>

            <Space>
                <Button onClick={() => navigate("/dashboard/contract-history")} size="large" style={{ padding: 0, marginRight: 20 }} type="text"><ArrowLeftOutlined /></Button>
                <Typography.Text style={{ fontSize: 24, color: theme.token.colorPrimary }} strong>
                    Contract Details
                </Typography.Text>
            </Space>
            <Breadcrumb separator=">" style={{ marginBottom: 16, cursor: "pointer", marginLeft: 46 }}>
                <Breadcrumb.Item
                    onClick={_ => navigate(`/dashboard/enquiry`)}
                >
                    <span style={{ fontWeight: 600 }}>
                        Contract ID - {contractDetails?.id}
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ fontWeight: 600 }}>
                        View Details
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>


            {<div style={{ position: "relative", marginBottom: 78 }}>
                <div style={statusStyle}>
                    <Tag style={{ fontSize: 20, textAlign: "center", padding: "8px 40px", display: "flex", alignItems: "center", color: "#CEAF0C"}} color="yellow">
                        <div style={{position: "relative", marginRight: 8}}>
                            <ArrowDownOutlined style={{fontSize: "10px", position: "absolute", top: "-1px", right: "1px"}} />
                            <img src="/assets/update-qty.png"/> 
                        </div>
                        Contract Qty. updated
                    </Tag>
                </div>

            </div>}

            {!uploaderOpen && <div style={{display: "flex", justifyContent: "center", gap: 120, marginTop: 128}}>
                <div style={quantityCardStyle}>
                    <Typography.Title style={{padding: 0, margin: 0,fontSize: 18}}>Old Qty.</Typography.Title>
                    <Typography.Title style={{padding: 0, margin: 0,fontSize: 20, marginTop: 4, color: theme.token.colorPrimary}}>{contractDetails?.oldQuantity} MMBTU</Typography.Title>
                </div>

                <div style={quantityCardStyle}>
                    <Typography.Title style={{padding: 0, margin: 0,fontSize: 18}}>Updated Qty.</Typography.Title>
                    <Typography.Title style={{padding: 0, margin: 0,fontSize: 20, marginTop: 4, color: theme.token.colorPrimary}}>{contractDetails?.newQuantity} MMBTU</Typography.Title>
                </div>
            </div>}

            {!uploaderOpen ? <>
                <div style={{ display: "flex", justifyContent: "center" }}>

                    <div style={{ width: "70%", marginTop: 40 }}>
                        <div style={cardStyle}>
                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                <img src="/assets/enquiry-icon.png" />
                                <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>Contract Proposal</Typography.Text>
                            </div>

                            <div style={{ display: "flex", gap: 28 }}>
                                <div onClick={(e: any) => { handleDownload(e, contractDetails?.url) }} style={{ textAlign: "center", color: theme.token.colorPrimary, cursor: "pointer" }}>
                                    <DownloadOutlined style={{ fontSize: 24 }} /><br />
                                    Download
                                </div>
                                <Link target="_blank" to={contractDetails?.url} style={{ textAlign: "center", color: "rgba(27, 120, 223, 1)", cursor: "pointer" }}>
                                    <EyeOutlined style={{ fontSize: 24 }} /><br />
                                    View File
                                </Link>
                            </div>
                        </div>

                        <Form form={form} onValuesChange={handleProposalRemarkFormChanges} layout="vertical" style={{ marginTop: 28 }}>
                            <Form.Item name="remarks" rules={[{ required: false }]} label={<span style={{ fontWeight: 500, marginLeft: 20 }}>Remark</span>} >
                                <Input.TextArea rows={4} placeholder="Please enter your remark" style={{ width: "100%" }} size="large" />
                            </Form.Item>
                        </Form>

                        <div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                                
                                <Button onClick={() => {
                                    const remarksValue = form.getFieldValue("remarks")
                                    setRemarks(remarksValue)
                                    setUploaderOpen(true)
                                }} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                                    Accept
                                </Button>
                        </div>
                    </div>
                </div>

            </> :
                <div style={{ ...cardStyle, border: "none", display: "block", marginTop: 160 }}>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        <Typography.Title level={3}>
                            Please upload a signed copy of the contract proposal in PDF format.
                        </Typography.Title>
                        <Divider />
                    </div>
                    <div
                        style={{ display: "flex", padding: "0px 40px", marginTop: 60 }}
                    >
                        <Steps
                            style={{ height: 300 }}
                            current={current}
                            onChange={onChange}
                            direction="vertical"
                            items={[
                                {
                                    title: 'Download Contract Proposal',
                                },
                                {
                                    title: 'Upload signed Contract Proposal',
                                },
                            ]}
                        />
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={(e) => { handleDownload(e, contractDetails?.url); setCurrent(1) }} style={{ width: 200 }} type="primary" size="large">Download</Button>
                            </div>
                            <div style={{ marginTop: 80 }}>
                                <Form onValuesChange={handleAcceptProposalFormChange} form={form} layout="vertical">

                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="file"
                                        rules={[{ required: true, message: "Please enter file (less than 5MB)" }]}
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            name='file'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={dummyRequest}
                                            beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields(["file"]))}
                                        >
                                            <Button
                                                onClick={() => setCurrent(1)}
                                                style={{ display: "flex", alignItems: "center", gap: 26, height: "auto", width: 400, justifyContent: "space-between" }}
                                                type="dashed" size="large"
                                                icon={<img src="/assets/upload.png" width="95%" />}
                                            >
                                                <span style={{ textAlign: "left", marginBlock: 10 }}>Upload Signed Copy<br />
                                                    <Typography.Text type="secondary">File type: PDF</Typography.Text >
                                                </span>
                                                <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} />
                                            </Button>
                                        </Upload>

                                    </Form.Item>
                                </Form>
                            </div>
                        </div>

                    </div>
                    <div style={{ textAlign: "center" }}>
                        <Button disabled={disableAcceptProposalButton} style={{ width: 200 }} size="large" type="primary" onClick={acceptProposal}>
                            Submit
                        </Button>
                    </div>

                </div>
            }

            {modalContex}

        </>
    )
}

export const cardStyle: React.CSSProperties = {
    padding: "24px 40px 24px 40px",
    border: "solid 1px rgba(226, 226, 226, 1)",
    background: "white",
    display: "flex",
    borderRadius: 16,
    justifyContent: "space-between",
    alignItems: "center"
}

const quantityCardStyle: React.CSSProperties = {
    padding: "15px 30px 15px 30px",
    background: "white"
}