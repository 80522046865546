import { Button, Col, Divider, Form, Input, Row } from "antd";
import { useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";

export default function AddressUpdateModal({ addressUpdateData, clientData, approveOrReject }: any) {
    const [form] = Form.useForm();
    const registeredOffice = addressUpdateData?.find((item: any) => {
        return item?.addressType === "REGISTERED_OFFICE_ADDRESS"
    })

    const plantOffice = addressUpdateData?.find((item: any) => {
        return item?.addressType === "PLANT_OFFICE_ADDRESS"
    })

    const auth = useAppSelector(authSelector);


    useEffect(() => {



        console.log("registeredOffice", registeredOffice, "plantOffice", plantOffice)


        form.setFieldsValue({
            "name": clientData?.clientHistory?.name,
            "mobileNo": clientData?.clientHistory?.mobileNo,
            "telephoneNo": clientData?.clientHistory?.telephoneNo,
            "registeredOfficeAddressLine1": registeredOffice?.addressLine1,
            "registeredOfficeAddressLine2": registeredOffice?.addressLine2,
            "registeredOfficeAddressCity": registeredOffice?.city,
            "registeredOfficeAddressState": registeredOffice?.state,
            "registeredOfficeAddressPinCode": registeredOffice?.pinCode,
            "registeredOfficeCountry": registeredOffice?.country,
            "plantOfficeAddressLine1": plantOffice?.addressLine1,
            "plantOfficeAddressLine2": plantOffice?.addressLine2,
            "plantOfficeAddressCity": plantOffice?.city,
            "plantOfficeAddressState": plantOffice?.state,
            "plantOfficeAddressPinCode": plantOffice?.pinCode,
            "plantOfficeCountry": plantOffice?.country,

        })
    }, [addressUpdateData])


    return (
        <Form
            // onChange={handleFormChange}
            style={{ marginTop: "20px" }}
            form={form}
            // disabled={loading}
            name="client-details"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            // onFinish={updateClient}
            autoComplete="off"
        >
            <Row gutter={24}>

                {clientData?.clientHistory?.name &&
                    <Col span={12}>
                        <Form.Item
                            label="Company name"
                            name="name"
                            rules={[{ required: true }]}
                        >
                            <Input disabled size="large" />
                        </Form.Item>
                    </Col>
                }

                {clientData?.clientHistory?.mobileNo &&
                    <Col span={12}>
                        <Form.Item
                            label="Mobile No."
                            name="mobileNo"
                            rules={[{ required: true }]}
                        >
                            <Input disabled type="number" size="large" style={{ width: "100%" }} prefix={<div style={{ display: "flex", alignItems: "center", gap: 8 }}><span>+91-</span><span style={{ height: 26, width: 1, background: "#DADADA" }}></span></div>} />
                        </Form.Item>
                    </Col>
                }

                {clientData?.clientHistory?.telephoneNo &&
                    <Col span={12}>
                        <Form.Item
                            label="Telephone No."
                            name="telephoneNo"
                            rules={[{ required: true }]}
                        >
                            <Input disabled size="large" />
                        </Form.Item>
                    </Col>
                }

                {registeredOffice && <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold" }} orientation="left">Registered Office Address</Divider>
                    </div>
                </Col>}

                {registeredOffice?.addressLine1 && <Col span={12}>
                    <Form.Item
                        label="Address Line 1"
                        name="registeredOfficeAddressLine1"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {registeredOffice?.addressLine2 && <Col span={12}>
                    <Form.Item
                        label="Address Line 2"
                        name="registeredOfficeAddressLine2"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {registeredOffice?.city && <Col span={12}>
                    <Form.Item

                        label="City"
                        name="registeredOfficeAddressCity"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {registeredOffice?.state && <Col span={12}>
                    <Form.Item
                        label="State"
                        name="registeredOfficeAddressState"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {registeredOffice?.pinCode && <Col span={12}>
                    <Form.Item
                        label="Pin-Code"
                        name="registeredOfficeAddressPinCode"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {registeredOffice?.country && <Col span={12}>
                    <Form.Item
                        label="Country"
                        name="registeredOfficeCountry"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {plantOffice && <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold" }} orientation="left">Plant Office Address</Divider>
                    </div>
                </Col>}

                {plantOffice?.addressLine1 && <Col span={12}>
                    <Form.Item
                        label="Address Line 1"
                        name="plantOfficeAddressLine1"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {plantOffice?.addressLine2 && <Col span={12}>
                    <Form.Item
                        label="Address Line 2"
                        name="plantOfficeAddressLine2"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {plantOffice?.city && <Col span={12}>
                    <Form.Item
                        label="City"
                        name="plantOfficeAddressCity"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {plantOffice?.state && <Col span={12}>
                    <Form.Item
                        label="State"
                        name="plantOfficeAddressState"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {plantOffice?.pinCode && <Col span={12}>
                    <Form.Item
                        label="Pin-Code"
                        name="plantOfficeAddressPinCode"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
                {plantOffice?.country && <Col span={12}>
                    <Form.Item
                        label="Country"
                        name="plantOfficeCountry"
                        rules={[{ required: true }]}
                    >
                        <Input disabled size="large" />

                    </Form.Item>
                </Col>}
            </Row>
            {((auth.isClient && clientData.addressUpdateStatus === "UPDATED_BY_ADMIN") || ((auth.isMainAdmin || auth.isAdmin) && clientData.addressUpdateStatus === "UPDATED"))
                && <div style={{ display: "flex", gap: 40, justifyContent: "center", marginTop: 20 }}>
                    <Button onClick={() => approveOrReject("CLIENT_DETAILS_REJECTED")} className="reset-button-calendar" size="large" type="default">Reject</Button>

                    <Button onClick={() => approveOrReject("CLIENT_DETAILS_APPROVED")} style={{ width: 120 }} size="large" type="primary">Approve</Button>
                </div>}

        </Form>
    )
}