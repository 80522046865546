import { Button, Col, Form, Modal, Row, Space, Typography, Upload } from "antd"
import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import { instance } from "../../../utils/api";
import { useState } from "react";
import ExcelUploadError from "../../../components/ExcelUploadError";
import { beforeUpload, showSuccess } from "../../../utils/common";

function UploadInvoiceForm({ closeModal, fetchData, startDate, endDate, contractId, id, uploadType, setUrlState }: any) {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [fileLoaded, setFileLoaded] = useState(false)
    const [hasError, setHasError] = useState(null);

    const handleSubmit = async (values: any) => {
        const formData = new FormData();
        const jsonData = {
            "fortnightStartDate": startDate,
            "fortnightEndDate": endDate,
            "contractId": contractId
          }
        formData.append(uploadType === "invoice" ? "invoice" : "jointTicket", values[uploadType]);
        formData.append('fortnight', new Blob([JSON.stringify(jsonData)], { type: 'application/json' }));

        let temp = values;


        delete temp[uploadType]
        setLoading(true)
        try {
            if(id){
                await instance.put(`orderservice/nominate/fortnight/${uploadType === "invoice" ? "invoice" : "joint-ticket"}?id=${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if(setUrlState) {
                        setUrlState((prevState:any)=>{
                            return {
                                ...prevState,
                                record: {
                                    id: res.data.id,
                                    invoice: res?.data?.invoice,
                                    jointTicket: res?.data?.jointTicket
                                }
                            }
                        })
                    }
                    handleSuccess()

                })
                
            } else {
                await instance.post(`orderservice/nominate/fortnight/${uploadType === "invoice" ? "invoice" : "joint-ticket"}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if(setUrlState) {
                        setUrlState((prevState:any)=>{
                            return {
                                ...prevState,
                                record: {
                                    id: res.data.id,
                                    invoice: res?.data?.invoice,
                                    jointTicket: res?.data?.jointTicket
                                }
                            }
                        })
                    }
                    handleSuccess()
                })
            }
            
            setFileLoaded(false)

            
        } catch (e:any) {
            if(e?.response?.data?.errorMessage){
                setHasError(e?.response?.data?.errorMessage)
            }
            setFileLoaded(false)
            console.log(e, "got error")
        }
        setLoading(false)
    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };


    const handleSuccess = async () => {
        form.resetFields()
        await showSuccess(<>The {uploadType} has been successfully shared with client.</>, Modal)
        // close modal after 2 seconds
        
        // setTimeout(() => {
            closeModal()
        // }, 2000)
        fetchData()
    }


    return (
        <div style={{ width: 750 }}>

            {!hasError ? <Form
                name={uploadType+"-upload-form"}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                style={{ marginTop: 20 }}
                form={form}
                onFinish={handleSubmit}
            >
                <Row gutter={24}>
                    <Col style={{ display: "flex", alignItems: "center" }} span={12}>
                        <div>
                            <Typography.Text style={{ fontSize: 18 }}>Upload {uploadType === "invoice" ? "Invoice" : "Ticket"} Document</Typography.Text>
                        </div>
                    </Col>
                    <Col span={12}>
                            {/*  */}
                            {/* <Space direction="vertical"> */}
                                <Form.Item
                                    rules={[{ required: true, message: "Please enter file (less than 5MB)" }]}
                                    style={{ margin: 0 }}
                                    name={uploadType}
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        name={uploadType}
                                        maxCount={1}
                                        accept="application/pdf"
                                        customRequest={dummyRequest}
                                        onChange={(info) => {
                                            if (info.fileList.length === 0) {
                                                setFileLoaded(false)
                                            } else {
                                                setFileLoaded(true)
                                            }
                                        }}
                                        beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields([uploadType]))}
                                    >
                                        <Button style={{display: "flex",alignItems: "center", gap: 26, height: "auto"}}  type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{textAlign: "left", marginBlock: 10}}>Upload {uploadType === "invoice" ? "Invoice" : "Ticket"}<br/><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                    </Upload>

                                </Form.Item>
                            {/* </Space> */}

                            {/*  */}
                    </Col>

                </Row>

                <Space style={{ width: "100%", justifyContent: "center", marginTop: 40 }}>
                    <Button loading={loading} disabled={loading || !fileLoaded} type="primary" size="large" htmlType="submit">Submit</Button>
                    <Button onClick={closeModal} size="large">Cancel</Button>
                </Space>
            </Form> : <ExcelUploadError error={hasError} setError={setHasError} />}
        </div>
    )
}

export default UploadInvoiceForm