import { Table, Typography } from "antd";
import { getPaginationCountApi } from "../../../utils/common";
import theme from "../../../utils/theme";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import { columns } from "../../../interfaces/TableColumns/WorkflowReport";
import { TableDataType } from "../../../interfaces/TableData";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { Item } from "../../../interfaces/TableColumns/Confirmation";
import EmptyList from "../GasOperations/EmptyList";

export interface DataType extends TableDataType {
    items: Item[] | [];
}

export default function WorkflowReport() {

    const [data, setData] = useState<any | null>(null);

    const { tableParams, handleSort } = useTableFilters("auditInfo.creationDate|DESC", '', true ,false,true);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchData();
    }, [tableParams])

    const fetchData = async () => {
        const params = {
            ...tableParams,
            // filter and contract endDate is less than current date
            filterAnd: `${tableParams.filterAnd ? `${tableParams.filterAnd}` : ""}`
        }
        setLoading(true)
        try {
            const res = await instance.get("orderservice/nominate", { params })
            setData(res.data);
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setLoading(false)
    };


    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>

            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, marginTop: 0 }}>Workflow Report</Typography.Title>

            {<Table
                loading={loading}
                onChange={handleSort}
                rowClassName="table-row"
                columns={columns}
                dataSource={data?.items}
                pagination={{
                    pageSize: tableParams.size,
                    total: data?.totalItems,
                    current: tableParams.page,
                    showSizeChanger: false,
                    position: ["bottomCenter"]
                }}
                footer={()=><div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                rowKey={(record) => record.id}
                scroll={{ x: true
                    , y: data?.totalItems > 0 ? "70vh" : undefined 
                }}
                showHeader={!data?.items || data?.items?.length === 0 ? false : true}
                locale={loading ? undefined : { emptyText: <EmptyList customMessage={<>You have no Nominations</>}/> }}
            />}


        </div>
    )
}