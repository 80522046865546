import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons"
import { Row, Col, Upload, Button, Form, Divider, message, Input, Space, Typography, Select, Modal } from "antd"
import { useEffect, useState } from "react"
import { instance } from "../../../utils/api";
import theme from "../../../utils/theme";
import { Link } from "react-router-dom";
import ViewPDF from "../../../components/ViewPDF";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import { beforeUpload } from "../../../utils/common";
const _debounce = require('lodash/debounce');
interface KYCDetails {
    id?: number;
    url?: string | null;
    type: string;
    kycId: string;
}

interface Values {
    [key: string]: any;
}

function KycForm({ clientID, registeredName, getKycDetails, kycDetails, setDefaulttab, getClientData, clientData, setInProgrss }: { clientID?: string | undefined, registeredName: any, getKycDetails: any, kycDetails?: any, setDefaulttab: any, getClientData: any, clientData: any, setInProgrss?: any }) {

    const [loading, setLoading] = useState(false)
    const [enableReset, setEnableReset] = useState(false)
    const [editing, setEditing] = useState(true)
    const [modal, modalContex] = Modal.useModal();
    const [options, setOptions] = useState<any>([]);
    const auth = useAppSelector(authSelector);


    const [form] = Form.useForm()

    useEffect(() => {
        setEditing(kycDetails?.length < 1 ? true : false)
    }, [kycDetails])


    const dummyRequest = ({ file, onSuccess }: any, fileType: string) => {
        setTimeout(() => {
            onSuccess("ok");
            form.setFields([
                {
                    name: `${fileType}File`,
                    errors: []
                },
            ])
        }, 0);
    }

    useEffect(() => {
        // form.setFieldsValue(data)
        setForm()
    }, [kycDetails, clientData])

    useEffect(() => {
        if (setInProgrss) {
            setInProgrss(editing)
        }
    }, [editing])

    const setForm = () => {
        form.setFieldValue("registeredName", registeredName)
        if (kycDetails) {
            setFormValues()
        }
    }

    const setFormValues = () => {
        setEnableReset(false)

        // const aadhaarNo = kycDetails?.find((i: any) => i.type === "AADHAAR")?.kycId;
        const panNo = kycDetails?.find((i: any) => i.type === "PAN")?.kycId;
        const vatNo = kycDetails?.find((i: any) => i.type === "VAT")?.kycId;
        const cinNo = kycDetails?.find((i: any) => i.type === "CIN")?.kycId;
        const cstNo = kycDetails?.find((i: any) => i.type === "CST")?.kycId;
        const gstNo = kycDetails?.find((i: any) => i.type === "GST")?.kycId;

        // form.setFieldValue("aadhaarNo", aadhaarNo)
        form.setFieldValue("panNo", panNo)
        form.setFieldValue("vatNo", vatNo)
        form.setFieldValue("cinNo", cinNo)
        form.setFieldValue("cstNo", cstNo)
        form.setFieldValue("gstNo", gstNo)

    }

    const onUpdate = async (values: Values) => {
        console.log("Values received:xxx", values);

        setLoading(true);

        const kycTypes = ['cin', 'cst', 'gst', 'pan', 'vat'];
        const createKycDtoList: KYCDetails[] = [];
        const formData: any = new FormData();
        let hasError = false;
        kycTypes.forEach((type) => {
            const kycNo = values[`${type}No`];
            const kycFile = values[`${type}File`];
            const id = kycDetails?.find((i: any) => i.type === type.toUpperCase()).id
            const url = kycDetails?.find((i: any) => i.type === type.toUpperCase()).url

            console.log("kycFile", kycFile)
            if (kycNo) {
                if (kycFile) {

                    createKycDtoList.push({
                        type: type.toUpperCase(),
                        kycId: kycDetails?.find((i: any) => i.type === type.toUpperCase())?.kycId === kycNo ? null : kycNo,
                        id: id,
                        url: null
                    });
                    formData.append("files", kycFile);
                } else {
                    if (url) {

                        if (clientData?.registrationStatus == "ADMIN_ONBOARDING_COMPLETED" || clientData?.registrationStatus == "APPROVED") {

                            // if(!(kycDetails?.find((i: any) => i.type === type.toUpperCase())?.kycId === kycNo)){
                            //     form.setFields([
                            //         {
                            //             name: `${type}File`,
                            //             errors: [`Upload ${type.toUpperCase()} file`]
                            //         },
                            //     ])
                            //     hasError = true
                            // }

                            createKycDtoList.push({
                                type: type.toUpperCase(),
                                kycId: kycDetails?.find((i: any) => i.type === type.toUpperCase())?.kycId === kycNo ? null : kycNo,
                                id: id,
                                url: url
                            });
                        } else {
                            createKycDtoList.push({
                                type: type.toUpperCase(),
                                kycId: kycNo,
                                id: id,
                                url: url
                            });
                        }
                        formData.append("files", null);

                    } else {
                        message.error(`Upload ${type.toUpperCase()} file`);
                        hasError = true;
                    }
                }
            }
        });

        console.log("createKycDtoList", createKycDtoList)

        if (hasError) {

            setLoading(false);
            return;
        }

        const temp = {
            "clientId": clientID,
            "companyRegisteredName": values.registeredName.label,
            "companyRegisteredId": typeof values.registeredName.key === "string" ? null : values.registeredName.key,
            "createKycDtoList": createKycDtoList
        };

        console.log("temp", temp)


        console.log("Payload ready for submission:", temp);

        try {
            if (clientData?.registrationStatus == "ADMIN_ONBOARDING_COMPLETED" || clientData?.registrationStatus == "APPROVED") {
                
                temp.companyRegisteredName = registeredName.label === values.registeredName.label ? null : values.registeredName.label
                temp.createKycDtoList = createKycDtoList.filter((item:any)=>item.kycId !== null || !item.url)

                formData.append('details', new Blob([JSON.stringify(temp)], { type: 'application/json' }));

                console.log("temp", temp)

                Modal.confirm({
                    title: <div>
                        Are you sure you want to submit these changes?
                    </div>,
                    // content: 'Once deleted changes cannot be reverted',
                    okText: 'Yes',
                    okType: 'danger',
                    centered: true,
                    cancelText: 'No',
                    icon: null,
                    onOk: async () => {
                        try{
                            const res = await instance.put(`clientservice/clients/kyc/edit-profile`, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            });
                            message.success("Client KYC update request created successfully!");
                            getKycDetails();
                            getClientData();
                            //   form.resetFields();
                            setEditing(false);
                            setDefaulttab()
                        } catch(e){
                            console.log(e)
                        }
                    },
                    bodyStyle: {
                        textAlign: "center",
                    },
                    okButtonProps: {
                        size: "large",
                        type: "primary",
                        style: { background: theme.token.colorPrimary, width: 140, fontWeight: 600 }
                    },
                    cancelButtonProps: {
                        size: "large",
                        type: "default",
                        style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary }
                    }
                })

            } else {
                Modal.confirm({
                    title: <div>
                        Are you sure you want to submit these changes?
                    </div>,
                    // content: 'Once deleted changes cannot be reverted',
                    okText: 'Yes',
                    okType: 'danger',
                    centered: true,
                    cancelText: 'No',
                    icon: null,
                    onOk: async () => {
                        formData.append('details', new Blob([JSON.stringify(temp)], { type: 'application/json' }));

                        try{
                            const res = await instance.post(`clientservice/clients/kyc-registration-details`, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            });
                            message.success("Client KYC updated successfully!");
                            getKycDetails();
                            getClientData();
                            //   form.resetFields();
                            setEditing(false);
                            setDefaulttab()
                        } catch(e){
                            console.log(e)
                        }
                    },
                    bodyStyle: {
                        textAlign: "center",
                    },
                    okButtonProps: {
                        size: "large",
                        type: "primary",
                        style: { background: theme.token.colorPrimary, width: 140, fontWeight: 600 }
                    },
                    cancelButtonProps: {
                        size: "large",
                        type: "default",
                        style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary }
                    }
                })
                
            }
            
        } catch (error) {
            console.error("Failed to submit KYC details:", error);
            message.error("Failed to submit KYC details.");
        } finally {
            setLoading(false);
        }
    }


    const onCreate = async (values: Values) => {
        console.log("Values received:", values);
        setLoading(true);

        const kycTypes = ['cin', 'cst', 'gst', 'pan', 'vat'];
        const createKycDtoList: KYCDetails[] = [];
        const formData = new FormData();
        let hasError = false;

        kycTypes.forEach((type) => {
            const kycNo = values[`${type}No`];
            const kycFile = values[`${type}File`];
            if (kycNo && !kycDetails?.find((i: any) => i.type === type.toUpperCase())) {
                if (kycFile) {
                    createKycDtoList.push({
                        type: type.toUpperCase(),
                        kycId: kycNo,
                    });
                    formData.append("files", kycFile);
                } else {
                    //   message.error(`Upload ${type.toUpperCase()} file`);
                    form.setFields([
                        {
                            name: `${type}File`,
                            errors: [`Upload ${type.toUpperCase()} file`]
                        },
                    ])
                    hasError = true;
                }
            }
        });

        if (hasError) {
            setLoading(false);
            return;
        }

        const temp = {
            "clientId": clientID,
            "companyRegisteredName": values.registeredName.label,
            "companyRegisteredId": typeof values.registeredName.key === "string" ? null : values.registeredName.key,
            "createKycDtoList": createKycDtoList
        };

        formData.append('details', new Blob([JSON.stringify(temp)], { type: 'application/json' }));

        console.log("Payload ready for submission:", temp);

        try {
            const res = await instance.post(`clientservice/clients/kyc-registration-details`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            getKycDetails();
            getClientData();
            message.success("Client KYC added successfully!");
            //   form.resetFields();
            //   setForm()
            setEditing(false);
            setDefaulttab()
        } catch (error) {
            console.error("Failed to submit KYC details:", error);
            message.error("Failed to submit KYC details.");
        } finally {
            setLoading(false);
        }
    }



    const onSearch = async (value: string, options: any) => {
        value && await instance.get(`clientservice/clients/company-list`, {
            params: {
                filterAnd: `companyRegisteredName|like|${value}`
            }
        }).then((res: any) => {
            const filteredOptions = res.data;
            console.log("filteredOptions", filteredOptions, value.trim(), value)
            if (filteredOptions.length === 0 && value.trim() !== '') {
                const newItem = {
                    id: value.charAt(0).toUpperCase() + value.slice(1),
                    companyRegisteredName: value.charAt(0).toUpperCase() + value.slice(1)
                }
                const index = options.findIndex((item: any) => typeof (item.id) === "string");
                if (index !== -1) {
                    console.log("updated items,1 ")

                    // If item with id "new" exists, update its value
                    const updatedState = [...options];
                    updatedState[index] = newItem;
                    setOptions(updatedState);
                } else {
                    console.log("updated items, 2")

                    // If item with id "new" doesn't exist, add it to the state
                    setOptions((prevState: any) => [...prevState, newItem]);
                }
            } else if (value === "") {
                console.log("updated items, 3")

                setOptions((prevOptions: any) => {
                    return prevOptions.filter((item: any) => typeof (item.id) !== "string")
                })
            } else {
                console.log("updated items, 4", filteredOptions, value, "xxx")
                const newItem = {
                    id: value.charAt(0).toUpperCase() + value.slice(1),
                    companyRegisteredName: value.charAt(0).toUpperCase() + value.slice(1)
                }

                const exactMatch = filteredOptions.find((item: any) => item.companyRegisteredName === value)

                if (exactMatch) {
                    setOptions(filteredOptions)
                } else {
                    setOptions([...filteredOptions, newItem])
                }

            }
        }).catch((err: any) => {
            console.log("err", err)
        })
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const debouncedOnFilterSearch = _debounce((value: string, options: any) => {
        onSearch(value, options);
    }, 1000);

    const handleFormChange = () => {
        // const field = e.target.getAttribute('id');
        // const name = field.split("_")[1]

        const panNo = kycDetails?.find((i: any) => i.type === "PAN")?.kycId;
        const vatNo = kycDetails?.find((i: any) => i.type === "VAT")?.kycId;
        const cinNo = kycDetails?.find((i: any) => i.type === "CIN")?.kycId;
        const cstNo = kycDetails?.find((i: any) => i.type === "CST")?.kycId;
        const gstNo = kycDetails?.find((i: any) => i.type === "GST")?.kycId;

        let originalData: any = {
            "registeredName": registeredName,
            "cinNo": cinNo,
            "panNo": panNo,
            "vatNo": vatNo,
            "cstNo": cstNo,
            "gstNo": gstNo
        }
        const obj1String = JSON.stringify(originalData);
        const obj2String = JSON.stringify(form.getFieldsValue());

        console.log(obj2String)
        console.log(obj1String)



        if (obj1String === obj2String) {
            setEnableReset(false)

        } else {
            setEnableReset(true)
        }

    }

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold", color: theme.token.colorPrimary }} orientation="left">Registration details</Divider>
            </div>
            {modalContex}

            {

                <Form
                    scrollToFirstError
                    form={form}
                    disabled={!editing}
                    name="kyc-details"
                    layout="vertical"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onChange={() => setTimeout(() => { handleFormChange(); }, 500)}

                    // onFieldsChange={() => setEnableReset(true)}
                    // onFinish={kycDetails ? updateKYC : onCreate}
                    onFinish={kycDetails?.length < 1 ? onCreate : onUpdate}

                    encType='multipart/form-data'
                    autoComplete="off"
                >

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={8}>
                            <Form.Item style={{ marginBottom: 10 }} label="Company Registered Name"
                                name="registeredName"
                                rules={[{ required: true }]} >
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    placeholder="Enter company registered name"
                                    size="large"
                                    onSearch={(value: string) => debouncedOnFilterSearch(value, options)}
                                    labelInValue
                                    filterOption={filterOption}
                                    onChange={(value) => handleFormChange()}
                                    options={options?.map((d: any) => ({
                                        value: d.id,
                                        label: d.companyRegisteredName,
                                        // disabled: true
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="AADHAAR No."
                                name="aadhaarNo"
                                rules={[{
                                    validator: async (_, value) => {
                                        if(!value){
                                            return Promise.reject(new Error("Please input AADHAR No."));
    
                                        }
                                        if (value.length !== 12) {
                                            return Promise.reject(new Error('AADHAAR should be 12 Characters'));
                                        }
                                    },
                                }]}
                                >
                                <Input type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={18}>
                            {kycDetails?.find((i: any) => i.type === "AADHAAR") && !editing ?
                                <div style={{ display: "flex", gap: 20, float: "right" }}>
                                    <div className="hide-me">
                                        <ViewPDF name={`AADHAAR Card`} url={kycDetails?.find((i: any) => i.type === "AADHAAR")?.url} />
                                    </div>
                                    <div className="show-me" style={{ display: "none" }}>{kycDetails?.find((i: any) => i.type === "AADHAAR")?.url}</div>

                                </div> :
                                <div style={{ display: "flex", gap: 0, flexDirection: "column", float: "right" }}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="aadhaarFile"
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            disabled={!editing}
                                            name='aadhaarFile'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={dummyRequest}
                                        >
                                            <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 16, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ width: 160, textAlign: "left", marginBlock: 10 }}>Upload AADHAAR Certificate<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                        </Upload>

                                    </Form.Item>
                                    {kycDetails?.find((i: any) => i.type === "AADHAAR")?.url &&
                                        <div style={{ width: 200, marginTop: 8 }}>
                                            <Link target="_blank" to={kycDetails?.find((i: any) => i.type === "AADHAAR")?.url}>
                                                <Typography.Text style={{ color: theme.token.colorPrimary }} ellipsis>{kycDetails?.find((i: any) => i.type === "AADHAAR")?.url}</Typography.Text>
                                            </Link>
                                        </div>
                                    }

                                </div>}

                        </Col>

                    </Row> */}

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="Corporate Identification Number"
                                name="cinNo"
                                rules={[{ required: true }]} >
                                <Input type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={18}>
                            {kycDetails?.find((i: any) => i.type === "CIN") && !editing ?

                                <div style={{ display: "flex", gap: 20, float: "right" }}>
                                    <div className="hide-me">
                                        <ViewPDF name={`CIN Certificate`} url={kycDetails?.find((i: any) => i.type === "CIN")?.url} />
                                    </div>
                                    <div className="show-me" style={{ display: "none" }}>{kycDetails?.find((i: any) => i.type === "CIN")?.url}</div>

                                </div>
                                : <div style={{ display: "flex", gap: 0, flexDirection: "column", float: "right" }}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="cinFile"
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            disabled={!editing}
                                            name='cinFile'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={(options)=>dummyRequest(options, "cin")}
                                            beforeUpload={(file: any) => beforeUpload(file, () => form.resetFields(["cinFile"]))}
                                        >
                                            <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 16, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ width: 160, textAlign: "left", marginBlock: 10 }}>Upload CIN Certificate<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                        </Upload>

                                    </Form.Item>
                                    {kycDetails?.find((i: any) => i.type === "CIN")?.url &&
                                        <div style={{ width: 200, marginTop: 8 }}>
                                            <Link target="_blank" to={kycDetails?.find((i: any) => i.type === "CIN")?.url}>
                                                <Typography.Text style={{ color: theme.token.colorPrimary }} ellipsis>{kycDetails?.find((i: any) => i.type === "CIN")?.url}</Typography.Text>
                                            </Link>
                                        </div>
                                    }

                                </div>}

                        </Col>

                    </Row>

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="PAN Card No."
                                name="panNo"
                                rules={[{ required: true }]} >
                                <Input type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={18}>
                            {kycDetails?.find((i: any) => i.type === "PAN") && !editing ?
                                <div style={{ display: "flex", gap: 20, float: "right" }}>
                                    <div className="hide-me">
                                        <ViewPDF name={`PAN Card`} url={kycDetails?.find((i: any) => i.type === "PAN")?.url} />
                                    </div>
                                    <div className="show-me" style={{ display: "none" }}>{kycDetails?.find((i: any) => i.type === "PAN")?.url}</div>

                                </div>

                                : <div style={{ display: "flex", gap: 0, flexDirection: "column", float: "right" }}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="panFile"
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            disabled={!editing}
                                            name='panFile'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={(options)=>dummyRequest(options, "pan")}
                                            beforeUpload={(file: any) => beforeUpload(file, () => form.resetFields(["panFile"]))}
                                        >
                                            <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 16, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ width: 160, textAlign: "left", marginBlock: 10 }}>Upload PAN Card<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                        </Upload>

                                    </Form.Item>
                                    {kycDetails?.find((i: any) => i.type === "PAN")?.url &&
                                        <div style={{ width: 200, marginTop: 8 }}>
                                            <Link target="_blank" to={kycDetails?.find((i: any) => i.type === "PAN")?.url}>
                                                <Typography.Text style={{ color: theme.token.colorPrimary }} ellipsis>{kycDetails?.find((i: any) => i.type === "PAN")?.url}</Typography.Text>
                                            </Link>
                                        </div>
                                    }

                                </div>}

                        </Col>

                    </Row>



                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="VAT No."
                                name="vatNo"
                                rules={[{ required: true }]} >
                                <Input type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={18}>
                            {kycDetails?.find((i: any) => i.type === "VAT") && !editing ?
                                <div style={{ display: "flex", gap: 20, float: "right" }}>
                                    <div className="hide-me">
                                        <ViewPDF name={`VAT Certificate`} url={kycDetails?.find((i: any) => i.type === "VAT")?.url} />
                                    </div>
                                    <div className="show-me" style={{ display: "none" }}>{kycDetails?.find((i: any) => i.type === "VAT")?.url}</div>

                                </div>
                                : <div style={{ display: "flex", gap: 0, flexDirection: "column", float: "right" }}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="vatFile"
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            disabled={!editing}
                                            name='vatFile'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={(options)=>dummyRequest(options, "vat")}
                                            beforeUpload={(file: any) => beforeUpload(file, () => form.resetFields(["vatFile"]))}
                                        >
                                            <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 16, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ width: 160, textAlign: "left", marginBlock: 10 }}>Upload VAT Certificate<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                        </Upload>

                                    </Form.Item>
                                    {kycDetails?.find((i: any) => i.type === "VAT")?.url &&
                                        <div style={{ width: 200, marginTop: 8 }}>
                                            <Link target="_blank" to={kycDetails?.find((i: any) => i.type === "VAT")?.url}>
                                                <Typography.Text style={{ color: theme.token.colorPrimary }} ellipsis>{kycDetails?.find((i: any) => i.type === "VAT")?.url}</Typography.Text>
                                            </Link>
                                        </div>
                                    }

                                </div>}

                        </Col>

                    </Row>



                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="CST No."
                                name="cstNo"
                                rules={[{ required: true }]} >
                                <Input type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={18}>
                            {kycDetails?.find((i: any) => i.type === "CST") && !editing ?

                                <div style={{ display: "flex", gap: 20, float: "right" }}>
                                    <div className="hide-me">
                                        <ViewPDF name={`CST Certificate`} url={kycDetails?.find((i: any) => i.type === "CST")?.url} />
                                    </div>
                                    <div className="show-me" style={{ display: "none" }}>{kycDetails?.find((i: any) => i.type === "CST")?.url}</div>
                                </div>
                                : <div style={{ display: "flex", gap: 0, flexDirection: "column", float: "right" }}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="cstFile"
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            disabled={!editing}
                                            name='cstFile'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={(options)=>dummyRequest(options, "cst")}
                                            beforeUpload={(file: any) => beforeUpload(file, () => form.resetFields(["cstFile"]))}
                                        >
                                            <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 16, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ width: 160, textAlign: "left", marginBlock: 10 }}>Upload CST Certificate<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                        </Upload>

                                    </Form.Item>

                                    {kycDetails?.find((i: any) => i.type === "CST")?.url &&
                                        <div style={{ width: 200, marginTop: 8 }}>
                                            <Link target="_blank" to={kycDetails?.find((i: any) => i.type === "CST")?.url}>
                                                <Typography.Text style={{ color: theme.token.colorPrimary }} ellipsis>{kycDetails?.find((i: any) => i.type === "CST")?.url}</Typography.Text>
                                            </Link>
                                        </div>
                                    }

                                </div>}

                        </Col>

                    </Row>

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="GST No."
                                name="gstNo"
                                rules={[{ required: true }]} >
                                <Input type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={18}>
                            {kycDetails?.find((i: any) => i.type === "GST") && !editing ?

                                <div style={{ display: "flex", gap: 20, float: "right" }}>
                                    <div className="hide-me">
                                        <ViewPDF name={`GST Certificate`} url={kycDetails?.find((i: any) => i.type === "GST")?.url} />
                                    </div>
                                    <div className="show-me" style={{ display: "none" }}>{kycDetails?.find((i: any) => i.type === "GST")?.url}</div>

                                </div>
                                : <div style={{ display: "flex", gap: 0, flexDirection: "column", float: "right" }}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="gstFile"
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            disabled={!editing}
                                            name='gstFile'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={(options)=>dummyRequest(options, "gst")}
                                            beforeUpload={(file: any) => beforeUpload(file, () => form.resetFields(["gstFile"]))}
                                        >
                                            <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 16, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ width: 160, textAlign: "left", marginBlock: 10 }}>Upload GST Certificate<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                        </Upload>
                                    </Form.Item>

                                    {kycDetails?.find((i: any) => i.type === "GST")?.url &&
                                        <div style={{ width: 200, marginTop: 8 }}>
                                            <Link target="_blank" to={kycDetails?.find((i: any) => i.type === "GST")?.url}>
                                                <Typography.Text style={{ color: theme.token.colorPrimary }} ellipsis>{kycDetails?.find((i: any) => i.type === "GST")?.url}</Typography.Text>
                                            </Link>
                                        </div>
                                    }

                                </div>}

                        </Col>

                    </Row>
                </Form>


            }
            <div className="hide-me" style={{ display: "flex", justifyContent: "center", marginTop: 36 }}>

                {kycDetails?.length >= 1 && !editing ? <Space size="large">
                    {((auth.isClient && clientData?.kycUpdateStatus !== "UPDATED_BY_ADMIN") || ((auth.isAdmin || auth.isMainAdmin) && clientData?.kycUpdateStatus !== "UPDATED")) && <Button onClick={() => { setEditing(true) }} style={{ width: 200 }} size="large">
                        Edit
                    </Button>}
                    <Button onClick={setDefaulttab} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                        Next
                    </Button>
                </Space> : <Space size="large">
                    <Button onClick={() => {
                        if (kycDetails?.length < 1) {
                            form.resetFields()
                        } else {
                            setForm(); setEditing(false)
                        }
                    }} style={{ width: 200 }} size="large">
                        Cancel
                    </Button>
                    <Button disabled={!enableReset} loading={loading} onClick={() => form.submit()} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                        Save & Next
                    </Button>
                </Space>
                }

            </div>
        </div>
    )

}

export default KycForm

// AADHAAR,
// PAN,
// VAT,
// CIN,
// CST,
// GST;