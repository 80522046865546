import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import KycForm from './KycForm';
import { Modal, Skeleton } from 'antd';
import UpdateStatusLabel from './UpdateStatusLabel';
import theme from '../../../utils/theme';
import KycUpdateModal from './KycUpdateModal';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import KycEmpty from './KycEmpty';


function KycDetails({ id, registeredName, clientData, setDefaulttab, getClientData, updateStatus, approveOrReject,setInProgrss,tabStates }: { id: string | undefined, registeredName: any, setDefaulttab: Dispatch<SetStateAction<any | null>>, getClientData: any, clientData: any, updateStatus: string, approveOrReject: any,setInProgrss?: any,tabStates?:any }) {

    const [kycDetails, setKycDetails] = useState<[]>()
    const [fetchingDetails, setFetchingDetails] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const auth = useAppSelector(authSelector);
    const [showKycForm, setShowKycForm] = useState(false);



    useEffect(() => {
        if(setInProgrss){
            if(!tabStates.isKycCompleted){
                setInProgrss(true)
            }else {
                setInProgrss(false)
            }
        }
        
        getKycDetails()
    }, [])

    useEffect(()=>{
        if(kycDetails && kycDetails?.length >  0) {
            setShowKycForm(true)
        }
    }, [kycDetails])


    const getKycDetails = async () => {
        setFetchingDetails(true)
        try {
            const res = await instance.get(`clientservice/clients/${id}/kyc`);
            setKycDetails(res.data)
        } catch (e) {
            console.log(e);
        }
        setFetchingDetails(false)
    }

    return (
        <React.Fragment>
            <UpdateStatusLabel label="Registration" showModal={() => setIsModalOpen(true)} updateStatus={updateStatus} />

            {!fetchingDetails
                ?

                <>

                    {kycDetails?.length === 0 && !showKycForm ? <KycEmpty setShowKycForm={setShowKycForm} /> : 
                    <KycForm
                        setInProgrss={setInProgrss}
                        clientData={clientData}
                        getClientData={getClientData}
                        registeredName={registeredName}
                        clientID={id} getKycDetails={getKycDetails}
                        kycDetails={kycDetails}
                        setDefaulttab={setDefaulttab}
                    />}
                </>

                :
                <Skeleton />}

            <Modal destroyOnClose width={869} footer={null} style={{ textAlign: "center" }} bodyStyle={{ paddingBottom: 16 }} title={<div style={{ marginTop: 16, color: theme.token.colorPrimary }}>
                {(clientData?.kycUpdateStatus === "UPDATED_BY_ADMIN" && (auth.isClient)) && "Admin has edited your Registration details, please review the information."}
                {(clientData?.kycUpdateStatus === "UPDATED_BY_ADMIN" && (auth.isAdmin || auth.isMainAdmin)) && "Registration details has been updated."}


                {(clientData?.kycUpdateStatus === "UPDATED" && (auth.isClient)) && "Registration details has been updated."}
                {(clientData?.kycUpdateStatus === "UPDATED" && (auth.isAdmin || auth.isMainAdmin)) && "Client has edited your Registration details, please review the information."}

            </div>} open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>
                <KycUpdateModal approveOrReject={(type: any) => { approveOrReject(type, getKycDetails); setIsModalOpen(false) }} clientData={clientData} />
            </Modal>

        </React.Fragment>
    )

};

export default KycDetails;