import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Select } from "antd";
import { useCallback } from "react";
import theme from "../utils/theme";

function FilterSelect({ options, onFilerSearch, confirmationList }: any) {
    const [form] = Form.useForm();

    const handleSelectAll = () => {
        const selectedIds = form.getFieldValue("filter")
        const optionIds = options.map((i: any) => confirmationList ? i.contractId : i.nomination.contractId )
        
        if(selectedIds?.length === optionIds?.length){
            form.setFieldValue("filter", [])
        } else {
            form.setFieldValue("filter", optionIds)
        }
    }

    const dropdownRender = useCallback((menu: any) => (
        <>
            {options && options?.length > 0 && <div onClick={handleSelectAll} className='select-all'>
                {form.getFieldValue("filter")?.length !== options?.length ? "Select All" : "Unselect-all"}
            </div>}
            {menu}
        </>
    ), [options]);

    // useEffect(()=>{
    //     onSearch("", confirmationList ? "contractId" : "nomination.contractId", form.getFieldValue("filter"))
    // },[])

    const handleKeyPress = (e: React.KeyboardEvent) => {
        console.log(e.key)
        if (e.key === 'Enter') {
            e.stopPropagation();
            const searchButton = document.getElementById('search-button');
            if (searchButton) {
                searchButton.focus()
                searchButton.click();
            }
        }
    };

    const handleSearch = () => {
        const ids = form.getFieldValue("filter")?.join(',')
        onFilerSearch(ids,confirmationList ? "contractId" : "nomination.contractId")
    }

    // const onScroll = (event: { target: any; }) => {
    //     var target = event.target
    //     console.log("target.scrollTop, target.offsetHeight, target.scrollHeight)",target.scrollTop,target.offsetHeight,target.scrollHeight,target.scrollTop + target.offsetHeight === target.scrollHeight)
    // }

    return (
        <div id="filter-select-container">
            <Form form={form}>
                <Form.Item
                    name="filter"
                    noStyle
                >
                    <Select
                        showSearch
                        id="filter-select"
                        mode="multiple"
                        style={{ width: 250 }}
                        size='large'
                        placeholder="Enter Contract ID"
                        popupClassName='filter-select-popup'
                        options={options?.map((d: any) => ({
                            value: confirmationList ? d?.contractId : d?.nomination?.contractId,
                            label: <div><Checkbox checked={false} /><b style={{marginLeft: 10, display: "inline-block"}}>&nbsp;</b>{confirmationList ? d?.contractId : d?.nomination?.contractId}</div>,
                        }))}
                        listHeight={200}
                        // onPopupScroll={onScroll}
                        dropdownRender={dropdownRender}
                        onInputKeyDown={handleKeyPress}
                    />
                </Form.Item>
            </Form>
            <Button onClick={handleSearch} id="search-button" type="text" icon={<SearchOutlined style={{color: theme.token.colorPrimary}}/>} />
        </div>
    )
}

export default FilterSelect