import { EyeOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { Link } from "react-router-dom";

export default function ViewPDF({name, url}:any) {
    return (
        <Link target="_blank" to={url} style={{ border: "dashed 1px rgba(226, 226, 226, 1)", background: "rgba(248, 250, 255, 1)", height: "81px", display: "flex", alignItems: "center", gap: 8, paddingInline: 20, cursor: "pointer", borderRadius: 16, width: 270, justifyContent: "space-between" }}>
            <div>
                <div style={{ fontSize: 16, color: "#333333" }}>
                    {name}
                </div>
                <div style={{ width: "99px", }}>
                    <Typography.Text style={{ color: "#B6B6B6", fontSize: 11 }} ellipsis={true}>
                        PDF {url}
                    </Typography.Text>
                </div>
            </div>
            <div style={{ fontSize: 12, color: "#1B78DF" }}>
                <div style={{textAlign: "center"}}>
                    <EyeOutlined />
                </div>
                <div>View File</div>
            </div>

        </Link>
    )
}