import { Button, Col, Form, Modal, Row, Space, Typography, Upload } from "antd"
import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import { instance } from "../../../utils/api";
import { useState } from "react";
import Lottie from "lottie-react";
import SuccessAnimation from "../../../utils/success.json";
import ExcelUploadError from "../../../components/ExcelUploadError";
import { beforeUpload } from "../../../utils/common";

function UploadExcelForm({ closeModal, nominationType, fetchData }: any) {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [modal, modalContex] = Modal.useModal();
    const [fileLoaded, setFileLoaded] = useState(false)
    const [hasError, setHasError] = useState(null);

    const handleSubmit = async (values: any) => {
        const formData = new FormData();

        formData.append("file", values["file"]);
        let temp = values;


        delete temp["file"]
        setLoading(true)
        try {
            const res = await instance.post(`orderservice/excel/nominate${nominationType === "IntraDay" ? "/intraday" : ""}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setFileLoaded(false)

            handleSuccess()
            
        } catch (e:any) {
            setHasError(e.response.data.errorMessage)
            setFileLoaded(false)
            console.log(e)
        }
        setLoading(false)
    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };


    const handleSuccess = () => {
        form.resetFields()
        modal.success({
            // show success message along with image
            mask: true,
            maskClosable: true,
            closeIcon:<></>,
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10 }}>
                    <Lottie loop={false} style={{ width: 198 }} animationData={SuccessAnimation} />
                    <Typography.Title style={{ textAlign: "center", fontWeight: 500, margin: 0 }} level={4}>Your excel document submitted sucsuccessfully .</Typography.Title>

                    <Space>
                        <Button onClick={() => window.location.reload()} type="default" size="large">Done</Button>
                        <Button onClick={() => window.location.reload()} type="primary" size="large">Place new nomination</Button>
                    </Space>

                </div>
            ),
            footer: null,
            icon: null
        });
        fetchData()

    }


    return (
        <div style={{ width: 750 }}>
            {modalContex}

            {!hasError ? <Form
                name="intraday-form"
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                style={{ marginTop: 20 }}
                form={form}
                onFinish={handleSubmit}
            >
                <Row gutter={24}>
                    <Col style={{ display: "flex", alignItems: "center" }} span={12}>
                        <div>
                            <Typography.Text style={{ fontSize: 18 }}>Upload Excel Document</Typography.Text>
                        </div>
                    </Col>
                    <Col span={12}>
                            {/*  */}
                            {/* <Space direction="vertical"> */}
                                <Form.Item
                                    rules={[{ required: true, message: "Please enter file (less than 5MB)" }]}
                                    style={{ margin: 0 }}
                                    name="file"
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        name='file'
                                        maxCount={1}
                                        customRequest={dummyRequest}
                                        onChange={(info) => {
                                            if (info.fileList.length === 0) {
                                                setFileLoaded(false)
                                            } else {
                                                setFileLoaded(true)
                                            }
                                        }}
                                        beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields(["file"]))}
                                    >
                                        <Button style={{display: "flex",alignItems: "center", gap: 26, height: "auto"}}  type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{textAlign: "left", marginBlock: 10}}>Upload Excel<br/><Typography.Text type="secondary">File type: xlsx</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                    </Upload>

                                </Form.Item>
                            {/* </Space> */}

                            {/*  */}
                    </Col>

                </Row>

                <Space style={{ width: "100%", justifyContent: "center", marginTop: 40 }}>
                    <Button loading={loading} disabled={loading || !fileLoaded} type="primary" size="large" htmlType="submit">Submit</Button>
                    <Button onClick={closeModal} size="large">Cancel</Button>
                </Space>
            </Form> : <ExcelUploadError error={hasError} setError={setHasError} />}
        </div>
    )
}

export default UploadExcelForm