import { Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Item } from './Confirmation';

export const columns: ColumnsType<Item> = [
    {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        align: "center",
        render: (client: string) => <Tooltip title={client}><Typography.Text ellipsis style={{ width: 100 }}>
            {client}</Typography.Text >
        </Tooltip>,
        
        // sorter: true
    },
    {
        title: 'Contract ID',
        dataIndex: 'contractId',
        key: 'contractId',
        align: "center",
        render: (contractId: string) => <div style={{ width: 80, display: "inline-block" }}>{contractId}</div>,
        // sorter: true,
    },
    {
        title: 'Duration',
        key: 'startDate',
        render: (record) => 
        <div style={{ 
            width: 160,
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2px",
        }}>
            {dayjs(record.startDate, "DD/MM/YYYY").format("DD MMM")}            
            <img width={15} src="/assets/date-line.svg" />
            {dayjs(record.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}
        </div>,
        align: "center",
        // sorter: true,
    },
    
    
    {
        title: <>Total Contractual<br/>Qty. (MMBTU)</>,
        dataIndex: 'totalContractQty',
        key: 'totalContractQty',
        render: (totalContractQty: string) => <div style={{ width: 150, display: "inline-block" }}>{totalContractQty}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: <>Total Allocated<br/>Qty. (MMBTU) </>,
        dataIndex: 'totalAllocatedQty',
        key: 'totalAllocatedQty',
        render: (value) => <div style={{ width: 140, display: "inline-block" }}>{value ? value : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: 'DCQ',
        dataIndex: 'totalDcq',
        key: 'totalDcq',
        render: (totalDcq: string) => <div style={{ width: 80, display: "inline-block" }}>{totalDcq}</div>,
        align: "center",
        // sorter: true
    },
    {
        title: 'Deviation',
        dataIndex: 'deviation',
        key: 'deviation',
        render: (deviation) => <div style={{ width: 80, display: "inline-block" }}>{deviation ? deviation.toFixed(2)+"%" : "----"}</div>,
        align: "center",
    },
    {
        title: 'Status',
        dataIndex: 'contractStatus',
        key: 'contractStatus',
        render: (contractStatus
        ) => {
            return <Tag style={{ fontSize: 14, textAlign: "center", textTransform: "uppercase" }} color={contractStatus === "Completed" ? "green" : "orange"}>{contractStatus}</Tag>
        },
        align: "center",
        // sorter: true
    },
    {
        title: 'Action',
        key: 'action',
        render: (record) => <Link style={{textDecoration: "underline"}} to={`/dashboard/nomination-history/${record.clientId}`} state={{name: record.client}}>View Details</Link>,
        align: "center",
        ellipsis: true
    },
];