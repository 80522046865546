
// notification constants
export enum NotificationStatus {
    NOT_NOTIFIED = "NOT_NOTIFIED",
    NOTIFIED = "NOTIFIED"
}

export const NotificationStatusDisplay: Record<NotificationStatus, string> = {
    [NotificationStatus.NOT_NOTIFIED]: "NOT NOTIFIED",
    [NotificationStatus.NOTIFIED]: "NOTIFIED"
}

export enum NotificationStatusColors {
    NOT_NOTIFIED = "orange",
    NOTIFIED = "green"
}

// nomination constants
export enum NominationStatus {
    NOMINATION_PLACED="NOMINATION_PLACED",
    NOMINATION_CONFIRMED="NOMINATION_CONFIRMED",
    NOMINATION_CHANGED="NOMINATION_CHANGED",
    NOMINATION_SCHEDULED="NOMINATION_SCHEDULED" ,
    ALLOCATION_CONFIRMED="ALLOCATION_CONFIRMED"  
}

export const NominationStatusDisplay: Record<NominationStatus, string> = {
    [NominationStatus.NOMINATION_CHANGED]: "NOMINATION CHANGED",
    [NominationStatus.NOMINATION_CONFIRMED]: "TENTATIVE SCHEDULE SENT",
    [NominationStatus.NOMINATION_PLACED]: "NOMINATION PLACED",
    [NominationStatus.NOMINATION_SCHEDULED]: "NOMINATION SCHEDULED",
    [NominationStatus.ALLOCATION_CONFIRMED]: "ALLOCATION CONFIRMED",
}

export enum nominationStatusColors {
    NOMINATION_PLACED= "orange",
    NOMINATION_CONFIRMED="green",
    NOMINATION_CHANGED="pink",
    NOMINATION_SCHEDULED="blue",
    ALLOCATION_CONFIRMED="purple"
}

// enquiry constants
export enum EnquiryStatus {
  ACTION_PENDING="ACTION_PENDING",
  PROPOSAL_RECEIVED="PROPOSAL_RECEIVED",
  REMARK_SENT="REMARK_SENT",
  PROPOSAL_ACCEPTED="PROPOSAL_ACCEPTED",
  CONTRACT_RECEIVED="CONTRACT_RECEIVED",
  CONTRACT_REGISTRATION_PENDING="CONTRACT_REGISTRATION_PENDING",
  CONTRACT_CREATED="CONTRACT_CREATED",
  PROPOSAL_TO_BE_SENT="PROPOSAL_TO_BE_SENT",
  PROPOSAL_SENT="PROPOSAL_SENT",
  REMARKS_RECEIVED="REMARKS_RECEIVED",
  CONTRACT_NOT_SENT="CONTRACT_NOT_SENT",
  SIGNED_DOCUMENT_PENDING="SIGNED_DOCUMENT_PENDING",
  ENQUIRY_CANCELLED="ENQUIRY_CANCELLED"
}

export const enquiryStatusDisplay: Record<EnquiryStatus, string> = {
    [EnquiryStatus.ACTION_PENDING]: "Action Pending",
    [EnquiryStatus.CONTRACT_CREATED]: "Contract Created",
    [EnquiryStatus.CONTRACT_RECEIVED]: "Contract Received",
    [EnquiryStatus.CONTRACT_REGISTRATION_PENDING]: "Contract Registration Pending",
    [EnquiryStatus.PROPOSAL_ACCEPTED]: "Proposal Accepted",
    [EnquiryStatus.PROPOSAL_RECEIVED]: "Proposal Received",
    [EnquiryStatus.REMARK_SENT]: "Remark Sent",
    [EnquiryStatus.PROPOSAL_TO_BE_SENT]:"Proposal To Be Sent",
    [EnquiryStatus.PROPOSAL_SENT]:"Proposal Sent",
    [EnquiryStatus.REMARKS_RECEIVED]:"Remarks Recieved",
    [EnquiryStatus.CONTRACT_NOT_SENT]:"Contract Not Sent",
    [EnquiryStatus.SIGNED_DOCUMENT_PENDING]:"Signed Document Pending",
    [EnquiryStatus.ENQUIRY_CANCELLED]: "Enquiry Deleted"
}

export enum enquiryStatusColors {
    ACTION_PENDING="orange",
    PROPOSAL_RECEIVED="green",
    REMARK_SENT="blue",
    PROPOSAL_ACCEPTED="cyan",
    CONTRACT_RECEIVED="purple",
    CONTRACT_REGISTRATION_PENDING="geekblue",
    CONTRACT_CREATED="lime",
    PROPOSAL_TO_BE_SENT="yellow",
    PROPOSAL_SENT="green",
    REMARKS_RECEIVED="blue",
    CONTRACT_NOT_SENT="red",
    SIGNED_DOCUMENT_PENDING="orange",
    ENQUIRY_CANCELLED="red"
    
}

export enum ProposalStatus {
    PROPOSAL_RECEIVED="PROPOSAL_RECEIVED",
    REMARKS_SENT="REMARKS_SENT",
    ACCEPTED="ACCEPTED",
    SIGNED="SIGNED",
    WAITING_FOR_RESPONSE="WAITING_FOR_RESPONSE",
    REMARKS_RECEIVED="REMARKS_RECEIVED",
    APPROVED="APPROVED",
}

export const proposalStatusDisplay: Record<ProposalStatus, string> = {
    [ProposalStatus.ACCEPTED]: "Accepted",
    [ProposalStatus.PROPOSAL_RECEIVED]: "Proposal Recieved",
    [ProposalStatus.REMARKS_SENT]: "Remarks Sent",
    [ProposalStatus.SIGNED]: "Signed",
    [ProposalStatus.REMARKS_RECEIVED]: "Remarks Received",
    [ProposalStatus.APPROVED]: "Approved",
    [ProposalStatus.WAITING_FOR_RESPONSE]: "Waiting for Response",
}

export enum proposalStatusColors {
    PROPOSAL_RECEIVED="#2C66E3",
    REMARKS_SENT="#2C66E3",
    ACCEPTED="#71A84D",
    SIGNED="#F2923A",
    REMARKS_RECEIVED="#2C66E3",
    APPROVED="#71A84D",
    WAITING_FOR_RESPONSE="#F2923A",
}
