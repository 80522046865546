import { UploadOutlined } from "@ant-design/icons";
import { Button, Divider, Steps, Typography,Form, Upload, Modal } from "antd";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import { beforeUpload, showSuccess } from "../../../utils/common";
import { useNavigate } from "react-router-dom";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppSelector } from "../../../app/hooks";

export default function UploadPDF({id, getClientDetails, downloadRegistrationPDF,setInProgrss}:any) {
    const [current, setCurrent] = useState(0);
    const [disableAcceptProposalButton, setDisableAcceptProposalButton] = useState(true);
    const [modal, modalContex] = Modal.useModal();
    const navigate = useNavigate();
    const auth = useAppSelector(authSelector);


    useEffect(()=>{
        if(setInProgrss){
            setInProgrss(true)
        }
    }, [])

    const [form] = Form.useForm()


    const onChange = (value: number) => {
        console.log('onChange:', value);
        setCurrent(value);
    };

    const handleDownload = async (e: any, urlString: string, downloadType: string = "proposal") => {
        e.preventDefault();
        e.stopPropagation();

        await fetch(urlString).then((response) => {
            response.blob().then((blob) => {

                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = downloadType + "_" + "";
                alink.click();
            });
        });
    };

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleAcceptProposalFormChange = (values: any) => {
        if (values?.file) {
            setDisableAcceptProposalButton(false)
        } else {
            setDisableAcceptProposalButton(true)
        }
    }

    const uploadPDF = () => {
        modal.confirm({
            title: <>Do you want to save all the changes ?</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            icon: null,
            onOk: async () => {
                try {
                    const formValues = form.getFieldsValue();
                    console.log(formValues);

                    const formData = new FormData();

                    formData.append("file", formValues["file"]);
                    formData.append("clientId", id)



                    // setLoading(true)
                    await instance.post(`clientservice/clients/upload/registration-doc`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    showSuccess(auth.isClient ? <>Details successfully sent for approval</> : <>Client successfully onboarded.</>, Modal)
                    if(auth.isClient){
                        navigate(-1)
                    } else {
                        navigate("/dashboard/registrations?tab=KYC")
                    }

                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })
    }


    return (
        <>
            {<div style={{ ...cardStyle, border: "none", display: "block", marginTop: 80 }}>
                <div style={{ textAlign: "center", width: "100%" }}>
                    <Typography.Title level={3}>
                    Please upload a signed copy of the filled details in PDF format.
                    </Typography.Title>
                    <Divider />
                </div>
                <div
                    style={{ display: "flex", padding: "0px 40px", marginTop: 60 }}
                >
                    <Steps
                        style={{ height: 300 }}
                        current={current}
                        onChange={onChange}
                        direction="vertical"
                        items={[
                            {
                                title: 'Download Registration Details',
                            },
                            {
                                title: 'Upload Signed Registered Details',
                            },
                        ]}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ textAlign: "right" }}>
                            <Button onClick={downloadRegistrationPDF} style={{ width: 200 }} type="primary" size="large">Download</Button>
                        </div>
                        <div style={{ marginTop: 80 }}>
                            <Form onValuesChange={handleAcceptProposalFormChange} form={form} layout="vertical">

                                <Form.Item
                                    style={{ margin: 0 }}
                                    name="file"
                                    rules={[{ required: true, message: "Please enter file (less than 5MB)" }]}
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        name='file'
                                        accept="application/pdf"
                                        maxCount={1}
                                        listType="text"
                                        customRequest={dummyRequest}
                                        beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields(["file"]))}
                                    >
                                        <Button
                                            onClick={() => setCurrent(1)}
                                            style={{ display: "flex", alignItems: "center", gap: 26, height: "auto", width: 400, justifyContent: "space-between" }}
                                            type="dashed" size="large"
                                            icon={<img src="/assets/upload.png" width="95%" />}
                                        >
                                            <span style={{ textAlign: "left", marginBlock: 10 }}>Upload Signed Copy<br />
                                                <Typography.Text type="secondary">File type: PDF</Typography.Text >
                                            </span>
                                            <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} />
                                        </Button>
                                    </Upload>

                                </Form.Item>
                            </Form>
                        </div>
                    </div>

                </div>
                <div style={{ textAlign: "center" }}>
                    <Button disabled={disableAcceptProposalButton} style={{ width: 200 }} size="large" type="primary" onClick={uploadPDF}>
                        Submit
                    </Button>
                </div>

            </div>}
            {modalContex}
        </>
    )
}

 const cardStyle: React.CSSProperties = {
    padding: "24px 40px 24px 40px",
    border: "solid 1px rgba(226, 226, 226, 1)",
    background: "white",
    display: "flex",
    borderRadius: 16,
    justifyContent: "space-between",
    alignItems: "center"
}
