import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import ContractForm from './ContractForm';
import { Skeleton } from 'antd';

function ContractDetails({ id }: { id: string | undefined, setDefaulttab?: Dispatch<SetStateAction<any | null>> }) {

    const [contractDetails, setContractDetails] = useState<[]>()
    const [fetchingDetails, setFetchingDetails] = useState(false)
    

    useEffect(() => {
      getContractDetails()
    }, [])

    
    const getContractDetails = async () => {
        setFetchingDetails(true)
        try {
            const res = await instance.get(`clientservice/clients/${id}/contract`);
            setContractDetails(res.data)
        } catch (e) {
            console.log(e);
        }
        setFetchingDetails(false)
    }
    

    return (
        <React.Fragment>
            {!fetchingDetails ? contractDetails?.map((item, key) => {
                return <ContractForm key={key} itemKey={key} clientID={id} data={item} setContractDetails={setContractDetails} contractDetails={contractDetails} />
            }) : <Skeleton />}
            <ContractForm clientID={id} setContractDetails={setContractDetails} getContractDetails={getContractDetails} contractDetails={contractDetails} />
        </React.Fragment>
    )

};

export default ContractDetails;