import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import { Button, Col, Divider, Form, Input, Row, Space, Typography, Upload } from 'antd';
import theme from '../../../utils/theme';
import { CloudUploadOutlined, UploadOutlined } from '@ant-design/icons';

function BankDetails({ id, bankDetails, setDefaulttab }: { id: string | undefined, bankDetails: any, setDefaulttab: Dispatch<SetStateAction<any | null>> }) {
    const [form] = Form.useForm()
    const [editing, setEditing] = useState(false)

    const onCreate = async (values: any) => {
        const formData = new FormData();

        formData.append("file", values["file"]);
        console.log("files", values["file"])

        const temp = {
            "clientId": id,
            "accountNumber": values.accountNumber,
            "accountName": values.accountName,
            "bankName": values.bankName,
            "branchName": values.branchName,
            "ifscCode": values.ifscCode
        }


        formData.append('details', new Blob([JSON.stringify(temp)], { type: 'application/json' }));
        try {
            await instance.post(`clientservice/clients/bank-details`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            // document?.querySelector(`#contract${res.data.id}`)?.scrollIntoView();
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            "accountName": bankDetails[0]?.accountName,
            "accountNumber": bankDetails[0]?.accountNumber,
            "bankName": bankDetails[0]?.bankName,
            "branchName": bankDetails[0]?.branchName,
            "ifscCode": bankDetails[0]?.ifscCode,
        })
    }, [])

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <React.Fragment>
            <Form
                disabled={!editing && bankDetails.length > 0}
                // onChange={handleFormChange}
                onFinish={onCreate}
                name={"bank-details"}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                form={form}
                encType='multipart/form-data'
            >

                <Row gutter={24}>
                    <Col span={24}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold", color: theme.token.colorPrimary }} orientation="left">Bank Account details</Divider>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="Account Name"
                                        name="accountName"
                                        rules={[{ required: true, message: 'Please input type!' }]} >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="Account Number"
                                        name="accountNumber"
                                        rules={[{ required: true, message: 'Please input type!' }]} >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                            </Row>


                        </div>
                    </Col>

                    <Col span={24}>
                        <div style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="Bank Name"
                                        name="bankName"
                                        rules={[{ required: true, message: 'Please input type!' }]} >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="Branch Name"
                                        name="branchName"
                                        rules={[{ required: true, message: 'Please input type!' }]} >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                            </Row>


                        </div>
                    </Col>

                    <Col span={24}>
                        <div style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item style={{ marginBottom: 10 }} label="IFSC Code"
                                        name="ifscCode"
                                        rules={[{ required: true, message: 'Please input type!' }]} >
                                        <Input size='large' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col span={24}>
                        <div style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }}>
                            <Row gutter={24}>
                                <Col span={16} style={{ display: "flex", alignItems: "center" }}>
                                    Cancelled Cheque <span style={{ color: "red", fontSize: 10 }}>&nbsp;*</span>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="file"
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            name='file'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={dummyRequest}
                                        >
                                            <Button style={{ display: "flex", alignItems: "center", gap: 26, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Excel<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                        </Upload>

                                    </Form.Item>
                                    <a style={{ marginTop: 10, display: "block" }} href={bankDetails[0]?.cancelledCheque} target='_blank'>previous uploaded file</a>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Form>
            <div style={{ display: "flex", justifyContent: "center", marginTop: 36 }}>
                {bankDetails.length > 0 && !editing ? <Space size="large">
                    <Button onClick={() => setEditing(true)} style={{ width: 200 }} size="large">Edit</Button>

                    <Button onClick={setDefaulttab} style={{ width: 200 }} size="large" type="primary">
                        Next
                    </Button>
                </Space> : <Space size="large">
                    {<Button onClick={() => setEditing(false)} style={{ width: 200 }} size='large' type="default">
                        Cancel
                    </Button>}
                    <Button style={{ width: 200 }} onClick={() => form.submit()} size='large' type="primary" htmlType="submit">
                        Add Bank Details
                    </Button>

                </Space>}
            </div>

        </React.Fragment>
    )

};

export default BankDetails;