import { useEffect, useState } from "react";
import { Layout } from 'antd';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setUser } from "../../features/auth/userSlice";
import { userSelector } from "../../features/auth/userSlice";
import { authSelector } from "../../features/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Sidenav from "../../components/Sidenav";
import Topnav from '../../components/Topnav';

import { Outlet } from "react-router";
import jwtDecode from "jwt-decode";
import "../../styles/Pages.sass"
import "../../styles/Nav.sass"
import { instance } from "../../utils/api";
import { dashboardSelector, setContracts, setRegistrationStatus } from "../../features/auth/dashboardSlice";

const { Header, Content, Sider } = Layout;

function Dashboard() {
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(userSelector);
  const selectAuth = useAppSelector(authSelector);
  const navigate = useNavigate();
  const auth = useAppSelector(authSelector);
  const dashboard = useAppSelector(dashboardSelector);  
  const [contractLoading, setContractLoading] = useState(true)
  const url = useLocation();

  useEffect(() => {
    if(!auth?.isClient){
      const data: { name: string, email: string } = jwtDecode(selectAuth.accessToken);
      dispatch(setUser({ ...selectedUser, name: data.name, email: data.email }));
      return
    }
  }, [selectedUser]);

  useEffect(() => {
    if (auth?.isClient) {
      getClients()
    }
  }, [])

  const getClients = async () => {
    setContractLoading(true)
    console.log(dashboard.stats.contracts, "ram")
    try {
      const response = await instance.get(`/clientservice/clients`);
      // setClients(response.data);
      if (auth.isClient && response?.data?.length === 1) {
        if (response.data[0]?.id) {
          dispatch(setUser({ ...selectedUser, clientId: response.data[0]?.id, name: response.data[0]?.name, email: response.data[0]?.email }));
          fetchContracts(response.data[0]?.id, response.data[0]?.registrationStatus)
        }
      }
    } catch (error) {
      console.error('Error fetching dbdata:', error);
    }
  }

  const fetchContracts = async (id: string, status: string) => {
    try {
      const response = await instance.get(`/clientservice/clients/${id}/contract`);
      console.log("ola", response.data)
      dispatch(setContracts(response.data))
      console.log("urlxx", url)
      const pattern = /^\/dashboard\/enquiry\/[a-zA-Z0-9]+$/
      if(status === "KYC_PENDING" || status === "SIGNED_DOCUMENT_PENDING" || status === "APPROVAL_PENDING"){
        dispatch(setRegistrationStatus(status))

        navigate("/dashboard/kyc-pending")
      } else if(response.data.length === 0 && !(url.pathname === "/dashboard/enquiry" || url.pathname === "/dashboard/create-enquiry" || pattern.test(url.pathname))){
        navigate("/dashboard/enquiry")
      }
    } catch (error) {
      console.error('Error fetching dbdata:', error);
    }
    setContractLoading(false)

  }

  const breadCrum = url.pathname.split("/");
  breadCrum.shift();

  return (
    contractLoading && auth.isClient ? <></> : <Layout hasSider>
      {!(auth.isClient && dashboard?.stats?.contracts?.length==0) &&<Sider style={{
        overflow: 'auto',
        height: '100vh',

      }}
        width={selectAuth.isClient ? 300 : 250}
        theme="light"
      >
        <Sidenav />
      </Sider>}
      <Layout>
        <Header style={{ padding: 0, height: 60, margin: "10px 0px" }}>
          <Topnav showLogo={(auth.isClient && dashboard?.stats?.contracts?.length===0)} {...selectedUser} />
        </Header>
        <Content style={{width: (auth.isClient && dashboard?.stats?.contracts?.length===0) ? "80%" : "96%"}} className="page-container hide-scroll">
          <Outlet />
        </Content>
      </Layout>
    </Layout>


  );
};


export default Dashboard;