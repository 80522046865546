import { Button, Col, Divider, Form, Input, Row, Typography } from "antd"
import React, { useEffect, useState } from "react"
import theme from "../../utils/theme"
import EmailOtp from "./EmailOtp";
import PhoneOtp from "./PhoneOtp";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { authSelector } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { stopScroll } from "../../utils/common";

type FieldType = {
    email?: string;
    name?: string;
    mobileNo?: number;
    telephoneNo?: number;
    registeredOfficeAddressLine1: string;
    registeredOfficeAddressLine2: string;
    registeredOfficeAddressCity: string;
    registeredOfficeAddressState: string;
    registeredOfficeAddressPinCode: string;
    registeredOfficeCountry: string;
    plantOfficeAddressLine1: string;
    plantOfficeAddressLine2: string;
    plantOfficeAddressCity: string;
    plantOfficeAddressState: string;
    plantOfficeAddressPinCode: string;
    plantOfficeCountry: string;
    password: string;
    confirmPassword: string
};
export const specialCharacterRegex = /[^A-Za-z0-9]/;


export default function SignUp() {

    const [clientData, setClientData] = useState<any>(null)
    const [submitEnabled, setSubmitEnabled] = useState(false)
    const auth = useAppSelector(authSelector);
    const navigate = useNavigate();
    const [verifiedEmail, setVerifiedEmail] = useState<any>(null)



    const [screen, setScreen] = useState("form")
    const [form] = Form.useForm()

    useEffect(() => {
        if (auth.isAuth) {
            auth.isFinance ? navigate("/dashboard/joint-ticket") : navigate("/dashboard/confirmation");
            console.log("redirect")
        }
    }, [])



    const getClientDetails = () => {
        const values = form.getFieldsValue()
        let formData = {
            "email": values.email,
            "name": values.name,
            "mobileNo": values.mobileNo,
            "telephoneNo": values.telephoneNo ? values.telephoneNo : "",
            "password": values.password,
            "addressDtoList": [
                {
                    "id": null,
                    "addressLine1": values.registeredOfficeAddressLine1,
                    "addressLine2": values.registeredOfficeAddressLine2 ? values.registeredOfficeAddressLine2 : "",
                    "city": values.registeredOfficeAddressCity,
                    "state": values.registeredOfficeAddressState,
                    "pinCode": values.registeredOfficeAddressPinCode,
                    "country": values.registeredOfficeCountry,
                    "addressType": "REGISTERED_OFFICE_ADDRESS",
                },
                {
                    "id": null,
                    "addressLine1": values.plantOfficeAddressLine1,
                    "addressLine2": values.plantOfficeAddressLine2 ? values.plantOfficeAddressLine2 : "",
                    "city": values.plantOfficeAddressCity,
                    "state": values.plantOfficeAddressState,
                    "pinCode": values.plantOfficeAddressPinCode,
                    "country": values.plantOfficeCountry,
                    "addressType": "PLANT_OFFICE_ADDRESS"
                }
            ]
        }

        setClientData(formData)
        if (verifiedEmail === formData?.email) {
            sendPhoneOtp()
        } else {
            sendEmailOtp(formData)
        }
    }

    const sendEmailOtp = async (formData: any) => {
        try {
            const res = axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/clientservice/clients/send-otp?verificationId=${formData.email}`)
            setScreen("email")
        } catch (e) {
            console.log(e)
        }
    }
    const handleFormChange = (values: any) => {
        const errors = form.getFieldError("email")

        const errorCount = form.getFieldsError().filter(({ errors }) => errors.length).length
        console.log("emailerror", errors, errorCount)
        const formValues = form.getFieldsValue()
        console.log(formValues)
        const { telephoneNo, registeredOfficeAddressLine2, plantOfficeAddressLine2, ...filteredFormValues } = formValues;



        const hasUndefinedValues = Object.values(filteredFormValues).some(value => value === undefined);

        if (hasUndefinedValues || formValues.password !== formValues.confirmPassword || errorCount !== 0) {
            setSubmitEnabled(false);
        } else {
            setSubmitEnabled(true);
        }
    }

    const sendPhoneOtp = async () => [
        await axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/clientservice/clients/send-otp?verificationId=${clientData.mobileNo}`).then(res => {
            setScreen("phone")
        })
    ]

    return (
        <div style={containerStyle}>
            <div style={{ ...childContainer, width: "45%", background: "#fbfff8", textAlign: "left" }}>
                <img src="/assets/logo.svg" style={{ margin: "40px 0px 0px 40px" }} />

                <div style={{ textAlign: "center", marginTop: 80 }}>
                    <Typography.Title style={{ lineHeight: "57px", fontWeight: 600, letterSpacing: 1 }}>Stimulating growth of<br /><span style={{ color: theme.token.colorPrimary }}>Natural Gas</span></Typography.Title>
                </div>

                <img src="/assets/signup-bg.png" style={{ position: "absolute", bottom: 0, width: "100%", left: 0 }} />
            </div>

            <div className="registration-form" style={{ ...childContainer, width: "55%", overflow: "scroll" }}>

                {screen === "form" && <>
                    <div style={{ paddingTop: 40, paddingLeft: 48 }}>
                        <Typography.Title style={{ fontSize: 40, letterSpacing: "1.5px", fontWeight: 700, margin: 0, width: "fit-content" }}>Registration</Typography.Title>
                        <Typography.Title style={{ margin: 0, color: "#707070", width: "fit-content", marginTop: 4 }} level={3}>Company Details</Typography.Title>
                    </div>

                    <div style={{ width: "80%", paddingLeft: 40, marginTop: 32, paddingBottom: 100 }}>
                        <Form
                            onChange={(values) => setTimeout(() => { handleFormChange(values); }, 500)}
                            scrollToFirstError
                            form={form}
                            // disabled={loading}
                            name="registration-form"
                            layout="vertical"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            // onFinish={getClientDetails}
                            autoComplete="off"
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item<FieldType>
                                        label="Company name"
                                        name="name"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="Enter Company name" size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item<FieldType>
                                        label="Email ID"
                                        name="email"
                                        rules={[{
                                            required: true,
                                            type: "email",
                                            message: "Please enter valid email ID",
                                        },]}
                                    >
                                        <Input placeholder="Enter email id" size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}
                                    style={{ position: "relative", marginBottom: 16 }}
                                >
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>New Password</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="password"
                                        rules={[{ required: false },
                                        {
                                            validator: async (_, value) => {
                                                const password = form.getFieldValue("password")
                                                if (!value) {
                                                    return Promise.reject(new Error('Please enter New Password'));
                                                } else {
                                                    if (!(value && value.length >= 8 && specialCharacterRegex.test(value))) {
                                                        return Promise.reject(new Error('Minimum 8 character and special character'));
                                                    }
                                                }
                                            }
                                        }

                                        ]}
                                    >
                                        <Input
                                            onChange={(event) => {
                                                const password = form.getFieldValue("confirmPassword")

                                                if (password !== event.target.value && password) {
                                                    form.setFields([
                                                        {
                                                            name: 'confirmPassword',
                                                            errors: ['Password did not match'],
                                                        },
                                                    ]);
                                                } else {
                                                    form.setFields([
                                                        {
                                                            name: 'confirmPassword',
                                                            errors: [],
                                                        },
                                                    ]);
                                                }


                                            }}
                                            placeholder="Enter new password" type='password' size="large"
                                        />

                                    </Form.Item>
                                    <span style={{ position: "absolute", display: "flex", bottom: 2 }}>
                                        <Typography.Text style={{ color: "#979797" }}>Minimum 8 character and special character</Typography.Text>
                                    </span>
                                </Col>
                                <Col span={24}>
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>Confirm Password</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="confirmPassword"
                                        rules={[
                                            { required: false },
                                            {
                                                validator: async (_, value) => {
                                                    const password = form.getFieldValue("password")
                                                    if (!value) {
                                                        return Promise.reject(new Error('Please confirm password'));
                                                    } else {
                                                        if (value !== password) {
                                                            return Promise.reject(new Error(`Your Password does't match`));
                                                        }
                                                    }
                                                }
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Confirm password" type='password' size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label="Mobile No."
                                        name="mobileNo"
                                        rules={[{ required: true, len: 10 }]}
                                    >
                                        <Input onFocus={stopScroll} type="number" size="large" style={{ width: "100%" }} prefix={<div style={{ display: "flex", alignItems: "center", gap: 8 }}><span>+91-</span><span style={{ height: 26, width: 1, background: "#DADADA" }}></span></div>} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label="Telephone No."
                                        name="telephoneNo"
                                        rules={[{ required: false }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold" }} orientation="left">Registered Office Address</Divider>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item<FieldType>
                                        label="Address Line 1"
                                        name="registeredOfficeAddressLine1"
                                        rules={[{ required: true, }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item<FieldType>
                                        label="Address Line 2"
                                        name="registeredOfficeAddressLine2"
                                        rules={[{ required: false }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>City</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="registeredOfficeAddressCity"
                                        rules={[{
                                            validator: async (_, value) => {
                                                if (!value) {
                                                    return Promise.reject(new Error("Please input City"));

                                                }
                                                if (!/^[A-Za-z ]+$/.test(value)) {
                                                    return Promise.reject(new Error('Only letters allowed'));
                                                }
                                            },
                                        }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>State</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="registeredOfficeAddressState"
                                        rules={[{
                                            validator: async (_, value) => {
                                                if (!value) {
                                                    return Promise.reject(new Error("Please input State"));

                                                }
                                                if (!/^[A-Za-z ]+$/.test(value)) {
                                                    return Promise.reject(new Error('Only letters allowed'));
                                                }
                                            },
                                        }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>Pin-Code</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="registeredOfficeAddressPinCode"
                                        rules={[{
                                            validator: async (_, value) => {
                                                if(!value){
                                                    return Promise.reject(new Error("Please input Pin-Code"));
                                                }
                                                if (isNaN(value) || parseInt(value) < 0 || value.includes('.')) {
                                                    return Promise.reject(new Error('only numbers allowed'));
                                                }
                                            }
                                        }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>Country</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="registeredOfficeCountry"
                                        rules={[{
                                            validator: async (_, value) => {
                                                if(!value){
                                                    return Promise.reject(new Error("Please input Country"));
            
                                                }
                                                if (!/^[A-Za-z ]+$/.test(value)) {
                                                    return Promise.reject(new Error('Only letters allowed'));
                                                }
                                            },
                                        }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: "bold" }} orientation="left">Plant Office Address</Divider>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <Form.Item<FieldType>
                                        label="Address Line 1"
                                        name="plantOfficeAddressLine1"
                                        rules={[{ required: true }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item<FieldType>
                                        label="Address Line 2"
                                        name="plantOfficeAddressLine2"
                                        rules={[{ required: false }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>City</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="plantOfficeAddressCity"
                                        rules={[{
                                            validator: async (_, value) => {
                                                if(!value){
                                                    return Promise.reject(new Error("Please input City"));
            
                                                }
                                                if (!/^[A-Za-z ]+$/.test(value)) {
                                                    return Promise.reject(new Error('Only letters allowed'));
                                                }
                                            },
                                        }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>State</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="plantOfficeAddressState"
                                        rules={[{
                                            validator: async (_, value) => {
                                                if(!value){
                                                    return Promise.reject(new Error("Please input State"));
            
                                                }
                                                if (!/^[A-Za-z ]+$/.test(value)) {
                                                    return Promise.reject(new Error('Only letters allowed'));
                                                }
                                            },
                                        }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>Pin-Code</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="plantOfficeAddressPinCode"
                                        rules={[{
                                            validator: async (_, value) => {
                                                if(!value){
                                                    return Promise.reject(new Error("Please input Pin-Code"));
                                                }
                                                if (isNaN(value) || parseInt(value) < 0 || value.includes('.')) {
                                                    return Promise.reject(new Error('only numbers allowed'));
                                                }
                                            }
                                        }]}
                                    >
                                        <Input size="large" />

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item<FieldType>
                                        label={<div style={{ display: "flex", gap: 2 }}><div>Country</div><div style={{ color: "orangered" }}>*</div></div>}
                                        name="plantOfficeCountry"
                                        rules={[{
                                            validator: async (_, value) => {
                                                if(!value){
                                                    return Promise.reject(new Error("Please input Country"));
            
                                                }
                                                if (!/^[A-Za-z ]+$/.test(value)) {
                                                    return Promise.reject(new Error('Only letters allowed'));
                                                }
                                            },
                                        }]}
                                    >
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>




                        </Form>
                    </div>

                    <div style={{ position: "sticky", zIndex: 100, bottom: 0, padding: "20px 10px", background: "white", textAlign: "center", boxShadow: "0px -4px 12px 0px rgba(0, 0, 0, 0.12)", borderRadius: 20 }}>
                        <Button disabled={!submitEnabled} onClick={() => { getClientDetails() }} style={{ width: "80%", height: 48, zIndex: 1000 }} size="large" type="primary">Submit</Button>
                    </div>
                </>}

                {screen === "email" && <EmailOtp setVerifiedEmail={setVerifiedEmail} sendPhoneOtp={sendPhoneOtp} sendEmailOtp={sendEmailOtp} clientData={clientData} setScreen={setScreen} />}

                {screen === "phone" && <PhoneOtp sendPhoneOtp={sendPhoneOtp} clientData={clientData} setScreen={setScreen} />}




            </div>
        </div>
    )
}

const containerStyle: React.CSSProperties = {
    width: "100vw",
    height: "100vh",
    background: "white",
    display: "flex"
}

const childContainer: React.CSSProperties = {
    height: "100vh",
    position: "relative",
}