import { Button, Typography } from "antd";

export default function KycEmpty({setShowKycForm}:any) {
    return (<>
    <div style={{display: "flex", justifyContent: 'center', alignItems: "center", height: "60vh"}}>
        <div style={{background: "white", textAlign: "center", padding: "40px 80px"}}>
            <div>
                <img width={150} src="/assets/kyc-pending-empty.png" />
            </div>
            <div style={{marginTop: 20}}>
                <Typography.Text strong>No KYC information has been registered yet. </Typography.Text>
            </div>
            <div style={{marginTop: 20}}>
                <Button onClick={()=>setShowKycForm(true)} type="primary" size="large" style={{width: 340}}>
                    Fill KYC
                </Button>
            </div>

        </div>
    </div>
    

    </>)
}