import { Tooltip, Typography } from "antd";
import theme from "../../utils/theme";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { proposalStatusColors, proposalStatusDisplay } from "../../utils/healpers/constants";

export const columns = [
    {
        title: 'Date',
        dataIndex: 'auditInfo',
        key: "auditInfo.creationDate",
        render: (auditInfo: any) => { return <div>{dayjs(auditInfo.creationDate).format("DD MMM YYYY")}</div> },
        align: "center",
        width: 170,
        sorter: true
    },
    {
        title: 'Proposal',
        dataIndex: 'proposalUrl',
        key: 'proposalUrl',
        align: "center",
        render: (item: any, record: any) => <Link target="_blank" to={item} style={{ display: "inline-flex", gap: 10, alignItems: "center" }}>
            <div style={{height: 16.47}}>
            <img src="/assets/file-icon.png" style={{ width: 14 }} />
            </div>
            <Typography.Text style={{ color: theme.token.colorPrimary, textDecoration: "underline" }}>Proposal ID {record?.id}
            </Typography.Text>
        </Link>,
        width: 320
    },
    {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        align: "center",
        render: ((remarks: string)=><Tooltip title={remarks ? remarks : ""}><Typography.Text ellipsis>{remarks ? remarks : ""}</Typography.Text></Tooltip>),
        ellipsis: true
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: "center",
        render: (item: any) => <>
            <Typography.Text style={{ color: (proposalStatusColors as any)[item] }}>{(proposalStatusDisplay as any)[item]}</Typography.Text>
        </>,
        width: 250
    },
];