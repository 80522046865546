import { useEffect, useState } from "react";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { instance } from "../../../utils/api";
import { columns } from "../../../interfaces/TableColumns/ProposalHistory";
import { columns as adminColumns } from "../../../interfaces/TableColumns/AdminProposalHistory";

import { Table } from "antd";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";

export default function ProposalHistoryModal({ id }: any) {
    const { tableParams, handleSort } = useTableFilters("auditInfo.creationDate|DESC", '', true, false, true);
    const [proposals, setProposals] = useState<any>(null)
    const [loading, setLoading] = useState(true)

    const auth = useAppSelector(authSelector);

    useEffect(() => {
        fetchProposals()
    }, [tableParams]);

    const fetchProposals = async () => {

        const params = {
            ...tableParams,
            sort: tableParams.sort != undefined ? tableParams.sort : "auditInfo.creationDate|DESC",
            filterAnd: `enquiry.id|eq|${id}`,
        }
        setLoading(true)
        try {
            const res = await instance.get("/clientservice/clients/proposal", { params })
            console.log("res", res)
            setProposals({ ...res.data, items: res.data.items })
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return (
        <>
            <Table
                loading={loading}
                style={{marginTop: 28}}
                rowClassName="table-row"
                columns={auth.isAdmin ? (adminColumns as any) : (columns as any)}
                dataSource={proposals?.items}
                rowKey={(record) => record.id}
                pagination={false}
                onChange={handleSort}
                className="white-header"
            />
        </>
    )
}