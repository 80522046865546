import { Breadcrumb, Button, Modal, Space, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import ClientDetails from './ClientDetails';
import PocDetails from './PocDetails';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import theme from '../../../utils/theme';
import { instance } from '../../../utils/api';
import KycDetails from './KycDetails';
import BankDetails from './BankDetails';
import { useAppSelector } from '../../../app/hooks';
import { userSelector } from '../../../features/auth/userSlice';
import UploadPDF from './UploadPDF';
import { authSelector } from '../../../features/auth/authSlice';
import TabHeader from './TabHeader';
import { showSuccess } from '../../../utils/common';
import { usePDF } from 'react-to-pdf';
import RegistrationSummary from './RegistrationSummary';

function ClientProfile() {
    const auth = useAppSelector(authSelector);
    let { state } = useLocation();

    let { id } = useParams();
    const [modal, modalContex] = Modal.useModal();
    const { toPDF, targetRef } = usePDF({ filename: 'registration-summary.pdf' });



    const selectedUser = useAppSelector(userSelector);

    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab")

    const [defaultTab, setDefaultTab] = useState<string>(tab ? tab : "")
    const [clientData, setClietData] = useState<any>(undefined)
    const [fetchingDetails, setFetchingDetails] = useState(false)
    const [bankDetails, setBankDetails] = useState([])
    const [lastOpened, setlastOpened] = useState(1)
    const [inProgress, setInProgrss] = useState(false)
    const navigate = useNavigate();
    const [tabStates, setTabStates] = useState({
        isDetailsCompleted: true,
        isKycCompleted: false,
        isBankDetailsCompleted: false,
        isPocCompleted: false,
        isDocumentsUploaded: false,
    })

    const getClientDetails = async () => {
        setFetchingDetails(true)
        try {
            const res = await instance.get(`clientservice/clients/${id ? id : selectedUser.clientId}`);
            setBankDetails(res.data.bankDetails)

            if (res?.data?.registrationStatus === "SIGNED_DOCUMENT_PENDING" ||
                res?.data?.registrationStatus == "ADMIN_ONBOARDING_COMPLETED" || 
                res?.data?.registrationStatus == "APPROVED" ||
                res?.data?.registrationStatus == "APPROVAL_PENDING" ||
                res?.data?.registrationStatus == "SIGNED_DOCUMENT_PENDING_ADMIN"
            ) {
                setTabStates({
                    isDetailsCompleted: true,
                    isKycCompleted: true,
                    isBankDetailsCompleted: true,
                    isPocCompleted: true,
                    isDocumentsUploaded: true,
                })
            } else {
                setTabStates({ ...tabStates, isBankDetailsCompleted: res.data.bankDetails.length > 0, isKycCompleted: !!res.data?.companyRegisteredId })
            }
            setFetchingDetails(false)

            setClietData(res.data)
        } catch (e) {
            console.log(e)
            setFetchingDetails(false)

        }

    }

    useEffect(() => {
        console.log("tabStates", tabStates)
    }, [tabStates])


    useEffect(() => {
        if (selectedUser.clientId || id) {
            getClientDetails()
        } else {
            setClietData(undefined)
        }
    }, [])

    const approveOrReject = async (type: string, updateFunction?: any) => {
        modal.confirm({
            title: <div>
                Do you want to save all the changes?
            </div>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            centered: true,
            cancelText: 'No',
            icon: null,
            onOk: async () => {

                try {
                    const res = await instance.post(`/clientservice/clients/approve-or-reject?clientId=${clientData.id}&type=${type}`)
                    getClientDetails()
                    updateFunction()
                    Modal.destroyAll()
                    showSuccess(<>Changes Updated</>, Modal)
                } catch (e) {
                    console.log(e)
                }
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })

    }



    const items: TabsProps['items'] = [
        {
            key: 'client-details-1',
            label: <TabHeader
                index={1}
                inProgress={inProgress}
                defaultTab={defaultTab}
                registrationStatus={clientData?.registrationStatus}
                showUpdateStatus={clientData?.addressUpdateStatus}
                completed={tabStates.isDetailsCompleted}
                isOpen={defaultTab === "client-details-1"}
                title="Company Details" status="Completed"
                showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")}
            />,
            children: <ClientDetails 
                    tabStates={tabStates} 
                    setInProgrss={setInProgrss} 
                    updateStatus={clientData?.addressUpdateStatus} 
                    approveOrReject={approveOrReject} 
                    id={id ? id : selectedUser?.clientId} 
                    getClientData={getClientDetails} 
                    fetchingDetails={fetchingDetails} 
                    clientData={clientData} 
                    setClientData={setClietData} 
                    setDefaulttab={() => setDefaultTab("client-details-2")} 
                />,
        },
        {
            key: 'client-details-2',
            label: <TabHeader index={2} inProgress={inProgress} defaultTab={defaultTab} registrationStatus={clientData?.registrationStatus} showUpdateStatus={clientData?.kycUpdateStatus} completed={tabStates.isKycCompleted} isOpen={defaultTab === "client-details-2"} disabled={!(parseInt(defaultTab?.split("-")[2]) >= 2 || 2 <= lastOpened)} title="Registration Details" status="Completed" showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")} />,
            children: <KycDetails
                tabStates={tabStates}
                setInProgrss={setInProgrss}
                approveOrReject={approveOrReject}
                updateStatus={clientData?.kycUpdateStatus}
                clientData={clientData}
                registeredName={clientData?.companyRegisteredId ? {
                    "label": clientData.companyRegisteredName,
                    "value": clientData.companyRegisteredId,
                    "key": clientData.companyRegisteredId
                } : null}
                getClientData={getClientDetails}
                id={id ? id : selectedUser?.clientId}
                setDefaulttab={() => setDefaultTab("client-details-3")}
            />,
            disabled: !(parseInt(defaultTab?.split("-")[2]) >= 2 || 2 <= lastOpened || tabStates.isKycCompleted)
        },
        {
            key: 'client-details-3',
            label: <TabHeader index={3} inProgress={inProgress} defaultTab={defaultTab} registrationStatus={clientData?.registrationStatus} showUpdateStatus={clientData?.bankUpdateStatus} completed={tabStates.isBankDetailsCompleted} isOpen={defaultTab === "client-details-3"} disabled={!(parseInt(defaultTab?.split("-")[2]) >= 3 || 3 <= lastOpened)} title="Bank Account Details" status="Completed" showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")} />,
            children: <BankDetails tabStates={tabStates} setInProgrss={setInProgrss} approveOrReject={approveOrReject} updateStatus={clientData?.bankUpdateStatus} clientData={clientData} bankDetails={bankDetails} getClientData={getClientDetails} id={id ? id : selectedUser?.clientId} setDefaulttab={() => setDefaultTab("client-details-4")} />,
            disabled: !(parseInt(defaultTab?.split("-")[2]) >= 3 || 3 <= lastOpened || tabStates.isBankDetailsCompleted),
        },
        {
            key: 'client-details-4',
            label: <TabHeader index={4} inProgress={inProgress} defaultTab={defaultTab} registrationStatus={clientData?.registrationStatus} showUpdateStatus={clientData?.pocUpdateStatus} completed={tabStates.isPocCompleted} isOpen={defaultTab === "client-details-4"} disabled={!(parseInt(defaultTab?.split("-")[2]) >= 4 || 4 <= lastOpened)} title='POC Details' status="Completed" showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")} />,
            children: <PocDetails tabStates={tabStates} setInProgrss={setInProgrss} approveOrReject={approveOrReject} updateStatus={clientData?.pocUpdateStatus} setTabStates={setTabStates} getClientDetails={getClientDetails} clientData={clientData} id={id ? id : selectedUser?.clientId} setDefaulttab={() => setDefaultTab("client-details-5")} />,
            disabled: !(parseInt(defaultTab?.split("-")[2]) >= 4 || 4 <= lastOpened || tabStates.isPocCompleted),
        },

    ];

    if (
        (auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING"))
        || ((auth.isAdmin || auth.isMainAdmin) && (clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING_ADMIN" || clientData?.registrationStatus === "ADMIN_ONBOARDING"))
        || ((auth.isAdmin || auth.isMainAdmin) && !id)
    ) {
        items.push({
            key: 'client-details-5',
            label: <TabHeader index={5} inProgress={inProgress} defaultTab={defaultTab} registrationStatus={clientData?.registrationStatus} isOpen={defaultTab === "client-details-5"} disabled={!(parseInt(defaultTab?.split("-")[2]) >= 4 || 4 <= lastOpened)} title='Upload pdf' status="Completed" showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")} />,
            children: <>

                <UploadPDF setInProgrss={setInProgrss} downloadRegistrationPDF={() => toPDF()} getClientDetails={getClientDetails} clientData={clientData} clienb id={id ? id : selectedUser?.clientId} />
                <div className='registration-summary-div' style={{ zIndex: 0, position: "absolute" }} ref={targetRef}>
                    <RegistrationSummary tabStates={tabStates} setInProgrss={setInProgrss} setDefaultTab={setDefaultTab} approveOrReject={approveOrReject} id={id} selectedUser={selectedUser} fetchingDetails={fetchingDetails} clientData={clientData} setClietData={setClietData} bankDetails={bankDetails} setTabStates={setTabStates} />
                </div>
            </>,
            disabled: !(parseInt(defaultTab?.split("-")[2]) >= 4 || 4 <= lastOpened || tabStates.isDocumentsUploaded)
        },)
    }

    useEffect(() => {
        console.log(tab, "tab")
        setDefaultTab(tab ? tab : "client-details-1")
    }, [tab]);

    useEffect(()=>{
        console.log("setInProgrss",inProgress)
    }, [inProgress])

    useEffect(() => {
        if (parseInt(defaultTab?.split("-")[2]) > lastOpened) {
            setlastOpened(parseInt(defaultTab?.split("-")[2]))
        }
    }, [defaultTab])

    return (
        <React.Fragment>

            {clientData?.name && !auth.isClient && <Breadcrumb separator=">" style={{ marginBottom: 16, cursor: "pointer" }}>
                {state?.fromRegistration ?
                    <Breadcrumb.Item
                        onClick={_ => navigate(`/dashboard/registrations?tab=KYC`)}
                    >
                        <span style={{ fontWeight: 600 }}>
                            Registration
                        </span>
                    </Breadcrumb.Item>
                    :
                    <Breadcrumb.Item
                        onClick={_ => navigate(`/dashboard/client-list`)}
                    >
                        <span style={{ fontWeight: 600 }}>
                            Client list
                        </span>
                    </Breadcrumb.Item>}

                {state?.fromRegistration ? <Breadcrumb.Item>
                    <span style={{ fontWeight: 600 }}>
                        View Details
                    </span>
                </Breadcrumb.Item> : <Breadcrumb.Item>
                    <span style={{ fontWeight: 600 }}>
                        {clientData?.name}
                    </span>
                </Breadcrumb.Item>}
            </Breadcrumb>}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Space>
                    <Button onClick={() => navigate(-1)} size="large" style={{ padding: 0, marginRight: 30 }} type="text"><ArrowLeftOutlined /></Button>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography.Text style={{ fontSize: 24, color: theme.token.colorPrimary }} strong>{clientData?.name ? clientData?.name : "Create Client"}</Typography.Text>
                        {clientData?.clientCode && <div style={{ color: "#6B6B6B", fontSize: 21 }}>Client Code: {clientData?.clientCode}</div>}
                    </div>
                </Space>

                {clientData?.signedDocumentUrl && <Link to={clientData?.signedDocumentUrl} target="_blank">
                    <Button style={{ display: "flex", alignItems: "center", gap: 8, width: "fit-content", paddingInline: 20 }} className='reset-button-calendar' size="large">
                        <img width={25} src="/assets/pdf-icon.png" />
                        <Typography.Text style={{ fontSize: 18, color: theme.token.colorPrimary }} underline>View Registration Summary</Typography.Text>
                    </Button>
                </Link>}
            </div>

            {defaultTab !== null && <Tabs destroyInactiveTabPane className='client-profile-tab' onTabClick={(tab) => setDefaultTab(tab)} style={{ marginTop: 50 }} activeKey={`${defaultTab}`} items={items} />}
            {modalContex}
        </React.Fragment>
    )

};

export default ClientProfile;