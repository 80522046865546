import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Confirmations {
  confirmations: any
}

const initialState: Confirmations = {
    confirmations: []
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setConfirmations: (state, action: PayloadAction<Confirmations[]>) => {
        state.confirmations = action.payload;
    }
  },
});
export const { setConfirmations } = appSlice.actions;
export const appSelector = (state: RootState) => state.app;
export default appSlice.reducer;