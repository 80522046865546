import { UploadOutlined } from "@ant-design/icons";
import { Button, Divider, Form, InputNumber, Modal, Typography, Upload } from "antd";
import { beforeUpload, showSuccess, stopScroll } from "../../../utils/common";
import theme from "../../../utils/theme";
import { useState } from "react";
import { instance } from "../../../utils/api";

export default function UpdateQtyModal({totalQuantity, contractId, handleQtyChange}:any) {

    const [form] = Form.useForm()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [submitting, setSubmitting] = useState(false)

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleUploadSignedContractFormChange = (values:any) => {
        const formValues = form.getFieldsValue()
        console.log("formValues",formValues)
        if(formValues.totalQuantity && formValues.file){
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    }

    const updateQty = async () => {
        setSubmitting(true)
        const formValues = form.getFieldsValue()

        const params = {
            contractId: contractId,
            totalQty: formValues.totalQuantity,
            file: formValues.file
        }

        const formData = new FormData();

        formData.append("file", formValues.file);
        formData.append("totalQty", formValues.totalQuantity);
        formData.append("contractId", contractId);



        // console.log(params)
        try {
            await instance.put(`/clientservice/clients/edit-quantity`, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            showSuccess(<>Contract quantity updated, please wait for the client to verify the same.</>, Modal)
            handleQtyChange()
        } catch(e){
            console.log(e)
        }
        setSubmitting(false)

    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: 798 }}>
                <Divider />
                <div>
                    <Form
                        form={form} layout="vertical"
                        onValuesChange={handleUploadSignedContractFormChange}
                    >
                        <div style={{ padding: "20px 80px 20px 80px", display: "flex", justifyContent: "space-between", background: "#F8FAFF", alignItems: "center", borderRadius: 16 }}>
                            <div style={{ textAlign: "left" }}>
                                <Typography.Text >Current Contract Qty. </Typography.Text>
                                <Typography.Title style={{ margin: 0, padding: 0, color: theme.token.colorPrimary }} level={4}>{totalQuantity} MMBTU  </Typography.Title>
                            </div>
                            <Form.Item style={{textAlign: "left"}} label="Updated  MMBTU Quantity"
                                name="totalQuantity"
                                rules={[{ required: true, message: 'Please input total quantity!', type: 'number' }]} >
                                <InputNumber onFocus={stopScroll} type='number' style={{ width: 264 }} size="large" />
                            </Form.Item>
                        </div>

                        <div style={{ padding: "20px 0px", display: "flex", justifyContent: "space-between", marginTop: 28, alignItems: "center", borderRadius: 16 }}>
                            <div style={{ textAlign: "left" }}>
                                <Typography.Text style={{fontSize: 18}}>Upload updated contract<br/>Qty. Document</Typography.Text>
                            </div>
                            <Form.Item
                                style={{ margin: 0 }}
                                name="file"
                                rules={[{ required: true, message: "Please enter file (less than 5MB)" }]}
                                getValueFromEvent={({ file }) => { return file.originFileObj }}
                            >

                                <Upload
                                    name='file'
                                    accept="application/pdf"
                                    maxCount={1}
                                    listType="text"
                                    customRequest={dummyRequest}
                                    beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields(["file"]))}
                                >
                                    <Button
                                        style={{ display: "flex", alignItems: "center", gap: 26, height: "auto", width: 400, justifyContent: "space-between" }}
                                        type="dashed" size="large"
                                        icon={<img src="/assets/upload.png" width="95%" />}
                                    >
                                        <span style={{ textAlign: "left", marginBlock: 10 }}>Upload updated Contract Qty.<br />
                                            <Typography.Text type="secondary">File type: PDF</Typography.Text >
                                        </span>
                                        <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} />
                                    </Button>
                                </Upload>

                            </Form.Item>
                        </div>

                        <Button onClick={updateQty} loading={submitting} style={{width: 200, marginTop: 28}} disabled={buttonDisabled || submitting} type="primary" size="large">Submit</Button>




                    </Form>
                </div>

            </div>
        </div>
    )
}