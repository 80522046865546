import { Button, Typography } from "antd";
import theme from "../../utils/theme";
import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import OtpInput from 'react-otp-input';
import { useEffect, useState } from "react";
import axios from "axios";

export default function EmailOtp({ setScreen, clientData, sendEmailOtp, sendPhoneOtp, setVerifiedEmail }: any) {
    const [otp, setOtp] = useState('');
    const [resendEnabled, setResendEnabled] = useState(false)
    const [err, setErr] = useState<any>(null)
    const [countdown, setCountdown] = useState(30);
    const [submitting, setSubmitting] = useState(false)


    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setResendEnabled(true);
        }
    }, [countdown]);

    


    const verifyEmailOtp = async () => {
        setSubmitting(true)
        setErr(null)

        await axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/clientservice/clients/verify-email-otp?verificationId=${clientData.email}&otp=${otp}`).then(async (res) => {
            setVerifiedEmail(clientData.email)
            sendPhoneOtp()
        }).catch(e => {
            console.log(e.response.data, "error")

            if (e?.response?.data?.errorMessage) {
                setErr(e?.response?.data?.errorMessage)
            }
        })
        setSubmitting(false)

    }
    
    useEffect(() => {
        const handleKeyDown = (event:any) => {
            if (event.key === "Enter") {
                verifyEmailOtp();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [otp, verifyEmailOtp]);

    const handleResendOtp = () => {
        setErr(null)
        setCountdown(30);
        setResendEnabled(false);
        sendEmailOtp(clientData);
    }

    function obscureEmail(email:string) {
        // Split the email address into two parts: before and after the "@" symbol
        const [localPart, domain] = email.split('@');
        
        // Keep the first four characters of the local part and replace the rest with asterisks
        const visiblePart = localPart.slice(0, 4);
        const obscuredPart = '*'.repeat(localPart.length - 4);
        
        // Combine the visible part, obscured part, and domain
        const obscuredEmail = `${visiblePart}${obscuredPart}@${domain}`;
        
        return obscuredEmail;
      }
      

    return (
        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div>
                <div>
                    <Typography.Title style={{ fontSize: 24, fontWeight: 700, margin: 0, width: "fit-content", color: theme.token.colorPrimary }}>2 Step Verification</Typography.Title>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: 8, marginTop: 20 }}>
                    <Typography.Text style={{ color: theme.token.colorPrimary, margin: 0, fontSize: 14, fontWeight: 600 }}>1 Email</Typography.Text>
                    <div style={{ marginTop: -6 }}>
                        <img style={{ marginTop: -6 }} width={116} src="/assets/long-seperator.png" />
                    </div>
                    <Typography.Text style={{ color: "#707070", margin: 0, fontSize: 14 }}>2 Mobile No.</Typography.Text>
                </div>

                <div>
                    <Typography.Title style={{ color: theme.token.colorPrimary, fontSize: 18, marginTop: 40 }}>Email Verification</Typography.Title>
                    <Typography.Text>6 digit verification code has been sent to your authorised email ID<br /> <span style={{ color: "#2C66E3", textDecoration: "underline" }}>{obscureEmail(clientData.email)}</span>
                        <span onClick={() => setScreen("form")} style={{ padding: "2px 0", display: "inline-flex", marginLeft: 8, gap: 10, background: "rgba(27, 120, 223, 0.08)", alignItems: "center", width: 64, justifyContent: "center", borderRadius: 52, fontSize: 12, color: "#2C66E3", cursor: "pointer" }}><EditOutlined /> Edit</span>
                    </Typography.Text>
                </div>

                <div style={{ marginTop: 40 }}>
                    <Typography.Text>OTP</Typography.Text>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span>   </span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                            width: 59,
                            height: 56,
                            marginRight: "0.7rem",
                            fontSize: "24px",
                            borderRadius: "4px",
                            border: `1px solid rgba(0, 0, 0, 0.3)`,
                        }}
                        containerStyle={{ marginTop: 10 }}
                    />
                </div>

                <div style={{ marginTop: 12 }}>
                    {err && <Typography.Text style={{marginRight: 4,fontSize: 12}} type="danger">{err}</Typography.Text>}
                    {resendEnabled ? (
                        <Typography.Text onClick={handleResendOtp} style={{ textDecoration: "underline", color: theme.token.colorPrimary, cursor: "pointer", fontSize: 12 }}>Resend OTP</Typography.Text>
                    ) : (
                        <Typography.Text style={{color: "#979797"}}><span style={{textDecoration: "underline",fontSize: 12}}>Resend OTP</span> in {countdown} sec</Typography.Text>
                    )} 
                </div>

                <div style={{ marginTop: 80 }}>
                    <Button loading={submitting} disabled={otp.length !== 6 || submitting} onClick={verifyEmailOtp} size="large" type="primary" style={{ width: 500, height: 48 }}>Verify <ArrowRightOutlined style={{color: "white", fontSize: 20, position: "absolute", right: 20, top: "28%"}} /></Button>
                </div>


            </div>
        </div>
    )
}