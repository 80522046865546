import { Breadcrumb, Space, Table } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { columns } from "../../../interfaces/TableColumns/WorkflowReportDetails";
import { getPaginationCountApi } from "../../../utils/common";
import { useEffect, useState } from "react";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { instance } from "../../../utils/api";
import { EyeOutlined } from "@ant-design/icons";
import { UploadInvoiceDropdown } from "../NominationHistory/UploadInvoice";
import dayjs from "dayjs";

export default function WorkflowReportDetails() {
    let { state } = useLocation();

    const navigate = useNavigate();

    const [data, setData] = useState<any | null>(null);

    const { tableParams, handleSort } = useTableFilters("", ``, false, false, true, true);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchData();
    }, [tableParams])

    const fetchData = async () => {
        const params = {
            ...tableParams,
            // filter and contract endDate is less than current date
            filterAnd: `${tableParams.filterAnd ? `${tableParams.filterAnd}` : ""}`
        }
        params.startDate = state?.startDate;
        params.endDate = state?.endDate;
        params.nominationId = state?.nominationId
        setLoading(true)
        try {
            const res = await instance.get("orderservice/nominate/fortnight/details/invoice", { params })
            setData(res.data);
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setLoading(false)
    };

    return (<div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <Breadcrumb separator=">" style={{ marginBottom: 0, cursor: "pointer" }}>
            {<Breadcrumb.Item
                onClick={_ => navigate("/dashboard/workflow-report")}
            >
                <span style={{ fontWeight: 600 }}>
                    {"Workflow Report"}
                </span>
            </Breadcrumb.Item>}
            <Breadcrumb.Item>
                <span style={{ fontWeight: 600 }}>
                    Contract ID- {state.contractId} ({dayjs(state?.startDate, "DD/MM/YYYY").format("DD MMM")}-{dayjs(state?.endDate, "DD/MM/YYYY").format("DD MMM YYYY")})
                </span>
            </Breadcrumb.Item>
        </Breadcrumb>

        <Table
            loading={loading}
            onChange={handleSort}
            rowClassName="table-row"
            columns={[...columns, {
                title: "Action",
                key: "action",
                render: (record) => <Space size="large">
                    <Link to={`${state?.nominationId}`} state={{
                        clientId: state.clientId,
                        clientName: state.name,
                        startDate: state?.startDate,
                        endDate: state?.endDate,
                        contractId: state.contractId,
                        contractStatus: state.contractStatus,
                        clientCode: state.clientCode,
                        tradeId: state?.tradeId,
                        record: record
                    }}>
                        <div style={{ textAlign: "center" }}>
                            <EyeOutlined style={{ color: "#1B78DF" }} />
                            <div style={{ color: "#1B78DF", fontSize: 12, width: "max-content" }}>
                                View Details
                            </div>
                        </div>
                    </Link>

                    <UploadInvoiceDropdown
                        icon={true}
                        record={record}
                        contractId={state.contractId}
                        startDate={state.startDate}
                        endDate={state.endDate}
                        fetchData={fetchData}
                        dropDownType="invoice"
                    />
                    <UploadInvoiceDropdown
                        icon={true}
                        record={record}
                        contractId={state.contractId}
                        startDate={state.startDate}
                        endDate={state.endDate}
                        fetchData={fetchData}
                        dropDownType="ticket"
                    />
                </Space>,
                align: "center",

            }]}
            dataSource={data?.items}
            pagination={{
                pageSize: tableParams.size,
                total: data?.totalItems,
                current: tableParams.page,
                showSizeChanger: false,
                position: ["bottomCenter"]
            }}
            footer={() => <div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
            rowKey={(record) => record.id}
            scroll={{ x: true
                , y: data?.totalItems > 0 ? "70vh" : undefined 
            }}
        />

    </div>)
}