import { Form, FormInstance, Input, Select, Spin, Table } from "antd";
import React, { useContext, useRef, useState } from "react";
import { Item } from "../interfaces/TableColumns/NominationDetail";
import { ExclamationCircleOutlined, UpOutlined } from "@ant-design/icons";
import { stopScroll } from "../utils/common";
import { instance } from "../utils/api";


export interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
    parentRecord: any,
    setErrors: any,
    errors?: any;
}

export interface EditableRowProps {
    index: number;
}

export type EditableTableProps = Parameters<typeof Table>[0];


export const EditableContext = React.createContext<FormInstance<any> | null>(null);

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

export const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    parentRecord,
    setErrors,
    errors,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const [contract, setContract] = useState<any>(null)
    const [fetchingContract, setFetchingContract] = useState(false)
    const form = useContext(EditableContext)!;

    const toggleEdit = async () => {
        setEditing(!editing);

        if (!contract && dataIndex === "deliveryEndPoint") {
            setFetchingContract(true)
            try {
                const response = await instance.get(`/clientservice/clients/contract?id=${record.nomination.contractId}`);
                setContract(response.data)
                form.setFieldsValue({ [dataIndex]: record[dataIndex] });
            } catch (error) {
                console.error('Error fetching dbdata:', error);
            }
            setFetchingContract(false)
        } else {
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };


    const save = async (e?:any, value?: any) => {
        if(e){
            if((isNaN(e.target.value) || parseInt(e.target.value) < 0) && !["sourceId", "pipelineId"].includes(dataIndex)){
                setErrors((prevErr:any) => {
                    const existingErrorIndex = prevErr.findIndex((err:any) => err.id === record.id && err.dataIndex === dataIndex);
                
                    if (existingErrorIndex !== -1) {
                      // Error already exists, update the message
                      const newErr = [...prevErr];
                      newErr[existingErrorIndex].error = "only numbers allowed";
                      return newErr;
                    } else {
                      // Error doesn't exist, add a new error object
                      return [...prevErr, { id: record.id, error: "only numbers allowed", dataIndex, gasDay: record.gasDay }];
                    }
                });            
            } else if((!/^\d+(\.\d{1,3})?$/.test(e?.target?.value) && e?.target?.value) && !["sourceId", "pipelineId"].includes(dataIndex)) {
                setErrors((prevErr:any) => {
                    const existingErrorIndex = prevErr.findIndex((err:any) => err.id === record.id && err.dataIndex === dataIndex);
                
                    if (existingErrorIndex !== -1) {
                      // Error already exists, update the message
                      const newErr = [...prevErr];
                      newErr[existingErrorIndex].error = "3 decimal places allowed";
                      return newErr;
                    } else {
                      // Error doesn't exist, add a new error object
                      return [...prevErr, { id: record.id, error: "3 decimal places allowed", dataIndex, gasDay: record.gasDay }];
                    }
                }); 
            } 
            else {
                // Delete the specific key (record.gasDay)
                setErrors((prevErr:any) => {
                    const newErr = prevErr.filter((err:any) => !(err.id === record.id && err.dataIndex === dataIndex));
                    return newErr;
                  });
            }
        }
        else {
            setErrors((prevErr: any) => {
                const newErr = prevErr.filter((err: any) => !(err.dataIndex === dataIndex && err.id === record.id));
                return newErr;
            });
        }
        
        try {
            let values = await form.validateFields();
            if(dataIndex === "deliveryEndPoint"){
                values.deliveryId = contract?.deliveryEndPoints?.find((i:any)=>
                    i.deliveryEndPoint === value
                ).id
            }
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    const disableDeliveryOption = (id:any) => {
        if(!record.nominationDetails) {
            const parentData = parentRecord.find((item:any)=>{
                return item.gasDay === record.gasDay && item.nomination.id === record.nomination.id
           })
           console.log("parent data", parentData)

           const deliveryIds = parentData.nominationDetails?.map((item:any)=> item.deliveryId)
           return deliveryIds?.includes(id)
        } else {
            return false
        }
    }

    let childNode = children;

    if (editable && (record.nominationDetails?.length < 2 || !record.nominationDetails)) {
        childNode = <div id={`nomination-entry-${record.nomination.contractId}`}>{editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                // rules for type number and value greter than 0
                // rules={[dataIndex === "deliveryEndPoint" || dataIndex === "sourceId" || dataIndex === "pipelineId" ? {} : { type: "number", min: 1 }]}
            >
                {/* type text for "deliveryEndPoint" dataIndex */}
                {dataIndex === "sourceId" || dataIndex === "pipelineId" || dataIndex === "deliveryId"
                    ?
                    <Input.TextArea className="hide-scroll" style={{ width: 173, whiteSpace: "nowrap" }} autoFocus ref={inputRef} onChange={(value: any) => { value && save() }} onBlur={(e: any) => { toggleEdit() }} />
                    :
                    dataIndex === "deliveryEndPoint" ? <Select suffixIcon={fetchingContract ? <Spin size="small"/> : <UpOutlined />} defaultOpen={true} autoFocus style={{ width: 173 }} ref={inputRef} onChange={(value) => save(null, value)} onBlur={(e: any) => { toggleEdit() }}>
                        {contract?.deliveryEndPoints?.map((i: any) => {
                            return <Select.Option className="nomination-client-rounded" disabled={disableDeliveryOption(i.id)} key={i.id} value={i.deliveryEndPoint}>{i.deliveryEndPoint}</Select.Option>
                        })}
                    </Select> : 
                    <Input onFocus={stopScroll} className="hide-scroll" autoFocus style={{ width: 173 }} ref={inputRef} onChange={save} onBlur={(e: any) => { toggleEdit() }} />}
            </Form.Item>
        ) : (
            dataIndex === "deliveryEndPoint" ? 
                <div style={{ position: "relative", width: 173, border: `solid 1px ${errors?.find((err:any)=>err.id === record.id && err.dataIndex === dataIndex) ? "red" : "lightgray"}`, padding: "5px 0px", borderRadius: 12, background: "white", minHeight: 32 }} 
                // onClick={toggleEdit}
                >
                {children}
                {/* <DownOutlined style={{fontSize: 12, position: "absolute", right: 10, top: 0, bottom: 0}} /> */}
            </div> :
            
            <div className="hide-scroll" style={{ width: 173, whiteSpace: "nowrap", overflow: "scroll", border: `solid 1px ${errors?.find((err:any)=>err.id === record.id && err.dataIndex === dataIndex) ? "red" : "lightgray"}`, padding: "5px 0px", borderRadius: 12, background: "white", minHeight: 32 }} onClick={toggleEdit} tabIndex={0} onFocus={toggleEdit}>
                {children}
            </div>
        )}
            {errors.map((error: any) => {
                return error.dataIndex === dataIndex && record.id === error.id && error.gasDay === record.gasDay && <span style={{ position: "absolute", color: "red", right: 0, left: 0, display: "block", fontSize: 10 }}>
                    <ExclamationCircleOutlined />{" "}{error.error}
                </span>
            })}
        </div>;
    }

    return <td {...restProps}>
        {childNode}
    </td>;
};