import { Modal, Table, Typography } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import theme from "../../../utils/theme";
import { getPaginationCountApi } from "../../../utils/common";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { columns } from "../../../interfaces/TableColumns/ClientRunningContracts";
import ContractDetailsModal from "./ContractDetailsModal";

interface Item {
    id: number;
    gasDay: string,
    dcq: number,
    nominatedQty: number,
    confirmedQty: number | null,
    deliveryEndPoint: string,
    deliveryId: string,
    sourceId: string,
    pipelineId: string,
    status: string,
    contractId: string,
    notification: string,
    lastUpdatedBy: string,
    nominationDetails: any,
    updatedByUserType: string
}



export default function ClientRunningContracts() {
    let { state, pathname } = useLocation();
    const [searchParam, setSearchParam] = useState("")

    const navigate = useNavigate()
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [data, setData] = useState<any>([]);
    const [urlState, setUrlState] = useState(state);
    const { tableParams, onFilerSearch, handleSort, onSearch, pagination } = useTableFilters("contractUpdateDate|DESC", '', false, false, true);
    const [selectedData, setSelectedData] = useState(null)

    const [options, setOptions] = useState([])
    const auth = useAppSelector(authSelector);

    let { id, contractId, nominationId } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (record: any) => {
        setSelectedData(record)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        fetchDetails()
    }, [tableParams]);


    const fetchDetails = async () => {
        const params = {
            ...tableParams,
        }
        setLoadingDetails(true)
        try {
            const res = await instance.get(`orderservice/nominate/client-nomination-view`, { params });
            setData(res.data)
            setOptions(res?.data?.items)
        }
        catch (e) {
            console.log(e)
        }
        setLoadingDetails(false)
    }




    return (
        <>
            {searchParam}
            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, marginTop: 0 }}>Running Contracts</Typography.Title>

            <div style={{ display: "flex", flexDirection: "column", gap: 40, marginTop: 20 }}>

                <Table
                    loading={loadingDetails}
                    onChange={handleSort}
                    rowClassName="table-row"
                    columns={[...columns, {
                        title: 'Action',
                        key: 'action',
                        render: (record: any) =>
                           record?.details && <Link to={record?.contractUpdateStatus === "UPDATED_QTY" ? `/dashboard/update-qty` : ""}
                                state={{
                                    id: record?.contractId
                                }}
                                style={{ textDecoration: "underline", width: 90, display: "block" }}
                                onClick={() => {
                                    record?.contractUpdateStatus !== "UPDATED_QTY" && showModal(record)
                                }}
                            >
                                View Details
                            </Link>,
                        align: "center"
                    }] as any}
                    dataSource={data?.items}
                    pagination={{
                        pageSize: tableParams.size,
                        total: data?.totalItems,
                        current: tableParams.page,
                        showSizeChanger: false,
                        showQuickJumper: false,
                        position: ["bottomCenter"],
                        showPrevNextJumpers: false,
                    }}
                    footer={() => <div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                    rowKey={(record) => record.id}
                    scroll={{
                        x: true
                        , y: data?.totalItems > 0 ? "70vh" : undefined
                    }}
                    showHeader={!data?.items || data?.items?.length === 0 ? false : true}
                // locale={loadingDetails ? undefined : { emptyText: <EmptyList customMessage={<>You have no Enquiries</>} /> }}
                />


            </div>

            <Modal destroyOnClose width={869} footer={null} style={{textAlign: "center"}} bodyStyle={{paddingBottom: 16}} title={<div style={{marginTop: 16}}>Contract Registration</div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <ContractDetailsModal data={selectedData} />
            </Modal>

        </>
    )
}