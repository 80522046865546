import { Button, Modal, Space, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import dayjs from 'dayjs';
import theme from '../../../utils/theme';
import { useNotify } from '../../../utils/hooks/useNotify';
import { useTableFilters } from '../../../utils/hooks/useTableFilters';
import { ColumnTypes } from '../../../interfaces/TableColumns/NominationDetail';
import { defaultColumns } from '../../../interfaces/TableColumns/Allocation';
import { useEditableCell } from '../../../utils/hooks/useEditableCell';

import { fetchStats, getPaginationCountApi, showSuccess } from '../../../utils/common';
import FilterSelect from '../../../components/FilterSelect';
import useNavigationWithUnfinishedTasks from '../../../utils/hooks/useUnfinishedTaskAlert';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setTotalAllocatedCount, setUnSavedData } from '../../../features/auth/dashboardSlice';
import EmptyList from './EmptyList';
import { flatMap } from 'lodash';
import _isEqual from 'lodash/isEqual';
import { authSelector } from '../../../features/auth/authSlice';
const filterString = `gasDay|eqd|${dayjs().subtract(1, "day").format("DD/MM/YYYY")}&scheduledQty|neq|null`
function Allocation() {
    const [modal, modalContex] = Modal.useModal();

    const { tableParams, onFilerSearch, handleSort, onSearch, pagination } = useTableFilters("auditInfo.creationDate|ASC", filterString);
    const { data, originalData, editedItems, columns, components, setTableData, resetData, setEditedItems, setErrors, getErrors, errors,expandedCols } = useEditableCell(defaultColumns)
    const { notifyClients, rowSelection, hasSelected, sendingNotifications, openNotify, closeNotify, setOpenNotify } = useNotify("ALLOCATION",true, setTableData, "allocatedQty", ["sourceId", "pipelineId", "deliveryEndPoint"])
    const checkUnfinishedTasks = useNavigationWithUnfinishedTasks(editedItems.length != 0)
    const [hasErrors, setHasErrors] = useState(false)
    const dispatch = useAppDispatch();
    const [options, setOptions] = useState(null)
    const auth = useAppSelector(authSelector);
    

    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        if(editedItems.length != 0){
            dispatch(setUnSavedData(true))
        } else {
            dispatch(setUnSavedData(false))
        }
    
        return () => {
            dispatch(setUnSavedData(false))
        }
    },[editedItems])

    useEffect(() => {
        fetchDetails()
    }, [tableParams])

    useEffect(()=>{
        const checkHasError = errors.find((item:any)=>item.error)
        setHasErrors(checkHasError ? true : false)
    }, [errors])

    // useEffect(()=>{
    //     data?.items?.map((item:any, index:number)=>{
    //      ["allocatedQty", "sourceId", "pipelineId", "deliveryEndPoint"].map((checkingIndex:string)=>{
    //       if(!item[checkingIndex] && originalData.items[index][checkingIndex]){
    //           setErrors((prevErr:any) => {
    //               const existingErrorIndex = prevErr.findIndex((err:any) => err.contractId === originalData.items[index].nomination.contractId && err.dataIndex === checkingIndex);
              
    //               if (existingErrorIndex !== -1) {
    //                 // Error already exists, update the message
    //                 const newErr = [...prevErr];
    //                 newErr[existingErrorIndex].error = `Fill the ${checkingIndex.replace(/([A-Z])/g, ' $1').trim()}.`;
    //                 return newErr;
    //               } else {
    //                 // Error doesn't exist, add a new error object
    //                 return [...prevErr, { contractId: originalData.items[index].nomination.contractId, error: `Fill the ${checkingIndex.replace(/([A-Z])/g, ' $1').trim()}.`, dataIndex: checkingIndex }];
    //               }
    //           }); 
    //          }  else {
    //           // Delete the specific key (record.gasDay)
    //           setErrors((prevErr:any) => {
    //               const newErr = prevErr.filter((err:any) => !(err.contractId === originalData.items[index].nomination.contractId && err.dataIndex === checkingIndex && err.error !== "only numbers allowed"));
    //               return newErr;
    //             });
    //          }  
    //      })
    //     })  
    //   },[data])


    const fetchDetails = async () => {
        setLoading(true)
        const params = {
            ...tableParams,
            filterAnd: filterString + `${tableParams.filterAnd ? `&${tableParams.filterAnd}` : ""}`
        }
        // &scheduledQty|neq|null
        try {
            const response = await instance.get(`orderservice/nominate/details`, { params });
            setTableData(response.data)
            if(!options){
                setOptions(response?.data?.items)
            }
            dispatch(setTotalAllocatedCount(response.data.totalItems))
        } catch (error) {
            console.log(error)
        }
        setLoading(false)

    }

    const saveData = () => {
        Modal.destroyAll();

        let originalItems = flatMap(originalData?.items, (item:any)=>{
            return item.nominationDetails
        })
        originalItems = originalItems.map((item:any)=>{
            let newItem = {...item, allocatedQty: !item?.allocatedQty ? null : parseInt(item?.allocatedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId} 

            return newItem
        })

        const newEditedItems = editedItems.filter((item:any)=>{
            const originalItem = originalItems.find((i)=>i.id === item.id)
            let newItem = {...item, allocatedQty: !item?.allocatedQty ? null : parseInt(item?.allocatedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId}


            console.log(originalItem, newItem, "itemzz", (_isEqual(originalItem, newItem)))
            return !(_isEqual(originalItem, newItem))
        })

        let itemsToCheck = originalItems.filter((item:any)=>{
            return newEditedItems.find((i:any)=>i.gasDay === item.gasDay && i.contractId === item.contractId)
        })

        itemsToCheck = itemsToCheck.map((item:any) => {
            return newEditedItems.find((i:any) => i.id === item.id) ? newEditedItems.find((i:any) => i.id === item.id) : item
        })

        const aggregatedArr = Object.entries(itemsToCheck.reduce((acc:any, { gasDay, contractId, allocatedQty, nomination }:any) => {
            const key = `${gasDay}_${contractId}`;
            acc[key] = {
                gasDay,
                contractId,
                totalAllocatedQty: acc[key]?.totalAllocatedQty + Number(allocatedQty) || Number(allocatedQty),
                totalDCQ: nomination.dcq
            };
            return acc;
        }, {})).map(([, { gasDay, contractId, totalAllocatedQty, totalDCQ }]:any) => ({
            gasDay,
            contractId,
            totalAllocatedQty,
            totalDCQ
        }));

        console.log(aggregatedArr, "aggregatedArr")

        if (aggregatedArr.some((item:any) => item.totalAllocatedQty > (item.totalDCQ*2)) && !auth.isClient) {
            // Notify the user about the error and exit the function
            Modal.confirm({
                // show success message along with image
                icon: null,
                width: 430,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 15 }}>
                        <img width={96} src="/assets/warning-icon.png" />
                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 10, marginTop: 20, fontSize: 16 }}>DCQ MMBTU Limit Exceeding</Typography.Text>

                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 20, color: "#707070" }}>Allocated quantity for Contract ID {aggregatedArr.filter((item:any) => item.totalAllocatedQty > (item.totalDCQ*2)).map((i:any, key:number)=> <b style={{color: "black"}}>{`${i.contractId}${key<aggregatedArr.length-1 ? ", " : ""}`}</b>)} is exceeding DCQ by <span style={{color: "red"}}>200%</span>. Do you wish to continue?</Typography.Text>
                    </div>
                ),
                okText: 'Yes',
                cancelText: 'No, edit',
                onOk: async () => {
                    await updatedAllocatedQty(newEditedItems, true)
                },
                onCancel: () => {
                    const errorItems = aggregatedArr.filter((item:any) => item.totalAllocatedQty > (item.totalDCQ*2))

                    document?.getElementById(`nomination-entry-${errorItems[0].contractId}`)?.scrollIntoView({ behavior: 'auto' /*or smooth*/, block: 'center' });
                },
                okButtonProps: {
                    size: "large",
                    type: "primary",
                    style: {background: theme.token.colorPrimary, width: 140, fontWeight: 600}
                },
                cancelButtonProps: {
                    size: "large",
                    type: "default",
                    style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary}
                }
            });
        } else {
            updatedAllocatedQty(newEditedItems)
        }

        setErrors([])
        
    }

    const  updatedAllocatedQty = async (newEditedItems:any, dontConfirm?: boolean) => {

        if(!dontConfirm){
            modal.confirm({
                icon: null,
                title: "Do you want to proceed and send Allocation to the client?",
                okText: 'Yes',
                cancelText: 'No',
                onOk: async () => await updateNominationApi(newEditedItems),
                onCancel() {
                    resetData();
                },
                centered: true,
                bodyStyle: {
                    textAlign: "center"
                },
                okButtonProps: { size: "large", style: { width: 140, marginTop: 10 } },
                cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 } }
            });
        } else {
            updateNominationApi(newEditedItems)
        }        
    }

    const updateNominationApi = async (newEditedItems: any) => {
        const formData: any = [];
        newEditedItems.forEach((item: any) => {
            formData.push({
                "id": item.id,
                "gasDay": item.gasDay,
                "allocatedQty": item?.allocatedQty,
                "sourceId": item?.sourceId,
                "pipelineId": item?.pipelineId,
                "deliveryEndPoint": item?.deliveryEndPoint,
                "deliveryId": item?.deliveryId,
            });
        })
        try {
            await instance.put("/orderservice/nominate/details/allocate", { orders: formData })
            fetchStats()

            showSuccess(<>Allocation sent successfully</>, modal)
            setEditedItems([])
            setErrors([])
            fetchDetails()
        } catch (error:any) {
            const errors = error?.response?.data?.errorMessage
            if(errors){
                const filteredResult = getErrors(errors)
                setErrors(filteredResult)
            }
            console.log(error)
        }
    }

    const handleNotify = () => {
        modal.confirm({
            centered: true,
            icon: null,
            title: 'Do you want to proceed and send Confirmation to the client?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                notifyClients(fetchDetails)
            },
            bodyStyle: {
                textAlign: "center"
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 } },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 } }

        });
    }
    
    const saveDisabled = () => {
        let emptyItems = editedItems.filter((item:any)=>{
            return (!item?.allocatedQty || item?.allocatedQty === "") || (!item?.sourceId || item?.sourceId === "") || (!item?.pipelineId || item?.pipelineId === "")
        })
        let originalItems = flatMap(originalData?.items, (item:any)=>{
            return item.nominationDetails
        })
        originalItems = originalItems.map((item:any)=>{
            let newItem = {...item, allocatedQty: !item?.allocatedQty ? null : parseInt(item?.allocatedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId} 

            return newItem
        })
        emptyItems = emptyItems.filter((item:any)=>{
            const originalItem = originalItems.find((i)=>i.id === item.id)
            let newItem = {...item, allocatedQty: !item?.allocatedQty ? null : parseInt(item?.allocatedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId}


            console.log(originalItem, newItem, "itemxx", (_isEqual(originalItem, newItem)))
            return !(_isEqual(originalItem, newItem))
        })
        const newEditedItems = editedItems.filter((item:any)=>{
            const originalItem = originalItems.find((i)=>i.id === item.id)
            let newItem = {...item, allocatedQty: !item?.allocatedQty ? null : parseInt(item?.allocatedQty, 10)}
            newItem = {...newItem, sourceId: item?.sourceId === "" ? null : item?.sourceId}
            newItem = {...newItem, pipelineId: item?.pipelineId === "" ? null : item?.pipelineId}


            console.log(originalItem, newItem, "itemzz", (_isEqual(originalItem, newItem)))
            return !(_isEqual(originalItem, newItem))
        })
        console.log("empty items", emptyItems)
        if (emptyItems.length > 0 || newEditedItems.length === 0) {
            return true
        }
        return false
    }


    return (
        <>
            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, marginTop: 0 }}>Allocations</Typography.Title>

            <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
                {modalContex}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <Space>
                        <div>
                            <Typography.Text style={{ fontSize: 16, display: "block" }} type="secondary">Gas Day</Typography.Text>
                            <Typography.Text style={{ fontSize: 18 }}>{dayjs().subtract(1, "day").format("Do MMM' YY")} (Yesterday)</Typography.Text>
                        </div>
                    </Space>
                    <Space style={{ display: openNotify ? "none" : "flex" }}>
                        <FilterSelect onFilerSearch={onFilerSearch} options={options} onSearch={onSearch} />

                        <Space>
                            {(editedItems.length === 0) && <Button disabled={!originalData?.items?.length} onClick={() => { resetData(); setOpenNotify(true) }} size='large' type="primary">
                                Notify
                            </Button>}
                            <Button onClick={saveData} disabled={saveDisabled() || editedItems.length === 0 || hasErrors} size='large' type="primary">
                                Save
                            </Button>
                            {editedItems.length !== 0 && <Button onClick={resetData} disabled={editedItems.length === 0} size='large'>
                                Cancel
                            </Button>}
                        </Space>
                    </Space>
                    <Space style={{ display: !openNotify ? "none" : "flex" }}>
                        <Button loading={sendingNotifications} onClick={handleNotify} size='large' type="primary" disabled={!hasSelected}>Send Email</Button>
                        <Button size='large' type="default" onClick={() => closeNotify()}>Cancel</Button>
                    </Space>
                </div>

                <div>
                    <Table
                        loading={loading}
                        onChange={handleSort}
                        components={components}
                        rowClassName={(record) => `${errors.find((i: any) => i.contractId === record.nomination.contractId) ? "table-row error-bg" : "table-row"} ${record.hasOwnProperty('nominationDetails') ? "parent-row" : "child-row"} ${record.nominationDetails?.length > 1 ? "mg-expandable-row" : ""}`}
                        columns={openNotify ? columns.map((i: any) => { return { ...i, onCell: (record: any, index: any) => expandedCols(record, index,i) } }) : columns as ColumnTypes}
                        dataSource={data?.items?.map((item: any) => {
                            return { ...item, nominationDetails: item.nominationDetails?.length > 1 ? item.nominationDetails : null }
                        })
                        }
                        pagination={{
                            pageSize: 15,
                            // total: data.totalItems,
                            // current: tableParams.page,
                            showPrevNextJumpers: false,
                            showSizeChanger: false,
                            position: ["bottomCenter"]
                        }}
                        rowSelection={openNotify ? { ...rowSelection, checkStrictly: false } : undefined}
                        rowKey={(record) => { return record.hasOwnProperty('nominationDetails') ? record.id + "parent" : record.id + "child" }}
                        footer={()=><div className='footer-pagination'>{data && getPaginationCountApi(data, pagination)}</div>}
                        showHeader={!data?.items || data?.items?.length === 0 ? false : true}
                        locale={loading ? undefined : { emptyText: <EmptyList customMessage={<>No Allocation Found</>}/> }}
                        scroll={{ x: true
                            // , y: data?.totalItems > 0 ? "65vh" : undefined 
                        }}
                        expandable={{
                            childrenColumnName: "nominationDetails",
                            rowExpandable: (record) => record.nominationDetails.length > 1,
                            indentSize: 0,
                            expandIcon: () => null,
                            onExpand: (expanded, record) => {
                                const rowItem = document.querySelectorAll(`[data-row-key="${record.id}parent"]`);
                                // add class name to rowItem
                                if (rowItem.length > 0) {
                                    // Iterate through each rowItem and add the class name
                                    rowItem.forEach(item => {
                                        console.log("expanded", expanded)
                                        if (expanded) {
                                            item.classList.add("mg-expandabled-row")
                                        } else {
                                            item.classList.remove("mg-expandabled-row")
                                        }
                                    });
                                }
        
                            },
                            expandRowByClick: true,
                        }}
                    />
                </div>

            </div>
        </>
    )

};

export default Allocation;