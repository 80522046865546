import { Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
// import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

export interface Item {
    id: number,
    startDate: string,
    endDate: string,
    nominatedQty: number,
    scheduledQty: number,
    allocaedQty: number,
    dcq: number,
    deviation: string,
    status: string,
}


export const columns: ColumnsType<Item> = [
   
    {
        title: 'Fortnight',
        key: 'f.START_DATE_FORTNIGHT',
        render: (record) => 
        <div style={{ 
            width: 160,
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2px"
        }}>
            {dayjs(record.startDateFortnight, "DD/MM/YYYY").format("DD MMM")}            
            <img width={15} src="/assets/date-line.svg" />
            {dayjs(record.endDateFortnight, "DD/MM/YYYY").format("DD MMM YYYY")}
        </div>,
        align: "center",
        sorter: true,
    },
    
    
    {
        title: <>Total Nominated<br/>Qty. (MMBTU)</>,
        dataIndex: 'totalNominatedQty',
        key: 'totalNominatedQty',
        render: (totalNominatedQty: string) => <div style={{ width: 150, display: "inline-block" }}>{totalNominatedQty ? totalNominatedQty : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: <>Scheduled Supply<br/>Qty. (MMBTU)</>,
        dataIndex: 'totalScheduledQty',
        key: 'totalScheduledQty',
        render: (totalScheduledQty: string) => <div style={{ width: 150, display: "inline-block" }}>{totalScheduledQty ? totalScheduledQty : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: <>Total Allocated<br/>Qty. (MMBTU)</>,
        dataIndex: 'totalAllocatedQty',
        key: 'totalAllocatedQty',
        render: (totalAllocatedQty) => <div style={{ width: 140, display: "inline-block" }}>{totalAllocatedQty ? totalAllocatedQty : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: 'DCQ',
        dataIndex: 'totalDcq',
        key: 'totalDcq',
        render: (totalDcq: string) => <div style={{ width: 80, display: "inline-block" }}>{totalDcq}</div>,
        align: "center",
        // sorter: true
    },
    {
        title: 'Deviation',
        dataIndex: 'deviation',
        key: 'deviation',
        render: (deviation) => <div style={{ width: 80, display: "inline-block" }}>{deviation ? deviation.toFixed(2) + "%" : "----"}</div>,
        align: "center",
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'STATUS',
        render: (status) => {
            return <Tag style={{ fontSize: 14, textAlign: "center",  textTransform: "uppercase" }} color={status === "Completed" ? "green" : "orange"}>{status}</Tag>
        },
        align: "center",
        sorter: true
    },
    
];