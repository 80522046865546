import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./auth/userSlice";
import authSlice from "./auth/authSlice";
import utilsSlice from "./utils/utilsSlice";

const rootReducer = combineReducers({
    user: userSlice,
    auth: authSlice,
    utils: utilsSlice
})

export default rootReducer;