import { Button, Form, Input, Modal, Select, Table, Tooltip, Typography, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import type { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { instance } from '../../../utils/api';
import { fetchStats } from '../../../utils/common';
import { DownOutlined, ExclamationCircleOutlined, PlusSquareFilled, UpOutlined } from '@ant-design/icons';
import useNavigationWithUnfinishedTasks from '../../../utils/hooks/useUnfinishedTaskAlert';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setUnSavedData } from '../../../features/auth/dashboardSlice';
import _isEqual from 'lodash/isEqual';
import { authSelector } from '../../../features/auth/authSlice';
import theme from '../../../utils/theme';

dayjs.extend(isSameOrBefore)
dayjs.extend(minMax)

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

interface Item {
    gasDay: string,
    nominatedQty: number | null,
    deliveryEndPoint?: string | null,
    items: any
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface ParamType {
    clientId: number,
    client: string,
    contractId: number,
    dcq: number,
    startDate: string,
    endDate: string,
    createNominationDetailDtoList: Item[],
}

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    contract: any;
    setIdsToDelete: any;
    handleSave: (record: Item) => void;
    setErr: any;
    error: any
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    setIdsToDelete,
    setErr,
    error,
    contract,
    ...restProps
}) => {
    const [editing, setEditing] = useState("");
    const form = useContext(EditableContext)!;
    const auth = useAppSelector(authSelector);


    // useEffect(() => {
    //     if (editing) {
    //         // focus by id

    //     }
    // }, [editing]);

    const toggleEdit = (index?: any) => {
        if (index + 1) {
            setEditing(`${record.gasDay}-${index}-${dataIndex}`);
        } else {
            setEditing("")
        }
        // const formValues = form.getFieldsValue()
        // form.setFieldsValue({ [dataIndex]: record?.items?.map((i:any)=>i[`${dataIndex}`] ? i[`${dataIndex}`] : [null]) });
    };

    const save = async (e?: any, index?:any) => {
        if (e) {
            if (isNaN(e.target.value) || parseInt(e.target.value) < 0) {
                setErr((prevErr: any) => ({
                    ...prevErr,
                    [`${record.gasDay}`+index+dataIndex]: "only numbers allowed",
                }));
            } else if(!/^\d+(\.\d{1,3})?$/.test(e?.target?.value) && e.target.value){
                setErr((prevErr: any) => ({
                    ...prevErr,
                    [`${record.gasDay}`+index+dataIndex]: "3 decimal places allowed",
                }));
            } else {
                // Delete the specific key (record.gasDay)
                if((!e.target.value || e.target.value==="") && index > 0){
                    setErr((prevErr: any) => ({
                        ...prevErr,
                        [`${record.gasDay}`+index+dataIndex]: "please enter "+dataIndex,
                    })); 
                } else {
                    setErr((prevErr: any) => {
                        const { [`${record.gasDay}`+index+dataIndex]: omitKey1, [`${record.gasDay}`+"parent"+dataIndex]: omitKey2, ...newErr } = prevErr;
                        return newErr;
                    });
                }
                
            }
        } else {
            if(dataIndex==="deliveryEndPoint"){
                if(index+1){
                    setErr((prevErr: any) => {
                        const { [`${record.gasDay}`+index+"deliveryEndPoint"]: omitKey, ...newErr } = prevErr;
                        return newErr;
                    });
                }
            }
        }
        // if(typeof(inputValue)==="number"){

        try {
            const values = form.getFieldsValue();
            handleSave({
                ...record, items: values[`${dataIndex}`].map((i: any, key: any) => {
                    if (record.items && record.items[key]) {
                        // If the item at the specific index exists, update it
                        return {
                            ...record.items[key],
                            gasDay: record.gasDay,
                            [`${dataIndex}`]: i
                        };
                    } else {
                        // If the item at the specific index doesn't exist, create it
                        return {
                            [`${dataIndex}`]: i,
                            gasDay: record.gasDay
                        };
                    }
                })
            });
            const valuesToCheck = values.deliveryEndPoint.filter((item:any, key:any)=>key!=index)
            
            // if(dataIndex=="deliveryEndPoint" && valuesToCheck.find((i:any)=>i==values.deliveryEndPoint[index])){
            //     console.log("index", index)
            //     if(index+1){
            //         setErr((prevErr: any) => ({
            //             ...prevErr,
            //             [`${record.gasDay}`+index+dataIndex]: "cannot be duplicate",
            //         })); 
            //     }
            // }

        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
        // }
    };

    let childNode = children;

    const checkFortnight = () => {
        const currentHour = dayjs().hour();
        if(auth.isClient) {
            return dayjs(record.gasDay, "DD/MM/YYYY").subtract(1, "day").isBefore(dayjs().subtract(1, "day")) || (dayjs(record.gasDay, "DD/MM/YYYY").isSame(dayjs().add(1,"day"), 'day') && currentHour >= 12);
        } else {
            return dayjs(record.gasDay, "DD/MM/YYYY").subtract(1, "day").isBefore(dayjs().subtract(1, "day"))
        }
    }

    if (editable) {
        childNode =
            <div id={`nomination-entry-${record.gasDay}`}>

                <Form.List name={dataIndex} initialValue={record.items ? record?.items?.map((i: any) => i[`${dataIndex}`]) : [null]}>
                    {(fields, { add, remove }) => (
                        fields.map((field, index) => (
                            <>
                            <div style={{ display: "flex", alignItems: "center", marginTop: index != 0 ? "5px" : "unset", gap: 2, marginBottom: 17 }}>
                                <div style={{width: dataIndex==="deliveryEndPoint" ? "100%": "unset"}}>

                                    {editing === `${record.gasDay}-${index}-${dataIndex}` ? (
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            // set input max value to 5
                                            key={field.key}
                                        >
                                            <Form.Item
                                                {...field}
                                                style={{ margin: 0 }}
                                                rules={[
                                                    {
                                                        required: false,
                                                        // validator: (_, value) => {
                                                        //     if (value > contract.dcq) {
                                                        //         return Promise.reject('Nomination cannot be greater than DCQ');
                                                        //     }
                                                        //     return Promise.resolve();
                                                        // }
                                                    },
                                                    // { type: "number", min: 0 }
                                                ]}
                                            >
                                                {dataIndex === "deliveryEndPoint" ? <Select suffixIcon={<UpOutlined />} defaultOpen={true} autoFocus id={`${record.gasDay}-${index}-${dataIndex}`} style={{ width: "100%" }} onChange={(e, option)=>{save(null,index)}} onBlur={() => { toggleEdit() }}>
                                                    {contract.deliveryEndPoints?.map((i:any)=>{
                                                        return <Select.Option className="nomination-client-rounded" disabled={form.getFieldsValue().deliveryEndPoint.includes(i.deliveryEndPoint)} key={i.id} value={i.deliveryEndPoint}>{i.deliveryEndPoint}</Select.Option>
                                                    })}

                                                </Select> :
                                                <Input autoFocus id={`${record.gasDay}-${index}-${dataIndex}`} style={{ width: 150 }} onChange={(e)=>save(e,index)} onBlur={() => { toggleEdit() }} />
                                                }
                                            </Form.Item>
                                        </Form.Item>

                                    ) : (
                                        <div 
                                            id={`error-${record.gasDay}-${index}`} 
                                            style={{ position: "relative", border: `solid 1px ${error[`${record.gasDay}`+index+dataIndex] ? 'red' : 'lightgray'}`, padding: "5px 10px", borderRadius: 12, background: checkFortnight() ? "#E9E9E9" : record?.items?.[index].id ? "#f2f2f2" : "white", height: 32, width: dataIndex === "deliveryEndPoint" ? "100%" : 150, display: "flex", justifyContent: "center" }} 
                                            onClick={checkFortnight() ? () => { } : () => { toggleEdit(index) }} 
                                            onFocus={checkFortnight() ? () => { } : () => { 
                                                document?.getElementById(`nomination-entry-${record.gasDay}`)?.scrollIntoView({ behavior: 'smooth' /*or smooth*/, block: 'center' });
                                                toggleEdit(index) 
                                            }} 
                                            tabIndex={checkFortnight() ? undefined : 0}
                                        >
                                            <Typography.Text style={{width: "90%"}} ellipsis>
                                                {dataIndex=== "deliveryEndPoint" ?
                                                <Tooltip title={record && record.items && record.items[index] ? record.items[index][`${dataIndex}`] : null}>{record && record.items && record.items[index] ? record.items[index][`${dataIndex}`] : null}</Tooltip>
                                                :record && record.items && record.items[index] ? record.items[index][`${dataIndex}`] : null}
                                            </Typography.Text>
                                            {dataIndex=== "deliveryEndPoint" && !record?.items?.[index].id &&  <DownOutlined style={{fontSize: 12, position: "absolute", right: 10, top: 0, bottom: 0}} />}
                                            {record?.items?.[index].id && !checkFortnight() && <img width={12.75} src="/assets/edit-icon.svg" />}
                                        </div>
                                    )}
                                    {error[`${record.gasDay}`+index+dataIndex] && <span style={{ color: "red", display: "block", fontSize: 10, marginTop: 1, marginBottom: -15, position: "absolute", left: -36, right: 0, margin: "auto" }}>
                                        <ExclamationCircleOutlined />{" "}{error[`${record.gasDay}`+index+dataIndex]}
                                    </span>}
                                </div>
                                {!checkFortnight() && dataIndex === "nominatedQty" && fields.length - 1 === index && fields.length < 5 && index < contract?.deliveryEndPoints?.length-1 && <PlusSquareFilled
                                    style={{ color: "#2C66E3" }}
                                    onClick={() => {
                                        add();
                                        form.setFieldValue("deliveryEndPoint", [...form.getFieldValue("deliveryEndPoint"), null]);
                                        save()
                                    }}
                                />}
                                {!checkFortnight() && (fields.length > 1) && dataIndex === "nominatedQty" ? (
                                    <div
                                        style={{ color: "#FF3B30", height: 20, cursor: "pointer" }}
                                        onClick={() => {
                                            setErr((prevErr: any) => {
                                                const { [`${record.gasDay}`+"parent"+dataIndex]: omitKey,[`${record.gasDay}${index}deliveryEndPoint`]: omitKey1,[`${record.gasDay}${index}deliveryEndPoint`]: omitKey2, ...newErr } = prevErr;
                                                return newErr;
                                            });
                                            remove(field.name);
                                            if(record.items?.[index]?.nominatedBy){
                                                setIdsToDelete((prevIds:any) => {
                                                    return [...prevIds, record.items[index].id]   
                                                })
                                            }
                                            const formValues = form.getFieldValue("deliveryEndPoint");
                                            form.setFieldValue("deliveryEndPoint", formValues.filter((item: any, i: any) => i !== index));
                                            // setErr((prevErr: any) => {
                                            //     const { [`${record.gasDay}`+index+"deliveryEndPoint"]: omitKey, ...newErr } = prevErr;
                                            //     return newErr;
                                            // });
                                            handleSave({ ...record, items: record.items?.filter((item: any, key: any) => key !== index) });
                                        }}
                                    >
                                            <img width={16} src="/assets/delete.png" />
                                    </div>
                                ) : null}
                            </div>
                            {record.items?.filter((i:any)=>i.nominatedBy).length === contract?.deliveryEndPoints?.length && !checkFortnight() && fields.length <= 5 && contract?.deliveryEndPoints?.length-1 === index && <Typography.Text style={{fontSize: 11, position: "absolute", width: "180%", zIndex: 1, right: -190, bottom: 15}} type="secondary">{dataIndex != "deliveryEndPoint" && "All delivery endpoints already added, change the Qty. accordingly"}</Typography.Text>}
                            {record.items?.filter((i:any)=>i.nominatedBy).length != 5 && fields.length > 4 && index===4 && <Typography.Text style={{fontSize: 11, marginLeft: -30}} type="secondary">{dataIndex != "deliveryEndPoint" && "Only 5 entries are allowed"}</Typography.Text>}
                            </>
                            
                        ))
                        
                    )}


                </Form.List>
                {error[`${record.gasDay}`+"parent"+dataIndex] && <span style={{ color: "red", display: "block", fontSize: 10, position: "absolute", left: 0, right: 0, bottom: 2, margin: "auto" }}>
                                        <ExclamationCircleOutlined />{" "}{error[`${record.gasDay}`+"parent"+dataIndex]}
                                    </span>}
            </div>
            ;
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function CreateNominationForm({ nominations, contract, selectedClient, dateRange, handleSuccess }: any) {
    const [submitting, setSubmitting] = useState(false)
    const [err, setErr] = useState<any>("")
    const checkUnfinishedTasks = useNavigationWithUnfinishedTasks(true)
    const dispatch = useAppDispatch();
    const [idsToDelete, setIdsToDelete] = useState([])
    const auth = useAppSelector(authSelector);

    useEffect(() => {
        dispatch(setUnSavedData(true))

        return () => {
            dispatch(setUnSavedData(false))
        }
    }, [])

    useEffect(() => {
        console.log("idsToDelete",idsToDelete.join(','))
    }, [idsToDelete])


    useEffect(() => {
        setData(getDatesInRange(contract.startDate, contract.endDate, dayjs(dateRange[0]).format("DD/MM/YYYY"), dayjs(dateRange[1]).format("DD/MM/YYYY")))
        setOriginalData(getDatesInRange(contract.startDate, contract.endDate, dayjs(dateRange[0]).format("DD/MM/YYYY"), dayjs(dateRange[1]).format("DD/MM/YYYY")))
    }, [contract, dateRange])

    function getDatesInRange(contractStartDate: string, contractEndDate: string, selectedStartDate: string, selectedEndDate: string) {
        const startDate: any = dayjs.max(dayjs(contractStartDate, "DD/MM/YYYY"), dayjs(selectedStartDate, "DD/MM/YYYY"));
        const endDate: any = dayjs.min(dayjs(contractEndDate, "DD/MM/YYYY"), dayjs(selectedEndDate, "DD/MM/YYYY"));

        const datesInRange = [];

        // get all dates between startDate and endDate using dayjs
        for (let d = startDate; d <= endDate; d = d.add(1, 'day')) {
            const record = nominations.find((i: any) => i.gasDay === d.format('DD/MM/YYYY'))
            let preNominatedQty = record?.nominatedQty
            let items = record?.nominationDetails
            datesInRange.push({
                gasDay: d.format('DD/MM/YYYY'),
                nominatedQty: preNominatedQty,
                items: items,
            });

        }
        return datesInRange;
    }

    const [data, setData] = useState<Item[] | []>([]);
    const [originalData, setOriginalData] = useState<Item[] | []>([]);


    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Gas Day',
            dataIndex: 'gasDay',
            key: 'gasDay',
            align: "center",
            render: (gasDay) => {
                //  change format of string date
                return dayjs(gasDay, "DD/MM/YYYY").format("DD MMM YYYY")
            }
        },
        {
            title: 'DCQ',
            dataIndex: 'dcq',
            key: 'dcq',
            align: "center",
            render: () => contract.dcq
        },
        {

            title: <>Nominated Quantity<br />(MMBTU) {typeof (err) === "string" && <p style={{ fontSize: 10, margin: 0, color: "red" }}>{err}</p>}</>,
            dataIndex: 'nominatedQty',
            align: "center",
            editable: true,
            render: (nominatedQty) => <div style={{ width: 150 }}>{nominatedQty}</div>

        },
        {
            title: "Delivery End Point",
            dataIndex: "deliveryEndPoint",
            align: "center",
            editable: true,
        }
    ];

    const handleSave = (row: Item) => {
        console.log("rowxxx", row)
        const newData = [...data];
        const index = newData.findIndex((item) => row.gasDay === item.gasDay);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setData(newData);
        const updatedErr = { ...err };
        // Delete the specified key from the copy
        if (err[row.gasDay] !== "only numbers allowed" && err[row.gasDay] !== "3 decimal places allowed"  && err[row.gasDay]) {
            delete updatedErr[row.gasDay];
            // Set the state with the updated copy
            setErr(updatedErr);
        }
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => {
                return ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                    setIdsToDelete,
                    setErr,
                    error: err,
                    contract,
                })
            },
        };
    });

    const getCreateNominationDetailDtoList = () => {
        const currentHour = dayjs().hour();
        const combinedArray = auth.isClient 
            ? data.filter((i) => !(dayjs(i.gasDay, "DD/MM/YYYY").isBefore(dayjs()) ||  (dayjs(i.gasDay, "DD/MM/YYYY").isSame(dayjs().add(1,"day"), 'day') && currentHour >= 12))).flatMap(obj => obj.items)
            : data.filter((i) => !dayjs(i.gasDay, "DD/MM/YYYY").isBefore(dayjs())).flatMap(obj => obj.items)
        const createNominationDetailDtoList = combinedArray.filter(i=>i).map((item: any) => {
            return {
                id: item?.id,
                gasDay: item.gasDay,
                nominatedQty: item.nominatedQty === "" ? null : item.nominatedQty,
                deliveryId: contract?.deliveryEndPoints?.find((i:any)=>i.deliveryEndPoint === item.deliveryEndPoint)?.id,
                deliveryEndPoint: item.deliveryEndPoint
            }
        })
        return createNominationDetailDtoList
    }

    const getOriginalCreateNominationDetailDtoList = () => {
        const currentHour = dayjs().hour();
        const combinedArray = auth.isClient 
            ? originalData.filter((i) => !(dayjs(i.gasDay, "DD/MM/YYYY").isBefore(dayjs()) ||  (dayjs(i.gasDay, "DD/MM/YYYY").isSame(dayjs().add(1,"day"), 'day') && currentHour >= 12))).flatMap(obj => obj.items)
            : originalData.filter((i) => !dayjs(i.gasDay, "DD/MM/YYYY").isBefore(dayjs())).flatMap(obj => obj.items)
        const createNominationDetailDtoList = combinedArray.filter(i=>i).map((item: any) => {
            return {
                id: item?.id,
                gasDay: item.gasDay,
                nominatedQty: item.nominatedQty === "" ? null : item.nominatedQty,
                deliveryId: contract?.deliveryEndPoints?.find((i:any)=>i.deliveryEndPoint === item.deliveryEndPoint)?.id,
                deliveryEndPoint: item.deliveryEndPoint
            }
        })
        return createNominationDetailDtoList
    }

    const compareData = () => {
        const newData  = getCreateNominationDetailDtoList().filter((item:any)=>item.id || item.deliveryEndPoint || item.nominatedQty)
        const newDataToCheck = newData.map((item:any)=>{
            return {...item, nominatedQty: item.nominatedQty ? Number(item.nominatedQty) : undefined}
        })
        const combinedArray = originalData.filter((i) => !dayjs(i.gasDay, "DD/MM/YYYY").isBefore(dayjs())).flatMap(obj => obj.items);
        const oldData = combinedArray.filter(i=>i).map((item: any) => {
            return {
                id: item?.id,
                gasDay: item.gasDay,
                nominatedQty: item.nominatedQty === "" ? null : item.nominatedQty,
                deliveryId: contract?.deliveryEndPoints?.find((i:any)=>i.deliveryEndPoint === item.deliveryEndPoint)?.id,
                deliveryEndPoint: item.deliveryEndPoint
            }
        })
        console.log("originalData", oldData)
        const areArraysEqual = _isEqual(newDataToCheck, oldData);
        console.log("areArraysEqual", areArraysEqual)

        return areArraysEqual
    }

    const handleSubmit = async () => {
        Modal.destroyAll()
        const createNominationDetailDtoList = getCreateNominationDetailDtoList().filter((i) => i.nominatedQty != "" && i.nominatedQty && i.deliveryEndPoint)
        const originalCreateNominationDetailDtoList = getOriginalCreateNominationDetailDtoList().filter((i) => i.nominatedQty != "" && i.nominatedQty && i.deliveryEndPoint)

        const getEditedItemes = createNominationDetailDtoList.filter((item:any)=>{
            if(!originalCreateNominationDetailDtoList.find((i:any)=>item.gasDay === i.gasDay && item.deliveryId === i.deliveryId && Number(item.nominatedQty) === i.nominatedQty)){
                return item
            }
        })

        const aggregatedArr = Object.entries(createNominationDetailDtoList.reduce((acc:any, {gasDay, nominatedQty}) => {
            if(getEditedItemes.find((nomination:any)=>nomination.gasDay === gasDay)){
                acc[gasDay] = acc[gasDay] ? acc[gasDay] + Number(nominatedQty) : Number(nominatedQty);
            }
            return acc;
        }, {})).map(([gasDay, totalNominatedQty]) => ({ gasDay, totalNominatedQty }));

        if (aggregatedArr.some((item:any) => item.totalNominatedQty > (contract.dcq*2)) && !auth.isClient) {
            // Notify the user about the error and exit the function
            Modal.confirm({
                // show success message along with image
                icon: null,
                width: 430,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 15 }}>
                        <img width={96} src="/assets/warning-icon.png" />
                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 10, marginTop: 20, fontSize: 16 }}>DCQ MMBTU Limit Exceeding</Typography.Text>

                        <Typography.Text style={{ textAlign: "center", fontWeight: 500,marginBottom: 20, color: "#707070" }}>Nominated quantity for Gas day {aggregatedArr.map((i:any, key:number)=> <b style={{color: "black"}}>{`${dayjs(i.gasDay, "DD/MM/YYYY").format("DD MMM YYYY")}${key<aggregatedArr.length-1 ? ", " : ""}`}</b>)} is exceeding DCQ of {contract.dcq} MMBTU by <span style={{color: "red"}}>200%</span>. Do you wish to continue?</Typography.Text>
                    </div>
                ),
                okText: 'Yes',
                cancelText: 'No, edit',
                onOk: async () => {
                    createNomination(createNominationDetailDtoList)
                },
                onCancel: () => {
                    document?.getElementById(`nomination-entry-${aggregatedArr[0].gasDay}`)?.scrollIntoView({ behavior: 'auto' /*or smooth*/, block: 'center' });
                },
                okButtonProps: {
                    size: "large",
                    type: "primary",
                    style: {background: theme.token.colorPrimary, width: 140, fontWeight: 600}
                },
                cancelButtonProps: {
                    size: "large",
                    type: "default",
                    style: { width: 140, fontWeight: 600, color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary}
                }
            });
        } else {
            createNomination(createNominationDetailDtoList)
        }
    }

    const createNomination = async (createNominationDetailDtoList:any) => {
        let params = {
            clientId: selectedClient.id,
            client: selectedClient.name,
            contractId: contract.id,
            dcq: contract.dcq,
            startDate: contract.startDate,
            endDate: contract.endDate,
            clientCode: selectedClient.clientCode,
            tradeId: contract.tradeId,
            totalContractQty: contract.totalQuantity,
            // filter prev gay days
            createNominationDetailDtoList: createNominationDetailDtoList,
            
        }
        if (createNominationDetailDtoList.filter((item:any) => item.nominatedQty != null).length != 0) {
            setSubmitting(true)
            try {
                await instance.post(`orderservice/nominate?${idsToDelete.length > 0 ? 'deleteIds='+idsToDelete.join(',') : ''}`, params);
                setSubmitting(false)
                !auth.isClient && fetchStats()
                handleSuccess();
            } catch (e: any) {
                setSubmitting(false)
                if (e?.response?.status === 400) {
                    if(typeof (e.response.data.errorMessage) === "string"){
                        setErr(e.response.data.errorMessage)
                    } else {
                        const gasDayError = e.response.data.errorMessage
                        let errObject:any = {}
                    
                        if(Object.keys(gasDayError).length > 0) {
                            // map over object gasDayError
                             Object.keys(gasDayError).map((key) => {
                                // find index of gasDayError[key]
                                const parts = key.split(',');
                                const dataIndex = "nominatedQty";
                                    let actualIndex = parts[1] ? createNominationDetailDtoList.filter((i:any)=>i.gasDay === parts[0]).findIndex((x:any)=>x.deliveryId===parts[1]) : "parent"
                                    errObject[parts[0]+actualIndex+dataIndex] = gasDayError[key]
                            })
                            
                            
                        }
                        setErr(errObject)
                        document?.getElementById(`nomination-entry-${Object.keys(e.response.data.errorMessage)[0]}`)?.scrollIntoView({ behavior: 'auto' /*or smooth*/, block: 'center' });
                    }
                    
                }
            }
        } else {
            message.error("Enter Nomination and Delivery Endpoint & atleast for one date.")
        }
    }

    const disableSubmit = () => {
        return getCreateNominationDetailDtoList().filter((i)=> getCreateNominationDetailDtoList().filter((x)=>x.gasDay === i.gasDay).length > 1 && (!i.nominatedQty || i.nominatedQty==="" || !i.deliveryEndPoint || i.deliveryEndPoint === "")).length > 0 
        || getCreateNominationDetailDtoList().filter((i)=> getCreateNominationDetailDtoList().filter((x)=>x.gasDay === i.gasDay).length === 1 && (!i.nominatedQty  || !i.deliveryEndPoint) && !(!i.nominatedQty && !i.deliveryEndPoint)).length > 0 
        || getCreateNominationDetailDtoList().filter((i)=>i.nominatedQty && i.deliveryEndPoint).length === 0
        || Object.keys(err)?.length > 0 
        || submitting 
        || data.filter((item: Item) => !dayjs(item.gasDay, "DD/MM/YYYY").isBefore(dayjs())).length === 0
    }

    return (
        <>
            <Table
                sticky={true}
                components={components}
                rowClassName="table-row"
                className='create-nomination-table'
                columns={columns as ColumnTypes}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record.gasDay}
            />
            <div style={{
                position: "absolute", bottom: 1, background: "white", width: "919px",
                right: 0,
                left: 0,
                margin: "auto",
                textAlign: "center",
                padding: "20px 0px",
                borderTop: "solid lightgray",
                borderRadius: "0 0 20px 20px",
                zIndex: 2
            }}>
                <Button disabled={compareData() || disableSubmit()} 
                    loading={submitting} 
                    onClick={handleSubmit} 
                    type="primary" 
                    style={{ width: 230 }}
                    size="large">
                        Submit
                </Button>
            </div>
        </>
    )

};

export default CreateNominationForm;