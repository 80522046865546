import { Tag } from "antd";

export default function ContractStatusTag({ tag }: { tag: string }) {
    return (
        <>
            {tag === "No Contract" && <Tag style={{ fontSize: 14, textAlign: "center", textTransform: "uppercase" }} color="red">{tag}</Tag>}
            {tag === "Contract Created" && <Tag style={{ fontSize: 14, textAlign: "center", textTransform: "uppercase" }} color="blue">{tag}</Tag>}
            {tag === "In Progress" && <Tag style={{ fontSize: 14, textAlign: "center", textTransform: "uppercase" }} color="orange">{tag}</Tag>}
            {tag === "Completed" && <Tag style={{ fontSize: 14, textAlign: "center", textTransform: "uppercase" }} color="green">{tag}</Tag>}
        </>
    )
}