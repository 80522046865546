
import type { ColumnsType } from 'antd/es/table';
import { Item } from './Confirmation';
import { Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import ContractStatusTag from '../../pages/Dashboard/ClientDetails/ContractStatusTag';

export const columns: ColumnsType<Item> = [
    {
        title: 'Contract ID',
        dataIndex: 'contractId',
        key: 'contractId',
        align: "center",
        render: (contractId: string) => <div style={{ width: 80, display: "inline-block" }}>{contractId}</div>,
        // sorter: true,
    },
    {
        title: 'Client',
        dataIndex: 'name',
        key: 'name',
        align: "center",
        render: (client: string) => <Tooltip title={client}><Typography.Text ellipsis style={{ width: 100 }}>
            {client}</Typography.Text >
        </Tooltip>,
        // sorter: true
    },
    {
        title: "Client Code",
        dataIndex: "clientCode",
        render: (clientCode) => <div style={{ display: "inline-block", width: 100 }}>{clientCode}</div>,
        align: "center"
    },
    {
        title: 'Contract Duration',
        key: 'startDate',
        render: (record) =>
            <div style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
                minWidth: 200
            }}>
                {!record.startDate ? <>NA</> : <>
                    {dayjs(record.startDate, "DD/MM/YYYY").format("DD MMM")}
                    <img width={15} src="/assets/date-line.svg" />
                    {dayjs(record.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}
                </>}
            </div>,
        align: "center",
    },
    {
        title: <>Total Contract<br />Qty. (MMBTU)</>,
        dataIndex: 'totalQuantity',
        key: 'totalQuantity',
        render: (totalQuantity: string) => <div style={{ width: 150, display: "inline-block" }}>{totalQuantity ? totalQuantity : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: 'DCQ',
        dataIndex: 'dcq',
        key: 'dcq',
        render: (dcq: string) => <div style={{ width: 80, display: "inline-block" }}>{dcq}</div>,
        align: "center",
        // sorter: true
    },
    {
        title: <>Total Allocated<br />Qty. (MMBTU) </>,
        dataIndex: 'nominatedQty',
        key: 'nominatedQty',
        render: (value) => <div style={{ width: 140, display: "inline-block" }}>{value ? value : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: <>Total Allocated<br />Qty. (MMBTU) </>,
        dataIndex: 'totalAllocatedQty',
        key: 'totalAllocatedQty',
        render: (value) => <div style={{ width: 140, display: "inline-block" }}>{value ? value : "----"}</div>,
        align: "center",
        // sorter: true,
    },
    {
        title: 'Contract Status',
        dataIndex: 'contractStatus',
        key: 'contractStatus',
        render: (contractStatus: any, record: any
        ) => {
            return <div style={{ width: 150, display: "inline-block" }}>
                {record?.contractUpdateStatus !== "UPDATED_QTY" ? <ContractStatusTag tag={contractStatus} /> :
                    <Tag style={{ fontSize: 14, textAlign: "center", textTransform: "uppercase" }} color="pink">UPDATED QTY</Tag>
                }

            </div>
        },
        align: "center",
        // sorter: true
    },


];