import { CloudUploadOutlined, DeleteOutlined, FileImageOutlined, UploadOutlined } from "@ant-design/icons"
import { Row, Col, Upload, Button, Form, message, Input, Space, Card, Typography, Select, Modal } from "antd"
import { useEffect, useState } from "react"
import { instance } from "../../../utils/api";
import { Link } from "react-router-dom";
const _debounce = require('lodash/debounce');
interface KYCDetails {
    type: string;
    kycId: string;
  }
  
  interface Values {
    [key: string]: any;
  }

function KycForm({ clientID,registeredName, getKycDetails, kycDetails, setDefaulttab,getClientData }: { clientID?: string | undefined,registeredName:any, getKycDetails: any, kycDetails?: any, setDefaulttab: any,getClientData:any }) {

    const [loading, setLoading] = useState(false)
    const [enableReset, setEnableReset] = useState(false)
    const [docLength, setDocLength] = useState(10)
    const [editing, setEditing] = useState(true)
    const [modal, modalContex] = Modal.useModal();
    const [options, setOptions] = useState<any>([]);

    const [form] = Form.useForm()

    useEffect(() => {
        setEditing(kycDetails?.length < 1 ? true : false)
    }, [kycDetails])


    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const deleteKyc = async (id: number) => {

        modal.confirm({
            title: <>Are you sure?<br /> Delete this KYC document</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            icon: null,
            onOk: async () => {
                try {
                    setLoading(true)
                    await instance.delete(`clientservice/clients/kyc/${id}`)
                    message.success("client KYC deleted successfully!")
                    getKycDetails()
                    getClientData()
                } catch (e: any) {
                    console.log(e);
                }
                setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })

    }

    useEffect(() => {
        // form.setFieldsValue(data)
        form.setFieldValue("registeredName", registeredName)
        console.log("registeredNameccc",registeredName)
        if (kycDetails) {
            setFormValues()
        }
    }, [kycDetails])

    const setFormValues = () => {
        setEnableReset(false)

        const aadhaarNo = kycDetails?.find((i: any) => i.type === "AADHAAR")?.kycId;
        const panNo = kycDetails?.find((i: any) => i.type === "PAN")?.kycId;
        const vatNo = kycDetails?.find((i: any) => i.type === "VAT")?.kycId;
        const cinNo = kycDetails?.find((i: any) => i.type === "CIN")?.kycId;
        const cstNo = kycDetails?.find((i: any) => i.type === "CST")?.kycId;
        const gstNo = kycDetails?.find((i: any) => i.type === "GST")?.kycId;

        form.setFieldValue("aadhaarNo", aadhaarNo)
        form.setFieldValue("panNo", panNo)
        form.setFieldValue("vatNo", vatNo)
        form.setFieldValue("cinNo", cinNo)
        form.setFieldValue("cstNo", cstNo)
        form.setFieldValue("gstNo", gstNo)

    }


    const onCreate = async (values: Values) => {
        console.log("Values received:", values);
        setLoading(true);
      
        const kycTypes = ['aadhaar', 'cin', 'cst', 'gst', 'pan', 'vat'];
        const createKycDtoList: KYCDetails[] = [];
        const formData = new FormData();
        let hasError = false;
      
        kycTypes.forEach((type) => {
          const kycNo = values[`${type}No`];
          const kycFile = values[`${type}File`];
          if (kycNo && !kycDetails?.find((i: any) => i.type === type.toUpperCase())) {
            if (kycFile) {
              createKycDtoList.push({
                type: type.toUpperCase(),
                kycId: kycNo,
              });
              formData.append("files", kycFile);
            } else {
              message.error(`Upload ${type.toUpperCase()} file`);
              hasError = true;
            }
          }
        });
      
        if (hasError) {
          setLoading(false);
          return;
        }
      
        const temp = {
          "clientId": clientID,
          "companyRegisteredName": values.registeredName.label,
          "companyRegisteredId": typeof values.registeredName.key === "string" ? null : values.registeredName.key,
          "createKycDtoList": createKycDtoList
        };
      
        formData.append('details', new Blob([JSON.stringify(temp)], { type: 'application/json' }));
        
        console.log("Payload ready for submission:", temp);
      
        try {
          const res = await instance.post(`clientservice/clients/kyc-registration-details`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          getKycDetails();
          getClientData()
          message.success("Client KYC added successfully!");
          form.resetFields();
          setEditing(false);
        } catch (error) {
          console.error("Failed to submit KYC details:", error);
          message.error("Failed to submit KYC details.");
        } finally {
          setLoading(false);
        }
      }

    const updateKYC = async (values: any) => {
        setLoading(true)

        try {
            // await instance.put(`clientservice/clients/kyc/${data.id}?type=${values.type}&kycId=${values.kycId}`)
            message.success("client KYC updated successfully!")
            setEnableReset(false)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)

    }

    const onSearch = async (value: string, options: any) => {
        value && await instance.get(`clientservice/clients/company-list`, {
            params: {
                filterAnd: `companyRegisteredName|like|${value}`
            }
        }).then((res: any) => {
            const filteredOptions = res.data;
            console.log("filteredOptions", filteredOptions, value.trim(), value  )
            if (filteredOptions.length === 0 && value.trim() !== '') {
                const newItem = {
                    id: value.charAt(0).toUpperCase() + value.slice(1),
                    companyRegisteredName: value.charAt(0).toUpperCase() + value.slice(1)
                }
                const index = options.findIndex((item: any) => typeof (item.id) === "string");
                if (index !== -1) {
                    console.log("updated items,1 ")

                    // If item with id "new" exists, update its value
                    const updatedState = [...options];
                    updatedState[index] = newItem;
                    setOptions(updatedState);
                } else {
                    console.log("updated items, 2")

                    // If item with id "new" doesn't exist, add it to the state
                    setOptions((prevState: any) => [...prevState, newItem]);
                }
            } else if (value === "") {
                console.log("updated items, 3")

                setOptions((prevOptions: any) => {
                    return prevOptions.filter((item: any) => typeof (item.id) !== "string")
                })
            } else {
                console.log("updated items, 4")

                setOptions(filteredOptions)
            }
        }).catch((err: any) => {
            console.log("err", err)
        })
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const debouncedOnFilterSearch = _debounce((value: string, options: any) => {
        onSearch(value, options);
    }, 1000);

    return (
        <div>
            {modalContex}

            {

                <Form
                    form={form}
                    name="kyc-details"
                    layout="vertical"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    // onFinish={kycDetails ? updateKYC : onCreate}
                    onFinish={onCreate}

                    encType='multipart/form-data'
                    autoComplete="off"
                >

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={8}>
                            <Form.Item style={{ marginBottom: 10 }} label="Company's Registered Name"
                                name="registeredName"
                                rules={[{ required: true, message: 'Please input type!' }]} >
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    placeholder="Select a person"
                                    size="large"
                                    onSearch={(value: string) => debouncedOnFilterSearch(value, options)}
                                    labelInValue
                                    filterOption={filterOption}
                                    onChange={(value)=>console.log("value", value)}
                                    options={options?.map((d: any) => ({
                                        value: d.id,
                                        label: d.companyRegisteredName,
                                        // disabled: true
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="AADHAR No."
                                name="aadhaarNo"
                                rules={[{ required: true, message: 'Please input type!' }]} >
                                <Input disabled={kycDetails?.find((i: any) => i.type === "AADHAAR")?.kycId} type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={16}>
                            {kycDetails?.find((i: any) => i.type === "AADHAAR") ? <Card style={{ height: "100%", display: "flex", alignItems: "center", float: "inline-end" }} bordered>

                                <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                                    <FileImageOutlined style={{ fontSize: 24, color: "#2C66E3" }} />
                                    <Space direction="vertical">
                                        <Link style={{ textDecoration: "underline" }} target="_blank" to={""}>View file: {kycDetails?.find((i: any) => i.type === "AADHAAR")?.url.substring("".lastIndexOf('/') + 1)}</Link>
                                    </Space>
                                </div>


                            </Card> : <div style={{ display: "flex", gap: 20, float: "inline-end" }}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    name="aadhaarFile"
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        disabled={!editing}
                                        name='aadhaarFile'
                                        accept="application/pdf"
                                        maxCount={1}
                                        listType="text"
                                        customRequest={dummyRequest}
                                    >
                                        <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 26, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Excel<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                    </Upload>

                                </Form.Item>

                            </div>}

                        </Col>
                        
                        {kycDetails?.find((i: any) => i.type === "AADHAAR") && editing && <Col span={2}>
                            <Button onClick={()=>deleteKyc(kycDetails?.find((i: any) => i.type === "AADHAAR")?.id)} danger icon={<DeleteOutlined />}/>
                        </Col>}
                    </Row>

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="PAN No."
                                name="panNo"
                                rules={[{ required: true, message: 'Please input type!' }]} >
                                <Input disabled={kycDetails?.find((i: any) => i.type === "PAN")} type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={16}>
                            {kycDetails?.find((i: any) => i.type === "PAN") ? <Card style={{ height: "100%", display: "flex", alignItems: "center", float: "inline-end" }} bordered>

                                <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                                    <FileImageOutlined style={{ fontSize: 24, color: "#2C66E3" }} />
                                    <Space direction="vertical">
                                        <Link style={{ textDecoration: "underline" }} target="_blank" to={""}>View file: {kycDetails?.find((i: any) => i.type === "PAN")?.url.substring("".lastIndexOf('/') + 1)}</Link>
                                    </Space>
                                </div>


                            </Card> : <div style={{ display: "flex", gap: 20, float: "inline-end" }}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    name="panFile"
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        disabled={!editing}
                                        name='panFile'
                                        accept="application/pdf"
                                        maxCount={1}
                                        listType="text"
                                        customRequest={dummyRequest}
                                    >
                                        <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 26, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Excel<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                    </Upload>

                                </Form.Item>

                            </div>}

                        </Col>

                        {kycDetails?.find((i: any) => i.type === "PAN") && editing && <Col span={2}>
                            <Button onClick={()=>deleteKyc(kycDetails?.find((i: any) => i.type === "PAN")?.id)} danger icon={<DeleteOutlined />}/>
                        </Col>}
                    </Row>



                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="VAT No."
                                name="vatNo"
                                rules={[{ required: true, message: 'Please input type!' }]} >
                                <Input disabled={kycDetails?.find((i: any) => i.type === "VAT")} type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={16}>
                            {kycDetails?.find((i: any) => i.type === "VAT") ? <Card style={{ height: "100%", display: "flex", alignItems: "center", float: "inline-end" }} bordered>

                                <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                                    <FileImageOutlined style={{ fontSize: 24, color: "#2C66E3" }} />
                                    <Space direction="vertical">
                                        <Link style={{ textDecoration: "underline" }} target="_blank" to={""}>View file: {kycDetails?.find((i: any) => i.type === "VAT")?.url.substring("".lastIndexOf('/') + 1)}</Link>
                                    </Space>
                                </div>


                            </Card> : <div style={{ display: "flex", gap: 20, float: "inline-end" }}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    name="vatFile"
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        disabled={!editing}
                                        name='vatFile'
                                        accept="application/pdf"
                                        maxCount={1}
                                        listType="text"
                                        customRequest={dummyRequest}
                                    >
                                        <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 26, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Excel<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                    </Upload>

                                </Form.Item>

                            </div>}

                        </Col>

                        {kycDetails?.find((i: any) => i.type === "VAT") && editing && <Col span={2}>
                            <Button onClick={()=>deleteKyc(kycDetails?.find((i: any) => i.type === "VAT")?.id)} danger icon={<DeleteOutlined />}/>
                        </Col>}
                    </Row>

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="CIN No."
                                name="cinNo"
                                rules={[{ required: true, message: 'Please input type!' }]} >
                                <Input disabled={kycDetails?.find((i: any) => i.type === "CIN")} type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={16}>
                            {kycDetails?.find((i: any) => i.type === "CIN") ? <Card style={{ height: "100%", display: "flex", alignItems: "center", float: "inline-end" }} bordered>

                                <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                                    <FileImageOutlined style={{ fontSize: 24, color: "#2C66E3" }} />
                                    <Space direction="vertical">
                                        <Link style={{ textDecoration: "underline" }} target="_blank" to={""}>View file: {kycDetails?.find((i: any) => i.type === "CIN")?.url.substring("".lastIndexOf('/') + 1)}</Link>
                                    </Space>
                                </div>


                            </Card> : <div style={{ display: "flex", gap: 20, float: "inline-end" }}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    name="cinFile"
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        disabled={!editing}
                                        name='cinFile'
                                        accept="application/pdf"
                                        maxCount={1}
                                        listType="text"
                                        customRequest={dummyRequest}
                                    >
                                        <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 26, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Excel<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                    </Upload>

                                </Form.Item>

                            </div>}

                        </Col>

                        {kycDetails?.find((i: any) => i.type === "CIN") && editing && <Col span={2}>
                            <Button onClick={()=>deleteKyc(kycDetails?.find((i: any) => i.type === "CIN")?.id)} danger icon={<DeleteOutlined />}/>
                        </Col>}
                    </Row>

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="CST No."
                                name="cstNo"
                                rules={[{ required: true, message: 'Please input type!' }]} >
                                <Input disabled={kycDetails?.find((i: any) => i.type === "CST")} type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={16}>
                            {kycDetails?.find((i: any) => i.type === "CST") ? <Card style={{ height: "100%", display: "flex", alignItems: "center", float: "inline-end" }} bordered>

                                <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                                    <FileImageOutlined style={{ fontSize: 24, color: "#2C66E3" }} />
                                    <Space direction="vertical">
                                        <Link style={{ textDecoration: "underline" }} target="_blank" to={""}>View file: {kycDetails?.find((i: any) => i.type === "CST")?.url.substring("".lastIndexOf('/') + 1)}</Link>
                                    </Space>
                                </div>


                            </Card> : <div style={{ display: "flex", gap: 20, float: "inline-end" }}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    name="cstFile"
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        disabled={!editing}
                                        name='cstFile'
                                        accept="application/pdf"
                                        maxCount={1}
                                        listType="text"
                                        customRequest={dummyRequest}
                                    >
                                        <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 26, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Excel<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                    </Upload>

                                </Form.Item>

                            </div>}

                        </Col>

                        {kycDetails?.find((i: any) => i.type === "CST") && editing && <Col span={2}>
                            <Button onClick={()=>deleteKyc(kycDetails?.find((i: any) => i.type === "CST")?.id)} danger icon={<DeleteOutlined />}/>
                        </Col>}
                    </Row>

                    <Row style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }} gutter={24}>
                        <Col span={6}>
                            <Form.Item label="GST No."
                                name="gstNo"
                                rules={[{ required: true, message: 'Please input type!' }]} >
                                <Input disabled={kycDetails?.find((i: any) => i.type === "GST")} type="text" />
                            </Form.Item>
                        </Col>

                        <Col style={kycDetails && { marginBottom: 10 }} span={16}>
                            {kycDetails?.find((i: any) => i.type === "GST") ? <Card style={{ height: "100%", display: "flex", alignItems: "center", float: "inline-end" }} bordered>

                                <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                                    <FileImageOutlined style={{ fontSize: 24, color: "#2C66E3" }} />
                                    <Space direction="vertical">
                                        <Link style={{ textDecoration: "underline" }} target="_blank" to={""}>View file: {kycDetails?.find((i: any) => i.type === "GST")?.url.substring("".lastIndexOf('/') + 1)}</Link>
                                    </Space>
                                </div>


                            </Card> : <div style={{ display: "flex", gap: 20, float: "inline-end" }}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    name="gstFile"
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        disabled={!editing}
                                        name='gstFile'
                                        accept="application/pdf"
                                        maxCount={1}
                                        listType="text"
                                        customRequest={dummyRequest}
                                    >
                                        <Button disabled={!editing} style={{ display: "flex", alignItems: "center", gap: 26, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Excel<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>
                                    </Upload>

                                </Form.Item>

                            </div>}

                        </Col>

                        {kycDetails?.find((i: any) => i.type === "GST") && editing && <Col span={2}>
                            <Button onClick={()=>deleteKyc(kycDetails?.find((i: any) => i.type === "GST")?.id)} danger icon={<DeleteOutlined />}/>
                        </Col>}
                    </Row>
                </Form>


            }
            <div style={{ display: "flex", justifyContent: "center", marginTop: 36 }}>

                {kycDetails?.length >= 1 && !editing ? <Space size="large">
                    <Button onClick={() => { setEditing(true) }} style={{ width: 200 }} size="large">
                        Edit
                    </Button>
                    <Button onClick={setDefaulttab} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                        Next
                    </Button>
                </Space> :<Space size="large">
                        <Button disabled={kycDetails?.length < 1} onClick={() => { form.resetFields(); setEditing(false) }} style={{ width: 200 }} size="large">
                            Cancel
                        </Button>
                        <Button loading={loading} onClick={() => form.submit()} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                            Add KYC
                        </Button>
                    </Space>
                    }

            </div>
        </div>
    )

}

export default KycForm

// AADHAAR,
// PAN,
// VAT,
// CIN,
// CST,
// GST;