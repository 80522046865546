import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import PocForm from './PocForm';
import { Skeleton } from 'antd';

type FieldType = {
    email?: string;
    name?: string;
    state?: string;
    designation?: string
    phone?: string
};

type PropType = { id: string | undefined, setDefaulttab: Dispatch<SetStateAction<any | null>> }

function PocDetails({ id, setDefaulttab }: PropType) {
    const [pocDetails, setPocDetails] = useState<FieldType[] | []>([])
    const [fetchingDetails, setFetchingDetails] = useState(false)


    useEffect(() => {
        getPocDetails();
    }, [])

    const getPocDetails = async () => {
        setFetchingDetails(true)
        try {
            const pocDetails = await instance.get(`clientservice/clients/${id}/poc`);
            setPocDetails(pocDetails.data)
        } catch (e) {
            console.log(e);
        }
        setFetchingDetails(false)
    }

    return (
        <React.Fragment>
            {!fetchingDetails ? <PocForm id={id} data={pocDetails} setPocDetails={setPocDetails} setDefaulttab={setDefaulttab}  />
                : <><Skeleton /></>}
            {/* <PocForm id={id} setPocDetails={setPocDetails} /> */}
        </React.Fragment>
    )

};

export default PocDetails;