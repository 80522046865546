import React, { useEffect, useState } from "react";
import { Form, Input, Button, DatePicker, Row, Col, message, Select, InputNumber } from "antd";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppSelector } from "../../../app/hooks";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { stopScroll } from "../../../utils/common";
interface FormDetails {
    email: string,
    firstname: string,
    lastname: string,
    roles: string[],
    contact_no: string,
    department: string,
    designation: string,
    location: string,
    date_of_joining: string,
    username: string,
}

const initFormState = {
    email: "",
    firstname: "",
    lastname: "",
    roles: [],
    contact_no: "",
    department: "",
    designation: "",
    location: "",
    date_of_joining: "",
    username: ""
};

const handleChange = (value: string) => {
    console.log(`selected ${value}`);
};

const CreateUser = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [fromData, setFormData] = useState<FormDetails>(initFormState);
    const [role, setRole] = useState<any>([]);
    const auth = useAppSelector(authSelector);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState<any>(null)

    useEffect(() => {   
        fetchData();
    }, [search]);

    const fetchData = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/roles`, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                },
                params: {
                    filterAnd: !search ? "" : `name|like|${search}`
                }
            }).then(res => {
                let input: any = [];
                res.data.map((e: any) => {
                    input.push({
                        label: e.name,
                        value: e.name
                    });
                })
                setRole(input);
            })

        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
    };



        const fetchRoles = async (search: any) => {
//             setLoading(true);
            try {
                const res = await axios.get(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/roles?filterAnd=name|like|${search}`, {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Content-Type": "application/json"
                    }
                })
                const fetchedRoles = res.data.map((e:any)=> ({
                    label: e.name,
                    value: e.name
                }));
                setRole(fetchedRoles);
            } catch (error) {
                console.error('Error fetching roles:', error);
            } finally {
//                 setLoading(false);
            }
        };

    const onFinish = async (values: FormDetails) => {
        setFormData(values);
        const body = {
            firstName: values.firstname,
            lastName: values.lastname,
            emailId: values.email,
            username: values.username,
            role: [values.roles],
            contactNo: values.contact_no,
            department: values.department,
            designation: values.designation,
            location: values.location,
            dateOfJoining: (dayjs(values.date_of_joining).toISOString().split("T")[0]).split("-").join("/")
        }

        try {
            setLoading(true)
            await axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users`, body, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if (res.status === 204 || res.status === 200) {
                    messageApi.success("User added!")
                    setTimeout(() => {
                        navigate("/dashboard/users")
                    }, 1000);
                }
                else
                    messageApi.error("Failed to add user!");
            });
            setLoading(false)
            form.resetFields(["firstname", "lastname", "email", "roles"]);
        } catch (err: any) {
            console.log("Error: ", err);
            // messageApi.error(`${err.response.data.errors[0]}`);
        }
    };


    return (
        <React.Fragment>
            {contextHolder}
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <h4>Create User</h4>
                <Row className="">
                    <Col className="form-column">
                        <Form.Item
                            label="First Name"
                            name="firstname"
                            rules={[{ required: true, message: 'Please enter first name!' }]}
                        >
                            <Input size="large" placeholder="First name" />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="lastname"
                            rules={[{ required: true, message: 'Please enter last name!' }]}
                        >
                            <Input size="large" placeholder="Last name" />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: false, message: 'Please input your email!' }]}
                        >
                            <Input size="large" placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input size="large" placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            label="Designation"
                            name="designation"
                            rules={[{ required: true, message: 'Please enter designation!' }]}
                        >

                            <Select size="large">
                                <Select.Option value="Chief Operations Officer (COO)">Chief Operations Officer (COO)</Select.Option>
                                <Select.Option value="Chief Executive Officer (CEO)">Chief Executive Officer (CEO)</Select.Option>
                                <Select.Option value="Director">Director</Select.Option>
                                <Select.Option value="Chief compliance officer">Chief compliance officer</Select.Option>
                                <Select.Option value="President">President</Select.Option>
                                <Select.Option value="Chief financial officer (CFO)">Chief financial officer (CFO)</Select.Option>
                                <Select.Option value="Chief Technology Officer (CTO)">Chief Technology Officer (CTO)</Select.Option>
                                <Select.Option value="Vice-president">Vice-president</Select.Option>
                                <Select.Option value="Sales Manager">Sales Manager</Select.Option>
                                <Select.Option value="Executive Assistant">Executive Assistant</Select.Option>
                                {/* <Select.Option value="Other">Other</Select.Option> */}
                            </Select>

                        </Form.Item>
                        <Form.Item
                            label="Location"
                            name="location"
                            rules={[{ required: true, message: 'Please enter location!' }]}
                        >
                            <Input size="large" placeholder="Location" />
                        </Form.Item>
                    </Col>
                    <Col className="form-column">
                        <Form.Item
                            label="Date of joining"
                            name="date_of_joining"
                            rules={[{ required: true, message: 'Please enter date of joining!' }]}
                        >
                            <DatePicker
                                size="large"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Role"
                            name="roles"
                            rules={[{ required: true, message: 'Please input user roles!' }]}
                        >
                            <Select
                                showSearch
                                size="large"
                                style={{ width: '100%' }}
                                placeholder="Roles"
                                onChange={handleChange}
                                onSearch={(value)=>setSearch(value)}
                                options={role}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Department"
                            name="department"
                            rules={[{ required: true, message: 'Please enter department!' }]}
                        >
                            <Input size="large" placeholder="Department" />
                        </Form.Item>
                        <Form.Item
                            label="Contact Number"
                            name="contact_no"
                            rules={[{ required: true, message: 'Please input your contact number!' },
                            { pattern: /^[0-9]{10}$/, message: 'Please input a valid contact number!' }
                            ]}
                        >
                            <InputNumber  onFocus={stopScroll} type='number' style={{width: "100%"}} size="large" maxLength={10} placeholder="Contact number" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button loading={loading} size="large" type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};

export default CreateUser;