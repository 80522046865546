import {
  Dropdown,
  Space,
  Typography,
  Avatar,
  MenuProps,
} from 'antd';
import "../styles/Nav.sass"
import { SignOut } from "../utils/common";
import { DownOutlined, UpOutlined, UserOutlined } from '@ant-design/icons';
import { useAppSelector } from '../app/hooks';
import { userSelector } from '../features/auth/userSlice';
import theme from '../utils/theme';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authSelector } from '../features/auth/authSlice';

function Topnav(props: any) {
  const user = useAppSelector(userSelector);
  const navigate = useNavigate()
  const [dropDownOpen, setDropDownOPen] = useState(false)
  const auth = useAppSelector(authSelector);

  const signout = async () => {
    SignOut()
  }

  const items: MenuProps['items'] = [
    // {
    //   key: '1',
    //   label: (
    //     <Typography>{user.name}</Typography>
    //   ),
    // },
    {
      key: '2',
      label: (
        <div onClick={()=>navigate(auth.isAdmin || auth.isMainAdmin ? "/dashboard/admin-profile" : "/dashboard/profile")} style={{ display: "flex", gap: 8 }}>
          <img src="/assets/profile.png" />
          <Typography style={{ color: "#333333", fontWeight: "bold" }}>Profile</Typography>
        </div>
      ),
    },
    {
      key: '1',
      label: (
        <div onClick={signout} style={{ display: "flex", gap: 8 }}>
          <img src="/assets/logout.png" />
          <Typography style={{ color: "#333333", fontWeight: "bold" }}>Log out</Typography>
        </div>
      ),
    },
  ];

  return (
    <div className="top-nav">
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <MenuOutlined className="hamburger" style={{marginRight: "10px"}} onClick={showDrawer} /> */}
        {props.showLogo && <img onClick={()=>navigate("/")} width={166} src={`${__dirname}assets/matrix-logo.png`} alt="" />}
      </div>
      <Dropdown onOpenChange={(open) => {
        setDropDownOPen(open)
      }} menu={{ items }}>
        <Space style={{ marginRight: 20 }}>
          <Avatar shape="square" style={{ borderRadius: 11, background: theme.token.colorPrimary }} size="large" icon={<UserOutlined />} />
          <div style={{display: "flex"}}>
            <Typography.Text ellipsis style={{ fontSize: 16, maxWidth: 150 }}>
              {user.name}
            </Typography.Text>
          </div>
          {!dropDownOpen ? <DownOutlined /> : <UpOutlined />}
        </Space>
      </Dropdown>
    </div>
  );
}

export default Topnav;