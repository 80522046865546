import { Tag, Tooltip, Typography } from 'antd';
import { NominationStatus, NominationStatusDisplay, nominationStatusColors } from '../../utils/healpers/constants';
import dayjs from 'dayjs';
import { ColumnTypes } from './NominationDetail';

export const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [

    {
        title: 'S. no.',
        dataIndex: 's_no',
        key: 'contractId',
        render: (text, record, index) => {
            if(record?.nominationDetails?.length >= 1){
                return         <div style={{ width: 50, display: "inline-block" }}>{index + 1}</div>

            } else {
                return <div style={{ width: 50, display: "inline-block" }}>{index + 1}</div>
            }
        },
        align: "center",
    },
    {
        title: 'Contract ID',
        dataIndex: 'contractId',
        key: 'contractId',
        render: (_,record) => <div style={{ width: 100, display: "inline-block", position: "relative", height: record.nominationDetails?.length < 2 ? 22 : "unset" }}>
            <span style={{ position: record?.nominationDetails?.length < 2 ? "absolute" : "unset", width: "100%", left: 0, zIndex: 1 }}>
                {record.nomination.contractId}
            </span>
            </div>,
        align: "center",
        sorter: true,
    },
    {
        title: 'Gas Day',
        dataIndex: 'gasDay',
        key: 'gasDay',
        render: (value) => <div style={{ width: 100, display: "inline-block", position: "relative", height: 22 }}>
            <span style={{ position: "absolute", width: "100%", left: 0, zIndex: 1 }}>
                {dayjs(value, "DD/MM/YYYY").format("DD MMM YYYY")}
            </span>
        </div>,
        align: "center",
        sorter: true,
    },
    {
        title: 'DCQ',
        dataIndex: 'dcq',
        key: 'dcq',
        render: (_,record) => <div style={{ width: 110, display: "inline-block", position: "relative", height: 22 }}>
                <span style={{ position: "absolute", width: "100%", left: 0, zIndex: 1 }}>
                    {record.nomination.dcq}
                </span>
            </div>,
        align: "center",
        // sorter: true
    },
    {
        title: <>Nominated Qty.<br />(MMBTU)</>,
        dataIndex: 'nominatedQty',
        key: 'nominatedQty',
        render: (value) => <div style={{ width: 150, display: "inline-block", }}>{value ? value : "----"}</div>,
        align: "center",
        // sorter: true

    },
    {
        title: <>Tentative Schedule<br />Qty. (MMBTU)</>,
        dataIndex: 'confirmedQty',
        key: 'confirmedQty',
        render: (value) => <div style={{ width: 150, display: "inline-block", }}>{value ? value : "----"}</div>,
        align: "center",
        // sorter: true
    },

    {
        // @ts-ignore
        title: <div style={{textWrap: "nowrap"}}>Scheduled Qty.<br />(MMBTU)</div>,
        dataIndex: 'scheduledQty',
        key: 'scheduledQty',
        editable: false,
        align: "center",
        render: (value) => <div style={{ width: 150, display: "inline-block", }}>{value ? value : "----"}</div>,
        // sorter: true
    },
    {
        // @ts-ignore
        title: <div style={{textWrap: "nowrap"}}>Allocated Qty.<br />(MMBTU)</div>,
        dataIndex: 'allocatedQty',
        key: 'allocatedQty',
        editable: false,
        align: "center",
        render: (value) => <div style={{ width: 150, display: "inline-block", }}>{value ? value : "----"}</div>,
        // sorter: true
    },
    {
        // @ts-ignore
        title: <div style={{textWrap: "nowrap"}}>Status</div>,
        dataIndex: 'nominationStatus',
        key: 'nominationStatus',
        render: (nominationStatus:
            NominationStatus
            , record: any) => {
                if(record?.nominationDetails?.length > 1){
                    return <div style={{width: 100, display: "inline-block"}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
                } else {
                    const displayValue = NominationStatusDisplay[nominationStatus];
                    return <Tag style={{ fontSize: 14, textAlign: "center" }} color={nominationStatusColors[nominationStatus]}>{displayValue.split(" ")[0]}<br />{displayValue.split(" ")[1]}</Tag>
                }
            },
        align: "center",
        sorter: true

    },
    {
        title: 'Pipeline ID',
        dataIndex: 'pipelineId',
        key: 'pipelineId',
        editable: false,
        align: "center",
        render: (pipelineId, record) => {
            if(record?.nominationDetails?.length > 1){
                return <div style={{minWidth: 173, display: "inline-block",}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
            } else {
                return <div style={{minWidth: 173, display: "inline-block",}}>{pipelineId ? pipelineId : "----"}</div>
            }
        },
        sorter: true,
    },
    {
        title: 'Delivery end point',
        dataIndex: 'deliveryEndPoint',
        key: 'deliveryEndPoint',
        editable: false,
        align: "center",
        render: (deliveryEndPoint:
            any
        , record: any) => {
            if(record?.nominationDetails?.length > 1){
                return <div style={{minWidth: 173, display: "inline-block",}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
            } else {
                return <Tooltip title={deliveryEndPoint}
                ><Typography.Text style={{width: 140, display: "inline-block",}} ellipsis>
                                                {deliveryEndPoint ? deliveryEndPoint : "----"}
                                            </Typography.Text></Tooltip>
            }
        },
        sorter: true,
    },
    // {
    //     dataIndex: 'deliveryId',
    //     editable: false,
    //     align: "center",
    //     hide: true,
    //     render:()=> null
    // },
];