import { PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Input, Table, message, Form, Modal, Space, Typography, Spin } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { instance } from '../../../utils/api';
import type { InputRef } from 'antd';
import type { FormInstance } from 'antd/es/form';
import theme from '../../../utils/theme';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import { getPaginationCountClient } from '../../../utils/common';
const _debounce = require('lodash/debounce');

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

interface Item {
    id: number;
    name: string;
    email: string;
    state: string;
    role: string;
    active: boolean;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
    loadingIndex: number | null
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    loadingIndex,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            if(record.role !== values.role) {
                handleSave({ ...record, ...values });
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input style={{ width: 150 }} ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div style={{ width: 150, border: "solid 1px lightgray", padding: "5px 10px", borderRadius: 12, background: "white", minHeight: 32 }} onClick={toggleEdit}>
                {loadingIndex === record.id && <Spin spinning={true} />} {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    id: number;
    name: string;
    email: string;
    state: string;
    role: string;
    active: boolean;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;


const ClientList: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<DataType[] | []>([]);
    const [modal, modalContex] = Modal.useModal();
    const auth = useAppSelector(authSelector);
    const [loading, setLoading] = useState(false)
    const [loadingIndex, setLoadingIndex] = useState<any>(null)
    const [pagination, setPagination] = useState<any>({current: 1, pageSize: 15})


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async (name?:string) => {
        setLoading(true)
        const params = name && {
            filterAnd: `name|like|${name}`,
        };
        try {
            const response = await instance.get(`/clientservice/clients`, { params });
            setData(response.data.reverse());
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setLoading(false)
    };

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Name',
            dataIndex: 'name',  
            key: 'name',
            render: (name, record) => <Link style={{textDecoration: "underline"}} to={"/dashboard/client-details/" + record.id}>{name}</Link>,
            ellipsis: true
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            editable: auth.isMainAdmin,
        },
        // {
        //     title: 'Active',
        //     dataIndex: 'active',
        //     render: (active) => <Switch defaultChecked={active} />
        // },
    ];

    const handleSave = async (row: DataType) => {
        setLoadingIndex(row.id)
        try {
            await instance.put(`clientservice/clients/${row.id}/update-role?role=${row.role}`)
            message.success("role updated!")
            const newData = [...data];
            const index = newData.findIndex((item) => row.id === item.id);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setData(newData);
        } catch (e) {
            console.log(e)
        }
        setLoadingIndex(null)
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
                loadingIndex
            }),
        };
    });


    const debouncedOnFilterSearch = _debounce((value:string) => {
        fetchData(value);
    }, 1000);

    return (
        <React.Fragment>
            {modalContex}
            <Typography.Title level={4} style={{color: theme.token.colorPrimary, margin: 0}}>Client List</Typography.Title>
            <div className="page-header">
                <Space>
                    <Input.Search 
                        onSearch={(value)=> fetchData(value) } 
                        onChange={(e) => debouncedOnFilterSearch(e.target.value)}
                        placeholder='Enter Client Name' 
                        style={{ width: 296 }} 
                        size='large' 
                    />
                    <Button size='large' type="primary" icon={<PlusOutlined />} onClick={_ => navigate("/dashboard/create-client")}>
                        Add Client
                    </Button>
                </Space>
            </div>
            <Table
                loading={{
                    spinning: loading,
                }}
                components={components}
                rowClassName="table-row"
                columns={columns as ColumnTypes}
                dataSource={data}
                pagination={data.length <= 15 ? false : {
                    pageSize: 15,
                    position: ["bottomCenter"]
                }}
                onChange={(pagination)=>{setPagination(pagination)}}
                footer={()=><div className='footer-pagination'>{data && getPaginationCountClient(data,pagination)}</div>}
                rowKey={(record) => record.id}
                scroll={{ x: 800, y: "65vh" }}
            />
        </React.Fragment>
    )

};

export default ClientList;