import { Button, Divider, Table, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import theme from "../../../utils/theme";
import { getPaginationCountApi } from "../../../utils/common";
import { columns } from "../../../interfaces/TableColumns/Enquiries";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import EmptyEnquiry from "./EmptyEnquiry";
import { instance } from "../../../utils/api";
import { dashboardSelector } from "../../../features/auth/dashboardSlice";

interface Item {
    id: number;
    applicationDate: string,
    dcq: number,
    nominatedQty: number,
    confirmedQty: number | null,
    deliveryEndPoint: string,
    deliveryId: string,
    sourceId: string,
    pipelineId: string,
    status: string,
    enquiryId: string,
    notification: string,
    lastUpdatedBy: string,
    enquiryDetails: any,
    updatedByUserType: string
}



export default function EnqiuryListing() {
    const navigate = useNavigate()
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [data, setData] = useState<any>([]);
    const { tableParams, handleSort } = useTableFilters("auditInfo.creationDate|DESC", '', false, false, true);
    const dashboard = useAppSelector(dashboardSelector);  

    useEffect(() => {
        fetchDetails()
        return () => {
            const layoutElement = document.querySelector('.ant-layout-content') as HTMLElement;
            if (layoutElement) {
                // Apply CSS styles to the element
                layoutElement.style.background = '#F5FFFB';
                layoutElement.style.border = '1px solid #E2E2E2';
            }
        };
    }, [tableParams]);


    const fetchDetails = async () => {
        const params = {
            ...tableParams,
            sort: tableParams.sort != undefined ? tableParams.sort : "auditInfo.creationDate|DESC",
            filterAnd: "status|notLike|CONTRACT_CREATED"
        }
        setLoadingDetails(true)
        try {

            const res: any = await instance.get("/clientservice/clients/enquiry", {params})

            console.log("res", res)
            if(res.data.items.length === 0) {
                const layoutElement = document.querySelector('.ant-layout-content') as HTMLElement;
                if (layoutElement) {
                    // Apply CSS styles to the element
                    layoutElement.style.background = 'white';
                    layoutElement.style.border = 'none';
                }
            }
            setData(res.data)
        }
        catch (e) {
            console.log(e)
        }
        setLoadingDetails(false)
    }

    const getUniqueItems = (array: any) => {
        const uniqueItems: any = {};

        // Iterate through the array
        array?.forEach((item: any) => {
            // Check if the enquiryId already exists in the uniqueItems object
            if (!uniqueItems[item.enquiryId]) {
                // If it doesn't exist, add the item to the uniqueItems object
                uniqueItems[item.enquiryId] = item;
            }
        });

        // Extracting the unique items from the object and converting it back to an array
        const uniqueItemsArray = Object.values(uniqueItems);

        return uniqueItemsArray

    }

    return (
        data?.items?.length === 0 ? <EmptyEnquiry /> : <>
            {dashboard?.stats?.contracts?.length>0 ? <><Typography.Title level={4} style={{ color: theme.token.colorPrimary, margin: 0 }}>All Enquiries</Typography.Title>
            <Typography.Text style={{ color: "#6B6B6B", fontWeight: 500 }}>Client Code: {data?.items?.[0]?.client?.clientCode}</Typography.Text>
            </>
                :
            <div style={{ textAlign: "center" }}>
                <Typography.Title level={4} style={{ color: theme.token.colorPrimary, marginTop: 20 }}>Contract Proposals</Typography.Title>
                <Typography.Text style={{ fontSize: 21, color: "#6B6B6B", fontWeight: 500 }}>Client Code: {data?.items?.[0]?.client?.clientCode}</Typography.Text>
                <Divider />
            </div>}

            <div style={{ display: "flex", flexDirection: "column", gap: 40, marginTop: 20, alignItems: "center" }}>
                {/* <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "baseline" }}>
                    
                        <FilterSelect onFilerSearch={onFilerSearch} options={getUniqueItems(options)} onSearch={onSearch} />
                    
                </div> */}
    
                <Table
                    style={{width: "100%"}}
                    loading={loadingDetails}
                    onChange={handleSort}
                    rowClassName="table-row"
                    columns={columns}
                    dataSource={data?.items}
                    pagination={{
                        pageSize: tableParams.size,
                        total: data?.totalItems,
                        current: tableParams.page,
                        showSizeChanger: false,
                        showQuickJumper: false,
                        position: ["bottomCenter"],
                        showPrevNextJumpers: false,
                    }}
                    footer={()=><div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                    rowKey={(record) => record.id}
                    scroll={{
                        x: true
                        , y: data?.totalItems > 0 ? "70vh" : undefined
                    }}
                    showHeader={!data?.items || data?.items?.length === 0 ? false : true}
                    // locale={loadingDetails ? undefined : { emptyText: <EmptyList customMessage={<>You have no Enquiries</>} /> }}
                />

                    {dashboard?.stats?.contracts?.length==0 && <Button onClick={()=>navigate("/dashboard/create-enquiry")} style={{width: 300}} size="large" type="primary">Add New Enquiry</Button>}

            </div>
            

        </>
    )
}