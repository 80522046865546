import { Menu, MenuProps, Modal, message } from 'antd';
import { useNavigate, useLocation } from "react-router";
import "../styles/Nav.sass"
import {
  UserOutlined,
} from '@ant-design/icons';
import { useAppSelector } from '../app/hooks';
import { authSelector } from '../features/auth/authSlice';
import { useEffect, useState } from 'react';
import { dashboardSelector } from '../features/auth/dashboardSlice';
import { fetchStats, handleMenuItemClick } from '../utils/common';

type MenuItem = Required<MenuProps>['items'][number];

// type StatsType = {
//   countNominatedQty: number
//   countConfirmedQty: number
//   countScheduledQty: number
//   countAllocatedQty: number
//   countIntraDayQty: number
// } | null

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

function Sidenav() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlKey = location.pathname.split("/")[2];
  const auth = useAppSelector(authSelector);
  const dashboard: any = useAppSelector(dashboardSelector)
  const [allowNavigation, setAllowNavigation] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, modalContex] = Modal.useModal();
  const [cancelled, setCancelled] = useState(false)

  const items: MenuItem[] = [
  ];

  if (auth.isClient) {
    items.push(getItem('Nomination Management', 'gas-operations', <div><img width={21} className="inactive" src="/assets/nomination-entry-icon.svg" /><img width={21} className="active" src="/assets/nomination-entry-icon-active.svg" /></div>, [
      getItem(<>Nomination Entry</>, 'confirmation'),
      getItem(<>Nomination Workflow</>, 'nomination-workflow'),
    ]),
      getItem('Workflow Report', 'workflow-report', <div><img width={20} className="inactive" src="/assets/nomination-workflow-icon.svg" /><img width={20} className="active" src="/assets/nomination-workflow-icon-active.svg" /></div>),
      getItem('Contract Details', 'contract-details', <div><img width={21} className="inactive" src="/assets/nomination-entry-icon.svg" /><img width={21} className="active" src="/assets/nomination-entry-icon-active.svg" /></div>, [
        getItem(<>Create New Enquiry</>, 'create-enquiry'),
        getItem(<>All Enquiries</>, 'enquiry'),
        getItem(<>Contract History</>, 'contract-history'),
      ]),

    )
  }
  // UNCOMMENT TO ADD THE CONITIONAL RENDERING IAM MENU
  if (auth.isAdmin || auth.isMainAdmin) {
    console.log(items, "items")
    items.push(getItem('Gas Operations', 'gas-operations', <div><img width={21} className="inactive" src="/assets/nomination-icon.svg" /><img width={21} className="active" src="/assets/nomination-icon-active.svg" /></div>, [
      getItem(<>Tentative Schedule</>, 'confirmation', <span id="nav-stats">{dashboard.stats?.countConfirmedQty}</span>),
      getItem(<>Schedule</>, 'scheduled-list', <span id="nav-stats">{dashboard.stats?.countScheduledQty}</span>),
      getItem(<>Allocation</>, 'allocation', <span id="nav-stats">{dashboard.stats?.countAllocatedQty}</span>),
      getItem(<>Intra-day</>, 'intra-day', <span id="nav-stats">{dashboard.stats?.countIntraDayQty}</span>),
    ]),
      getItem('Registrations', 'registrations', <div><img width={20} className="inactive" src="/assets/nomination-history-icon.svg" /><img width={20} className="active" src="/assets/nomination-history-icon-active.svg" /></div>),
      getItem('Nomination History', 'nomination-history', <div><img width={20} className="inactive" src="/assets/nomination-history-icon.svg" /><img width={20} className="active" src="/assets/nomination-history-icon-active.svg" /></div>),
      getItem('Invoice & Joint Ticket', 'joint-ticket', <div><img width={20} className="inactive" src="/assets/invoice-icon.svg" /><img width={20} className="active" src="/assets/invoice-icon-active.svg" /></div>),
      getItem('Running Contracts', 'running-contracts', <div><img width={15} className="inactive" src="/assets/running-contracts-icon.png" /><img width={15} className="active" src="/assets/running-contracts-icon-active.png" /></div>),
      getItem('Client Details', 'client-details', <div><img width={20} className="inactive" src="/assets/client-icon.svg" /><img width={20} className="active" src="/assets/client-icon-active.svg" /></div>),

    )

  }
  if (auth.isFinance && !items.find((i: any) => i.label === "Invoice")) {
    items.push(
      getItem('Invoice & Joint Ticket', 'joint-ticket', <div><img width={20} className="inactive" src="/assets/invoice-icon.svg" /><img width={20} className="active" src="/assets/invoice-icon-active.svg" /></div>),)
  }
  if (auth.isMainAdmin) {
    // items.push(
    //   getItem('Client Details old', 'client-list', <div><img width={20} className="inactive" src="/assets/client-icon.svg" /><img width={20} className="active" src="/assets/client-icon-active.svg" /></div>),
    // )
    items.push(getItem('IAM', 'iam-management', <UserOutlined />, [
      getItem('Users', 'users'),
      getItem('Roles', 'add-role'),
    ]))
  }


  useEffect(() => {
    !auth.isClient && fetchStats()
  }, [])



  return (
    <div style={{ paddingLeft: 20 }}>
      {contextHolder}
      {modalContex}
      <img onClick={()=>navigate("/")} style={{ marginBottom: 20, marginTop: 10 }} width={166} src={`${__dirname}assets/matrix-logo.png`} alt="" />
      <Menu
        defaultOpenKeys={["gas-operations"]}
        className="sidenav"
        onClick={(e) => handleMenuItemClick(e.key.toString(), dashboard, modal, navigate, messageApi, setAllowNavigation, location, cancelled, setCancelled)}
        theme='light'
        defaultSelectedKeys={allowNavigation ? [urlKey] : []}
        selectedKeys={allowNavigation ? [urlKey] : [location.pathname.split("/")[2]]}
        mode="inline" items={items} />
    </div>
  );
}

export default Sidenav;