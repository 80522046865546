import { Breadcrumb, Button, Space, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import ClientDetails from './ClientDetails';
import PocDetails from './PocDetails';
import ContractDetails from './ContractDetails';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import theme from '../../../utils/theme';
import { instance } from '../../../utils/api';
import KycDetails from './KycDetails';
import BankDetails from './BankDetails';

function CreateClient() {
    let { id } = useParams();
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab")

    const [defaultTab, setDefaultTab] = useState<string>("")
    const [clientData, setClietData] = useState<any>(undefined)
    const [fetchingDetails, setFetchingDetails] = useState(false)
    const [bankDetails, setBankDetails] = useState([])
    const [lastOpened, setlastOpened] = useState(1)
    const navigate = useNavigate();

    const getClientDetails = async () => {
        setFetchingDetails(true)
        const res = await instance.get(`clientservice/clients/${id}`);
        setBankDetails(res.data.bankDetails)
        setFetchingDetails(false)

        setClietData(res.data)
    }

    const items: TabsProps['items'] = [
        {
            key: 'client-details-1',
            label: 'Client Details',
            children: <ClientDetails getClientData={getClientDetails} fetchingDetails={fetchingDetails} clientData={clientData} setClientData={setClietData} setDefaulttab={()=>setDefaultTab("client-details-2")} />,
        },
        {
            key: 'client-details-2',
            label: 'POC Details',
            children: <PocDetails id={id} setDefaulttab={setDefaultTab} />,
            disabled:  !(parseInt(defaultTab?.split("-")[2]) >= 2 || 2 <= lastOpened),
        },
        {
            key: 'client-details-3',
            label: 'KYC Details',
            children: <KycDetails registeredName={clientData?.companyRegisteredId ? {
                "label": clientData.companyRegisteredName,
                "value": clientData.companyRegisteredId,
                "key": clientData.companyRegisteredId
            } : null} getClientData={getClientDetails} id={id} setDefaulttab={()=>setDefaultTab("client-details-4")} />,
            disabled:  !(parseInt(defaultTab?.split("-")[2]) >= 3 || 3 <= lastOpened) 

        },
        {
            key: 'client-details-4',
            label: 'Bank Details',
            children: <BankDetails bankDetails={bankDetails} id={id} setDefaulttab={()=>setDefaultTab("client-details-5")} />,
            disabled:  !(parseInt(defaultTab?.split("-")[2]) >= 4 || 4 <= lastOpened) 
        }, 
        {
            key: 'client-details-5',
            label: 'Contract Related Details',
            children: <ContractDetails id={id} setDefaulttab={()=>setDefaultTab("")} />,
            disabled:  !(parseInt(defaultTab?.split("-")[2]) >= 5 || 5 <= lastOpened) 
        },  
    ];

    useEffect(() => {
          console.log(tab, "tab")
          setDefaultTab(tab ? tab : "client-details-1")
      }, [tab]);

      useEffect(() => {
        if(id){
            getClientDetails() 
        } else {
            setClietData(undefined)
        }
      }, [id])

      useEffect(() => {
        if(parseInt(defaultTab?.split("-")[2]) > lastOpened){
            setlastOpened(parseInt(defaultTab?.split("-")[2]))
        }
      }, [defaultTab])
      
      

    return (
        <React.Fragment>
            {clientData?.name && <Breadcrumb separator=">" style={{ marginBottom: 16, cursor: "pointer" }}>
                <Breadcrumb.Item 
                    onClick={_=>navigate(`/dashboard/client-list`)}
                >
                    <span style={{fontWeight: 600}}>
                        Client list
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{fontWeight: 600}}>
                        {clientData?.name}
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>}
            <Space>
                <Button onClick={()=>navigate("/dashboard/client-list")} size="large" style={{padding: 0, marginRight: 30}} type="text"><ArrowLeftOutlined /></Button>
                <Typography.Text style={{fontSize: 24, color: theme.token.colorPrimary}} strong>{clientData?.name ? clientData?.name : "Create Client"}</Typography.Text>
            </Space>
           {defaultTab !==null && <Tabs onTabClick={(tab)=>setDefaultTab(tab)} style={{marginTop: 50}} activeKey={`${defaultTab}`} items={items} />}
        </React.Fragment>
    )

};

export default CreateClient;