import { Breadcrumb, Button, Modal, Skeleton, Space, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import ContractDetails from './ContractDetails';
import { ArrowDownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { statusStyle, tagStyle } from '../Client-Enquiry/EnuiryDetails';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import theme from '../../../utils/theme';
import TabHeader from '../Client-Profile/TabHeader';
import ClientDetails from '../Client-Profile/ClientDetails';
import KycDetails from '../Client-Profile/KycDetails';
import BankDetails from '../Client-Profile/BankDetails';
import PocDetails from '../Client-Profile/PocDetails';
import { showSuccess } from '../../../utils/common';
import { instance } from '../../../utils/api';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import { EnquiryStatus } from '../../../utils/healpers/constants';
import UploadContract from './UploadContract';

function RegistrationsDetails() {
    const auth = useAppSelector(authSelector);

    let { id } = useParams();
    // let { state, pathname } = useLocation();
    const navigate = useNavigate()
    const [modal, modalContex] = Modal.useModal();
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");
    const [defaultTab, setDefaultTab] = useState<string>("contract-details-5")
    const [clientData, setClietData] = useState<any>(undefined)
    const [fetchingDetails, setFetchingDetails] = useState(false)
    const [bankDetails, setBankDetails] = useState([])
    const [tabStates, setTabStates] = useState({
        isDetailsCompleted: true,
        isKycCompleted: true,
        isBankDetailsCompleted: false,
        isPocCompleted: false,
        isDocumentsUploaded: false,
    })
    const [state, setState] = useState<any>({})
    const [loadingState, setLoadingState] = useState(true)

    useEffect(() => {
        fetchState()
    }, [])

    const fetchState = async () => {
        setLoadingState(true)
        const params = {
            filterAnd: `id|eq|${id}`
        }
        try {
            const res: any = await instance.get("/clientservice/clients/enquiry", {params})
            if(res?.data?.items[0]?.status === "CONTRACT_CREATED"){
                navigate("/dashboard/running-contracts")
            }

            if(res?.data?.items[0]){
                setState({
                    ...res?.data?.items[0], 
                    createDeliveryDtoList: res?.data?.items[0]?.deliveryEndPoints,
                    status: res?.data?.items[0]?.adminStatus,
                    name: res?.data?.items[0]?.client?.name,
                    clientCode: res?.data?.items[0]?.client?.clientCode,
                    clientId: res?.data?.items[0]?.client?.id
                })
            }
            setLoadingState(false)

            
        } catch(e) {
            setLoadingState(false)

            console.log(e)
        }
    }


    const approveOrReject = async (type: string, updateFunction?: any) => {
        modal.confirm({
            title: <div>
                Do you want to save all the changes?
            </div>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            centered: true,
            cancelText: 'No',
            icon: null,
            onOk: async () => {

                try {
                    const res = await instance.post(`/clientservice/clients/approve-or-reject?clientId=${clientData.id}&type=${type}`)
                    getClientDetails()
                    updateFunction()
                    Modal.destroyAll()
                    showSuccess(<>Changes Updated</>, Modal)
                } catch (e) {
                    console.log(e)
                }
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })

    }

    useEffect(() => {
        if (state?.clientId) {
            getClientDetails()
        }
    }, [state?.clientId])

    const getClientDetails = async () => {
        setFetchingDetails(true)
        try {
            const res = await instance.get(`clientservice/clients/${state?.clientId}`);
            setBankDetails(res.data.bankDetails)
            setTabStates({ ...tabStates, isBankDetailsCompleted: res.data.bankDetails.length > 0, isKycCompleted: !!res.data?.companyRegisteredId })
            setFetchingDetails(false)

            setClietData(res.data)
        } catch (e) {
            console.log(e)
            setFetchingDetails(false)

        }

    }


    const items: TabsProps['items'] = [
        {
            key: 'client-details-1',
            label: <TabHeader 
                showUpdateStatus={clientData?.addressUpdateStatus} 
                completed={tabStates.isDetailsCompleted} 
                isOpen={defaultTab === "client-details-1"} 
                title="Company Details" 
                status="Completed" 
                showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")} 
            />,
            children: <ClientDetails 
                updateStatus={clientData?.addressUpdateStatus} 
                approveOrReject={approveOrReject} 
                id={state?.clientId} 
                getClientData={getClientDetails} 
                fetchingDetails={fetchingDetails} 
                clientData={clientData} 
                setClientData={setClietData} 
                setDefaulttab={() => setDefaultTab("client-details-2")}
            />,
        },
        {
            key: 'client-details-2',
            label: <TabHeader showUpdateStatus={clientData?.kycUpdateStatus} completed={tabStates.isKycCompleted} isOpen={defaultTab === "client-details-2"}
                title="Registration Details" status="Completed" showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")} />,
            children: <KycDetails approveOrReject={approveOrReject} updateStatus={clientData?.kycUpdateStatus} clientData={clientData} registeredName={clientData?.companyRegisteredId ? {
                "label": clientData.companyRegisteredName,
                "value": clientData.companyRegisteredId,
                "key": clientData.companyRegisteredId
            } : null} getClientData={getClientDetails} id={state?.clientId} setDefaulttab={() => setDefaultTab("client-details-3")} />,
        },
        {
            key: 'client-details-3',
            label: <TabHeader showUpdateStatus={clientData?.bankUpdateStatus} completed={tabStates.isBankDetailsCompleted} isOpen={defaultTab === "client-details-3"}
                title="Account Details" status="Completed" showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")} />,
            children: <BankDetails approveOrReject={approveOrReject} updateStatus={clientData?.bankUpdateStatus} clientData={clientData} bankDetails={bankDetails} getClientData={getClientDetails} id={state?.clientId} setDefaulttab={() => setDefaultTab("client-details-4")} />,
        },
        {
            key: 'client-details-4',
            label: <TabHeader showUpdateStatus={clientData?.pocUpdateStatus} completed={tabStates.isPocCompleted} isOpen={defaultTab === "client-details-4"}
                title='POC Details' status="Completed" showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")} />,
            children: <PocDetails approveOrReject={approveOrReject} updateStatus={clientData?.pocUpdateStatus} setTabStates={setTabStates} getClientDetails={getClientDetails} clientData={clientData} id={state?.clientId} setDefaulttab={() => setDefaultTab("client-details-5")} />,
        },
        {
            key: 'contract-details-5',
            label: [EnquiryStatus.CONTRACT_NOT_SENT, EnquiryStatus.CONTRACT_REGISTRATION_PENDING, EnquiryStatus.SIGNED_DOCUMENT_PENDING].includes(state.status) ? "Contract Details" : 'Enquiry Details',
            children: loadingState ? <Skeleton /> : <ContractDetails state={state} setDefaultTab={setDefaultTab} />,
        },
    ];

    if (state?.status === EnquiryStatus.CONTRACT_NOT_SENT) {
        items.push({
            key: 'client-details-6',
            label: <TabHeader showUpdateStatus={clientData?.pocUpdateStatus} completed={tabStates.isPocCompleted} isOpen={defaultTab === "client-details-4"}
            title='Upload PDF' status="Completed" showStatus={auth.isClient && (clientData?.registrationStatus === "KYC_PENDING" || clientData?.registrationStatus === "SIGNED_DOCUMENT_PENDING")} />,
            children: <UploadContract state={state} setDefaultTab={setDefaultTab}  />,
            disabled: true
        },)
    }


    return (
        <React.Fragment>
            <>
                {<Breadcrumb separator=">" style={{ marginBottom: 20, cursor: "pointer" }}>
                    <Breadcrumb.Item
                        onClick={_ => navigate(`/dashboard/registrations`)}
                    >
                        <span style={{ fontWeight: 600 }}>
                            Registration
                        </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span style={{ fontWeight: 600 }}>
                            View Details
                        </span>
                    </Breadcrumb.Item>
                </Breadcrumb>}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Space>

                        <Button onClick={() => navigate(-1)} size="large" style={{ padding: 0, marginRight: 20 }} type="text"><ArrowLeftOutlined /></Button>
                        <div>
                            <Typography.Text style={{ fontSize: 24, color: theme.token.colorPrimary }} strong>{state.name}</Typography.Text><br />
                            <Typography.Text style={{ fontSize: 21, color: "#6B6B6B" }}>Client Code: {state.clientCode}</Typography.Text>

                        </div>

                    </Space>
                    {clientData?.signedDocumentUrl && <Link to={clientData?.signedDocumentUrl} target="_blank">
                        <Button  style={{ display: "flex", alignItems: "center", gap: 8, width: "fit-content", paddingInline: 20 }} className='reset-button-calendar' size="large">
                            <img width={25} src="/assets/pdf-icon.png" />
                            <Typography.Text style={{ fontSize: 18, color: theme.token.colorPrimary }} underline>View Registration Summary</Typography.Text>
                        </Button>
                    </Link>}
                </div>

            </>
            {<Tabs style={{ marginTop: 40 }} items={items} activeKey={`${defaultTab}`} onTabClick={(tab) => setDefaultTab(tab)} />}
        </React.Fragment>
    )

};

export default RegistrationsDetails;