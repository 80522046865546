import { Button, Divider, Form, Modal, Typography, Upload } from "antd";
import { cardStyle } from "../Client-Enquiry/EnuiryDetails";
import { UploadOutlined } from "@ant-design/icons";
import theme from "../../../utils/theme";
import { instance } from "../../../utils/api";
import { beforeUpload, showSuccess } from "../../../utils/common";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

export default function UploadContract({ setDefaultTab }:any) {

    const [form] = Form.useForm()
    const [modal, modalContex] = Modal.useModal();
    const navigate = useNavigate()
    let { id } = useParams();
    const [enableSubmit, setEnableSubmit] = useState(false)
    // const [showUploader, setShowUploader] = useState(false);


    const sendConract = async () => {
        modal.confirm({
            title: <>Do you want to proceed and<br />Submit the PDF?</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            icon: null,
            onOk: async () => {
                try {
                    const formValues = form.getFieldsValue();
                    console.log(formValues);

                    const formData = new FormData();

                    formData.append("file", formValues["file"]);
                    formData.append("enquiryId", id as any)

                    // setLoading(true)
                    await instance.post(`clientservice/clients/enquiry/send-contract`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    showSuccess(<>Contract successfully sent, please wait for the client to verify the same.</>, Modal)
                    navigate(-1)

                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })
    }

    const handleFormChange = () => {
        const formValues = form.getFieldsValue()
        console.log(formValues)
        if (
            !!formValues.file
        ) {
            setEnableSubmit(true)
        } else {
            setEnableSubmit(false)
        }

    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return <>
        <div style={{ ...cardStyle, border: "none", display: "block" }}>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        <Typography.Title level={3}>
                            Please upload contract in PDF format.
                        </Typography.Title>
                        <Divider />
                    </div>


                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 40px" }}>
                        <div>
                            <Typography.Text style={{ fontSize: 18 }}>Upload signed <br />Stamped Contract Details</Typography.Text>
                        </div>
                        <div>
                            <Form form={form} layout="vertical">
                                <Form.Item
                                    style={{ margin: 0 }}
                                    name="file"
                                    rules={[{ required: true, message: "Please enter file (less than 5MB)" }]}
                                    getValueFromEvent={({ file }) => { return file.originFileObj }}
                                >

                                    <Upload
                                        onChange={handleFormChange}
                                        name='file'
                                        accept="application/pdf"
                                        maxCount={1}
                                        listType="text"
                                        customRequest={dummyRequest}
                                        beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields(["file"]))}
                                    >
                                        <Button
                                            style={{ display: "flex", alignItems: "center", gap: 26, height: "auto", justifyContent: "space-between" }}
                                            type="dashed" size="large"
                                            icon={<img src="/assets/upload.png" width="95%" />}
                                        >
                                            <span style={{ textAlign: "left", marginBlock: 10 }}>Upload Contract<br />
                                                <Typography.Text type="secondary">File type: PDF</Typography.Text >
                                            </span>
                                            <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} />
                                        </Button>
                                    </Upload>

                                </Form.Item>
                            </Form>
                        </div>
                    </div>

                    <div style={{ textAlign: "center", marginTop: 48, display: "flex", justifyContent: "center", gap: 20 }}>
                        <Button style={{ borderColor: theme.token.colorPrimary, color: theme.token.colorPrimary, width: 200 }} onClick={() => setDefaultTab("contract-details-5")} size="large" type="default">
                            Back
                        </Button>
                        <Button disabled={!enableSubmit} style={{ width: 200 }} size="large" type="primary" onClick={() => sendConract()}>
                            Submit
                        </Button>
                    </div>

                </div>
                {modalContex}
    </>
}