import { Dispatch, SetStateAction, useState } from "react";
import { instance } from "../api";
import { Modal } from "antd";
import { showSuccess } from "../common";
const _ = require('lodash');

export function useNotify(orderType: string,gasDay: boolean, setData?: Dispatch<SetStateAction<any | null>>, notifyFor?: string, disabledFor?: string[]) {

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [sendingNotifications, setSendingNotification] = useState(false);
    const [openNotify, setOpenNotify] = useState(false)

    const closeNotify = () => {
        setOpenNotify(false);
        setSelectedRowKeys([]);
    }


    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        getCheckboxProps: (record: any) => {
            if (disabledFor) {
                return {
                    disabled: notifyFor && !(record[notifyFor] && record[disabledFor[0]] && record[disabledFor[1]] && record[disabledFor[2]]) ? true : false
                };

            } else {
                return {
                    disabled: notifyFor && !record[notifyFor] ? true : false
                };
            }

        },
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const notifyClients = async (fetchDetails:()=>void) => {
        setSendingNotification(true)
        console.log("selected ckeys", selectedRowKeys)
        const resultArray = selectedRowKeys.map((item:any) => typeof(item) === "string" ? item.replace(/\D/g, '') : item);
        const uniqueArr =  _.uniq(resultArray)

        try {
            await instance.post(`orderservice/nominate/${gasDay ? "details/" : ""}notify?orderType=${orderType}&ids=` + uniqueArr.join(','));
            // message.success('Notifications sent successfully');
            fetchDetails()

            showSuccess(<>Thanks !!<br/>The confirmation has been sent to the client.</>, Modal)
            closeNotify()
            // set stataus of selected keys to notified by setData

            setSelectedRowKeys([]);
        } catch (error) {
            console.log(error)
        }
        setSendingNotification(false)
    }


    return {
        notifyClients,
        rowSelection,
        hasSelected,
        sendingNotifications,
        openNotify,
        closeNotify,
        setOpenNotify
    }
}