// app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import reducers from '../features';
import authReducer from "../features/auth/authSlice";
import userReducer from "../features/auth/userSlice";
import dashboardReducer from "../features/auth/dashboardSlice";
import utilsReducer from "../features/utils/utilsSlice";
import appReducer from '../features/app/appSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user', 'utils'],
};

const rootReducer = combineReducers({
  ...reducers,
  auth: authReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  app: appReducer,
  utils: utilsReducer,
});


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
