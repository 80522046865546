import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import KycForm from './KycForm';
import { Skeleton } from 'antd';


function KycDetails({ id, registeredName, setDefaulttab, getClientData }: { id: string | undefined, registeredName: any, setDefaulttab: Dispatch<SetStateAction<any | null>>, getClientData:any }) {

    const [kycDetails, setKycDetails] = useState<[]>()
    const [fetchingDetails, setFetchingDetails] = useState(false)


    useEffect(() => {
        getKycDetails()
    }, [])


    const getKycDetails = async () => {
        setFetchingDetails(true)
        try {
            const res = await instance.get(`clientservice/clients/${id}/kyc`);
            setKycDetails(res.data)
        } catch (e) {
            console.log(e);
        }
        setFetchingDetails(false)

    }

    return (
        <React.Fragment>
            {!fetchingDetails 
                ? <KycForm getClientData={getClientData} registeredName={registeredName} clientID={id} getKycDetails={getKycDetails} kycDetails={kycDetails} setDefaulttab={setDefaulttab} /> : <Skeleton />}
            
        </React.Fragment>
    )

};

export default KycDetails;