import { Breadcrumb, Button, Card, Modal, Table, Typography } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import theme from "../../../utils/theme";
import { getPaginationCountApi } from "../../../utils/common";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { columns } from "../../../interfaces/TableColumns/ContractListClientDetails";
import ContractDetailsModal from "../Client-Enquiry/ContractDetailsModal";

interface Item {
    id: number;
    gasDay: string,
    dcq: number,
    nominatedQty: number,
    confirmedQty: number | null,
    deliveryEndPoint: string,
    deliveryId: string,
    sourceId: string,
    pipelineId: string,
    status: string,
    contractId: string,
    notification: string,
    lastUpdatedBy: string,
    nominationDetails: any,
    updatedByUserType: string
}



export default function ClientContractList() {
    let { state } = useLocation();
    let { id } = useParams();


    const navigate = useNavigate()
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [data, setData] = useState<any>([]);
    const { tableParams, handleSort, pagination } = useTableFilters("contractUpdateDate|DESC", '', false, false, true);
    const [selectedData, setSelectedData] = useState(null)


    const [isModalOpen, setIsModalOpen] = useState(false);



    useEffect(() => {
        fetchDetails()
    }, [tableParams]);


    const fetchDetails = async () => {
        const params = {
            ...tableParams,
            filterAnd: `clientId|eq|${id}`
        }
        setLoadingDetails(true)
        try {
            const res = await instance.get(`orderservice/nominate/client-nomination-view`, { params });
            setData(res.data)
        }
        catch (e) {
            console.log(e)
        }
        setLoadingDetails(false)
    }
    const showModal = (record: any) => {
        setSelectedData(record)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 40}}>
            <Breadcrumb separator=">" style={{ marginBottom: 0, cursor: "pointer" }}>
                <Breadcrumb.Item
                    onClick={_ => navigate("/dashboard/client-details")}
                >
                    <span style={{ fontWeight: 600 }}>
                        Client Details
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link
                        to={``}
                        style={{color: theme.token.colorPrimary}}
                    >
                        <span style={{ fontWeight: 600 }}>
                            {state?.name ? state?.name : data?.items?.[0]?.name}
                        </span>
                    </Link>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Button size="large" type="primary" onClick={()=>navigate(`/dashboard/create-contract/${id}`)}><PlusOutlined />New Contract</Button>
            </div>

            <Card>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div>
                        <Typography.Title style={{fontSize: 24, margin: 0, color: theme.token.colorPrimary}}>{state?.name ? state?.name : data?.items?.[0]?.name}</Typography.Title>
                        <Typography.Text style={{fontSize: 18, color: "#959595"}}>{state?.clientCode ? state?.clientCode : data?.items?.[0]?.clientCode}</Typography.Text>
                    </div>
                    <div>
                        <Link to={`/dashboard/profile/${id}`} style={{textDecoration: "underline", fontSize: 18,color: theme.token.colorPrimary}}>View Client Profile</Link>
                    </div>
                </div>

            </Card>

            <div style={{ display: "flex", flexDirection: "column", gap: 40, marginTop: 20 }}>

                <Table
                    loading={loadingDetails}
                    onChange={handleSort}
                    rowClassName="table-row"
                    columns={[...columns, {
                        title: 'Action',
                        key: 'action',
                        render: (record: any) =>
                           record?.details && <Link to=""
                                style={{ textDecoration: "underline", width: 90, display: "block" }}
                                onClick={() => showModal(record)}
                            >
                                View Details
                            </Link>,
                        align: "center"
                    }] as any}
                    dataSource={data?.items}
                    pagination={{
                        pageSize: tableParams.size,
                        total: data?.totalItems,
                        current: tableParams.page,
                        showSizeChanger: false,
                        showQuickJumper: false,
                        position: ["bottomCenter"],
                        showPrevNextJumpers: false,
                    }}
                    footer={() => <div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                    rowKey={(record) => record.id}
                    scroll={{
                        x: true
                        , y: data?.totalItems > 0 ? "70vh" : undefined
                    }}
                    showHeader={!data?.items || data?.items?.length === 0 ? false : true}
                // locale={loadingDetails ? undefined : { emptyText: <EmptyList customMessage={<>You have no Enquiries</>} /> }}
                />


            </div>

            <Modal destroyOnClose width={869} footer={null} style={{textAlign: "center"}} bodyStyle={{paddingBottom: 16}} title={<div style={{marginTop: 16}}>Contract Registration</div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <ContractDetailsModal data={selectedData} />
            </Modal>



        </div>
    )
}