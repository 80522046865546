const theme = {
    token: {
      // Seed Token
      colorPrimary: '#71A84D',
      borderRadius: 10,
      fontFamily: "'Poppins', sans-serif",
      colorBgLayout: "white",
      colorTextBase: "#333",
      // Alias Token
    },
    components: {
        Table: {
            colorTextHeading: "#848484",
            fontWeightStrong: 500,
            colorFillAlter: "transparent",
            colorBgContainer: "transparent",
            colorBorderSecondary: "transparent",
        },
        Button: {
          /* here is your component tokens */
          defaultShadow: "0 2px 0 rgb(255 255 255 / 0%)",
          primaryShadow: "0 2px 0 rgb(255 255 255 / 0%)"
        },
        Menu: {
            itemHeight: 36,
            itemColor: "#979797",
            itemSelectedBg: "rgba(0, 177, 118, 0.07)",
            subMenuItemBg: "white",
            activeBarBorderWidth: 0,
            iconSize: 20,
        },
        Tabs: {
          titleFontSize: 18,
          horizontalMargin: "0 0 20px 0"
        },
        Breadcrumb: {
          itemColor: "#4F4F4F",
          linkColor: "#4F4F4F",
          lastItemColor: "#71A84D",
          separatorColor: "#71A84D",
        },
      },
}

export default theme;