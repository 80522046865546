import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Dashboard {
    stats: {
        countNominatedQty: number;
        countConfirmedQty: number;
        countScheduledQty: number;
        countIntraDayQty: number;
        countAllocatedQty: number;
        totalIntraDayCount: number; 
        totalScheduledCount: number;
        totalAllocatedCount: number;
        pendingConfirmationCount: string;
        contracts: any;
        unSavedData: boolean;
        registrationStatus: string;
    };
}

const initialState: Dashboard = {
    stats: {
        countNominatedQty: 0,
        countConfirmedQty: 0,
        countScheduledQty: 0,
        countIntraDayQty: 0,
        countAllocatedQty: 0,
        totalIntraDayCount: 0, 
        totalScheduledCount: 0,
        totalAllocatedCount: 0,
        pendingConfirmationCount: "",
        contracts: [],
        unSavedData: false,
        registrationStatus: "",
    },
};

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setDashboard: (state, action: PayloadAction<Dashboard>) => {
            state.stats = action.payload.stats;
        },
        setTotalIntraDayCount: (state, action: PayloadAction<number>) => {
            state.stats.totalIntraDayCount = action.payload;
        },
        setTotalScheduledCount: (state, action: PayloadAction<number>) => {
            state.stats.totalScheduledCount = action.payload;
        },
        setTotalAllocatedCount: (state, action: PayloadAction<number>) => {
            state.stats.totalAllocatedCount = action.payload;
        },
        setPendingConfirmationCount: (state, action: PayloadAction<string>) => {
            state.stats.pendingConfirmationCount = action.payload;
        },
        setUnSavedData: (state, action: PayloadAction<boolean>) => {
            state.stats.unSavedData = action.payload;
        },
        setContracts: (state, action: PayloadAction<any>) => {
            state.stats.contracts = action.payload;
        },
        setRegistrationStatus: (state, action: PayloadAction<any>) => {
            state.stats.registrationStatus = action.payload;
        }
    },
});

export const { setDashboard, setTotalIntraDayCount, setTotalScheduledCount, setTotalAllocatedCount, setPendingConfirmationCount, setUnSavedData, setContracts,setRegistrationStatus } = dashboardSlice.actions;
export const dashboardSelector = (state: RootState) => state.dashboard;
export default dashboardSlice.reducer;
