import { Button, Table, Typography } from "antd";
import theme from "../../../utils/theme";
import { useEffect, useState } from "react";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { columns } from "../../../interfaces/TableColumns/AdminEnquiries";
import { columns as columns2 } from "../../../interfaces/TableColumns/AdminExistingContracts";
import { instance } from "../../../utils/api";
import EmptyList from "../GasOperations/EmptyList";
import { getPaginationCountApi, getPaginationCountClient } from "../../../utils/common";
import RegiistrationNavCard from "./RegistrationNavCards";
import { clientColumns } from "../../../interfaces/TableColumns/KycList";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";


const defaultEnquiryFilters = [
    "ACTION_PENDING",
    "PROPOSAL_RECEIVED",
    "REMARK_SENT",
    "ENQUIRY_CANCELLED"
]

const defaultContractFilters = [
    "CONTRACT_RECEIVED",
    "CONTRACT_REGISTRATION_PENDING",
    "PROPOSAL_ACCEPTED"
]

export default function Registrations() {

    const [loadingDetails, setLoadingDetails] = useState(false)
    const { tableParams, onFilerSearch, handleSort, options, onSearch,resetTableParams } = useTableFilters("auditInfo.creationDate|DESC", '', false, false, true);
    const [data, setData] = useState<any>([]);
    const [clientData, setClientData] = useState<any>([])
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab")
    const [activeTab, setActiveTab] = useState(tab ? tab : "KYC")
    const [statusFilter, setStatusFilter] = useState(defaultEnquiryFilters)
    const [contractStatusFilter, setContractStatusFilter] = useState(defaultContractFilters)
    // const [apiCounts, setApiCounts] = useState({})
    const [existinContractData, setexistinContractData] = useState<any>([])
    const [pagination, setPagination] = useState<any>({current: 1, pageSize: 15})

    const navigate = useNavigate()
    const [counts, setCount] = useState({
        proposalSent: 0,
        proposalToBeSent: 0,
        contractRegPending: 0,
        signedDocumentPending: 0,
        approvalPendingCount: 0,
        kycPendingCount: 0,
    })

    useEffect(() => {
        fetchDetails()
        // fetchClients()
    }, [tableParams]);

    useEffect(()=>{
        if(activeTab=="KYC"){
            fetchClients()
        }
    }, [])

    useEffect(() => {
        setStatusFilter([])
        setContractStatusFilter([])
        resetTableParams()
        getCount()
    }, [activeTab]);

    const getCount = async () => {
        const res = await instance.get("/clientservice//clients/enquiry/status-count")
        setCount({
            proposalSent: res?.data.proposalSent,
            proposalToBeSent: res?.data.proposalToBeSent,
            contractRegPending: res?.data.contractToBeRegistered,
            signedDocumentPending: res?.data.pendingRequest,
            approvalPendingCount: res?.data.approvalPendingCount,
            kycPendingCount: res?.data.kycPendingCount
        })
    }

    const fetchClients = async (name?: string) => {
        // const params = {
        //     filterOr: `registrationStatus|notLike|ADMIN_ONBOARDING_COMPLETED`
        // };
        try {
            const response = await instance.get(`/clientservice/clients?size=100000`);
            setClientData(response.data.reverse());
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
    };




    const fetchDetails = async () => {
        setLoadingDetails(true)
        const params = {
            ...tableParams,
            sort: tableParams.sort != undefined ? tableParams.sort : "auditInfo.creationDate|DESC",
            // filterAnd: "adminStatus|like|PROPOSAL_SENT"
        }

        // Create the filterAnd string from the statusFilter array
        let filterAnd = null;

        if (activeTab === "ENQUIRIES") {
            filterAnd = statusFilter?.length > 0 ? statusFilter.map(status => `status|like|${status}`).join('&') : defaultEnquiryFilters.map(status => `status|like|${status}`).join('&')
        } else {
            filterAnd = contractStatusFilter?.length > 0 ? contractStatusFilter.map(status => `status|like|${status}`).join('&') : defaultContractFilters.map(status => `status|like|${status}`).join('&')
        }

        // Update params with the dynamically created filterAnd string
        if (filterAnd) {
            params.filterOr = filterAnd;
        }
        try {

            const res: any = await instance.get("/clientservice/clients/enquiry", { params })

            console.log("res", res)
            setData({ ...res.data, items: res?.data?.items })
            setexistinContractData({ ...res.data, items: res?.data?.items})
            // data.length === 0 && setCount({
            //     proposalSent: res?.data?.items?.filter((i: any) => i.adminStatus === EnquiryStatus.PROPOSAL_SENT).length,
            //     proposalToBeSent: res?.data?.items?.filter((i: any) => i.adminStatus === EnquiryStatus.PROPOSAL_TO_BE_SENT).length,
            //     contractRegPending: res?.data?.items?.filter((i: any) => i.adminStatus === EnquiryStatus.CONTRACT_REGISTRATION_PENDING).length,
            //     signedDocumentPending: res?.data?.items?.filter((i: any) => i.adminStatus === EnquiryStatus.SIGNED_DOCUMENT_PENDING).length
            // })
        }
        catch (e) {
            console.log(e)
        }
        setLoadingDetails(false)
    }



    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography.Title level={4} style={{ color: theme.token.colorPrimary, marginTop: 0 }}>Registrations</Typography.Title>
                <Button onClick={() => navigate("/dashboard/profile")} size="large" type="primary"><PlusOutlined style={{ marginLeft: 8 }} />Add Registration</Button>
            </div>
            
            <div style={tabContainer}>
                <RegiistrationNavCard
                    icon={<img src="/assets/kyc-registration.png" />}
                    disabledIcon={<img src="/assets/kyc-registration-disabled.png" />}
                    title="KYC Registration"
                    cardColor="#71A84D"
                    tab="KYC"
                    counts={[{
                        text: <p>Clients pending<br />for KYC</p>,
                        value: counts.kycPendingCount
                    }, {
                        text: <p>Pending <br />Approvals</p>,
                        value: counts.approvalPendingCount
                    }
                    ]}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                />
                <RegiistrationNavCard
                    icon={<img src="/assets/contract-enquiries.png" />}
                    disabledIcon={<img src="/assets/contract-enquiries-disabled.png" />}
                    title="Contract Enquiries"
                    tab="ENQUIRIES"
                    counts={[{
                        text: <p>Proposals to be<br />sent</p>,
                        value: counts.proposalToBeSent
                    }, {
                        text: <p>Total proposal<br />sent</p>,
                        value: counts.proposalSent
                    }
                    ]}
                    setActiveTab={setActiveTab}
                    cardColor="#2C66E3"
                    activeTab={activeTab}
                />
                <RegiistrationNavCard
                    icon={<img src="/assets/existing-contracts.png" />}
                    disabledIcon={<img src="/assets/existing-contracts-disabled.png" />}
                    title="Existing Contracts"
                    tab="CONTRACTS"
                    counts={[{
                        text: <p>Contracts<br />Not Sent</p>,
                        value: counts.signedDocumentPending
                    },
                    {
                        text: <p>Contracts to<br />be registered</p>,
                        value: counts.contractRegPending
                    }]}
                    setActiveTab={setActiveTab}
                    cardColor="#F2923A"
                    activeTab={activeTab}
                />


            </div>

            {activeTab === "KYC" && <Table
                loading={loadingDetails}
                pagination={clientData.length <= 15 ? false : {
                    pageSize: 15,
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showPrevNextJumpers: false,
                }}
                onChange={(pagination)=>{setPagination(pagination)}}
                footer={()=><div className='footer-pagination'>{clientData && getPaginationCountClient(clientData,pagination)}</div>}
                rowClassName="table-row"
                columns={clientColumns}
                dataSource={clientData}
                rowKey={(record) => record.id}
                scroll={{
                    x: true
                    , y: clientData?.totalItems > 0 ? "70vh" : undefined
                }}
                locale={loadingDetails ? undefined : { emptyText: <EmptyList customMessage={<>You have no Enquiries</>} /> }}
            />}

            {activeTab === "ENQUIRIES" && <Table
                loading={loadingDetails}
                onChange={(pagination: any, filters: any, sorter: any, extra: any) => {
                    console.log("filters", filters)
                    setStatusFilter(filters.adminStatus)
                    handleSort(pagination, filters, sorter, extra)
                }}
                rowClassName="table-row"
                columns={columns}
                dataSource={data?.items}
                pagination={{
                    pageSize: tableParams.size,
                    total: data?.totalItems,
                    current: tableParams.page,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    position: ["bottomCenter"],
                    showPrevNextJumpers: false,
                }}
                footer={() => <div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                rowKey={(record) => record.id}
                scroll={{
                    x: true
                    , y: data?.totalItems > 0 ? "70vh" : undefined
                }}
                locale={loadingDetails ? undefined : { emptyText: <EmptyList customMessage={<>You have no Enquiries</>} /> }}
            />}

            {activeTab === "CONTRACTS" && <Table
                loading={loadingDetails}
                onChange={(pagination: any, filters: any, sorter: any, extra: any) => {
                    console.log("filters", filters)
                    setContractStatusFilter(filters.adminStatus)
                    handleSort(pagination, filters, sorter, extra)
                }}
                rowClassName="table-row"
                columns={columns2}
                dataSource={existinContractData?.items}
                pagination={{
                    pageSize: tableParams.size,
                    total: existinContractData?.totalItems,
                    current: tableParams.page,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    position: ["bottomCenter"],
                    showPrevNextJumpers: false,
                }}
                footer={()=><div className='footer-pagination'>{existinContractData && getPaginationCountApi(existinContractData)}</div>}
                rowKey={(record) => record.id}
                scroll={{
                    x: true
                    , y: existinContractData?.totalItems > 0 ? "70vh" : undefined
                }}
                locale={loadingDetails ? undefined : { emptyText: <EmptyList customMessage={<>You have no Enquiries</>} /> }}
            />}
        </>
    )
}

const tabContainer: React.CSSProperties = {
    display: "flex",
    // justifyContent: "space-between",
    marginTop: 40,
    marginBottom: 20,
    gap: 40
}

export const navCardStyle: React.CSSProperties = {
    borderRadius: 18,
    color: "#979797",
    width: "30%",
    textAlign: "center",
    background: "white",
    overflow: "hidden",
    fontWeight: 600,
    cursor: "pointer",
    scale: "",
}