import { Breadcrumb, Button, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select, Space, Typography, Upload } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import { beforeUpload, showSuccess, stopScroll } from "../../../utils/common";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CalendarOutlined, CloudUploadOutlined, DownOutlined, PlusSquareFilled, UpOutlined, UploadOutlined } from "@ant-design/icons";
import theme from "../../../utils/theme";

export default function CreateContract() {
    const navigate = useNavigate()
    const [submitEnable, setSubmitEnable] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [clientData, setClientData] = useState<any>({})
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [duplicates, setDuplicates] = useState<number[] | []>([])
    const [contractTypeState, setContractType] = useState("")
    const [kycDetails, setKycDetails] = useState<any>([])
    const [modal, modalContex] = Modal.useModal();


    const [form] = useForm()
    let { id } = useParams();

    const getClientDetails = async () => {
        try {
            const res = await instance.get(`clientservice/clients/${id}`);
            setClientData(res.data)
        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        getClientDetails()
        getKycDetails()
    }, [])

    const getKycDetails = async () => {
        try {
            const res = await instance.get(`clientservice/clients/${id}/kyc`);
            setKycDetails(res.data)
        } catch (e) {
            console.log(e);
        }
    }

    const handleCancel = () => {
        modal.confirm({
            title: <div>
                Do you want to save contract Information ?
            </div>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            centered: true,
            cancelText: 'No',
            icon: null,
            onOk: async () => {

                form.resetFields()
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })
    }

    const createContract = async (values: any) => {
        setSubmitting(true)
        const formData = new FormData();
        formData.append("file", values["file"]);

        const { createDeliveryDtoList, contractType, ...temp } = values;

        if (contractType !== "EXCHANGE") {
            temp.contractType = contractType;
        }


        temp.createDeliveryDtoList = values.createDeliveryDtoList.map((i: any) => {
            return { deliveryEndPoint: i }
        })

        temp.startDate = dayjs(values.startDate).format('DD/MM/YYYY')
        temp.endDate = dayjs(values.endDate).format('DD/MM/YYYY')
        // temp.paymentDueDate = dayjs(values.paymentDueDate).format('DD/MM/YYYY')

        delete temp["file"]

        console.log("values", temp)

        formData.append('contract', new Blob([JSON.stringify(temp)], { type: 'application/json' }));


        modal.confirm({
            title: <div>
                Do you want to save contract Information ?
            </div>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            centered: true,
            cancelText: 'No',
            icon: null,
            onOk: async () => {

                try {
                    await instance.post(`clientservice/clients/${id}/contract`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    showSuccess(<>Contract successfully created, please wait for the client to verify the same.</>, Modal)
                    navigate("/dashboard/registrations")
                    setSubmitting(false)

                    // document?.querySelector(`#contract${res.data.id}`)?.scrollIntoView();
                    form.resetFields()
                } catch (e) {
                    console.log(e)
                    setSubmitting(false)
                }
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })



    }

    const findDuplicateIndexes = (arr: string[]) => {
        const seen: Map<string, number[]> = new Map();

        arr.forEach((item, index) => {
            if (seen.has(item)) {
                seen.get(item)!.push(index);
            } else {
                seen.set(item, [index]);
            }
        });

        let duplicateIndexes: number[] = [];
        seen.forEach((indexes) => {
            if (indexes.length > 1) {
                duplicateIndexes = duplicateIndexes.concat(indexes);
            }
        });

        console.log(duplicateIndexes, "findDuplicateIndexes", arr);


        arr.map((i: any, index: number) => {
            const element = document.getElementById(`delivery-end-point-${index}`);
            if (element) {
                if (duplicateIndexes.includes(index)) {
                    element.style.borderColor = 'red'; // Change to the desired color
                } else {
                    element.style.borderColor = '#d9d9d9';
                }
            }
        })

        setDuplicates(duplicateIndexes)

    };


    const handleCreateContactFormChange = (values: any) => {
        const formData = form.getFieldsValue()
        const errorCount = form.getFieldsError([
            "additionalVAT",
            "applicableTaxes",
            "contractType",
            "createDeliveryDtoList",
            "creditDetail",
            "dcq",
            "deliveryPoint",
            "description",
            "endDate",
            "exchangeRate",
            "exchangeRateDef",
            "flexibility",
            "invoiceCycle",
            "linkageDef",
            // "linkagePercent",
            "linkagePrice",
            "linkageFormula",
            "linkageType",
            "linkageValue",
            "maxContractFlex",
            "maxSupplyFlex",
            "minContractFlex",
            "minSupplyFlex",
            "paymentDueDate",
            "paymentSecurity",
            "price",
            "startDate",
            "supplyOrPayLevel",
            "takeOrPayLevel",
            "tariffPath",
            "tariffZone",
            "taxOnTariff",
            "tenure",
            "totalQuantity",
            "transportationTariff"
        ]).filter(({ errors }) => errors.length).length

        console.log("formData", formData)
        let submitEnable = true
        const formContractType = form.getFieldValue("contractType")
        for (const key in formData) {
            if (formContractType !== "EXCHANGE") {
                if (!formData[key] && key !== "description" && key !== "tradeId") {
                    submitEnable = false;
                }
            } else {
                if (!formData[key] && key !== "description") {
                    submitEnable = false;
                }
            }
        }
        const deliveryEPs = form.getFieldValue("createDeliveryDtoList")

        let hasDuplicate = deliveryEPs.some((val: any, i: any) => deliveryEPs.indexOf(val) !== i)

        findDuplicateIndexes(deliveryEPs)

        setSubmitEnable(submitEnable && !hasDuplicate && errorCount === 0)
    }

    const getTenure = () => {
        const endDate = form.getFieldValue("endDate")
        const startDate = form.getFieldValue("startDate")
        const differencence = dayjs(endDate, "DD/MM/YYYY").endOf("day").diff(dayjs(startDate, "DD/MM/YYYY"), 'day') + 1
        console.log(differencence, startDate, endDate)
        form.setFieldsValue({
            tenure: differencence ? differencence + " Days" : 0 + " Days"
        })
    }

    const getDcq = () => {
        const dcq = form.getFieldValue("dcq");
        const tenure = form.getFieldValue("tenure")
        const tenureValue = parseInt(tenure.match(/\d+/)[0])
        const totalQuantity = dcq * tenureValue;
        console.log("totalQuantity.toFixed(3)", totalQuantity.toFixed(3))
        form.setFieldsValue({
            totalQuantity: totalQuantity ? totalQuantity.toFixed(3) : ""
        })
    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (

        <>

            <Breadcrumb separator=">" style={{ marginBottom: 0, cursor: "pointer" }}>
                <Breadcrumb.Item
                    onClick={_ => navigate("/dashboard/client-details")}
                >
                    <span style={{ fontWeight: 600 }}>
                        Client Details
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link
                        to={``}
                        style={{ color: theme.token.colorPrimary }}
                    >
                        <span style={{ fontWeight: 600 }}>
                            {clientData?.name}
                        </span>
                    </Link>
                </Breadcrumb.Item>
            </Breadcrumb><br />

            <div style={{ background: "white", padding: "20px 40px", border: "solid 1px #E2E2E2", borderRadius: 16, position: "relative" }}>
                <Row gutter={[20, 20]}>
                    <Col span={6}>
                        <div style={{ fontSize: 14, color: "#707070", marginBottom: 4 }}>
                            Company Name:
                        </div>
                        <div>
                            {clientData?.name}
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={{ fontSize: 14, color: "#707070", marginBottom: 4 }}>
                            Email ID:
                        </div>
                        <div>
                            {clientData?.email}
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={{ fontSize: 14, color: "#707070", marginBottom: 4 }}>
                            Phone No:
                        </div>
                        <div>
                            +91 {clientData?.mobileNo}
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={{ fontSize: 14, color: "#707070", marginBottom: 4 }}>
                            Address:
                        </div>
                        <div>
                            {clientData?.addressList?.[0].addressLine1}<br />{clientData?.addressList?.[0].addressLine2}
                        </div>
                    </Col>
                    {detailsOpen && <>
                        <Col span={6}>
                            <div style={{ fontSize: 14, color: "#707070", marginBottom: 4 }}>
                                VAT No:
                            </div>
                            <div>
                                {kycDetails?.find((item: any) => item.type === "VAT")?.kycId}
                            </div>

                        </Col>

                        <Col span={6}>
                            <div style={{ fontSize: 14, color: "#707070", marginBottom: 4 }}>
                                CST No:
                            </div>
                            <div>
                                {kycDetails?.find((item: any) => item.type === "CST")?.kycId}
                            </div>

                        </Col>

                        <Col span={6}>
                            <div style={{ fontSize: 14, color: "#707070", marginBottom: 4 }}>
                                GST No:
                            </div>
                            <div>
                                {kycDetails?.find((item: any) => item.type === "GST")?.kycId}
                            </div>

                        </Col>

                        <Col span={6}>
                            <div style={{ fontSize: 14, color: "#707070", marginBottom: 4 }}>
                                Cancelled Cheque:
                            </div>
                            <div>
                                <Link target="_blank" to={clientData?.bankDetails?.[0]?.cancelledCheque}>
                                    <Typography.Text ellipsis>{clientData?.bankDetails?.[0]?.cancelledCheque?.split('/')?.pop()}</Typography.Text>
                                </Link>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div style={{ fontSize: 14, color: "#707070", marginBottom: 4 }}>
                                Signed Contract:
                            </div>
                            <div>
                                <Link target="_blank" to={clientData?.signedDocumentUrl}>
                                    <Typography.Text style={{ color: theme.token.colorPrimary, textDecoration: "underline" }} ellipsis>{clientData?.signedDocumentUrl?.split('/')?.pop()}</Typography.Text>
                                </Link>
                            </div>
                        </Col>

                        <Col span={24}>
                            <Divider />
                            <div style={{ textAlign: "center" }}>
                                <Link to={`/dashboard/profile/${id}`} style={{ textDecoration: "underline", fontSize: 18, color: theme.token.colorPrimary }}>
                                    Edit Details
                                </Link>
                            </div>
                        </Col>
                    </>}


                </Row>
                <div onClick={() => setDetailsOpen(!detailsOpen)} style={{ position: "absolute", fontSize: 14, paddingBlock: "4px", background: theme.token.colorPrimary, color: "white", width: 160, textAlign: "center", borderRadius: "0px 0px 12px 12px", bottom: -30, right: 26, cursor: "pointer" }}>
                    {detailsOpen ? <>Close <UpOutlined /></> : <>View more details <DownOutlined /></>}
                </div>
            </div>


            <Form
                form={form}
                onFinish={createContract}
                scrollToFirstError
                name="contract-details"
                className="contract-form"
                onValuesChange={handleCreateContactFormChange}
                layout={undefined}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                encType='multipart/form-data'
                autoComplete="off"
                style={{ marginTop: 40 }}
            >
                <div style={{ display: "flex", justifyContent: "center" }}>

                    <div style={{ width: "85%" }}>
                        <div>
                            <Row gutter={40} style={{ alignItems: "center" }}>

                                <Col span={6}>
                                    <Form.Item label="Contract Type"
                                        name="contractType"
                                        rules={[{ required: true }]} >
                                        <Select onChange={(value) => {
                                            setContractType(value)
                                            form.setFields([
                                                {
                                                    name: 'tradeId',
                                                    errors: [],
                                                },
                                            ]);
                                        }} size="large">
                                            <Select.Option value="BILATERAL">
                                                BILATERAL
                                            </Select.Option>
                                            <Select.Option value="TENDER">
                                                TENDER
                                            </Select.Option>
                                            <Select.Option value="EXCHANGE">
                                                EXCHANGE
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Typography.Text style={{ marginLeft: 20, display: "block" }}>Tenure <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                        <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>


                            <Row gutter={40} style={{ alignItems: "center" }}>
                                <Col span={6}>
                                    <Form.Item label="From"
                                        name="startDate"
                                        rules={[{ required: true, message: 'Please input contract start date!', type: 'date' }]} >
                                        <DatePicker allowClear={false} suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: theme.token.colorPrimary }} />} disabledDate={(date) => {
                                            return date && date.valueOf() < dayjs().valueOf()
                                        }}
                                            onChange={() => { form.resetFields(["endDate"]); getTenure(); getDcq() }}
                                            size='large' style={{ width: "100%", border: `solid 1px ${theme.token.colorPrimary}` }} format='DD/MM/YYYY' />
                                    </Form.Item>
                                </Col>
                                <Col span={1} style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 10, paddingLeft: "0px", paddingRight: "0px" }}>
                                    <img width="90%" src="/assets/date-line-long.png" />
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="To"
                                        name="endDate"
                                        rules={[{ required: true, message: 'Please input contract end date!', type: 'date' }]} >
                                        <DatePicker allowClear={false} onChange={() => { getTenure(); getDcq() }} suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: theme.token.colorPrimary }} />} disabledDate={(date) => {
                                            return date && date.valueOf() < dayjs(form.getFieldValue("startDate"), "DD/MM/YYYY").add(1, "day").valueOf()
                                        }} size='large' style={{ width: "100%", border: `solid 1px ${theme.token.colorPrimary}` }} format='DD/MM/YYYY' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Tenure"
                                        name="tenure"
                                        rules={[{ required: false }]} >
                                        <Input disabled size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>



                        </div>

                        <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Quantity <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                        <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>


                            <Row gutter={40} style={{ alignItems: "center" }}>

                                <Col span={11}>
                                    <Form.Item label="Daily Quantity (MMBTU)"
                                        name="dcq"
                                        rules={[
                                            { required: true, message: 'Please input dcq!' },
                                            () => ({
                                              validator(_, value) {
                                                if (isNaN(value) || parseInt(value) < 0) {
                                                    return Promise.reject(new Error('only numbers allowed'));
                                                }
                                                if (value && !/^\d+(\.\d{1,3})?$/.test(value.toString())) {
                                                  return Promise.reject(new Error('3 decimal places allowed'));
                                                }
                                                return Promise.resolve();
                                              }
                                            })
                                          ]}
                                        >
                                        <Input onChange={getDcq} onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={2} style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 10, paddingLeft: "0px", paddingRight: "0px" }}>
                                    <img width="90%" src="/assets/dcq-line-long.png" />
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Total Contract Quantity MMBTU"
                                        name="totalQuantity"
                                    >
                                        <Input disabled onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        <div style={{ marginTop: 30 }}>
                            <Row gutter={40} style={{ alignItems: "center" }}>

                                <Col span={16}>
                                    <Form.List
                                        name="createDeliveryDtoList"
                                        initialValue={[undefined]}
                                        rules={[
                                            {
                                                validator: async (_, names) => {
                                                    if (!names || names.length < 1) {
                                                        return Promise.reject(new Error('At least 1 delivery-endpoint'));
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        label={index === 0 ? 'Delivery End Points' : ''}
                                                        required={true}
                                                        key={field.key}
                                                    >
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    // whitespace: true,
                                                                    validator: async (_, value) => {
                                                                        if (!value) {
                                                                            return Promise.reject(new Error("Please input delivery-endpoint"));
                                                                        }

                                                                    }
                                                                },
                                                            ]}
                                                            noStyle
                                                        >
                                                            {/* <Input onChange={()=>form.getFieldsValue()} size="large" placeholder="delivery endpoint" style={{ width: '60%' }} /> */}
                                                            <Input onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} id={`delivery-end-point-${index}`} placeholder="Enter Delivery Point" style={{ width: 400 }} size="large" />
                                                        </Form.Item>
                                                        {" "}
                                                        {fields.length - 1 === index && index < 4 ? (
                                                            <PlusSquareFilled
                                                                style={{ color: "#2C66E3", marginLeft: 4 }}
                                                                onClick={() => add()}
                                                            />
                                                        ) : null}
                                                        {" "}
                                                        {fields.length > 1 && index !== 0 && index < 5 ? (
                                                            <img width={16} src="/assets/delete2.png"
                                                                style={{ color: "#FF3B30", position: "absolute", marginTop: 11, marginLeft: 4, cursor: "pointer" }}
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}

                                                    </Form.Item>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                            {duplicates.length > 0 && <span style={{ color: "red" }} >Delivery end points must be unique</span>}
                        </div>

                        <div style={{ marginTop: 30 }}>
                            <Row gutter={40} style={{ alignItems: "center" }}>

                                <Col span={16}>
                                    <Form.Item label="Description"
                                        name="description"
                                        rules={[{ required: false }]} >
                                        <Input.TextArea rows={4} placeholder="Add your description here..." style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Linkage <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                        <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>
                            <Row gutter={10} style={{ alignItems: "center" }}>
                                <Col span={11}>
                                    <Form.Item rules={[{
                                        required: true
                                    }]} name="linkageFormula" label="Linkage Formula">
                                        <Input placeholder="e.g., 16.80%" style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                {/* <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#979797" }}>
                                    of
                                </Col>
                                <Col span={11}>
                                    <Form.Item rules={[{ required: false }]} name="linkageType" label={<></>}>
                                        <Input placeholder="e.g., Brent" style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col> */}

                                <Col span={11}>
                                    <Form.Item rules={[{ required: true }]} name="linkageDef" label="Linkage Def">
                                        <Input placeholder="e.g., 3 months Brent Avg." style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#979797" }}>
                                </Col>
                                <Col span={11}>
                                    <Form.Item rules={[{
                                        required: true, validator: async (_, value) => {
                                            if (isNaN(value) || parseInt(value) < 0) {
                                                return Promise.reject(new Error('only numbers allowed'));
                                            }

                                        }
                                    }]} name="linkageValue" label="Linkage Value">
                                        <Input placeholder="e.g., 84" onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item rules={[{
                                        required: true
                                    }]} name="linkagePrice" label="Linkage Price">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Applicable Exchange Rate<span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                        <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>
                            <Row gutter={20} style={{ alignItems: "center" }}>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="exchangeRateDef" label="Exchange Rate Def">
                                        <Input placeholder="e.g., Monthly or Fortnightly" style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="exchangeRate" label="Exchange Rate">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </div>

                        <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Flexibility Range<span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                        <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>
                            <Typography.Text style={{ fontSize: 14, color: "blue", paddingBottom: 16, display: "block" }}>Contract Limit</Typography.Text>

                            <Row gutter={20} style={{ alignItems: "center" }}>
                                <Col span={12}>
                                    <Form.Item rules={[{
                                        required: true, validator: async (_, value) => {
                                            if (isNaN(value) || parseInt(value) < 0) {
                                                return Promise.reject(new Error('only numbers allowed'));
                                            }
                                            if (value > 100) {
                                                return Promise.reject(new Error('enter a value less than or equal 100'));
                                            }

                                        }
                                    }]} name="minContractFlex" label="Lower Limit" >
                                        <Input onChange={(e) => {
                                            form.setFieldValue("minSupplyFlex", e.target.value);
                                            form.validateFields(["minSupplyFlex"])

                                        }} onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{
                                        required: true, validator: async (_, value) => {
                                            if (isNaN(value) || parseInt(value) < 0) {
                                                return Promise.reject(new Error('only numbers allowed'));
                                            }
                                            if (value > 100) {
                                                return Promise.reject(new Error('enter a value less than or equal 100'));
                                            }

                                        }
                                    }]} name="maxContractFlex" label="Upper Limit">
                                        <Input onChange={(e) => {
                                            form.setFieldValue("maxSupplyFlex", e.target.value);
                                            form.validateFields(["maxSupplyFlex"])
                                        }} onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </div>

                        <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10, backgroundColor: "#F8FAFF" }}>
                            <Typography.Text style={{ fontSize: 14, color: "blue", paddingBottom: 16, display: "block" }}>Supply Limit</Typography.Text>

                            <Row gutter={20} style={{ alignItems: "center" }}>
                                <Col span={12}>
                                    <Form.Item rules={[{
                                        required: true, validator: async (_, value) => {
                                            if (isNaN(value) || parseInt(value) < 0) {
                                                return Promise.reject(new Error('only numbers allowed'));
                                            }
                                            if (value > 100) {
                                                return Promise.reject(new Error('enter a value less than or equal 100'));
                                            }

                                        }
                                    }]} name="minSupplyFlex" label="Lower Limit">
                                        <Input onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{
                                        required: true, validator: async (_, value) => {
                                            if (isNaN(value) || parseInt(value) < 0) {
                                                return Promise.reject(new Error('only numbers allowed'));
                                            }
                                            if (value > 100) {
                                                return Promise.reject(new Error('enter a value less than or equal 100'));
                                            }

                                        }
                                    }]} name="maxSupplyFlex" label="Upper Limit">
                                        <Input onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </div>

                        <div style={{ marginTop: 30 }}>
                            <Row gutter={40}>

                                <Col span={24}>
                                    <Form.Item rules={[{ required: contractTypeState === "EXCHANGE" }]} name="tradeId" label="Trade ID">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="tariffPath" label="Tariff Path">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="tariffZone" label="Tariff Zone">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="transportationTariff" label="Transportation Tariff">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="taxOnTariff" label="Tax On Transportation Tariff">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="applicableTaxes" label="Applicable Taxes">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="additionalVAT" label="Additional Surcharge on VAT">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="takeOrPayLevel" label="Take Or Pay Level">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="supplyOrPayLevel" label="Supply Or Pay Level">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="paymentSecurity" label="Payment Security">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="invoiceCycle" label="Invoice Cycle">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Payment Due Date"
                                        name="paymentDueDate"
                                        rules={[{ required: true, message: 'Please input contract paymentDueDate !' }]} >
                                        <Input size='large' style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="creditDetail" label="Credit Detail">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="deliveryPoint" label="Delivery Point">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="flexibility" label="Flexibility">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name="price" label="Price">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}></Col>

                                <Col span={12}>
                                    <Typography.Text style={{ color: "#4F4F4F", fontSize: 18 }}>Upload signed Document</Typography.Text>
                                </Col>

                                <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="file"
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            name='file'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={dummyRequest}
                                            beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields(["file"]))}
                                        >
                                            <Button style={{ display: "flex", alignItems: "center", gap: 26, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Document<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>

                                        </Upload>

                                    </Form.Item>
                                </Col>

                            </Row>


                        </div>


                        {<div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                            <Space size="large">
                                <Button className="reset-button-calendar" style={{ width: 200 }} onClick={handleCancel} type="default" size="large">Cancel</Button>

                                <Button loading={submitting} disabled={submitting || !submitEnable} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Space>
                        </div>}

                    </div>
                </div>

            </Form >
            {modalContex}
        </>

    )
}