import { Button, Space, Typography } from "antd"

export default function ExcelUploadError({error, setError}:any) {

    return <>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, padding: 10 }}>
        <img width={180} src="/assets/excel-error.svg" />
        {typeof(error) === "string" ?
        <Typography.Title style={{ textAlign: "center", fontWeight: 500, margin: 0 }} level={5}>{error}</Typography.Title>
        :
        <>
        {Array.from(Array(Object.keys(error).length), (e, i) => {
            return <Typography.Title style={{ textAlign: "center", fontWeight: 500, margin: 0 }} level={5}>
            {Object.keys(error)[i]}: {error[Object.keys(error)[i]]}
        </Typography.Title>
        })}
        </>
        
        
    }

        <Space>
            <Button style={{marginTop: 30}} type="primary" size="large" onClick={()=>setError(null)}>Upload again</Button>
        </Space>

    </div></>
}