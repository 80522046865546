import { CloudUploadOutlined, DeleteFilled, FileImageOutlined, PlusOutlined, PlusSquareFilled, UploadOutlined } from "@ant-design/icons"
import { Row, Col, DatePicker, InputNumber, Upload, Button, Form, Divider, message, Space, Typography, Card, Modal, Input, Select } from "antd"
import dayjs from "dayjs";
import { Dispatch, useEffect, useState } from "react"
import { instance } from "../../../utils/api";
import theme from "../../../utils/theme";
import { Link } from "react-router-dom";
import { stopScroll } from "../../../utils/common";
const _debounce = require('lodash/debounce');
function ContractForm({ clientID, itemKey, data, setContractDetails, getContractDetails, contractDetails }: { clientID?: string | undefined, itemKey?: number, data?: any, setContractDetails: Dispatch<any>, getContractDetails?: () => Promise<void>, contractDetails: any }) {

    const [loading, setLoading] = useState(false)
    const [enableReset, setEnableReset] = useState(false)
    const [editing, setEditing] = useState(true)
    const [modal, modalContex] = Modal.useModal();
    const [options, setOptions] = useState<any>([]);
    const [form] = Form.useForm()

    useEffect(() => {
        setEditing(contractDetails?.length < 1 ? true : false)
    }, [contractDetails])


    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const deleteContract = async () => {

        try {
            setLoading(true)
            await instance.delete(`clientservice/clients/contract/${data?.id}`)
            message.success("client contract deleted successfully!")
            setContractDetails((prev: any) => prev.filter((item: any) => item.id !== data?.id))
        } catch (e: any) {
            console.log(e);
        }
        setLoading(false);
    }

    useEffect(() => {
        // form.setFieldsValue(data)
        if (data) {
            setFormValues();
        }
    }, [data])


    const setFormValues = () => {
        setEnableReset(false)
        const deliveryEndPoints = data.deliveryEndPoints?.map((i: any) => i.deliveryEndPoint)
        form.setFieldValue("maxContractFlex", data.maxContractFlex)
        form.setFieldValue("minContractFlex", data.minContractFlex)
        form.setFieldValue("maxSupplyFlex", data.maxSupplyFlex)
        form.setFieldValue("minSupplyFlex", data.minSupplyFlex)
        form.setFieldValue("deliveryEndPoints", deliveryEndPoints)
        form.setFieldValue("tradeId", data.tradeId)
        form.setFieldValue("totalQuantity", data.totalQuantity)
        form.setFieldValue("startDate", dayjs(data.startDate, "DD/MM/YYYY"))
        form.setFieldValue("endDate", dayjs(data.endDate, "DD/MM/YYYY"))
    }

    const downloadJson = (dataObject:any, fileName:string) => {
        // Step 1: Serialize the data into a JSON string and create a Blob from it
        const jsonString = JSON.stringify(dataObject);
        const blob = new Blob([jsonString], { type: 'application/json' });
    
        // Step 2: Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);
    
        // Step 3: Create a temporary download link
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName || 'download.json'; // Default filename if not specified
    
        // Step 4: Trigger the download by simulating a click on the link
        document.body.appendChild(downloadLink);
        downloadLink.click();
    
        // Step 5: Clean up by removing the link and revoking the blob URL
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
    };


    const onCreate = async (values: any) => {
        setLoading(true)
        const formData = new FormData();

        formData.append("file", values["file"]);
        const { deliveryEndPoints, ...temp } = values;
        temp.createDeliveryDtoList = values.deliveryEndPoints.map((i: any) => {
            return typeof (i.value) === "string" ? {
                deliveryEndPoint: i.label,
            } : {
                id: i.value,
                deliveryEndPoint: i.label
            }
        })

        temp.startDate = dayjs(values.startDate).format('DD/MM/YYYY')
        temp.endDate = dayjs(values.endDate).format('DD/MM/YYYY')
        temp.paymentDueDate = dayjs(values.paymentDueDate).format('DD/MM/YYYY')


        delete temp["file"]

        console.log("values", temp)

        formData.append('contract', new Blob([JSON.stringify(temp)], { type: 'application/json' }));
        try {
            await instance.post(`clientservice/clients/${clientID}/contract`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            getContractDetails && getContractDetails()
            message.success("client cotnract added successfully!")
            // document?.querySelector(`#contract${res.data.id}`)?.scrollIntoView();
            form.resetFields()
            setEditing(false)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)

    }

    const updateContract = async (values: any) => {
        setLoading(true)

        let temp = values;

        temp.startDate = dayjs(values.startDate).format('DD/MM/YYYY')
        temp.endDate = dayjs(values.endDate).format('DD/MM/YYYY')

        try {
            await instance.put(`clientservice/clients/contract/${data.id}`, temp)
            message.success("client cotnract updated successfully!")
            setEnableReset(false)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)

    }

    const onSearch = async (value: string, options: any) => {
        value && await instance.get(`clientservice/clients/delivery`, {
            params: {
                filterAnd: `deliveryEndPoint|like|${value}`
            }
        }).then((res: any) => {
            const filteredOptions = res.data.items;
            if (filteredOptions.length === 0 && value.trim() !== '') {
                const newItem = {
                    id: value.charAt(0).toUpperCase() + value.slice(1),
                    deliveryEndPoint: value.charAt(0).toUpperCase() + value.slice(1)
                }
                const index = options.findIndex((item: any) => typeof (item.id) === "string");
                if (index !== -1) {
                    // If item with id "new" exists, update its value
                    const updatedState = [...options];
                    updatedState[index] = newItem;
                    setOptions(updatedState);
                } else {
                    // If item with id "new" doesn't exist, add it to the state
                    setOptions((prevState: any) => [...prevState, newItem]);
                }
            } else if (value === "") {
                setOptions((prevOptions: any) => {
                    return prevOptions.filter((item: any) => typeof (item.id) !== "string")
                })
            } else {
                setOptions(filteredOptions)
            }
        }).catch((err: any) => {
            console.log("err", err)
        })
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const debouncedOnFilterSearch = _debounce((value: string, options: any) => {
        onSearch(value, options);
    }, 1000);

    return (
        <div>
            {modalContex}
            {!editing && !data && <div style={{ display: "flex", alignItems: "center" }}>
                <Divider orientationMargin="0" style={{ color: theme.token.colorPrimary, minWidth: "unset" }} orientation="left">{itemKey !== undefined ? `KYC Detail-${data?.id}` : 'Create New Contract'}</Divider>
                <Button onClick={() => { setEditing(true) }} icon={<PlusOutlined />} size='large' loading={loading} type="default" style={{ color: theme.token.colorPrimary, borderColor: theme.token.colorPrimary }}>
                    {`Add new contract`}
                </Button>

            </div>}
            {(editing || data) && <div id={`contract${data?.id}`} style={{ marginBottom: 50, padding: "19px 28px", background: "#FFF", borderRadius: 16 }}>
                <Form
                    disabled={data?.id ? true : false}
                    onBlur={() => setEnableReset(true)}
                    form={form}
                    name="contract-details"
                    layout="vertical"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={data ? updateContract : onCreate}
                    encType='multipart/form-data'
                    autoComplete="off"
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Divider orientationMargin="0" style={{ color: theme.token.colorPrimary, minWidth: "unset" }} orientation="left">{itemKey !== undefined ? `Contract Detail-${data?.id}` : 'Create New Contract'}</Divider>
                    </div>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Start Date"
                                        name="startDate"
                                        rules={[{ required: true, message: 'Please input contract start date!', type: 'date' }]} >
                                        <DatePicker disabledDate={(date) => {
                                            return date && date.valueOf() < dayjs().valueOf()
                                        }}
                                            onChange={() => form.resetFields(["endDate"])}
                                            size='large' style={{ width: "100%" }} format='DD/MM/YYYY' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="End Date"
                                        name="endDate"
                                        rules={[{ required: true, message: 'Please input contract end date!', type: 'date' }]} >
                                        <DatePicker disabledDate={(date) => {
                                            return date && date.valueOf() < dayjs(form.getFieldValue("startDate"), "DD/MM/YYYY").add(1, "day").valueOf()
                                        }} size='large' style={{ width: "100%" }} format='DD/MM/YYYY' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Total Quantity"
                                        name="totalQuantity"
                                        rules={[{ required: true, message: 'Please input total quantity!', type: 'number' }]} >
                                        <InputNumber onFocus={stopScroll} type='number' style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Typography.Text style={{ color: "#2C66E3" }}>Contract Limit</Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="minContractFlex" label="Lower Limit" >
                                                <InputNumber onFocus={stopScroll} type='number' style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="maxContractFlex" label="Upper Limit">
                                                <InputNumber onFocus={stopScroll} type='number' style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Typography.Text style={{ color: "#2C66E3" }}>Supply Limit</Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="minSupplyFlex" label="Lower Limit">
                                                <InputNumber onFocus={stopScroll} type='number' style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="maxSupplyFlex" label="Upper Limit">
                                                <InputNumber onFocus={stopScroll} type='number' style={{ width: "100%" }} size="large" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Form.List
                                        name="deliveryEndPoints"
                                        initialValue={[undefined]}
                                        rules={[
                                            {
                                                validator: async (_, names) => {
                                                    if (!names || names.length < 1) {
                                                        return Promise.reject(new Error('At least 1 delivery-endpoint'));
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        label={index === 0 ? 'Delivery End Points' : ''}
                                                        required={true}
                                                        key={field.key}
                                                    >
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         whitespace: true,
                                                            //         message: "Please input delivery-endpoint",
                                                            //     },
                                                            // ]}
                                                            noStyle
                                                        >
                                                            {/* <Input onChange={()=>form.getFieldsValue()} size="large" placeholder="delivery endpoint" style={{ width: '60%' }} /> */}
                                                            <Select
                                                                style={{ width: '60%' }}
                                                                showSearch
                                                                placeholder="Select a person"
                                                                size="large"
                                                                onSearch={(value: string) => debouncedOnFilterSearch(value, options)}
                                                                labelInValue
                                                                filterOption={filterOption}
                                                                options={options?.map((d: any) => ({
                                                                    value: d.id,
                                                                    label: d.deliveryEndPoint,
                                                                    // disabled: true
                                                                }))}
                                                            />
                                                        </Form.Item>
                                                        {" "}
                                                        {fields.length - 1 === index ? (
                                                            <PlusSquareFilled
                                                                style={{ color: "#2C66E3" }}
                                                                onClick={() => add()}
                                                            />
                                                        ) : null}
                                                        {" "}
                                                        {fields.length > 1 && index !== 0 ? (
                                                            <DeleteFilled
                                                                style={{ color: "#FF3B30" }}
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}

                                                    </Form.Item>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="tradeId" label="Trade ID">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={24}>
                                    <Form.Item name="description" label="Description">
                                        <Input.TextArea style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="linkagePercent" label="Linkage Percent">
                                        <InputNumber onFocus={stopScroll} type='number' style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="linkageType" label="Linkage Type">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="linkageValue" label="Linkage Value">
                                        <InputNumber onFocus={stopScroll} type='number' style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="linkageDef" label="Linkage Def">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="linkagePrice" label="Linkage Price">
                                        <InputNumber onFocus={stopScroll} type='number' style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="exchangeRateDef" label="Exchange Rate Def">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="exchangeRate" label="Exchange Rate">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="tariffPath" label="Tariff Path">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="tariffZone" label="Tariff Zone">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="transportationTariff" label="Transportation Tariff">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="taxOnTariff" label="Tax On Tariff">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="applicableTaxes" label="Applicable Taxes">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="additionalVAT" label="Additional VAT">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="takeOrPayLevel" label="Take Or Pay Level">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="supplyOrPayLevel" label="Supply Or Pay Level">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="paymentSecurity" label="Payment Security">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="invoiceCycle" label="Invoice Cycle">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Payment Due Date"
                                        name="paymentDueDate"
                                        rules={[{ required: true, message: 'Please input contract paymentDueDate !', type: 'date' }]} >
                                        <DatePicker size='large' style={{ width: "100%" }} format='DD/MM/YYYY' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="creditDetail" label="Credit Detail">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="flexibility" label="Flexibility">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="price" label="Price">
                                        <Input style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>
                        <Col span={10} style={{ display: "flex", justifyContent: "end" }}>
                            {data ? <Card style={{ height: "fit-content", display: "flex", alignItems: "center", float: "left", border: "1px dashed #E2E2E2" }}>


                                <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                                    <FileImageOutlined style={{ fontSize: 24, color: "#2C66E3" }} />
                                    <Space direction="vertical">
                                        <Link style={{ textDecoration: "underline" }} target="_blank" to={data.url}>View file: {data.url.substring(data.url.lastIndexOf('/') + 1)}</Link>
                                    </Space>

                                </div>


                            </Card> :

                                <div style={{ display: "flex", gap: 20 }}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="file"
                                        getValueFromEvent={({ file }) => { return file.originFileObj }}
                                    >

                                        <Upload
                                            name='file'
                                            accept="application/pdf"
                                            maxCount={1}
                                            listType="text"
                                            customRequest={dummyRequest}
                                        >
                                            <Button style={{ display: "flex", alignItems: "center", gap: 26, height: "auto" }} type="dashed" size="large" icon={<CloudUploadOutlined style={{ fontSize: 34, color: "#2C66E3" }} />}><span style={{ textAlign: "left", marginBlock: 10 }}>Upload Document<br /><Typography.Text type="secondary">File type: PDF</Typography.Text ></span> <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} /></Button>

                                        </Upload>

                                    </Form.Item>

                                </div>}
                        </Col>

                    </Row>


                </Form>

            </div>}
            {!data && (<div style={{ display: "flex", justifyContent: "center", marginTop: 36 }}>
                {editing ?
                    <Space size="large">
                        <Button disabled={contractDetails?.length < 1} onClick={() => { form.resetFields(); setEditing(false) }} style={{ width: 200 }} size="large">
                            Cancel
                        </Button>
                        <Button loading={loading} onClick={() => form.submit()} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                            Add Contract
                        </Button>
                    </Space>
                    :
                    <Space size="large">
                        <Button onClick={() => { setEditing(true) }} style={{ width: 200 }} size="large">
                            Edit
                        </Button>
                        {/* <Button onClick={setDefaulttab} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                            Next
                        </Button> */}
                    </Space>}
            </div>)}
        </div>
    )

}

export default ContractForm