import { useEffect, useRef } from 'react';
import { useIdle } from 'react-use';

const InactivityHandler = () => {
  const IDLE_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
  const idle = useIdle(IDLE_TIMEOUT);
  const timeoutRef:any = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && idle) {
        window.location.reload();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (idle) {
      timeoutRef.current = setTimeout(() => {
        if (!document.hidden) {
          window.location.reload();
        }
      }, IDLE_TIMEOUT);
    } else {
      clearTimeout(timeoutRef.current);
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(timeoutRef.current);
    };
  }, [idle]);

  return null;
};

export default InactivityHandler;