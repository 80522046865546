import { Breadcrumb, Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Skeleton, Space, Typography } from "antd";
import theme from "../../../utils/theme";
import dayjs from "dayjs";
import { ArrowLeftOutlined, CalendarOutlined, CloseCircleOutlined, CloseOutlined, PlusSquareFilled } from "@ant-design/icons";
import { hasMoreThanThreeDecimals, stopScroll } from "../../../utils/common";
import React, { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { statusStyle, tagStyle } from "./EnuiryDetails";
import { EnquiryStatus, enquiryStatusColors, enquiryStatusDisplay } from "../../../utils/healpers/constants";


export default function CreateEnquiry() {
    // let { state } = useLocation();
    const [form] = Form.useForm()
    const [modal, modalContex] = Modal.useModal();
    const [submitting, setSubmitting] = useState(false)
    const navigate = useNavigate()
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [duplicates, setDuplicates] = useState<number[] | []>([])
    const [state, setState] = useState<any>({})
    let { id } = useParams();
    const [loadingState, setLoadingState] = useState(false)


    useEffect(() => {
        if(id){
            fetchState()
        }
    }, [])

    useEffect(()=>{
        setFormValues()
    }, [state])

    const fetchState = async () => {
        setLoadingState(true)
        const params = {
            filterAnd: `id|eq|${id}`
        }
        try {
            const res: any = await instance.get("/clientservice/clients/enquiry", {params})
            if(res?.data?.items[0]?.status === "CONTRACT_CREATED"){
                navigate("/dashboard/contract-history")
            }

            if(res?.data?.items[0]?.status === "ACTION_PENDING" || res?.data?.items[0]?.status === "ENQUIRY_CANCELLED"){
                if(res?.data?.items[0]){
                    setState({...res?.data?.items[0], createDeliveryDtoList: res?.data?.items[0]?.deliveryEndPoints})
                }
            } else {
                navigate(`/dashboard/enquiry/${id}`)
            }
            setLoadingState(false)

            
        } catch(e) {
            setLoadingState(false)

            console.log(e)
        }
    }

    const setFormValues = () => {
        if (id) {
            form.setFieldsValue({
                "startDate": dayjs(state.startDate, "DD/MM/YYYY"),
                "endDate": dayjs(state.endDate, "DD/MM/YYYY"),
                // "tenure": state.tenure,
                "totalQuantity": state.totalQuantity,
                // "dcq": "400.000",
                "createDeliveryDtoList": state.createDeliveryDtoList?.map((item: any) => {
                    return item.deliveryEndPoint
                }),

                "description": state.description
            })
            getTenure()
            getTotalQunatity()
        }
    }

    const getTotalQunatity = () => {
        const totalQuantity = form.getFieldValue("totalQuantity");
        const tenure = form.getFieldValue("tenure")
        const dcq = totalQuantity / tenure
        const decimal = hasMoreThanThreeDecimals(dcq)

        if(decimal){
            form.setFieldsValue({
                dcq: dcq.toFixed(3)
            })
        } else {
            form.setFieldsValue({
                dcq: dcq
            }) 
        }
    }


    const getTenure = () => {
        const endDate = form.getFieldValue("endDate")
        const startDate = form.getFieldValue("startDate")
        const differencence = dayjs(endDate, "DD/MM/YYYY").endOf("day").diff(dayjs(startDate, "DD/MM/YYYY"), 'day') + 1
        form.setFieldsValue({
            tenure: differencence ? differencence : 0
        })
        const tenureInput: any = document?.getElementById("tenure-date")
        tenureInput.innerHTML = `${differencence ? differencence : 0} Days`
    }

    const getDcq = () => {
        const dcq = form.getFieldValue("dcq");
        const tenure = form.getFieldValue("tenure")
        const totalQuantity = dcq * tenure
        form.setFieldsValue({
            totalQuantity: totalQuantity ? totalQuantity.toFixed(3) : ""
        })
    }


    const showSuccessModal = () => {
        modal.success({
            // show success message along with image
            maskClosable: true,
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 60 }}>
                    <CloseOutlined style={{ position: "absolute", cursor: "pointer", top: 28, right: 28 }} onClick={() => { Modal.destroyAll(); navigate("/dashboard/enquiry") }} />
                    <img src="/assets/enquiry-success.png" width={232} />
                    <Typography.Title style={{ textAlign: "center", fontWeight: 500, margin: 0, fontSize: 16 }} level={3}>{id ? "Your Updates have been saved" : "Your enquiry is under review."}<br />
                        {!id && "Expect a revert anytime soon!"}</Typography.Title>
                    <div style={{ display: "flex", justifyContent: "center", gap: 20 }}>
                        <Button className="reset-button-calendar" style={{ width: 234 }} onClick={() => window.location.reload()} type="default" size="large">Add New Enquiry </Button>
                        <Button style={{ width: 234 }} onClick={() => navigate("/dashboard/enquiry")} type="primary" size="large">Ok</Button>
                    </div>

                </div>
            ),
            width: 567,
            bodyStyle: { display: "flex", justifyContent: "center", paddingTop: 40, paddingBottom: 40 },
            icon: null,
            footer: null,
        });
    }


    const createEnquiry = async (values: any) => {

        setSubmitting(true)

        console.log("values", values)

        const formData = {
            ...values,
            startDate: dayjs(values.startDate).format("DD/MM/YYYY"),
            endDate: dayjs(values.endDate).format("DD/MM/YYYY"),
            createDeliveryDtoList: values.createDeliveryDtoList.map((item: any) => {
                const existingItem = state?.createDeliveryDtoList?.find((i: any) => i.deliveryEndPoint === item)
                return existingItem ? { deliveryEndPoint: item, id: existingItem.id } : { deliveryEndPoint: item }
            })
        }

        let idsToDelete = []
        if (id) {
            const finalDEs = formData.createDeliveryDtoList.map((item: any) => item.deliveryEndPoint);


            const deletedIds = state.createDeliveryDtoList
                .filter((item: any) => !finalDEs.includes(item.deliveryEndPoint))
                .map((item: any) => item.id);

            console.log({ deletedIds });
            if (deletedIds.length > 0) {
                idsToDelete = deletedIds
            }
        }

        try {
            const res = await instance.post(`/clientservice/clients/enquiry?${idsToDelete.length > 0 ? 'deleteIds=' + idsToDelete.join(',') : ''}`, id ? { ...formData, id: id } : formData)
            console.log(res, "response")
            !id && form.resetFields()
            showSuccessModal()

        } catch (e) {
            console.log("error submitting", e)
        }
        setSubmitting(false)


    }
    const findDuplicateIndexes = (arr: string[]) => {
        const seen: Map<string, number[]> = new Map();

        arr.forEach((item, index) => {
            if (seen.has(item)) {
                seen.get(item)!.push(index);
            } else {
                seen.set(item, [index]);
            }
        });

        let duplicateIndexes: number[] = [];
        seen.forEach((indexes) => {
            if (indexes.length > 1) {
                duplicateIndexes = duplicateIndexes.concat(indexes);
            }
        });

        console.log(duplicateIndexes, "findDuplicateIndexes", arr);


        arr.map((i: any, index: number) => {
            const element = document.getElementById(`delivery-end-point-${index}`);
            if (element) {
                if (duplicateIndexes.includes(index)) {
                    element.style.borderColor = 'red'; // Change to the desired color
                } else {
                    element.style.borderColor = '#d9d9d9';
                }
            }
        })

        setDuplicates(duplicateIndexes)

    };

    const handleFormChange = () => {
        const deliveryEPs = form.getFieldValue("createDeliveryDtoList")
        const error: any = form.getFieldsError(["totalQuantity"])

        console.log("errors", error[0].errors)

        let hasDuplicate = deliveryEPs.some((val: any, i: any) => deliveryEPs.indexOf(val) !== i)

        findDuplicateIndexes(deliveryEPs)

        const formError = form.getFieldsValue()
        if (
            !!formError.startDate &&
            !!formError.endDate &&
            !!formError.totalQuantity &&
            !!formError.createDeliveryDtoList[0] && !hasDuplicate && error[0].errors.length === 0
        ) {
            setDisableSubmit(false)
        } else {
            setDisableSubmit(true)
        }

    }


    const showCancelSuccess = (msg: any, modal: any, image?: string) => {

        let secondsToGo = 2;

        const instance = modal.success({
            // show success message along with image
            mask: true,
            maskClosable: true,
            closeIcon: <></>,
            centered: true,
            closable: true,
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {image ? <img src={image} /> : <></>}
                    <Typography.Title style={{ textAlign: "center", margin: 0, marginTop: 20, fontWeight: 500, fontSize: 16 }}>{msg}</Typography.Title>
                </div>
            ),
            footer: null,
            icon: null,
            bodyStyle: { paddingTop: 20, display: "flex", justifyContent: "center" }
        });

        const timer = setInterval(() => {
            secondsToGo -= 1;
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
            instance.destroy();
        }, secondsToGo * 1000);
    }

    const cancelEnquiry = async () => {
        modal.confirm({
            title: <div>
                Are you sure you want to delete this enquiry?
                <div style={{ textAlign: "left", marginTop: 20 }}>
                    <Typography.Text style={{ fontSize: 14, color: "#707070", fontWeight: 500, paddingBottom: 12 }}>Remarks</Typography.Text>
                    <div style={{ marginTop: 8 }}>
                        <Form form={form} name="cancel-remark-form">
                            <Form.Item name="cancel-remark">
                                <Input.TextArea rows={4} placeholder="Remarks for cancellation" />
                            </Form.Item>
                        </Form>
                    </div>
                </div>

            </div>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            centered: true,
            cancelText: 'No',
            icon: null,
            onOk: async () => {
                const formValues = form.getFieldsValue()
                try {
                    // setLoading(true)
                    await instance.post(`clientservice/clients/enquiry/cancel-enquiry?enquiryId=${id}&remark=${formValues["cancel-remark"]}`)
                    showCancelSuccess(<>Enquiry ID {id} has been deleted</>, Modal, "/assets/cancel-enquiry.png")
                    navigate("/dashboard/enquiry")
                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", onClick: () => { form.setFieldValue("cancel-remark", ""); Modal.destroyAll() } }
        })
    }

    return (
       loadingState ? <Skeleton /> : <>
            {id ? <>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        <Space>
                            <Button
                                // onClick={() => navigate("/dashboard/client-list")} 
                                size="large" onClick={() => navigate("/dashboard/enquiry")} style={{ padding: 0, marginRight: 20 }} type="text"><ArrowLeftOutlined /></Button>
                            <Typography.Text style={{ fontSize: 24, color: theme.token.colorPrimary }} strong>
                                Contract Proposals
                            </Typography.Text>
                        </Space>
                        <Breadcrumb separator=">" style={{ marginBottom: 16, cursor: "pointer", marginLeft: 46 }}>
                            <Breadcrumb.Item
                            // onClick={_ => navigate(`/dashboard/client-list`)}
                            >
                                <span style={{ fontWeight: 600 }}>
                                    Enquiry ID - {id}
                                </span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <span style={{ fontWeight: 600 }}>
                                    View Details
                                </span>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    <div>
                        {state?.status !== EnquiryStatus.ENQUIRY_CANCELLED && <Button onClick={cancelEnquiry} style={{ fontSize: 16, color: "#4F4F4F" }} type="text"><CloseCircleOutlined />Delete Enquiry</Button>}
                    </div>


                </div>


                <div style={{ position: "relative", marginBottom: 80 }}>
                    <div style={statusStyle}>
                        <div style={{ ...tagStyle, alignItems: "center", background: "rgba(242, 146, 58, 0.08)", color: (enquiryStatusColors as any)[state?.status] }}>
                            {state?.status !== EnquiryStatus.ENQUIRY_CANCELLED && <div style={{ height: 22 }}>
                                <img src="/assets/clock-icon.png" />
                            </div>}
                            {(enquiryStatusDisplay as any)[state?.status]}
                        </div>
                    </div>
                </div>
            </> :
                <Space>
                    <Button
                        // onClick={() => navigate("/dashboard/client-list")} 
                        size="large" onClick={() => navigate("/dashboard/enquiry")} style={{ padding: 0, marginRight: 20 }} type="text"><ArrowLeftOutlined /></Button>
                    <Typography.Title level={4} style={{ color: theme.token.colorPrimary, margin: 0 }}>New Enquiry</Typography.Title>

                </Space>
            }



            {state?.status === EnquiryStatus.ENQUIRY_CANCELLED &&
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%", marginTop: 10 }}>
                        <div>
                            <Typography.Text>Remarks</Typography.Text>
                            <Input.TextArea style={{ marginTop: 8 }} disabled value={state.remark} rows={4} />
                        </div>

                    </div>
                </div>
            }




            <Form
                form={form}
                disabled={state?.status === EnquiryStatus.ENQUIRY_CANCELLED}
                name="contract-details"
                className="contract-form"
                onValuesChange={handleFormChange}
                onFinish={createEnquiry}
                layout={undefined}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                encType='multipart/form-data'
                autoComplete="off"
            >
                <div style={{ display: "flex", justifyContent: "center" }}>

                    <div style={{ width: "80%", marginTop: 10 }}>
                        <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Tenure <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                        <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10 }}>


                            <Row gutter={20} style={{ alignItems: "center" }}>
                                <Col span={6}>
                                    <Form.Item label="From"
                                        name="startDate"
                                        rules={[{ required: true, message: 'Please input start date!', type: 'date' }]} >
                                        <DatePicker allowClear={false} suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: theme.token.colorPrimary }} />} disabledDate={(date) => {
                                            return date && date.valueOf() < dayjs().valueOf()
                                        }}
                                            onChange={() => { form.resetFields(["endDate"]); getTenure(); getDcq(); handleFormChange() }}
                                            size='large' style={{ width: "100%", border: `solid 1px ${theme.token.colorPrimary}` }} format='DD/MM/YYYY' />
                                    </Form.Item>
                                </Col>
                                <Col span={1} style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 10, paddingLeft: "0px", paddingRight: "0px" }}>
                                    <img width="90%" src="/assets/date-line-long.png" />
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="To"
                                        name="endDate"
                                        rules={[{ required: true, message: 'Please input end date!', type: 'date' }]} >
                                        <DatePicker allowClear={false} onChange={() => { getTenure(); getDcq(); handleFormChange() }} suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: theme.token.colorPrimary }} />} disabledDate={(date) => {
                                            return date && date.valueOf() < dayjs(form.getFieldValue("startDate"), "DD/MM/YYYY").add(1, "day").valueOf()
                                        }} size='large' style={{ width: "100%", border: `solid 1px ${theme.token.colorPrimary}` }} format='DD/MM/YYYY' />
                                    </Form.Item>
                                </Col>
                                <div style={{ display: "none" }}>
                                    <Form.Item label="Tenure"
                                        name="tenure"
                                    >
                                        <Input disabled size="large" />
                                    </Form.Item>
                                </div>
                                <Col span={11}>
                                    <Row style={{ marginBottom: 18 }} gutter={[12, 12]}>
                                        <Col span={24}>
                                            <Typography.Text style={{ fontSize: 14 }}>Tenure</Typography.Text>
                                        </Col>
                                        <Col span={24}>
                                            <div id="tenure-date" style={tenureStyle}></div>
                                        </Col>
                                    </Row>


                                </Col>
                            </Row>



                        </div>

                        <Typography.Text style={{ marginLeft: 20, marginTop: 30, display: "block" }}>Quantity <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14 }}>*</span></Typography.Text>
                        <div style={{ background: "white", padding: "20px 30px 10px 30px", borderRadius: 16, marginTop: 10 }}>


                            <Row gutter={20} style={{ alignItems: "center" }}>

                                <Col span={11}>
                                    <Form.Item label="Daily Quantity (MMBTU)"
                                        name="dcq"
                                        rules={[
                                            { required: true, message: 'Please input dcq!' },
                                            () => ({
                                              validator(_, value) {
                                                if (isNaN(value) || parseInt(value) < 0) {
                                                    return Promise.reject(new Error('only numbers allowed'));
                                                }
                                                if (value && !/^\d+(\.\d{1,3})?$/.test(value.toString())) {
                                                  return Promise.reject(new Error('3 decimal places allowed'));
                                                }
                                                return Promise.resolve();
                                              }
                                            })
                                          ]}
                                        >
                                        <Input onChange={getDcq} onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={2} style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", marginTop: 10, paddingLeft: "0px", paddingRight: "0px" }}>
                                    <img width="90%" src="/assets/dcq-line-long.png" />
                                </Col>
                                <Col span={11}>
                                    
                                    <Form.Item label="Total Contract Quantity MMBTU"
                                        name="totalQuantity">
                                        <Input disabled onFocus={stopScroll} style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        <div style={{ marginTop: 30 }}>
                            <Row gutter={40} style={{ alignItems: "center" }}>

                                <Col span={16}>
                                    <Form.List
                                        name="createDeliveryDtoList"
                                        initialValue={[undefined]}
                                        rules={[
                                            {
                                                validator: async (_, names) => {
                                                    if (!names || names.length < 1) {
                                                        return Promise.reject(new Error('At least 1 delivery-endpoint'));
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        label={index === 0 ? <div style={{ marginLeft: 20 }}>Delivery End Points <span style={{ color: "red", fontFamily: "SimSun,sans-serif", fontSize: 14, marginLeft: 3 }}>*</span></div> : ''}
                                                        // required={true}
                                                        // name={[field.name, index]}
                                                        key={field.key}
                                                        style={{ marginBottom: 12 }}
                                                    >
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    // whitespace: true,
                                                                    validator: async (_, value) => {

                                                                        if (!value) {
                                                                            return Promise.reject(new Error("Please input delivery-endpoint"));
                                                                        }

                                                                    }
                                                                },
                                                            ]}
                                                            noStyle
                                                        >
                                                            {/* <Input onChange={()=>form.getFieldsValue()} size="large" placeholder="delivery endpoint" style={{ width: '60%' }} /> */}
                                                            <Input onInput={(e:any) => e.target.value = e.target.value.toUpperCase()} id={`delivery-end-point-${index}`} placeholder="Enter Delivery Point" style={{ width: 400 }} size="large" />
                                                        </Form.Item>
                                                        {" "}
                                                        {!(state?.status === EnquiryStatus.ENQUIRY_CANCELLED) && fields.length - 1 === index && index < 4 ? (
                                                            <PlusSquareFilled
                                                                style={{ color: "#2C66E3", marginLeft: 4 }}
                                                                onClick={() => add()}
                                                            />
                                                        ) : null}
                                                        {" "}
                                                        {!(state?.status === EnquiryStatus.ENQUIRY_CANCELLED) && fields.length > 1 && index !== 0 && index < 5 ? (
                                                            <img width={16} src="/assets/delete2.png"
                                                                style={{ color: "#FF3B30", position: "absolute", marginTop: 11, marginLeft: 4, cursor: "pointer" }}
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}

                                                    </Form.Item>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                            {duplicates.length > 0 && <span style={{color: "red"}} >Delivery end points must be unique</span>}
                        </div>

                        <div style={{ marginTop: 30 }}>
                            <Row gutter={40} style={{ alignItems: "center" }}>

                                <Col span={16}>
                                    <Form.Item label={<span style={{ marginLeft: 20, fontSize: 14 }}>Description and Other Remarks</span>}
                                        name="description"
                                        rules={[{ required: false }]} >
                                        <Input.TextArea rows={4} placeholder="Add your description here..." style={{ width: "100%", border: "none" }} size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        {state?.status != EnquiryStatus.ENQUIRY_CANCELLED && <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
                            <Space size="large">
                                <Button disabled={submitting || disableSubmit} className="reset-button-calendar" onClick={() => { id ? setFormValues() : form.resetFields(); setDisableSubmit(true) }} style={{ width: 200 }} size="large">
                                    Cancel
                                </Button>
                                <Button loading={submitting} disabled={submitting || disableSubmit} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                                    {id ? "Update" : "Submit"}
                                </Button>
                            </Space>
                        </div>}

                    </div>
                </div>

            </Form>
            {modalContex}
        </>

    )
}

const tenureStyle: React.CSSProperties = {
    width: "100%",
    height: 38,
    background: "#f7f7f7",
    borderRadius: 10,
    border: "1px solid #d9d9d9",
    color: 'rgb(122, 121, 121)',
    fontSize: 16,
    padding: "4px 11px",
    display: "flex",
    alignItems: "center",
}