import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message, Select, DatePicker, InputNumber } from "antd";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppSelector } from "../../../app/hooks";
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { stopScroll } from "../../../utils/common";
const { Option } = Select;

interface FormDetails {
    email: string,
    username: string,
    firstname: string,
    lastname: string,
    role: string[],
    contactNo: string,
    department: string,
    designation: string,
    location: string,
    date_of_joining: string,
}

const initFormState = {
    email: "",
    username: "",
    firstname: "",
    lastname: "",
    role: [],
    contactNo: "",
    department: "",
    designation: "",
    location: "",
    date_of_joining: ""
}



const EditUser = () => {
    const payload = useLocation().state;
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [fromData, setFormData] = useState<FormDetails>(initFormState);
    const [designation, setDesignation] = useState<any>([]);
    const [role, setRole] = useState<any>();
    const auth = useAppSelector(authSelector);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState<any>(null)

    const prevDate = dayjs(payload.data.dateOfJoining).format("YYYY-MM-DD");

    const onFinish = async (values: any) => {
        setFormData(values);

        for (let key in values) {
            if (values[key] === undefined) {
                delete values[key];
            }
        }
        if (values.role)
            values.role = [values.role]
        setLoading(true)

        try {
            // Note: update api endpoint
            await axios.put(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/${payload.data.userId}`, values, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if (res.status === 200) {
                    messageApi.success("User details updates!");
                    setTimeout(() => {
                        navigate("/dashboard/users")
                    }, 1000);
                }
                else
                    messageApi.error("Failed to update user details!");
            })
        } catch (err) {
            console.log(err);
            messageApi.error("Somthing went wrong");
        }
        setLoading(false)
    };

    // fetching all roles
    useEffect(() => {
        fetchData();
    }, [search]);

    const fetchData = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/roles`, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                },
                params: {
                    filterAnd: !search ? "" : `name|like|${search}`
                }
            }).then(res => {
                let input: any = [];
                res.data.map((e: any) => {
                    input.push({
                        label: e.name,
                        value: e.name
                    });
                })
                setRole(input);
                setDesignation([{
                    label: "Chief Operations Officer (COO)",
                    value: "Chief Operations Officer (COO)"
                },
                {
                    label: "Chief Executive Officer (CEO)",
                    value: "Chief Executive Officer (CEO)"
                },
                {
                    label: "Director",
                    value: "Director"
                },
                {
                    label: "Chief compliance officer",
                    value: "Chief compliance officer"
                },
                {
                    label: "President",
                    value: "President"
                },
                {
                    label: "Chief financial officer (CFO)",
                    value: "Chief financial officer (CFO)"
                },
                {
                    label: "Chief Technology Officer (CTO)",
                    value: "Chief Technology Officer (CTO)"
                },
                {
                    label: "Vice-president",
                    value: "Vice-president"
                },
                {
                    label: "Sales Manager",
                    value: "Sales Manager"
                }])
            })

        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
    };

    const optionsToRender = (options: any[]) => {
        return options.map((value: any) => (
            <Option key={value.id} value={value.name}>
                {value.name}
            </Option>
        ));
    };

    return (
        <React.Fragment>
            {contextHolder}
            <Form
                // initialValues={initialValues}
                form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <h4>Edit User</h4>
                <Row className="">
                    <Col className="form-column">
                        <Form.Item
                            label="Name"
                            name="firstName"
                        >
                            <Input size="large" defaultValue={payload.data.name} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="First name" />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                        >
                            <Input size="large" disabled defaultValue={payload.data.emailId} prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            label="Username"
                            name="username"
                        >
                            <Input size="large" disabled defaultValue={payload.data.username} prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            label="Designation"
                            name="designation"
                            // rules={[{ required: true, message: 'Please enter designation!' }]}
                        >
                            <Select
                                size="large"
                                defaultValue={payload.data.designation}
                                style={{ width: '100%' }}
                                placeholder="Roles"
                                options={designation}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Location"
                            name="location"
                        // rules={[{ required: true, message: 'Please enter location!' }]}
                        >
                            <Input
                                size="large"
                                defaultValue={payload.data.location}
                                style={{ width: '100%' }}
                                placeholder="Locations"
                            // options={location}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="form-column">
                        <Form.Item
                            label="Date of joining"
                            name="date_of_joining"
                        // rules={[{ required: true, message: 'Please enter date of joining!' }]}
                        >
                            <DatePicker
                                size="large"
                                disabled
                                defaultValue={dayjs(prevDate, "YYYY/MM/DD")}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Role"
                            name="role"
                        // rules={[{ required: true, message: 'Please input user roles!' }]}
                        >
                            <Select
                                showSearch
                                size="large"
                                defaultValue={payload.data.role}
                                style={{ width: '100%' }}
                                placeholder="Roles"
                                onSearch={(value)=>setSearch(value)}
                                options={role}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Department"
                            name="department"
                        // rules={[{ required: true, message: 'Please enter department!' }]}
                        >
                            <Input
                                size="large"
                                defaultValue={payload.data.department}
                                style={{ width: '100%' }}
                                placeholder="Department"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Contact Number"
                            name="contactNo"
                        // rules={[{ required: true, message: 'Please input your contact number!' }]}
                        // rule for 10 digit number
                        rules={
                            [
                                { pattern: /^[0-9]{10}$/, message: 'Please input a valid contact number!' }
                            ] as any[] // typecasting to satisfy the rule type
                        
                        }
                        >
                            <InputNumber onFocus={stopScroll} type='number' style={{width: "100%"}} size="large" maxLength={10} defaultValue={payload.data.contactNo} placeholder="Contact number" />
                        </Form.Item>
                    </Col>
                    {/* <Col span={14}>
                        <Form.Item
                            label="Remark"
                            name="remark"
                        >
                            <TextArea
                                size="large"
                                showCount
                                maxLength={100}
                                style={{ height: 80 }}
                                placeholder="Add remark..."
                            />
                        </Form.Item>
                    </Col> */}
                </Row>

                <Form.Item>
                    <Button loading={loading} size="large" type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};

export default EditUser;