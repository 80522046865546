import { Breadcrumb, Table } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { defaultColumns } from "../../../interfaces/TableColumns/NominationHistoryFornight";
import { useEffect, useState } from "react";
import { ColumnTypes } from "../../../interfaces/TableColumns/NominationDetail";
import dayjs from "dayjs";
import { instance } from "../../../utils/api";
import { UploadInvoiceDropdown } from "./UploadInvoice";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";

interface Item {
    id: number;
    gasDay: string,
    dcq: number,
    nominatedQty: number,
    confirmedQty: number | null,
    deliveryEndPoint: string,
    deliveryId: string,
    sourceId: string,
    pipelineId: string,
    status: string,
    notification: string,
    lastUpdatedBy: string,
    nominationDetails: any
}



export default function FortnightDetails() {
    let { state, pathname } = useLocation();

    const navigate = useNavigate()
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [data, setData] = useState<Item[] | []>([]);
    const [urlState, setUrlState] = useState(state);
    const auth = useAppSelector(authSelector);

    let { id, contractId, nominationId } = useParams();

    useEffect(() => {
        fetchDetails()
    }, []);


    const fetchDetails = async () => {
        const params = {
            sort: "gasDay|ASC&auditInfo.creationDate|ASC",
            size: 10000,
            filterAnd: `gasDay|gted|${urlState?.record.startDateFortnight}&gasDay|lted|${urlState?.record.endDateFortnight}&nomination.id|eq|${nominationId}`
        }
        setLoadingDetails(true)
        try {
            const res = await instance.get(`orderservice/nominate/details`, { params });
            setData(res.data.items)
        }
        catch (e) {
            console.log(e)
        }
        setLoadingDetails(false)
    }


    const exppandedCols = (record: Item, index: any, col: any, notifying?: boolean) => {
        if ((col.dataIndex === "gasDay" || col.dataIndex === "dcq") && !(record.hasOwnProperty('nominationDetails')) && data.filter((i) => i.gasDay === record.gasDay)[0].nominationDetails?.[0].id === record.id) {
            return {
                rowSpan: data.filter((i) => i.gasDay === record.gasDay)[0].nominationDetails.length,
                className: col.dataIndex === "dcq" ? `child-border` : "child-border-left",
            };
        } else if ((col.dataIndex === "gasDay" || col.dataIndex === "dcq") && !(record.hasOwnProperty('nominationDetails')) && data.filter((i) => i.gasDay === record.gasDay)[0].nominationDetails?.[0].id != record.id) {

            return {
                rowSpan: 0,
            };
        } else {
            return {
                rowSpan: 1
            };
        }

    }

    const columns = defaultColumns.map((col) => {
        return {
            ...col,
            // onCell: (record: Item) => ({
            //     rowSpan: col.dataIndex === "gasDay" && !record.nominationDetails && data.filter((i)=>i.gasDay === record.gasDay)[0].nominationDetails[0].id === record.id ? data.filter((i)=>i.gasDay === record.gasDay)[0].nominationDetails.length : 1
            // }),
            onCell: (record: Item, index: any) => exppandedCols(record, index, col)
        }
    });

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <Breadcrumb separator=">" style={{ marginBottom: 0, cursor: "pointer" }}>
                {pathname.split("/")[2] === "joint-ticket" ? <Breadcrumb.Item
                    onClick={_ => navigate(`/dashboard/joint-ticket`)}
                >
                    <span style={{ fontWeight: 600 }}>
                    Invoice

                    </span>
                </Breadcrumb.Item> : <Breadcrumb.Item
                    onClick={_ => navigate("/dashboard/nomination-history")}
                >
                    <span style={{ fontWeight: 600 }}>
                    Nomination History
                    </span>
                </Breadcrumb.Item>}
                {pathname.split("/")[2] != "joint-ticket" && <Breadcrumb.Item>
                    <Link to={`/dashboard/nomination-history/${urlState.clientId}`} state={{ name: urlState.clientName }}>
                        <span style={{ fontWeight: 600 }}>
                            {urlState.clientName}- View Details
                        </span>
                    </Link>
                </Breadcrumb.Item>}
                <Breadcrumb.Item>
                    <Link
                        to={`/dashboard/${pathname.split("/")[2] === "joint-ticket" ? "joint-ticket":"nomination-history"}/${urlState.clientId}/${urlState.contractId.replace(/\//g, "-")}`}
                        state={{
                            clientId: urlState.clientId,
                            name: urlState.clientName,
                            contractStatus: urlState.contractStatus,
                            contractId: urlState.contractId,
                            daysLeft: dayjs(urlState.endDate, "DD/MM/YYYY").isAfter(dayjs(), 'day') || dayjs(urlState.endDate, "DD/MM/YYYY").isSame(dayjs(), 'day') ?
                            dayjs(urlState.endDate, "DD/MM/YYYY").endOf("day").diff(dayjs(), 'day') + 1 : 0,
                            startDate: urlState.startDate,
                            endDate: urlState.endDate,
                            nominationId: nominationId,
                            clientCode: urlState.clientCode,
                            tradeId: urlState?.tradeId,
                        }}
                    >
                        <span style={{ fontWeight: 600 }}>
                            {urlState.clientName} Details- {urlState.contractId}
                        </span>
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ fontWeight: 600}}>
                        {dayjs(urlState?.record?.startDateFortnight, "DD/MM/YYYY").format("DD MMM YYYY")} - {dayjs(urlState?.record?.endDateFortnight, "DD/MM/YYYY").format("DD MMM YYYY")}
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ textAlign: "right" }}>
                <UploadInvoiceDropdown
                    icon={false}
                    record={urlState.record}
                    contractId={urlState.contractId}
                    startDate={urlState.startDate}
                    endDate={urlState.endDate}
                    fetchData={() => { }}
                    setUrlState={setUrlState}
                />
            </div>

            <Table
                loading={loadingDetails}
                rowClassName={(record) => `table-row ${record.hasOwnProperty('nominationDetails') ? "parent-row" : "child-row"} ${record.nominationDetails?.length > 1 ? "mg-expandable-row" : ""}`}
                columns={columns as ColumnTypes}
                dataSource={data.map((item: any) => {
                    return { ...item, nominationDetails: item.nominationDetails?.length > 1 ? item.nominationDetails : null }
                })
                }
                pagination={false}
                rowKey={(record) => { return record.hasOwnProperty('nominationDetails') ? record.id + "parent" : record.id + "child" }}
                scroll={{ x: true }}
                expandable={{
                    childrenColumnName: "nominationDetails",
                    rowExpandable: (record) => record.nominationDetails.length > 1,
                    indentSize: 0,
                    expandIcon: () => null,
                    onExpand: (expanded, record) => {
                        const rowItem = document.querySelectorAll(`[data-row-key="${record.id}parent"]`);
                        // add class name to rowItem
                        if (rowItem.length > 0) {
                            // Iterate through each rowItem and add the class name
                            console.log("rowItem", rowItem)
                            rowItem.forEach(item => {
                                console.log("expanded", expanded)
                                if (expanded) {
                                    item.classList.add("mg-expandabled-row")
                                } else {
                                    item.classList.remove("mg-expandabled-row")
                                }
                            });
                        }

                    },
                    expandRowByClick: true,
                }}
            />
        </div>
    )
}