import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { instance } from '../../../utils/api';
import PocForm from './PocForm';
import { Modal, Skeleton } from 'antd';
import UpdateStatusLabel from './UpdateStatusLabel';
import theme from '../../../utils/theme';
import PocUpdateModal from './PocUpdateModal';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';

type FieldType = {
    email?: string;
    name?: string;
    state?: string;
    designation?: string
    phone?: string
};

type PropType = { id: string | undefined, setDefaulttab: Dispatch<SetStateAction<any | null>>, clientData: any, setTabStates: any, getClientDetails: any, updateStatus: string, approveOrReject: any, setInProgrss?:any, tabStates?:any }

function PocDetails({ id, setDefaulttab, clientData, setTabStates, getClientDetails, updateStatus, approveOrReject,tabStates,setInProgrss }: PropType) {
    const [pocDetails, setPocDetails] = useState<FieldType[] | []>([])
    const [fetchingDetails, setFetchingDetails] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const auth = useAppSelector(authSelector);

    useEffect(() => {
        if(setInProgrss){
            if(!tabStates?.isBankDetailsCompleted){
                setInProgrss(true)
            } else {
                setInProgrss(false)
            }
        }
        
        getPocDetails();
    }, [])

    const getPocDetails = async () => {
        setFetchingDetails(true)
        try {
            const pocDetails = await instance.get(`clientservice/clients/${id}/poc`);
            setPocDetails(pocDetails.data)
            
        } catch (e) {
            console.log(e);
        }
        setFetchingDetails(false)
    }

   

    return (
        <React.Fragment>
            <UpdateStatusLabel label="POC" showModal={() => setIsModalOpen(true)} updateStatus={updateStatus} />

            {!fetchingDetails ? <PocForm setTabStates={setTabStates} setInProgrss={setInProgrss} getClientDetails={getClientDetails} clientData={clientData} id={id} data={pocDetails} setPocDetails={setPocDetails} setDefaulttab={setDefaulttab} getPocDetails={getPocDetails} />
                : <><Skeleton /></>}
            {/* <PocForm id={id} setPocDetails={setPocDetails} /> */}

            <Modal destroyOnClose width={869} footer={null} style={{ textAlign: "center" }} bodyStyle={{ paddingBottom: 16 }} title={<div style={{ marginTop: 16, color: theme.token.colorPrimary }}>
                {(clientData?.pocUpdateStatus === "UPDATED_BY_ADMIN" && (auth.isClient)) && "Admin has edited your POC details, please review the information."}
                {(clientData?.pocUpdateStatus === "UPDATED_BY_ADMIN" && (auth.isAdmin || auth.isMainAdmin)) && "POC details has been updated."}

                {(clientData?.pocUpdateStatus === "UPDATED" && (auth.isClient)) && "POC details has been updated."}
                {(clientData?.pocUpdateStatus === "UPDATED" && (auth.isAdmin || auth.isMainAdmin)) && "Client has edited your POC details, please review the information."}
            </div>} open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>
                <PocUpdateModal pocDetails={pocDetails} approveOrReject={(type: any) => { approveOrReject(type, getPocDetails); setIsModalOpen(false) }} clientData={clientData} />
            </Modal>
        </React.Fragment>
    )

};

export default PocDetails;