import { Tag, Tooltip, Typography } from 'antd';
import { NotificationStatus, NotificationStatusColors, NotificationStatusDisplay } from '../../utils/healpers/constants';
import { ColumnTypes } from './NominationDetail';
// type nominationType = Item["nomination"]

export const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    // {
    //     title: 'Date',
    //     dataIndex: 'gasDay',
    //     key: 'gasDay',
    //     render: (value:string) => <div style={{ width: 100 }}>{dayjs(value, "DD/MM/YYYY").format("DD MMM YYYY")}</div>,
    //     align: "center",
    // },
    {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        render: (_,record) => <div style={{ width: 100, position: "relative", height: 22 }}>
            <span>
                <Tooltip title={record.nomination.client}>
                <Typography.Text ellipsis={true}>
                    {record.nomination.client}
                </Typography.Text>
                    </Tooltip>
                </span></div>,
        align: "center",
        
        // sorter: true,
    },
    {
        title: 'Client Code',
        dataIndex: 'clientCode',
        key: 'clientCode',
        render: (_,record) => <div style={{width: 90, position: "relative", height: 22}}>
            <span>
                {record.nomination.clientCode}
            </span>
        </div>,
        align: "center",
        // sorter: true,
    },
    {
        title: 'Contract ID',
        dataIndex: 'contractId',
        key: 'contractId',
        render: (_,record) => <div style={{ width: 100, position: "relative", height: record.nominationDetails?.length < 2 ? 22 : "unset" }}>
            <span style={{ position: record?.nominationDetails?.length < 2 ? "absolute" : "unset", width: "100%", left: 0, zIndex: 1 }}>
                {record.nomination.contractId}
            </span>
            </div>,
        align: "center",
        // sorter: true,
    },
    {
        title: 'IGX Trade ID',
        dataIndex: 'tradeId',
        key: 'tradeId',
        render: (_,record) => <div style={{ width: 110, position: "relative", height: 22 }}>
                <span style={{ position: "absolute", width: "100%", left: 0, zIndex: 1 }}>
                    {record.nomination.tradeId}
                </span>
            </div>,
        align: "center",
        // sorter: true
    },
    {
        title: 'DCQ',
        dataIndex: 'dcq',
        key: 'dcq',
        render: (_,record) => <div style={{ width: 110, position: "relative", height: 22 }}>
                <span style={{ position: "absolute", width: "100%", left: 0, zIndex: 1 }}>
                    {record.nomination.dcq}
                </span>
            </div>,
        align: "center",
        // sorter: true
    },
    {
        // @ts-ignore
        title: <div style={{textWrap: "nowrap"}}>Nomianted Qty.<br/>(MMBTU)</div>,
        dataIndex: 'nominatedQty',
        key: 'nominatedQty',
        render: (value:number) => <div style={{ width: 150 }}>{value}</div>,
        align: "center",
        // sorter: true
    },
    {
        // @ts-ignore
        title: <div style={{textWrap: "nowrap"}}>Tentative Schedule<br/>Qty. (MMBTU)</div>,
        dataIndex: 'confirmedQty',
        key: 'confirmedQty',
        render: (value:number) => <div style={{ width: 150 }}>{value}</div>,
        align: "center",
        // sorter: true
    },

    {
        // @ts-ignore
        title: <div style={{textWrap: "nowrap"}}>Scheduled Qty.<br/>(MMBTU)</div>,
        dataIndex: 'scheduledQty',
        key: 'scheduledQty',
        align: "center",
        // sorter: true
    },
    {
        // @ts-ignore
        title: <div style={{textWrap: "nowrap"}}>Allocated Qty.<br/>(MMBTU)</div>,
        dataIndex: 'allocatedQty',
        key: 'allocatedQty',
        editable: true,
        align: "center",
        // sorter: true
        
    },
    {
        // @ts-ignore
        title: <div style={{textWrap: "nowrap"}}>Notification</div>,
        dataIndex: 'notificationStatus',
        key: 'notificationStatus',
        render: (notificationStatus:
            NotificationStatus, record:any
            ) => {
                if(record?.nominationDetails?.length > 1){
                    return <div style={{width: 100}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
                } else {
                    const displayValue = NotificationStatusDisplay[notificationStatus];
                    return <Tag style={{ fontSize: 14 }} color={NotificationStatusColors[notificationStatus]}>{displayValue}</Tag>
                }          
            },
        align: "center",
        // sorter: true
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'nominationStatus',
    //     key: 'nominationStatus',
    //     render: (nominationStatus:
    //         NominationStatus
    //     ) => {
    //         const displayValue = NominationStatusDisplay[nominationStatus];
    //         return <Tag style={{ fontSize: 14 }} color={nominationStatusColors[nominationStatus]}>{displayValue}</Tag>
    //     },
    //     align: "center",
    //     sorter: true
    // },
    {
        title: 'Source ID',
        dataIndex: 'sourceId',
        editable: true,
        align: "center",
        render: (sourceId, record) => {
            if(record?.nominationDetails?.length > 1){
                return <div style={{minWidth: 173}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
            } else {
                return <div style={{minWidth: 173}}>{sourceId}</div>
            }
        }
    },
    {
        title: 'Pipeline ID',
        dataIndex: 'pipelineId',
        editable: true,
        align: "center",
        render: (pipelineId, record) => {
            if(record?.nominationDetails?.length > 1){
                return <div style={{minWidth: 173}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
            } else {
                return <div style={{minWidth: 173}}>{pipelineId}</div>
            }
        }
    },
    {
        title: 'Delivery end point',
        dataIndex: 'deliveryEndPoint',
        editable: true,
        align: "center",
        render: (deliveryEndPoint:
            any
        , record: any) => {
            if(record?.nominationDetails?.length > 1){
                return <div style={{minWidth: 173}}>Multiple <Tag style={{margin: 0}} color="blue">+{record?.nominationDetails?.length}</Tag></div>
            } else {
                return <Tooltip title={deliveryEndPoint}
                ><Typography.Text style={{width: 140}} ellipsis>
                                                {deliveryEndPoint}
                                            </Typography.Text></Tooltip>
            }
        },
    },
    {
        title: 'Last updated by',
        dataIndex: 'auditInfo',
        render: (auditInfo) => {return !auditInfo?.updatedBy ? auditInfo?.createdBy : auditInfo?.updatedBy},
        align: "center"
    },
    {
        dataIndex: 'deliveryId',
        editable: true,
        align: "center",
        render:()=> null
    },
];