import { Breadcrumb, Button, Col, Divider, Form, Input, Modal, Row, Skeleton, Space, Typography, message } from "antd";
import { useAppSelector } from "../../app/hooks";
import { userSelector } from "../../features/auth/userSlice";
import { useEffect, useState } from "react";
import { showSuccess, stopScroll } from "../../utils/common";
import { specialCharacterRegex } from "../Registration/SignUp";
import { instance } from "../../utils/api";
import axios from "axios";
import { authSelector } from "../../features/auth/authSlice";

export default function AdminProfile() {


    const user = useAppSelector(userSelector);
    const [enableEdit, setEnableEdit] = useState(false)
    const [form] = Form.useForm()
    const [submiting, setSubmiting] = useState(false)
    const auth = useAppSelector(authSelector);
    const [changedPassword, setChangedPassword] = useState(false)
    const [loading, setLoading] = useState(true);
    const [adminData, setAdminData] = useState<any>({})
    const [submitEnabled, setSubmitEnabled] = useState(false)


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const params = {
            filterAnd: `emailId|eq|${user.email}`
        }
        setLoading(true)
        try {
            const response = await instance.get(`/management/users`, { params });
            console.log("data", response.data)
            setAdminData(response.data[0])
            handleCancel()
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setLoading(false)
    };


    useEffect(()=>{
        setFormData()
    }, [adminData])

    const setFormData = () => {
        form.setFieldsValue({
            "name": adminData?.name,
            "username": adminData?.username,
            "designation": adminData?.designation,
            "department": adminData?.department,
            "contactNo": adminData?.contactNo
        })
    }

    const handleSave = async (values: any) => {
        console.log("values", values);

        const profileParams = {
            contactNo: values.contactNo,
            department: values.department,
            designation: values.designation,
            firstName: values.name,
            username: values.username
        }

        setSubmiting(true)
        try {


            await axios.put(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/${adminData?.userId}`, profileParams, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(async (res) => {
                if (res.status === 200) {
                    if (values.password) {
                        await axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/reset-password-auth?username=${values.username}&password=${values.password}`, {}, {
                            headers: {
                                "Authorization": `Bearer ${auth.accessToken}`,
                                "Content-Type": "application/json"
                            }
                        }).then(res=>{
                            console.log(res)
                        }).catch(e=>{
                            console.log(e)
                        })
                    }
                    showSuccess("Profile Updated Successfully", Modal)
                    fetchData()
                }
            })
        } catch (e) {
            console.log(e)
        }
        setSubmiting(false)

    }

    const handleFormChange = () => {
        const formData = form.getFieldsValue();

        const errorCount = form.getFieldsError().filter(({ errors }) => errors.length).length
        console.log("error Count", errorCount)
        if ((formData.name !== adminData?.name ||
            formData.username !== adminData?.username ||
            formData?.designation !== adminData?.designation ||
            formData?.department !== adminData?.department ||
            formData?.contactNo !== adminData?.contactNo) && errorCount === 0) {
            if (formData.password && !formData.confirmPassword) {
                setSubmitEnabled(false)
            } else {
                setSubmitEnabled(true)
            }
        } else {
            if (formData.password && formData.confirmPassword && errorCount === 0) {
                setSubmitEnabled(true)
            } else {
                setSubmitEnabled(false)
            }
        }

    }

    const handleCancel = () => {
        form.resetFields();
        setEnableEdit(false);
        setChangedPassword(false)
        setFormData()
    }

    return <>

        {enableEdit && <Breadcrumb separator=">" style={{ marginBottom: 16, cursor: "pointer" }}>
            <Breadcrumb.Item
                onClick={_ => { }}
            >
                <span style={{ fontWeight: 600 }}>
                    Profile
                </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <span style={{ fontWeight: 600 }}>
                    Edit Profile
                </span>
            </Breadcrumb.Item>
        </Breadcrumb>}

        <Typography.Title level={3}>Matrix</Typography.Title>

        {loading ? <Skeleton /> : <Form
            onChange={() => setTimeout(() => { handleFormChange(); }, 500)}
            validateTrigger={['onChange', 'onBlur']}
            form={form}
            // disabled={loading}
            name="client-details"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={handleSave}
            autoComplete="off"
        >
            <Row gutter={24}>
                <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: 400 }} orientation="left">Credentials</Divider>
                    </div>
                </Col>

                <Col span={8}>
                    <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        label="Name"
                        name="name"
                        rules={[{ required: true }]}
                    >
                        <Input disabled={!enableEdit} size="large" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        label="Designation"
                        name="designation"
                        rules={[{ required: true }]}
                    >
                        <Input disabled={!enableEdit} size="large" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        label="Department"
                        name="department"
                        rules={[{ required: true }]}
                    >
                        <Input disabled={!enableEdit} size="large" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        label="Email ID"
                        name="username"
                        rules={[{ required: true, type: "email" }]}
                    >
                        <Input disabled={true} size="large" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        label="Mobile No."
                        name="contactNo"
                        rules={[{ required: true, len: 10 }]}
                    >
                        <Input disabled={!enableEdit} onFocus={stopScroll} type="number" size="large" style={{ width: "100%" }} prefix={<div style={{ display: "flex", alignItems: "center", gap: 8 }}><span>+91-</span><span style={{ height: 26, width: 1, background: "#DADADA" }}></span></div>} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Divider orientationMargin="0" style={{ minWidth: "unset", fontWeight: 400 }} orientation="left">Passsword</Divider>
                    </div>
                </Col>

                <Col span={8}
                    style={{ position: "relative", marginBottom: 16 }}
                >
                    <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        label={<div style={{ display: "flex", gap: 2 }}><div>{enableEdit && "New"} Password</div>{changedPassword && <div style={{ color: "orangered" }}>*</div>}</div>}
                        name="password"
                        rules={[{ required: false },
                        {
                            validator: async (_, value) => {
                                const password = form.getFieldValue("password")
                                if (changedPassword) {
                                    if (!value) {
                                        return Promise.reject(new Error('Please enter New Password'));
                                    } else {
                                        if (!(value && value.length >= 8 && specialCharacterRegex.test(value))) {
                                            return Promise.reject(new Error('Minimum 8 character and special character'));
                                        }
                                    }
                                }

                            }
                        }

                        ]}
                    >
                        <Input
                            disabled={!enableEdit}
                            onChange={(event) => {

                                const password = form.getFieldValue("confirmPassword")

                                if (event.target.value || password) {
                                    setChangedPassword(true)
                                } else {
                                    setChangedPassword(false)
                                }

                                if (password !== event.target.value && password) {
                                    form.setFields([
                                        {
                                            name: 'confirmPassword',
                                            errors: ['Password did not match'],
                                        },
                                    ]);
                                } else {
                                    form.setFields([
                                        {
                                            name: 'confirmPassword',
                                            errors: [],
                                        },
                                    ]);
                                }


                            }}
                            placeholder={!enableEdit ? "**********" : "Enter new password"} type='password' size="large"
                        />

                    </Form.Item>
                    {/* <span style={{ position: "absolute", display: "flex", bottom: 2 }}>
                        <Typography.Text style={{ color: "#979797" }}>Minimum 8 character and special character</Typography.Text>
                    </span> */}
                </Col>
                {enableEdit && <Col span={8}>
                    <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        label={<div style={{ display: "flex", gap: 2 }}><div>Confirm Password</div>{changedPassword && <div style={{ color: "orangered" }}>*</div>}</div>}
                        name="confirmPassword"
                        rules={[
                            { required: false },
                            {
                                validator: async (_, value) => {
                                    const password = form.getFieldValue("password")

                                    if (changedPassword) {
                                        if (!value) {
                                            return Promise.reject(new Error('Please confirm password'));
                                        } else {
                                            if (value !== password) {
                                                return Promise.reject(new Error(`Your Password does't match`));
                                            }
                                        }
                                    }

                                }
                            }
                        ]}
                    >
                        <Input onChange={(event: any) => {
                            const password = form.getFieldValue("password")

                            if (event.target.value || password) {
                                setChangedPassword(true)
                            } else {
                                setChangedPassword(false)
                            }
                        }} disabled={!enableEdit} placeholder="Confirm password" type='password' size="large" />
                    </Form.Item>
                </Col>}

                <Col style={{ textAlign: "center", marginTop: 40 }} span={24}>
                    {!enableEdit ?
                        <Button style={{ width: 200 }} onClick={() => setEnableEdit(true)} size="large" type="primary">Edit</Button> :
                        <Space size="large">
                            <Button onClick={() => handleCancel()} style={{ width: 200 }} size="large" className="reset-button-calendar">Cancel</Button>
                            <Button loading={submiting} disabled={submiting || !submitEnabled} style={{ width: 200 }} htmlType="submit" size="large" type="primary">Save</Button>
                        </Space>}
                </Col>
            </Row>

        </Form>}


    </>
}