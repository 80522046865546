import { Button, Col, Form, Input, Row, Typography } from "antd";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import ViewPDF from "../../../components/ViewPDF";

export default function KycUpdateModal({ approveOrReject, clientData }: any) {
    const auth = useAppSelector(authSelector);

    return <>
        <Form
            style={{ marginTop: "20px" }}
            // disabled={loading}
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
        >
            <Row gutter={[24, 24]}>

                {clientData?.clientHistory?.companyRegisteredName &&
                    <Col span={24}>
                        <Form.Item style={{textAlign: "left"}} label="Company Registered Name">
                            <Input style={{width:300}} value={clientData?.clientHistory?.companyRegisteredName} disabled size="large" />
                        </Form.Item>
                    </Col>
                }

                {clientData?.kycDocumentHistoryList?.map((item: any, key: any) => {
                    return item &&
                        <Col span={24}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                {item?.kycId && <Form.Item
                                    style={{ width: "40%" }}
                                    label={item?.type + " No."}
                                >
                                    <Input value={item?.kycId} disabled size="large" />

                                </Form.Item>}

                                {item?.url && <ViewPDF name={`${item?.type} ${item?.type === "PAN" ? "Card" : "Certificate"}`} url={item?.url} />}
                            </div>

                        </Col>
                })}
            </Row>

        </Form>
        {((auth.isClient && clientData?.kycUpdateStatus === "UPDATED_BY_ADMIN") || ((auth.isMainAdmin || auth.isAdmin) && clientData?.kycUpdateStatus === "UPDATED"))
            && <div style={{ display: "flex", gap: 40, justifyContent: "center", marginTop: 20 }}>
                <Button onClick={() => approveOrReject("KYC_REJECTED")} className="reset-button-calendar" size="large" type="default">Reject</Button>

                <Button onClick={() => approveOrReject("KYC_APPROVED")} style={{ width: 120 }} size="large" type="primary">Approve</Button>
            </div>}
    </>
}