import { Breadcrumb, Button, DatePicker, Form, List, Modal, Select, Skeleton, Space, Spin, Typography, message } from "antd";
import theme from "../../../utils/theme";
import { DownOutlined, LeftOutlined, LoadingOutlined, RightOutlined, UpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import dayjs from "dayjs";
import CreateNominationForm from "./CreateNominationForm";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import SuccessAnimation from "../../../utils/success.json";
import { handleMenuItemClick } from "../../../utils/common";
import { useAppSelector } from "../../../app/hooks";
import { dashboardSelector } from "../../../features/auth/dashboardSlice";
import { authSelector } from "../../../features/auth/authSlice";

const { RangePicker } = DatePicker;

// const data = [{
//     gasDay: "02 May 2023",

// }]

function NominationEntry() {

    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [clients, setClients] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [form] = Form.useForm();
    const [selectedClient, setSelectedClient] = useState<any>(null)
    const [selectedContract, setSelectedContract] = useState<any>(null)
    const [modal, modalContex] = Modal.useModal();
    const [calendarEnabled, setCalendarEnabled] = useState(false)
    const [fetchingClients, setFetchingClients] = useState(false)
    const [fetchingContracts, setFetchingContracts] = useState(false)
    const [fetchingNomination, setFetchingNomination] = useState(false);
    const [nominations, setNominations] = useState([])
    const dashboard:any = useAppSelector(dashboardSelector)
    const [messageApi, contextHolder] = message.useMessage();
    const [selectBoxOpen, setSelectBoxOpen] = useState(true);
    const location = useLocation();
    const auth = useAppSelector(authSelector);

    const navigate = useNavigate();

    const getClients = async () => {
        setFetchingClients(true)
        try {
            const response = await instance.get(`/clientservice/clients/not-expired-contracts`);
            setClients(response.data);
            if(auth.isClient && response?.data?.length === 1){
                setSelectedClient(response.data[0])
            }
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setFetchingClients(false)
    }

    const handleSuccess = () => {
        handleReset()
        modal.success({
            // show success message along with image
            mask: true,
            maskClosable: true,
            closeIcon:<></>,
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10 }}>
                    <Lottie loop={false} style={{ width: 198 }} animationData={SuccessAnimation} />
                    <Typography.Title style={{ textAlign: "center", fontWeight: 500, margin: 0 }} level={3}>Successfully Submitted all your details.</Typography.Title>

                    <Space>
                        <Button onClick={() => navigate("/dashboard/confirmation")} type="default" size="large">Done</Button>
                        <Button onClick={() => window.location.reload()} type="primary" size="large">Place new nomination</Button>
                    </Space>

                </div>
            ),
            footer: null,
            icon: null
        });

    }


    const handleFocusOut = (e: any) => {
        if (document?.getElementById('calendar-box')?.contains(e.target)) {
        } else {
            if(!document?.getElementById('open-calendar-button')?.contains(e.target)){
                setDatePickerOpen(false)
            }
        }
    }


        useEffect(() => {
            window.addEventListener("click", handleFocusOut);
            getClients();
            return () => window.removeEventListener("click", handleFocusOut);
        }, [])

        const fetchContracts = async () => {
            setFetchingContracts(true)
            const endDate = form.getFieldValue("date-range") ? dayjs().format('DD/MM/YYYY') : null
            const params = endDate ? {
                filterAnd: `endDate|gted|${endDate}`
            } : {}
            try {
                const response = await instance.get(`/clientservice/clients/${selectedClient?.id}/contract`, { params });
                setContracts(response.data)
                setDatePickerOpen(true);
                setFetchingContracts(false)
            } catch (error) {
                console.error('Error fetching dbdata:', error);
            }
        }

        useEffect(() => {
            setSelectedContract(contracts[0])
        }, [contracts])

        useEffect(()=>{
                form.resetFields();
                setDataSubmitted(false)
                const startDate = dayjs(selectedContract?.startDate, "DD/MM/YYYY");
                const endDate = dayjs(selectedContract?.endDate, "DD/MM/YYYY");
                const selectedStartDate = form.getFieldValue("date-range")[0];
                const selectedEndDate = form.getFieldValue("date-range")[1];
                if(selectedEndDate && selectedStartDate){
                    const intersectionStartDate = selectedStartDate?.isAfter(startDate) ? selectedStartDate : startDate;
                    const intersectionEndDate = selectedEndDate?.isBefore(endDate) ? selectedEndDate : endDate;
    
                    // Calculate the number of days in the intersection
                    const numberOfDaysInIntersection = Math.max(0, intersectionEndDate.diff(intersectionStartDate, 'day') + 1);
    
                    // console.log(`Number of dates in the intersection: ${numberOfDaysInIntersection}`);
                    if(numberOfDaysInIntersection === 0) {
                        setCalendarEnabled(false)
                        calendarReset()
                        form.setFieldValue("date-range", dayjs().isBefore(dayjs().date(15), 'day') ? [dayjs().date(1), dayjs().date(15)] : [dayjs().date(16), dayjs().endOf('month')])
                    } else {
                        if(selectedEndDate.isAfter(endDate)){
                            setCalendarEnabled(false)

                            form.setFieldValue("date-range", [selectedStartDate, endDate])
                        } else {
                            setCalendarEnabled(false)

                            form.setFieldValue("date-range", dayjs().isBefore(dayjs().date(15), 'day') ? [dayjs().date(1), dayjs().date(15)] : [dayjs().date(16), dayjs().endOf('month')])
                        }
                    }
                    
                } else{
                    const newStartDate = dayjs().isBefore(dayjs().date(15), 'day') ? dayjs().date(1) : dayjs().date(16)
                    const newEndDate = dayjs().isBefore(dayjs().date(15), 'day') ? dayjs().date(15) : dayjs().endOf('month')
                    if(startDate.isSameOrBefore(dayjs(newStartDate)) && (endDate.isAfter(dayjs(newEndDate) || endDate.isSame(newEndDate)))) {
                        setCalendarEnabled(false)
                        form.setFieldValue("date-range", [newStartDate, newEndDate])
                    } else if(endDate.isSameOrBefore(dayjs(newEndDate)) && endDate.isAfter(newStartDate)){
                        setCalendarEnabled(false)
                        form.setFieldValue("date-range", [newStartDate, endDate])
                    }
                }
                if(selectedEndDate?.isBefore(startDate) && selectedStartDate?.isBefore(startDate)){
                    setCalendarEnabled(true)
                    form.setFieldValue("date-range",[null,null])
                }
                

        },[selectedContract])

        useEffect(() => {
            selectedClient && fetchContracts()
        }, [selectedClient])

        const handleApply = async () => {
            if (selectedContract && form.getFieldValue("date-range")?.length != 0) {
                const params = {
                    size: 100000,
                    filterAnd: `gasDay|gted|${dayjs(form.getFieldValue("date-range")[0]).format("DD/MM/YYYY")}&gasDay|lted|${dayjs(form.getFieldValue("date-range")[1]).format("DD/MM/YYYY")}&nomination.contractId|eq|${selectedContract.id}`,
                }
                setFetchingNomination(true)

                try {
                    const res = await instance.get("orderservice/nominate/details", { params: params })
                    setNominations(res.data.items)
                    setDataSubmitted(true);
                    setDatePickerOpen(false);
                } catch (e) {
                    console.log(e)
                    setFetchingNomination(false)
                }
                setFetchingNomination(false)
            } else {
                message.error("Please select a contract and date range")
            }
        }

        const handleClientChange = (value: any) => {
            handleReset()
            setSelectedClient(clients.filter((item: any) => item.id === value)[0])
        }

        const handleReset = () => {
            form.resetFields();
            setContracts([]);
            setSelectedClient(null)
            setSelectedContract(null)
            setDataSubmitted(false);
        }

        const calendarReset = () => {
            form.setFieldValue("date-range", [null, null])

            // setSelectedContract(null)
            setDataSubmitted(false)
            form.getFieldInstance('date-range').blur();


            setTimeout(() => {
                (document?.activeElement as HTMLElement).blur();
            }, 1500);

        }

        const handleDateRangeChange = (dates: any) => {
            setCalendarEnabled(false)
            const endDate = dayjs(selectedContract?.endDate, "DD/MM/YYYY")
            if (dates[0]) {
                if (dates && dates.length > 0) {
                    // const endDate = dayjs(dates[0]).add(14, 'day');
                    if (dates[0].isBefore(dayjs(dates[0]).date(15), 'day') || dates[0].isSame(dayjs(dates[0]).date(15), 'day')) {
                        form.setFieldsValue({
                            'date-range': [dayjs(dates[0]).date(1), (dayjs(dates[0]).date(15)).isAfter(endDate, "day") ? endDate : dayjs(dates[0]).date(15)]
                        });
                    } else {
                        form.setFieldsValue({
                            'date-range': [dayjs(dates[0]).date(16), (dayjs(dates[0]).endOf('month')).isAfter(endDate, "day") ? endDate : dayjs(dates[0]).endOf('month')]
                        });
                    }

                }

            } else {
                if (dates && dates.length > 0) {
                    if (dates[1].isBefore(dayjs(dates[1]).date(15), 'day') || dates[1].isSame(dayjs(dates[1]).date(15), 'day')) {
                        form.setFieldsValue({
                            'date-range': [dayjs(dates[1]).date(1), (dayjs(dates[1]).date(15)).isAfter(endDate, "day") ? endDate : dayjs(dates[1]).date(15)]
                        });
                    } else {
                        form.setFieldsValue({
                            'date-range': [dayjs(dates[1]).date(16), (dayjs(dates[1]).endOf('month')).isAfter(endDate, "day") ? endDate : dayjs(dates[1]).endOf('month')]
                        });
                    }
                }
            }

            setTimeout(() => {
                (document?.activeElement as HTMLElement).blur();
            }, 1500);

        };

        return (
            <>
            <Breadcrumb separator=">" style={{ marginBottom: 16, cursor: "pointer" }}>
                <Breadcrumb.Item 
                    onClick={_=>handleMenuItemClick(`/dashboard/confirmation`,dashboard, modal,navigate,messageApi,false, location)}
                >
                    <span style={{fontWeight: 600}}>
                        {auth.isClient ? "Nomination Management" : "Gas Operation"}
                        
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{fontWeight: 600}}>
                        {auth.isClient ? "Nomination Entry" : "Nomination"}
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>
                <div style={{ display: 'flex', gap: 10 }}>
                    {modalContex}
                    {contextHolder}
                    {auth.isClient && clients.length === 1 ? <></> : <Select 
                        dropdownStyle={{padding: 0}} 
                        suffixIcon={selectBoxOpen ? <UpOutlined className="ant-select-suffix" style={{ color: "white", fontSize: 16 }} /> : <DownOutlined className="ant-select-suffix" style={{ color: selectedClient ? "white" : "unset", fontSize: 16 }} />} 
                        className={`create-nomiantion-select`} 
                        loading={fetchingClients} 
                        open={selectBoxOpen}
                        size="large" 
                        onChange={handleClientChange} 
                        style={{ height: "fit-content", width: 250 }} 
                        placeholder="Company"
                        onDropdownVisibleChange={(open)=>setSelectBoxOpen(open)}
                    >
                        {fetchingClients ? <Select.Option><Skeleton title={false} paragraph={{
                            width: [400]
                        }} active /></Select.Option> :clients.map((item: any, key) => {
                            return <Select.Option className="nomination-client" key={key} value={item.id}>{item.name}</Select.Option>
                        })}
                    </Select>}
                    <div>
                        <Button 
                            id="open-calendar-button" 
                            // disabled={!selectedClient} 
                            size="large" 
                            onClick={() => {selectedClient && setDatePickerOpen(!datePickerOpen)}} 
                            type="primary">
                                {!selectedContract ? "Date Range" : `Contract-${selectedContract.id} (${dayjs(selectedContract?.startDate, "DD/MM/YYYY").format("DD MMM YYYY")}-${dayjs(selectedContract?.endDate, "DD/MM/YYYY").format("DD MMM YYYY")})`} 
                                {fetchingContracts ? <Spin indicator={<LoadingOutlined style={{ fontSize: 15, color: "white", marginLeft: 10 }} spin />} /> : datePickerOpen ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                        <div
                            id="calendar-box"
                            style={{
                                marginTop: 10, position: "absolute", background: "white",
                                boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
                                borderRadius: 12,
                                display: datePickerOpen ? "block" : "none",
                                zIndex: 100,
                            }}>
                            <div style={{ display: "flex" }}>
                                <List
                                    size="small"
                                    style={{
                                        borderRadius: "12px 0 0 12px",
                                        borderBottom: 0,
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: 250,
                                        height: 380,
                                        overflowY: "scroll",
                                        cursor: "pointer"
                                    }}
                                    bordered
                                    loading={fetchingContracts}
                                    dataSource={contracts}
                                    renderItem={(item: any) => (
                                        <List.Item onClick={() => setSelectedContract(item)} style={{ padding: 0, fontSize: 13, border: "none" }}>
                                            <div className={`contract-list-item ${selectedContract?.id === item.id && 'selected-contract'}`}>
                                                {item.id}<br/> {item.totalQuantity} MMBTU
                                            </div>
                                        </List.Item>
                                    )}
                                />
                                <div id="nomination-calendar">
                                    <div style={{fontWeight: 600, fontSize: 10,color: "#C9C9C9", marginTop: 10, marginLeft: 10}}>Select range for</div>
                                    <Form
                                        form={form}
                                        name="basic"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        initialValues={{ "date-range": dayjs().isBefore(dayjs().date(15), 'day') ? [dayjs().date(1), dayjs().date(15)] : [dayjs().date(16), dayjs().endOf('month')] }}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            noStyle
                                            name="date-range"
                                        >
                                            <RangePicker
                                                prevIcon={<div style={{background: "rgba(0, 177, 118, 0.08)", width: 20, height: 20, display: "flex", justifyContent: "center", borderRadius: 5}}><LeftOutlined style={{color: theme.token.colorPrimary, fontSize: 12}} /></div>}
                                                nextIcon={<div style={{background: "rgba(0, 177, 118, 0.08)", width: 20, height: 20, display: "flex", justifyContent: "center", borderRadius: 5, marginLeft: -7}}><RightOutlined style={{color: theme.token.colorPrimary, fontSize: 12}} /></div>}
                                                bordered={false}
                                                getPopupContainer={() => document.getElementById("nomination-calendar") as HTMLElement}
                                                disabledDate={(current) => current && (current < dayjs().endOf('day') || current > dayjs(selectedContract?.endDate, "DD/MM/YYYY").endOf('day') || current < dayjs(selectedContract?.startDate, "DD/MM/YYYY").startOf('day'))}
                                                cellRender={(value, value2) => {
                                                    if (value > dayjs(selectedContract?.endDate, "DD/MM/YYYY").endOf('day')) {
                                                        return { ...value2.originNode, props: { ...value2.originNode.props, className: "ant-picker-cell-inner non-contract-day" } }
                                                    } else {
                                                        return value2.originNode
                                                    }
                                                }}
                                                disabled={!calendarEnabled}
                                                onCalendarChange={handleDateRangeChange}
                                                // onChange={()=>form.getFieldInstance("date-range").blur()}
                                                open={datePickerOpen}
                                                style={{ width: "250px" }}
                                                renderExtraFooter={() =>
                                                    <Space style={{ padding: 10, width: "100%", justifyContent: "center" }}>
                                                        <Button className="reset-button-calendar" disabled={fetchingNomination || calendarEnabled} onClick={()=>{setCalendarEnabled(true);calendarReset()}} size="large" type="default">Reset</Button>
                                                        <Button style={{width:  120}} loading={fetchingNomination} disabled={!selectedContract || !form.getFieldValue("date-range")[0]} onClick={handleApply} size="large" type="primary">Apply</Button>
                                                    </Space>
                                                }
                                            />
                                        </Form.Item>
                                    </Form>
                                </div>

                            </div>
                        </div>
                        {<div style={{ marginTop: 10 }}>
                            {selectedContract && auth.isClient ? 
                            <Typography.Text style={{ marginTop: 10 }} type="secondary">Please create a nomination between the allowed range of {Math.round(selectedContract.dcq - ((selectedContract.minSupplyFlex/100)*selectedContract.dcq))}-{Math.round(selectedContract.dcq + ((selectedContract.maxSupplyFlex/100)*selectedContract.dcq))} as per the contract.</Typography.Text> :
                            <Typography.Text style={{ marginTop: 10 }} type="secondary">Note- Please select contract and date range to fill the nomination. </Typography.Text>}
                        </div>}
                    </div>
                    {/* {dataSubmitted && <Button disabled style={{ marginLeft: "auto" }}>Delivery End Point: Haryana City Gas, Gurgaon</Button>} */}
                </div>

                {dataSubmitted &&
                    <div className="nomination-form" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                        <div style={{ width: 920, height: "73vh", borderRadius: 20, border: "1px solid #E2E2E2", background: "white", marginTop: 10, overflow: "scroll", padding: "0px 20px 120px 20px" }}>
                            <CreateNominationForm nominations={nominations} contract={selectedContract} selectedClient={selectedClient} dateRange={form.getFieldValue("date-range")} handleSuccess={handleSuccess} />
                        </div>


                    </div>}


            </>
        )

    };

    export default NominationEntry;