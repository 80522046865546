import { Button, Modal, Space, Typography } from "antd";
import theme from "../../utils/theme";
import { EditOutlined } from "@ant-design/icons";
import OtpInput from 'react-otp-input';
import { useEffect, useState } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import SuccessAnimation from "../../utils//success.json";


export default function PhoneOtp({ setScreen, clientData, sendPhoneOtp }: any) {
    const [otp, setOtp] = useState('');
    const [modal, modalContex] = Modal.useModal();
    const navigate = useNavigate();
    const [err, setErr] = useState<any>(null)
    const [countdown, setCountdown] = useState(30);
    const [resendEnabled, setResendEnabled] = useState(false)
    const [submitting, setSubmitting] = useState(false)


    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setResendEnabled(true);
        }
    }, [countdown]);

    
    
    const handleResendOtp = () => {
        setErr(null)
        setCountdown(30);
        setResendEnabled(false);
        sendPhoneOtp();
    }

    function obscurePhoneNumber(phoneNumber:string) {
        // Keep the first three and the last two digits of the phone number
        const visibleStart = phoneNumber.slice(0, 3);
        const visibleEnd = phoneNumber.slice(-2);
        
        // Calculate the number of asterisks needed
        const numAsterisks = phoneNumber.length - 5;
        const obscuredPart = '*'.repeat(numAsterisks);
        
        // Combine the visible start, obscured part, and visible end
        const obscuredPhoneNumber = `${visibleStart}${obscuredPart}${visibleEnd}`;
        
        return obscuredPhoneNumber;
      }


    const createClient = async () => {
        setSubmitting(true)
        await axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/clientservice/clients/verify-mobile-otp?verificationId=${clientData.mobileNo}&otp=${otp}`, clientData).then((res) => {
            modal.success({
                // show success message along with image
                mask: true,
                maskClosable: true,
                closeIcon: <></>,
                closable: false,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10 }}>
                        <Lottie loop={false} style={{ width: 198 }} animationData={SuccessAnimation} />
                        <Typography.Title style={{ textAlign: "center", fontWeight: 500, margin: 0 }} level={3}>Your registration has been done.</Typography.Title>

                        <Space>
                            <Button style={{width: 328, marginTop: 16}} onClick={() => navigate("/")} type="primary" size="large">Return to Login</Button>
                        </Space>

                    </div>
                ),
                footer: null,
                icon: null
            });
        }).catch(e => {
            if (e?.response?.data?.errorMessage) {
                setErr(e?.response?.data?.errorMessage)
            }
            console.log(e)
        })
        setSubmitting(false)

    }

    useEffect(() => {
        const handleKeyDown = (event:any) => {
            if (event.key === "Enter") {
                createClient();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [otp, createClient]);

    return (
        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div>
                <div>
                    <Typography.Title style={{ fontSize: 24, fontWeight: 700, margin: 0, width: "fit-content", color: theme.token.colorPrimary }}>2 Step Verification</Typography.Title>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: 8, marginTop: 20 }}>
                    <Typography.Text style={{ color: theme.token.colorPrimary, margin: 0, fontSize: 14, fontWeight: 600 }}>1 Email</Typography.Text>
                    <div style={{ marginTop: -6 }}>
                        <img width={116} src="/assets/long-seperator.png" />
                    </div>
                    <Typography.Text style={{ color: theme.token.colorPrimary, margin: 0, fontSize: 14, fontWeight: 600 }}>2 Mobile No.</Typography.Text>
                </div>

                <div>
                    <Typography.Title style={{ color: theme.token.colorPrimary, fontSize: 18, marginTop: 40 }}>Mobile Verification</Typography.Title>
                    <Typography.Text>6 digit verification code has been sent to your authorised mobile no.<br /> <span style={{ color: "#2C66E3", textDecoration: "underline" }}>+91-{obscurePhoneNumber(clientData.mobileNo)}</span>
                        <span onClick={() => setScreen("form")} style={{ padding: "2px 0", display: "inline-flex", marginLeft: 8, gap: 10, background: "rgba(27, 120, 223, 0.08)", alignItems: "center", width: 64, justifyContent: "center", borderRadius: 52, fontSize: 12, color: "#2C66E3", cursor: "pointer" }}><EditOutlined /> Edit</span>
                    </Typography.Text>
                </div>

                <div style={{ marginTop: 40 }}>
                    <Typography.Text>OTP</Typography.Text>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span>   </span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                            width: 59,
                            height: 56,
                            marginRight: "0.7rem",
                            fontSize: "24px",
                            borderRadius: "4px",
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                        containerStyle={{ marginTop: 10 }}
                    />
                </div>

                <div style={{ marginTop: 12 }}>
                    {err && <Typography.Text style={{marginRight: 4,fontSize: 12}} type="danger">{err}</Typography.Text>}
                    {resendEnabled ? (
                        <Typography.Text onClick={handleResendOtp} style={{ textDecoration: "underline", color: theme.token.colorPrimary, cursor: "pointer", fontSize: 12 }}>Resend OTP</Typography.Text>
                    ) : (
                        <Typography.Text style={{color: "#979797"}}><span style={{textDecoration: "underline",fontSize: 12}}>Resend OTP</span> in {countdown} sec</Typography.Text>
                    )} 
                </div>

                <div style={{ marginTop: 80 }}>
                    <Button loading={submitting} disabled={otp.length !== 6 || submitting} onClick={createClient} size="large" type="primary" style={{ width: 500, height: 48 }}>Verify</Button>
                </div>


            </div>
            {modalContex}
        </div>
    )
}