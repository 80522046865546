import { Input, Table, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../../utils/theme";
import { getPaginationCountApi } from "../../../utils/common";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/api";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { columns } from "../../../interfaces/TableColumns/ClientListAdmin";

interface Item {
    id: number;
    gasDay: string,
    dcq: number,
    nominatedQty: number,
    confirmedQty: number | null,
    deliveryEndPoint: string,
    deliveryId: string,
    sourceId: string,
    pipelineId: string,
    status: string,
    contractId: string,
    notification: string,
    lastUpdatedBy: string,
    nominationDetails: any,
    updatedByUserType: string
}



export default function ClientListAdmin() {
    let { state } = useLocation();
    const [searchParam, setSearchParam] = useState("")

    const navigate = useNavigate()
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [data, setData] = useState<any>([]);
    const { tableParams, handleSort } = useTableFilters("clientUpdateDate|DESC", '', false, false, true);
    const [filterSearch, setFilterSearch] = useState(null)
    const [searchString, setSearchString] = useState("")

    const [options, setOptions] = useState([])

    useEffect(() => {
        fetchDetails()
    }, [tableParams, searchString]);


    const fetchDetails = async () => {
        const params = {
            filterAnd: searchString ? `name|like|${searchString}` : "",
            ...tableParams,
        }
        
        setLoadingDetails(true)
        try {
            const res = await instance.get(`orderservice/nominate/client-nomination-view`, { params });
            setData(res.data)
            setOptions(res?.data?.items)
        }
        catch (e) {
            console.log(e)
        }
        setLoadingDetails(false)
    }

    return (
        <>
            {searchParam}
            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, marginTop: 0 }}>Client Details</Typography.Title>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Input.Search className="mg-input-search" allowClear onSearch={(value)=>setSearchString(value)} placeholder="Search by client name" size="large" style={{width: 300}} />
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: 40, marginTop: 20 }}>

                <Table
                    loading={loadingDetails}
                    onChange={(pagination: any, filters: any, sorter: any, extra: any) => {
                        console.log("filters", filters)
                        setFilterSearch(filters.name?.[0])
                        handleSort(pagination, filters, sorter, extra)
                    }}
                    rowClassName="table-row"
                    columns={columns as any}
                    dataSource={data?.items}
                    pagination={{
                        pageSize: tableParams.size,
                        total: data?.totalItems,
                        current: tableParams.page,
                        showSizeChanger: false,
                        showQuickJumper: false,
                        position: ["bottomCenter"],
                        showPrevNextJumpers: false,
                    }}
                    footer={() => <div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                    rowKey={(record) => record.id}
                    scroll={{
                        x: true
                        , y: data?.totalItems > 0 ? "70vh" : undefined
                    }}
                    // showHeader={!data?.items || data?.items?.length === 0 ? false : true}
                // locale={loadingDetails ? undefined : { emptyText: <EmptyList customMessage={<>You have no Enquiries</>} /> }}
                />


            </div>

            

        </>
    )
}