import { CheckCircleFilled } from "@ant-design/icons";
import { Tag, Typography } from "antd";
import theme from "../../../utils/theme";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";

export default function TabHeader({title, status, showStatus, disabled, isOpen, completed, showUpdateStatus, defaultTab, registrationStatus,inProgress, index}:any) {

    const auth = useAppSelector(authSelector);


    return <div>
        <div style={{ display: "flex", alignItems: "center" }}>
            {showStatus && completed && <CheckCircleFilled style={{color: theme.token.colorPrimary}} />}
            {showStatus && !completed && <span style={{height: 18, width: 18, background: theme.token.colorPrimary, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 12, color: "white", borderRadius: "50%", marginRight: 12}}>{index}</span>}

            
            <Typography.Text style={{color: "#333333", fontSize: 18}}>{title}</Typography.Text>
            {showUpdateStatus && 
            <div style={{width: "fit-content", marginLeft: 4}}>
                
                    {showUpdateStatus === "UPDATED" && auth.isClient && <Tag color="orange" style={{display: "block"}}>Edited</Tag>}
                    {showUpdateStatus === "UPDATED" && (auth.isAdmin || auth.isMainAdmin) && <Tag color="orange" style={{display: "block"}}>Updated</Tag>}

                    {showUpdateStatus === "UPDATED_BY_ADMIN" && auth.isClient && <Tag color="orange" style={{display: "block"}}>Updated</Tag>}
                    {showUpdateStatus === "UPDATED_BY_ADMIN" && (auth.isAdmin || auth.isMainAdmin) && <Tag color="orange" style={{display: "block"}}>Edited</Tag>}

                    {(showUpdateStatus === "APPROVED" || showUpdateStatus === "APPROVED_BY_ADMIN") && <Tag color="green" style={{display: "block"}}>Approved</Tag>}

                    {(showUpdateStatus === "REJECTED_BY_ADMIN" || showUpdateStatus === "REJECTED") && <Tag color="red" style={{display: "block"}}>Rejected</Tag>}
                
            </div>
            } 
        </div>
        {showStatus && <div>
            {!completed && !isOpen && <Tag color="#E0E0E082" style={{color: "#848484"}}>Pending</Tag>}
            {completed && !isOpen && <Tag color="green">Completed</Tag>}
            {isOpen && inProgress && <Tag color="blue">In Process</Tag>}
            {completed && isOpen && !inProgress &&<Tag color="green">Completed</Tag>}

        </div>}

    </div>
}