import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Button, MenuProps, Modal, Space, Table, Typography } from 'antd';
import { instance } from '../../../utils/api';
import theme from '../../../utils/theme';
import EmptyList from './EmptyList';
import { useTableFilters } from '../../../utils/hooks/useTableFilters';
import { Item, columns } from '../../../interfaces/TableColumns/Confirmation';
import { useNotify } from '../../../utils/hooks/useNotify';
import { TableDataType } from '../../../interfaces/TableData';
import dayjs from 'dayjs';
import ImportModal from './ImportModal';
import { useNavigate } from 'react-router-dom';
import { getPaginationCountApi } from '../../../utils/common';
import FilterSelect from '../../../components/FilterSelect';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';

export interface DataType extends TableDataType {
    items: Item[] | [];
}

const filterString = `endDate|gted|${dayjs().format("DD/MM/YYYY")}`


function ConfirmationList() {

    const [data, setData] = useState<DataType | null>(null);
    const auth = useAppSelector(authSelector);

    const { tableParams, onFilerSearch, handleSort, onSearch } = useTableFilters("auditInfo.creationDate|DESC", filterString, true, false, true);
    const { notifyClients, rowSelection, hasSelected, sendingNotifications, openNotify, closeNotify, setOpenNotify } = useNotify("TOTAL_CONFIRMATION",false, setData)
    const [modal, modalContex] = Modal.useModal();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadType, setUploadType] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [options,setOptions] = useState(null)

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div  style={{ display: "flex", gap: 10, cursor: "pointer" }}>
                    <EditOutlined style={{ color: theme.token.colorPrimary, fontSize: 24 }} /><Typography.Text style={{ fontSize: 16 }}>Create New Entry</Typography.Text>
                </div>
            ),
        },
        // {
        //     key: '2',
        //     label: (
        //         <div onClick={() => {showModal();setUploadType("excel")}} style={{ display: "flex", gap: 10, cursor: "pointer" }}>
        //             <UploadOutlined style={{ color: theme.token.colorPrimary, fontSize: 24 }} /><Typography.Text style={{ fontSize: 16 }}>Upload Excel</Typography.Text>
        //         </div>
        //     ),
        // },
    ]


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setUploadType("")
    };

    useEffect(() => {
        fetchData();
    }, [tableParams])

    const fetchData = async () => {
        const params = {
            ...tableParams,
            // filter and contract endDate is less than current date
            filterAnd: filterString + `${tableParams.filterAnd ? `&${tableParams.filterAnd}` : ""}`
        }
        setLoading(true)
        try {
            const res = await instance.get("orderservice/nominate", { params })
            setData(res.data);
            if(!options){
                setOptions(res?.data?.items)
            }
        } catch (error) {
            console.error('Error fetching dbdata:', error);
        }
        setLoading(false)
    };


    const handleNotify = () => {
        modal.confirm({
            centered: true,
            icon: null,
            title: 'Do you want to proceed and send Confirmation to the client?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                notifyClients(fetchData)
            },
            bodyStyle: {
                textAlign: "center"
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 } },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 } }

        });
    }

    return (
        (data?.items?.length === 0 && !tableParams.filterAnd && !loading) || (auth.isClient) ? <EmptyList hideMessage={auth.isClient}  /> : <React.Fragment>
            {modalContex}
            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, margin: 0 }}>Confirmation</Typography.Title>
            <div className="page-header" style={{ gap: 10 }}>
                <Space style={{ display: openNotify ? "none" : "flex" }}>
                    <FilterSelect onFilerSearch={onFilerSearch} options={options} onSearch={onSearch} confirmationList={true} />

                    <div>
                        <Button loading={sendingNotifications} size='large' type="primary" onClick={() => setOpenNotify(true)}>
                            Notify
                        </Button>
                    </div>
                    <Button onClick={() => navigate("/dashboard/confirmation/nomination-entry")} size='large' type="primary">
                        Add New Nomination
                        <PlusOutlined style={{fontWeight: 600}} />
                    </Button>

                </Space>
                <Space style={{ display: !openNotify ? "none" : "flex" }}>
                    <Button loading={sendingNotifications} onClick={handleNotify} size='large' type="primary" disabled={!hasSelected}>Send Email</Button>
                    <Button size='large' type="default" onClick={() => closeNotify()}>Cancel</Button>
                </Space>
            </div>

            <Table
                loading={loading}
                onChange={handleSort}
                rowSelection={openNotify ? rowSelection : undefined}
                rowClassName="table-row"
                columns={columns}
                dataSource={data?.items}
                pagination={{
                    pageSize: tableParams.size,
                    total: data?.totalItems,
                    current: tableParams.page,
                    showSizeChanger: false,
                    position: ["bottomCenter"]
                }}
                footer={()=><div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                rowKey={(record) => record.id}
                scroll={{ x: true, y: "65vh" }}
            />
            <ImportModal nominationType={"nomination"} uploadType={uploadType} setUploadType={setUploadType} isModalOpen={isModalOpen} handleCancel={handleCancel} fetchData={fetchData} />

        </React.Fragment>
    )

};

export default ConfirmationList;