import { ArrowDownOutlined, ArrowLeftOutlined, DownOutlined, DownloadOutlined, EyeOutlined, UpOutlined, UploadOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Divider, Form, Input, Modal, Skeleton, Space, Steps, Table, Tag, Typography, Upload, message } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import theme from "../../../utils/theme";
import { useEffect, useState } from "react";
import { columns } from "../../../interfaces/TableColumns/ProposalHistory";
import { instance } from "../../../utils/api";
import { beforeUpload, handleMenuItemClick, showSuccess } from "../../../utils/common";
import dayjs from "dayjs";
import { EnquiryStatus, enquiryStatusColors, enquiryStatusDisplay } from "../../../utils/healpers/constants";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { dashboardSelector, setUnSavedData } from "../../../features/auth/dashboardSlice";



export default function EnquiryDetails() {
    // let { state } = useLocation();
    const dispatch = useAppDispatch();
    let { id } = useParams();
    const navigate = useNavigate();
    const [historyOpen, setHistoryOpen] = useState(false)
    const [proposals, setProposals] = useState<any>(null)
    const [modal, modalContex] = Modal.useModal();
    const [uploaderOpen, setUploaderOpen] = useState(false)
    const [remarks, setRemarks] = useState("")
    const [sendRemarksEnabled, setSendRemarksEnabled] = useState(false)
    const [form] = Form.useForm()
    const { tableParams, onFilerSearch, handleSort } = useTableFilters("auditInfo.creationDate|DESC", '', true, false, true);
    const [disableuploadSignedContractButton, setDisableuploadSignedContractButton] = useState(true)
    const [disableAcceptProposalButton, setDisableAcceptProposalButton] = useState(true)
    const [current, setCurrent] = useState(0);
    const dashboard:any = useAppSelector(dashboardSelector)
    const [messageApi] = message.useMessage();
    const location = useLocation();
    const [state, setState] = useState<any>({})
    const [loadingState, setLoadingState] = useState(true)


    useEffect(() => {
        fetchState()
    }, [])

    const fetchState = async () => {
        setLoadingState(true)
        const params = {
            filterAnd: `id|eq|${id}`
        }
        try {
            const res: any = await instance.get("/clientservice/clients/enquiry", {params})
            if(res?.data?.items[0]?.status === "CONTRACT_CREATED"){
                navigate("/dashboard/contract-history")
            }

            if(res?.data?.items[0]){
                setState({
                    ...res?.data?.items[0], 
                    createDeliveryDtoList: res?.data?.items[0]?.deliveryEndPoints,
                })

                if(res?.data?.items[0]?.status === "PROPOSAL_ACCEPTED"){
                    setHistoryOpen(true)
                }
            }
            setLoadingState(false)
            
        } catch(e) {
            setLoadingState(false)
            console.log(e)
        }
    }


    useEffect(() => {
        fetchProposals()
    }, [tableParams]);

    useEffect(() => {
        form.setFieldValue("remarks", proposals?.items[0].remarks)
    }, [proposals]);


    const onChange = (value: number) => {
        console.log('onChange:', value);
        setCurrent(value);
    };

    useEffect(() => {
        if (uploaderOpen) {
            dispatch(setUnSavedData(true))
        } else {
            dispatch(setUnSavedData(false))
        }

        return () => {
            dispatch(setUnSavedData(false))
        }
    }, [uploaderOpen])

    const fetchProposals = async () => {

        const params = {
            ...tableParams,
            sort: tableParams.sort != undefined ? tableParams.sort : "auditInfo.creationDate|DESC",
            filterAnd: `enquiry.id|eq|${id}`,
        }

        try {
            const res = await instance.get("/clientservice/clients/proposal", { params })
            console.log("res", res)
            setProposals({ ...res.data, items: res.data.items })
        } catch (e) {
            console.log(e)
        }
    }

    const handleDownload = async (e: any, urlString: string, downloadType: string = "proposal") => {
        e.preventDefault();
        e.stopPropagation();

        await fetch(urlString).then((response) => {
            response.blob().then((blob) => {

                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = downloadType + "_" + id;
                alink.click();
            });
        });
    };

    const sendRemarks = async () => {

        const params = {
            id: proposals?.items[0].id,
            remarks: form.getFieldValue("remarks"),
            enquiryId: id
        }

        form.validateFields()

        console.log(params, "parmas")

        modal.confirm({
            title: <>Are you sure<br />you want to send remark?</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            centered: true,
            cancelText: 'No',
            icon: null,
            onOk: async () => {
                try {
                    // setLoading(true)
                    await instance.put(`clientservice/clients/proposal/send-remarks`, params)
                    showSuccess(<>Your remark has been sent, Please wait for the admin to accept the proposal. </>, Modal)
                    navigate("/dashboard/enquiry")

                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })
    }

    const acceptProposal = async () => {

        console.log("remarks", remarks)
        modal.confirm({
            title: <>Are you sure<br />you want to accept the proposal?</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            icon: null,
            onOk: async () => {
                try {
                    const formValues = form.getFieldsValue();
                    console.log(formValues);

                    const formData = new FormData();

                    formData.append("file", formValues["file"]);
                    const temp: any = {}

                    temp.id = proposals?.items[0].id
                    temp.remarks = remarks
                    temp.enquiryId = id

                    const tempSignedPoposal: any = {}

                    tempSignedPoposal.date = dayjs().format("DD/MM/YYYY")
                    tempSignedPoposal.enquiryId = id

                    console.log("temp", temp)

                    formData.append('proposal', new Blob([JSON.stringify(temp)], { type: 'application/json' }));
                    formData.append('signedProposal', new Blob([JSON.stringify(tempSignedPoposal)], { type: 'application/json' }));

                    // setLoading(true)
                    await instance.put(`clientservice/clients/proposal/accept-proposal`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    showSuccess(<>Contract proposal successfully accepted, please wait for the admin to verify the same.</>, Modal)
                    navigate("/dashboard/enquiry")

                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })
    }

    const uploadSignedContract = async () => {

        modal.confirm({
            title: <>Are you sure<br />you want to send the signed contract?</>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            icon: null,
            onOk: async () => {
                try {
                    const formValues = form.getFieldsValue();
                    console.log(formValues);

                    const formData = new FormData();

                    formData.append("file", formValues["file"]);
                    formData.append("enquiryId", id as any);
                    formData.append("remark", formValues["remarks"])

                    // setLoading(true)
                    await instance.post(`clientservice/clients/enquiry/upload-signed-contract`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    showSuccess(<>Contract successfully submitted, please wait for the admin to verify the same.</>, Modal)
                    navigate("/dashboard/enquiry")

                    // message.success("client KYC deleted successfully!")
                } catch (e: any) {
                    console.log(e);
                }
                // setLoading(false);
            },
            bodyStyle: {
                textAlign: "center",
            },
            okButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "primary", danger: false },
            cancelButtonProps: { size: "large", style: { width: 140, marginTop: 10 }, type: "default" }
        })
    }

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleUploadSignedContractFormChange = (values: any) => {
        const formValues = form.getFieldsValue()
        if (formValues?.file) {
            setDisableuploadSignedContractButton(false)
        } else {
            setDisableuploadSignedContractButton(true)
        }
    }

    const handleAcceptProposalFormChange = (values: any) => {
        if (values?.file) {
            setDisableAcceptProposalButton(false)
        } else {
            setDisableAcceptProposalButton(true)
        }
    }

    const handleProposalRemarkFormChanges = (values:any) => {
        console.log(values.remarks === "")
        if(values.remarks && values.remarks != ""){
            setSendRemarksEnabled(true)
        } else {
            setSendRemarksEnabled(false)
        }
    }

    return (
      loadingState ? <Skeleton /> :  <div>
            <Space>
                <Button onClick={() => {
                    handleMenuItemClick(`/dashboard/enquiry`, dashboard, modal, navigate, messageApi, false, location);
                    }} size="large" style={{ padding: 0, marginRight: 20 }} type="text"><ArrowLeftOutlined /></Button>
                <Typography.Text style={{ fontSize: 24, color: theme.token.colorPrimary }} strong>
                    Contract Proposals
                </Typography.Text>
            </Space>
            <Breadcrumb separator=">" style={{ marginBottom: 16, cursor: "pointer", marginLeft: 46 }}>
                <Breadcrumb.Item
                    onClick={_ => handleMenuItemClick(`/dashboard/enquiry`, dashboard, modal, navigate, messageApi, false, location)}
                >
                    <span style={{ fontWeight: 600 }}>
                        Enquiry ID - {id}
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ fontWeight: 600 }}>
                        View Details
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>

            {state.status != "PROPOSAL_ACCEPTED" && <div style={{ position: "relative", marginBottom: 78 }}>
                <div style={statusStyle}>
                    <Tag style={{ fontSize: 20, textAlign: "center", padding: "8px 40px", display: "flex", alignItems: "center", color: state?.status === EnquiryStatus.CONTRACT_REGISTRATION_PENDING ? "#4e9cf1" : "" }} color={(enquiryStatusColors as any)[state.status]}>
                        {state.status === EnquiryStatus.PROPOSAL_RECEIVED && <ArrowDownOutlined style={{ transform: "rotateZ(41deg)", fontSize: 20, marginRight: 20 }} />}
                        {state.status === EnquiryStatus.REMARK_SENT && <img src="/assets/remark-sent.png" style={{ marginRight: 20 }} />}
                        {state.status === EnquiryStatus.CONTRACT_REGISTRATION_PENDING && <img src="/assets/contract-reg-pending.png" style={{ marginRight: 20 }} />}
                        {state.status === EnquiryStatus.CONTRACT_RECEIVED && <img src="/assets/contract-recieved.png" style={{ marginRight: 20 }} />}


                        {(enquiryStatusDisplay as any)[state.status]}
                    </Tag>
                </div>

            </div>}
            {!uploaderOpen && state.status != "PROPOSAL_ACCEPTED" && state?.status !== EnquiryStatus.CONTRACT_REGISTRATION_PENDING  && state?.status !== EnquiryStatus.CONTRACT_RECEIVED && <div style={noteStyle}>
                <div>
                    <span style={{ color: "red" }}>Note- </span>
                </div>
                <div>
                    Please review the contract proposal and accept to get started, otherwise submit your remarks and<br /> our team will get back to you.
                </div>
            </div>}

            {state?.status !== EnquiryStatus.CONTRACT_REGISTRATION_PENDING && <>
                {state.status != "CONTRACT_RECEIVED" ? <>
                    {!uploaderOpen ? <>
                        {state.status != "PROPOSAL_ACCEPTED" && <div style={{ display: "flex", justifyContent: "center" }}>

                            <div style={{ width: "70%", marginTop: 40 }}>
                                <div style={cardStyle}>
                                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                        <img src="/assets/enquiry-icon.png" />
                                        <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>Contract Proposal</Typography.Text>
                                    </div>

                                    <div style={{ display: "flex", gap: 28 }}>
                                        <div onClick={(e: any) => { handleDownload(e, proposals?.items[0].proposalUrl) }} style={{ textAlign: "center", color: theme.token.colorPrimary, cursor: "pointer" }}>
                                            <DownloadOutlined style={{ fontSize: 24 }} /><br />
                                            Download
                                        </div>
                                        <Link target="_blank" to={proposals?.items[0].proposalUrl} style={{ textAlign: "center", color: "rgba(27, 120, 223, 1)", cursor: "pointer" }}>
                                            <EyeOutlined style={{ fontSize: 24 }} /><br />
                                            View File
                                        </Link>
                                    </div>
                                </div>

                                <Form form={form} onValuesChange={handleProposalRemarkFormChanges} layout="vertical" style={{ marginTop: 28 }}>
                                    <Form.Item name="remarks" rules={[{ required: false }]} label={<span style={{ fontWeight: 500, marginLeft: 20 }}>Remark</span>} >
                                        <Input.TextArea rows={4} placeholder="Please enter your remark" style={{ width: "100%" }} size="large" />
                                    </Form.Item>
                                </Form>

                                <div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                                    <Space size="large">
                                        <Button disabled={!sendRemarksEnabled} onClick={sendRemarks} className="reset-button-calendar" style={{ width: 200 }} size="large">
                                            {proposals?.items[0].remarks ? "Edit Remark" : "Send Remark"}
                                        </Button>
                                        <Button onClick={() => {
                                            const remarksValue = form.getFieldValue("remarks")
                                            setRemarks(remarksValue)
                                            setUploaderOpen(true)
                                        }} style={{ width: 200 }} size="large" type="primary" htmlType="submit">
                                            Accept
                                        </Button>
                                    </Space>
                                </div>
                            </div>
                        </div>}

                        {/* {state.status != "PROPOSAL_ACCEPTED" && <div style={{ position: "relative", marginBottom: 125, marginTop: 48 }}>
                            <div style={{ ...statusStyle, background: "rgba(232, 247, 249, 1)" }} onClick={() => setHistoryOpen(!historyOpen)}>
                                <div style={{ ...tagStyle, color: "rgba(27, 120, 223, 1", background: "transparent" }}>
                                    Proposal History
                                    {historyOpen ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
                                </div>
                            </div>
                        </div>}


                        {historyOpen && <Table
                            rowClassName="table-row"
                            columns={columns as any}
                            dataSource={proposals?.items}
                            rowKey={(record) => record.id}
                            pagination={false}
                        />} */}
                    </> :
                        <div style={{ ...cardStyle, border: "none", display: "block", marginTop: 160 }}>
                            <div style={{ textAlign: "center", width: "100%" }}>
                                <Typography.Title level={3}>
                                    Please upload a signed copy of the contract proposal in PDF format.
                                </Typography.Title>
                                <Divider />
                            </div>
                            <div
                                style={{ display: "flex", padding: "0px 40px", marginTop: 60 }}
                            >
                                <Steps
                                    style={{ height: 300 }}
                                    current={current}
                                    onChange={onChange}
                                    direction="vertical"
                                    items={[
                                        {
                                            title: 'Download Contract Proposal',
                                        },
                                        {
                                            title: 'Upload signed Contract Proposal',
                                        },
                                    ]}
                                />
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ textAlign: "right" }}>
                                        <Button onClick={(e) => { handleDownload(e, proposals?.items[0].proposalUrl);setCurrent(1) }} style={{ width: 200 }} type="primary" size="large">Download</Button>
                                    </div>
                                    <div style={{ marginTop: 80 }}>
                                        <Form onValuesChange={handleAcceptProposalFormChange} form={form} layout="vertical">

                                            <Form.Item
                                                style={{ margin: 0 }}
                                                name="file"
                                                rules={[{ required: true, message: "Please enter file (less than 5MB)" }]}
                                                getValueFromEvent={({ file }) => { return file.originFileObj }}
                                            >

                                                <Upload
                                                    name='file'
                                                    accept="application/pdf"
                                                    maxCount={1}
                                                    listType="text"
                                                    customRequest={dummyRequest}
                                                    beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields(["file"]))}
                                                >
                                                    <Button
                                                        onClick={()=>setCurrent(1)}
                                                        style={{ display: "flex", alignItems: "center", gap: 26, height: "auto", width: 400, justifyContent: "space-between" }}
                                                        type="dashed" size="large"
                                                        icon={<img src="/assets/upload.png" width="95%" />}
                                                    >
                                                        <span style={{ textAlign: "left", marginBlock: 10 }}>Upload Signed Copy<br />
                                                            <Typography.Text type="secondary">File type: PDF</Typography.Text >
                                                        </span>
                                                        <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} />
                                                    </Button>
                                                </Upload>

                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>

                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Button disabled={disableAcceptProposalButton} style={{ width: 200 }} size="large" type="primary" onClick={acceptProposal}>
                                    Submit
                                </Button>
                            </div>

                        </div>
                    }
                </> : <>
                    <div style={{ ...cardStyle, border: "none", display: "block", marginTop: 100 }}>
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <Typography.Title level={3}>
                                Please upload a signed copy of the filled details in PDF format.
                            </Typography.Title>
                            <Divider />
                        </div>

                        <div
                            style={{ display: "flex", padding: "0px 40px", marginTop: 60, position: "relative", paddingBottom: 90 }}
                        >
                            <Steps
                                style={{ height: 300 }}
                                current={current}
                                onChange={onChange}
                                direction="vertical"
                                items={[
                                    {
                                        title: 'Download Contract PDF',
                                    },
                                    {
                                        title: 'Upload signed Contract',
                                    },
                                ]}
                            />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ textAlign: "right" }}>
                                    <Button onClick={(e) => { setCurrent(1); handleDownload(e, state?.contractUrl, "contract") }} style={{ width: 200 }} type="primary" size="large">Download</Button>
                                </div>
                                <div style={{ marginTop: 80, marginBottom: 80 }}>
                                    <Form
                                        form={form} layout="vertical"
                                        onValuesChange={handleUploadSignedContractFormChange}
                                    >

                                        <Form.Item
                                            style={{ margin: 0 }}
                                            name="file"
                                            rules={[{ required: true, message: "Please enter file (less than 5MB)" }]}
                                            getValueFromEvent={({ file }) => { return file.originFileObj }}
                                        >

                                            <Upload
                                                name='file'
                                                accept="application/pdf"
                                                maxCount={1}
                                                listType="text"
                                                customRequest={dummyRequest}
                                                beforeUpload={(file:any)=>beforeUpload(file,()=>form.resetFields(["file"]))}
                                            >
                                                <Button
                                                    onClick={()=>setCurrent(1)}
                                                    style={{ display: "flex", alignItems: "center", gap: 26, height: "auto", width: 400, justifyContent: "space-between" }}
                                                    type="dashed" size="large"
                                                    icon={<img src="/assets/upload.png" width="95%" />}
                                                >
                                                    <span style={{ textAlign: "left", marginBlock: 10 }}>Upload Signed Copy<br />
                                                        <Typography.Text type="secondary">File type: PDF</Typography.Text >
                                                    </span>
                                                    <UploadOutlined style={{ fontSize: 22, color: "#2C66E3" }} />
                                                </Button>
                                            </Upload>

                                        </Form.Item>

                                        <Form.Item style={{ position: "absolute", bottom: 0, left: 0, width: "100%" }} name="remarks" rules={[{ required: false }]} label={<span style={{ fontWeight: 500, marginLeft: 20 }}>Remark</span>} >
                                            <Input.TextArea rows={4} placeholder="Please enter your remark" style={{ width: "100%" }} size="large" />
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>

                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Button disabled={disableuploadSignedContractButton} style={{ width: 200 }} size="large" type="primary" onClick={uploadSignedContract}>
                                Submit
                            </Button>
                        </div>

                    </div>




                </>}

                {state.status != "PROPOSAL_ACCEPTED" && <div style={{ position: "relative", marginBottom: 125, marginTop: 48 }}>
                    <div style={{ ...statusStyle, background: "rgba(232, 247, 249, 1)" }} onClick={() => setHistoryOpen(!historyOpen)}>
                        <div style={{ ...tagStyle, color: "rgba(27, 120, 223, 1", background: "transparent" }}>
                            Proposal History
                            {historyOpen ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
                        </div>
                    </div>
                </div>}


                {historyOpen && <Table
                    rowClassName="table-row"
                    columns={columns as any}
                    dataSource={proposals?.items}
                    rowKey={(record) => record.id}
                    pagination={false}
                    onChange={handleSort}
                />}

                {modalContex}
            </>}
            {state?.status === EnquiryStatus.CONTRACT_REGISTRATION_PENDING && <div style={{ display: "flex", alignItems: "center", paddingTop: 40, flexDirection: "column" }}>
                <div style={{ width: "70%", marginTop: 10 }}>
                    <div style={cardStyle}>
                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                            <img src="/assets/enquiry-icon.png" />
                            <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>Signed Contract</Typography.Text>
                        </div>

                        <div style={{ display: "flex", gap: 28 }}>
                            <div onClick={(e: any) => { handleDownload(e, state?.signedContractUrl) }} style={{ textAlign: "center", color: theme.token.colorPrimary, cursor: "pointer" }}>
                                <DownloadOutlined style={{ fontSize: 24 }} /><br />
                                Download
                            </div>
                            <Link target="_blank" to={state?.signedContractUrl} style={{ textAlign: "center", color: "rgba(27, 120, 223, 1)", cursor: "pointer" }}>
                                <EyeOutlined style={{ fontSize: 24 }} /><br />
                                View File
                            </Link>
                        </div>
                    </div>
                </div>

                <div style={{ width: "70%", marginTop: 40 }}>
                    <div style={{ ...cardStyle, justifyContent: "center", gap: 32 }}>
                        <img width="85px" src="/assets/signed-document-pending.png" />
                        <Typography.Text style={{ fontSize: 18, fontWeight: 600 }}>Waiting for Contract registration from admin</Typography.Text>
                    </div>
                </div>

            </div>}

            {state?.status === EnquiryStatus.CONTRACT_REGISTRATION_PENDING && <>
            <div style={{ position: "relative", marginBottom: 125, marginTop: 48 }}>
                    <div style={{ ...statusStyle, background: "rgba(232, 247, 249, 1)" }} onClick={() => setHistoryOpen(!historyOpen)}>
                        <div style={{ ...tagStyle, color: "rgba(27, 120, 223, 1", background: "transparent" }}>
                            Proposal History
                            {historyOpen ? <UpOutlined style={{ fontSize: 14 }} /> : <DownOutlined style={{ fontSize: 14 }} />}
                        </div>
                    </div>
                </div>


                {historyOpen && <Table
                    rowClassName="table-row"
                    columns={columns as any}
                    dataSource={proposals?.items}
                    rowKey={(record) => record.id}
                    pagination={false}
                    onChange={handleSort}
                />}
            
            </>}

            
        </div>
    )
}

export const statusStyle: React.CSSProperties = {
    backgroundColor: "white",
    position: "absolute",
    width: "105%",
    left: -19,
    display: "flex",
    justifyContent: "center",
    padding: "12px 0",
    cursor: "pointer"

}

export const tagStyle: React.CSSProperties = {
    backgroundColor: "rgb(113, 168, 77, 0.14)",
    fontSize: 18,
    padding: "8px 40px",
    color: theme.token.colorPrimary,
    display: "flex",
    gap: 20,
    borderRadius: 8
}

const noteStyle: React.CSSProperties = {
    background: "rgba(255, 59, 48, 0.08)",
    display: "flex",
    gap: 8,
    padding: "8px 16px",
    borderRadius: "0px 0px 20px 20px",
    fontSize: 12,
    width: "fit-content",
    margin: "auto"
}

export const cardStyle: React.CSSProperties = {
    padding: "24px 40px 24px 40px",
    border: "solid 1px rgba(226, 226, 226, 1)",
    background: "white",
    display: "flex",
    borderRadius: 16,
    justifyContent: "space-between",
    alignItems: "center"
}