import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, LikeOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';

function Dashboard() {
    return (
        <React.Fragment>
            <Row gutter={16}>
                <Col span={12}>
                <Card bordered={false}>
                    <Statistic
                    title="Active"
                    value={11.28}
                    precision={2}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<ArrowUpOutlined />}
                    suffix="%"
                    />
                </Card>
                </Col>
                <Col span={12}>
                <Card bordered={false}>
                    <Statistic
                    title="Idle"
                    value={9.3}
                    precision={2}
                    valueStyle={{ color: '#cf1322' }}
                    prefix={<ArrowDownOutlined />}
                    suffix="%"
                    />
                </Card>
                </Col>
                <Col span={12}>
                    <Statistic title="Feedback" value={1128} prefix={<LikeOutlined />} />
                </Col>
                <Col span={12}>
                    <Statistic title="Unmerged" value={93} suffix="/ 100" />
                </Col>
            </Row>
        </React.Fragment>     
    )
            
};

export default Dashboard;