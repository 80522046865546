import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";

export default function UpdateStatusLabel({ showModal, updateStatus, label }: any) {

    const auth = useAppSelector(authSelector);

   return  <>
        {updateStatus === "UPDATED" && auth.isClient && <div style={profileUpdateStatus}>
            Note: The changes will be updated once verified by admin.{" "} <span onClick={showModal} style={{ textDecoration: "underline", cursor: "pointer" }}>View Changes</span>
        </div>}

        {updateStatus === "UPDATED" && (auth.isMainAdmin || auth.isAdmin) && <div style={profileUpdateStatus}>
            Note: Client has made few changes in the {label} section, please verify and approve. <span onClick={showModal} style={{ textDecoration: "underline", cursor: "pointer" }}>View Changes</span>
        </div>}

        {updateStatus === "UPDATED_BY_ADMIN" && auth.isClient && <div style={profileUpdateStatus}>
            Note: Admin has suggested few changes in the {label} section, please verify and approve. <span onClick={showModal} style={{ textDecoration: "underline", cursor: "pointer" }}>View Changes</span>
        </div>}

        {updateStatus === "UPDATED_BY_ADMIN" && (auth.isMainAdmin || auth.isAdmin) && <div style={profileUpdateStatus}>
            Note: The changes will be updated once verified by client. <span onClick={showModal} style={{ textDecoration: "underline", cursor: "pointer" }}>View Changes</span>
        </div>}

        {updateStatus === "REJECTED_BY_ADMIN" && auth.isClient && <div style={{ ...profileUpdateStatus, background: "rgba(255, 59, 48, 0.08)", color: "#FF3B30" }}>
            Note- {label} detail changes have been rejected by the admin. Please connect for support info@matrixgas.in 
        </div>}

        {updateStatus === "REJECTED_BY_ADMIN" && (auth.isAdmin || auth.isMainAdmin) && <div style={{ ...profileUpdateStatus, background: "rgba(255, 59, 48, 0.08)", color: "#FF3B30" }}>
            Note- {label} detail changes have been rejected.
        </div>}

        {updateStatus === "REJECTED" && (auth.isAdmin || auth.isMainAdmin) && <div style={{ ...profileUpdateStatus, background: "rgba(255, 59, 48, 0.08)", color: "#FF3B30" }}>
            Note- {label} detail changes have been rejected by the client.
        </div>}

        {updateStatus === "REJECTED" && auth.isClient && <div style={{ ...profileUpdateStatus, background: "rgba(255, 59, 48, 0.08)", color: "#FF3B30" }}>
            Note- {label} detail changes have been rejected.
        </div>}

        {(updateStatus === "APPROVED" || updateStatus === "APPROVED_BY_ADMIN") && <div style={{ ...profileUpdateStatus, background: "rgba(113, 168, 77, 0.08)", color: "#54C165" }}>
            Note- The changes have been approved and successfully updated.
        </div>}

    </>
}

export const profileUpdateStatus: React.CSSProperties = { background: "#F5F7EC", height: "58px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", color: "#F2923A", marginBottom: 20, borderRadius: 10 }
