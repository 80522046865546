import { navCardStyle } from ".";

export default function RegiistrationNavCard({cardColor, icon, disabledIcon, title, activeTab, setActiveTab, tab, counts}:any) {
    return (
        <div onClick={()=>{window.history.replaceState(null, tab, `/dashboard/registrations?tab=${tab}`);setActiveTab(tab)}} className="hover-zoom" style={{...navCardStyle, border: `solid 1px ${activeTab === tab ? cardColor : "#979797"}`}}>
            <div style={{ background: `${activeTab === tab ? cardColor : "#979797"}20`, padding: 8, display: "flex", gap: 8, justifyContent: "center", alignItems: "center", color: activeTab === tab ? cardColor : "#979797"}}>
                {activeTab === tab ? icon : disabledIcon}
                <span>{title}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: 8 }}>
                <div style={{ textAlign: "center", fontSize: 24 }}>
                    <span style={{ color: activeTab === tab ? cardColor : "#979797"}}>{counts[0].value}</span>
                    <p style={{ margin: 0, fontSize: 12, fontWeight: 400 }}>{counts[0].text}</p>
                </div>
                <div>
                    <img style={{display: "block", height: 50}} src="/assets/card-seperator.png" />
                </div>
                <div style={{ textAlign: "center", fontSize: 24 }}>
                    <span style={{ color: activeTab === tab ? cardColor : "#979797"}}>{counts[1].value}</span>
                    <p style={{ margin: 0, fontSize: 12, fontWeight: 400 }}>{counts[1].text}</p>
                </div>
            </div>
        </div>
    )
}