import { Table, Typography } from "antd";
import theme from "../../../utils/theme";
import { getPaginationCountApi } from "../../../utils/common";
import { defaultColumns } from "../../../interfaces/TableColumns/NominationWorkflow";
import { useEffect, useState } from "react";
import { ColumnTypes } from "../../../interfaces/TableColumns/NominationDetail";
import dayjs from "dayjs";
import { instance } from "../../../utils/api";
import { useTableFilters } from "../../../utils/hooks/useTableFilters";
import FilterSelect from "../../../components/FilterSelect";
import EmptyList from "../GasOperations/EmptyList";

interface Item {
    id: number;
    gasDay: string,
    dcq: number,
    nominatedQty: number,
    confirmedQty: number | null,
    deliveryEndPoint: string,
    deliveryId: string,
    sourceId: string,
    pipelineId: string,
    status: string,
    contractId: string,
    notification: string,
    lastUpdatedBy: string,
    nominationDetails: any,
    updatedByUserType: string
}



export default function NominationWorkflow() {
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [data, setData] = useState<any>([]);
    const { tableParams, onFilerSearch, handleSort, onSearch,pagination } = useTableFilters("gasDay|ASC&auditInfo.creationDate|ASC");
    const [options, setOptions] = useState(null)

    useEffect(() => {
        fetchDetails()
    }, [tableParams]);


    const fetchDetails = async () => {
       const startDate =  dayjs().isBefore(dayjs().date(15), 'day') ? dayjs().date(1) : dayjs().date(16)
        const params = {
            ...tableParams,
            filterAnd: `${tableParams.filterAnd ? 
                `gasDay|gted|${startDate.format("DD/MM/YYYY")}}&${tableParams.filterAnd}` 
                : 
                `gasDay|gted|${startDate.format("DD/MM/YYYY")}}`}`
        }
        setLoadingDetails(true)
        try {
            const res = await instance.get(`orderservice/nominate/details`, { params });
            setData(res.data)
            if(!options){
                setOptions(res?.data?.items)
            }
        }
        catch (e) {
            console.log(e)
        }
        setLoadingDetails(false)
    }


    const exppandedCols = (record: Item, index: any, col: any, notifying?: boolean) => {
        if ((col.dataIndex === "gasDay" || col.dataIndex === "dcq" || col.dataIndex === "contractId") &&
         !(record.hasOwnProperty('nominationDetails')) &&
          data?.items?.filter((i:any) => (i.gasDay === record.gasDay) && (i.contractId === record.contractId))[0].nominationDetails?.[0].id === record.id) {
            return {
                rowSpan: data?.items?.filter((i:any) => (i.gasDay === record.gasDay) && (i.contractId === record.contractId))[0].nominationDetails.length,
                className: col.dataIndex === "dcq" ? `child-border` : "child-border-left",
            };
        } else if ((col.dataIndex === "gasDay" || col.dataIndex === "dcq" || col.dataIndex === "contractId") && !(record.hasOwnProperty('nominationDetails')) && data?.items?.filter((i:any) => (i.gasDay === record.gasDay) && (i.contractId === record.contractId))[0].nominationDetails?.[0].id != record.id) {

            return {
                rowSpan: 0,
            };
        } 
        else {
            return {
                rowSpan: 1,
                className: col.dataIndex === "s_no" && record.updatedByUserType=="MAT_ADMIN" ? `admin-marker` : "",
            };
        }

    }

    const columns = defaultColumns.map((col) => {
        return {
            ...col,
            // onCell: (record: Item) => ({
            //     rowSpan: col.dataIndex === "gasDay" && !record.nominationDetails && data.filter((i)=>i.gasDay === record.gasDay)[0].nominationDetails[0].id === record.id ? data.filter((i)=>i.gasDay === record.gasDay)[0].nominationDetails.length : 1
            // }),
            onCell: (record: Item, index: any) => exppandedCols(record, index, col)
        }
    });


    const getUniqueItems = (array:any) => {
        const uniqueItems:any = {};

        // Iterate through the array
        array?.forEach((item:any)=> {
            // Check if the contractId already exists in the uniqueItems object
            if (!uniqueItems[item.contractId]) {
                // If it doesn't exist, add the item to the uniqueItems object
                uniqueItems[item.contractId] = item;
            }
        });
        
        // Extracting the unique items from the object and converting it back to an array
        const uniqueItemsArray = Object.values(uniqueItems);

        return uniqueItemsArray
        
    }

    return (
        <>
            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, marginTop: 0 }}>Nomination Workflow</Typography.Title>

            <div style={{ display: "flex", flexDirection: "column", gap: 40, marginTop: 20 }}>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "baseline" }}>
                    
                        <FilterSelect onFilerSearch={onFilerSearch} options={getUniqueItems(options)} onSearch={onSearch} />
                    
                </div>

            <Table
                loading={loadingDetails}
                rowClassName={(record) => `table-row ${record.hasOwnProperty('nominationDetails') ? "parent-row" : "child-row"} ${record.nominationDetails?.length > 1 ? "mg-expandable-row" : ""}`}
                columns={columns as ColumnTypes}
                dataSource={data?.items?.map((item: any) => {
                    return { ...item, nominationDetails: item.nominationDetails?.length > 1 ? item.nominationDetails : null }
                })
                }
                showHeader={!data?.items || data?.items?.length === 0 ? false : true}
                locale={loadingDetails ? undefined : { emptyText: <EmptyList customMessage={<>You have no Nominations</>}/> }}
                onChange={handleSort}
                pagination={{
                    pageSize: 15,
                    // total: data.totalItems,
                    // current: tableParams.page,
                    showPrevNextJumpers: false,
                    showSizeChanger: false,
                    position: ["bottomCenter"]
                }}
                footer={()=><div className='footer-pagination'>{data && getPaginationCountApi(data, pagination)}</div>}
                rowKey={(record) => { return record.hasOwnProperty('nominationDetails') ? record.id + "parent" : record.id + "child" }}
                scroll={{ x: true
                    , y: data?.totalItems > 0 ? "70vh" : undefined 
                }}
                expandable={{
                    childrenColumnName: "nominationDetails",
                    rowExpandable: (record) => record.nominationDetails.length > 1,
                    indentSize: 0,
                    expandIcon: () => null,
                    onExpand: (expanded, record) => {
                        const rowItem = document.querySelectorAll(`[data-row-key="${record.id}parent"]`);
                        // add class name to rowItem
                        if (rowItem.length > 0) {
                            // Iterate through each rowItem and add the class name
                            console.log("rowItem", rowItem)
                            rowItem.forEach(item => {
                                console.log("expanded", expanded)
                                if (expanded) {
                                    item.classList.add("mg-expandabled-row")
                                } else {
                                    item.classList.remove("mg-expandabled-row")
                                }
                            });
                        }

                    },
                    expandRowByClick: true,
                }}
            />
        </div>
        {data?.items?.length > 0 && <div style={{display: "flex", gap: 8}}>
                <div style={{width: 22, height: 20, borderRadius: 4, background: theme.token.colorPrimary}}></div><Typography.Text style={{color: theme.token.colorPrimary}}>Entries are Done/Updated by Admin</Typography.Text>
        </div>}
        </>
    )
}