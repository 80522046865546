
import type { ColumnsType } from 'antd/es/table';

import { Button, Input, Space, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import ContractStatusTag from '../../pages/Dashboard/ClientDetails/ContractStatusTag';

interface Item {
    clientCode: undefined | string,
    clientId: undefined | string,
    companyRegisteredId: undefined | string,
    companyRegisteredName: undefined | string,
    confirmedQty: undefined | string,
    contractId: undefined | string,
    contractIdKey: undefined | string,
    contractStatus: undefined | string,
    contractUpdateStatus: undefined | string,
    dcq: undefined | string,
    details: undefined | string,
    deviation: undefined | string,
    email: undefined | string,
    endDate: undefined | string,
    enquiryId: undefined | string,
    name: undefined | string,
    nominatedQty: undefined | string,
    nominationId: undefined | string,
    nominationStatus: undefined | string,
    notificationStatus: undefined | string,
    startDate: undefined | string,
    totalAllocatedQty: undefined | string,
    totalDcq: undefined | string,
    totalQuantity: undefined | string,
    totalScheduledQty: undefined | string,
    url: undefined | string,
}

export const columns: ColumnsType<Item> = [
    {
        title: 'Client',
        dataIndex: 'name',
        key: 'name',
        align: "center",
        render: (client: string) => <Tooltip title={client}><Typography.Text ellipsis style={{ width: 100 }}>
            {client}</Typography.Text >
        </Tooltip>,
        // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
        //     // const searchInput = useRef<any>(null);
        //     return (

        //         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        //             <Input
        //                 // ref={searchInput}
        //                 // placeholder={`Search ${dataIndex}`}
        //                 value={selectedKeys[0]}
        //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        //                 onPressEnter={() => confirm()}
        //                 style={{ marginBottom: 8, display: 'block' }}
        //             />
        //             <Space>
        //                 <Button
        //                     type="primary"
        //                     //   onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
        //                     onClick={() => { confirm() }}

        //                     icon={<SearchOutlined />}
        //                     size="small"
        //                     style={{ width: 90 }}
        //                 >
        //                     Search
        //                 </Button>
        //                 <Button
        //                     onClick={() => {
        //                         setSelectedKeys([]); // Clear selected keys
        //                         confirm(); // Trigger filter confirmation
        //                     }}
        //                     size="small"
        //                     style={{ width: 90 }}
        //                 >
        //                     Reset
        //                 </Button>

        //             </Space>
        //         </div>
        //     )
        // },
        sorter: true
    },
    {
        title: "Client Code",
        dataIndex: "clientCode",
        render: (clientCode) => <div style={{ display: "inline-block", width: 100 }}>{clientCode}</div>,
        align: "center",
    },
    {
        title: 'Contract ID',
        dataIndex: 'contractId',
        key: 'contractId',
        align: "center",
        render: (contractId: string) => <div style={{ width: 80, display: "inline-block" }}>{contractId}</div>,
        sorter: true,
    },
    {
        title: 'GST',
        dataIndex: 'gst',
        key: 'gst',
        align: "center",
        render: (gst: string) => <Tooltip title={gst}><Typography.Text ellipsis style={{ width: 100 }}>
            {gst}</Typography.Text >
        </Tooltip>,
    },
    {
        title: 'DCQ',
        dataIndex: 'dcq',
        key: 'dcq',
        render: (dcq: string) => <div style={{ width: 100, display: "inline-block" }}>{dcq}</div>,
        align: "center",
        sorter: true
    },
    {
        title: 'Contract Status',
        dataIndex: 'contractStatus',
        key: 'contractStatus',
        render: (contractStatus
        ) => {
            return <div style={{ width: 150, display: "inline-block" }}>
                <ContractStatusTag tag={contractStatus} /> 
            </div>
        },
        align: "center",
    },

    {
        title: 'Contract Duration',
        key: 'startDate',
        render: (record) =>
            <div style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
                minWidth: 200
            }}>
                {!record.startDate ? <>NA</> : <>
                    {dayjs(record.startDate, "DD/MM/YYYY").format("DD MMM")}
                    <img width={15} src="/assets/date-line.svg" />
                    {dayjs(record.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}
                </>}
            </div>,
        align: "center",
        sorter: true
    },
    {
        title: <>Total Contract<br />Qty. (MMBTU)</>,
        dataIndex: 'totalQuantity',
        key: 'totalQuantity',
        render: (totalQuantity: string) => <div style={{ width: 150, display: "inline-block" }}>{totalQuantity ? totalQuantity : "----"}</div>,
        align: "center",
        sorter: true,
    },

    {
        title: <>Total Nominated<br />Qty. (MMBTU) </>,
        dataIndex: 'nominatedQty',
        key: 'nominatedQty',
        render: (value) => <div style={{ width: 140, display: "inline-block" }}>{value ? value : "----"}</div>,
        align: "center",
        sorter: true,
    },
    {
        title: <>Total Allocated<br />Qty. (MMBTU) </>,
        dataIndex: 'totalAllocatedQty',
        key: 'totalAllocatedQty',
        render: (value) => <div style={{ width: 140, display: "inline-block" }}>{value ? value : "----"}</div>,
        align: "center",
    },
    {
        title: 'Action',
        key: 'action',
        render: (record: Item) => <Link state={{
            "name": record.name,
            "clientCode": record.clientCode,
        }} style={{ textDecoration: "underline" }} to={`/dashboard/client-details/client-contract-details/${record.clientId}`}>View Details</Link>,
        align: "center",
        ellipsis: true
    },



];