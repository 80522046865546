import { EditOutlined, PlusOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons';
import React, { useRef, useState, useEffect } from 'react';
import type { InputRef } from 'antd';
import { Button, Input, Space, Switch, Table, Tooltip, Popconfirm, message, Typography, Spin } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAppSelector } from '../../../app/hooks';
import { authSelector } from '../../../features/auth/authSlice';
import { instance } from '../../../utils/api';
import theme from '../../../utils/theme';
import { getPaginationCountApi } from '../../../utils/common';
import { useTableFilters } from '../../../utils/hooks/useTableFilters';

const { TextArea } = Input;

interface User {
    name: string;
    username: string;
    emailId: string;
    department: string;
    designation: string;
    contactNo: string;
    location: string;
    dateOfJoining: string;
    role: string;
    active: boolean;
}

type DataIndex = keyof User;

function UserList() {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [remark, setRemark] = useState("");
    const auth = useAppSelector(authSelector);
    const [data, setData] = useState<any>([]);
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [loadingIndex, setLoadingIndex] = useState<any>(null)
    const { tableParams, onFilerSearch, handleSort, onSearch, pagination } = useTableFilters("userId|DESC", '', false, false, true);
    const [filterSearch, setFilterSearch] = useState(null)
    const [options, setOptions] = useState([])


    // const [pagination, setPagination] = useState<any>({current: 1, pageSize: 15})

    useEffect(() => {
        fetchDetails();
    }, [tableParams]);

    const fetchDetails = async () => {
        const params = {
            filterAnd: filterSearch ? `name|like|${filterSearch}` : "",
            ...tableParams,
        }

        setLoadingDetails(true)
        try {
            const res = await instance.get(`/management/users/filter`, { params });
            setData(res.data)
            setOptions(res?.data?.items)
        }
        catch (e) {
            console.log(e)
        }
        setLoadingDetails(false)
    }



    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<User> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8, backgroundColor: "" }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    // value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {
                                handleReset(clearFilters)
                                handleSearch(selectedKeys as string[], confirm, dataIndex)
                            }
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            return (record[dataIndex] ? record[dataIndex] : "")
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            // todo update condition response
            searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    const editUser = async (data: any) => {
        navigate(`/dashboard/users/${data.username}`, { state: { data } });
    }

    const sendVerificationEmail = async (record: any) => {
        setLoadingIndex(record.userId)
        try {
            await axios.post(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/${record.userId}/send-verification-email`, {}, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if (res.status === 200)
                    messageApi.success("Verification email sent!");
            });
        } catch (err) {
            messageApi.error("Email already verified");
            console.log("Error: ", err);
        }
        setLoadingIndex(null)

    }

    const confirm = async (record: any) => {
        let payload: any = {};
        if (record.active)
            payload.enable = "false"
        else
            payload.enable = "true"
        payload.remark = remark;

        if (remark === "") {
            messageApi.warning("Remark is required!")
            return;
        }

        try {
            await axios.put(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/users/${record.userId}`, payload, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if (res.status === 200) {
                    messageApi.success(`User ${record.active ? "Activated" : "Deactivated"} successfully`);
                    window.location.reload();
                }
            })
        } catch (err) {
            console.log(err);
        }
    };

    const cancel = () => {
    };

    const renderDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }:any) => {
        // const searchInput = useRef<any>(null);
        return (

            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    // ref={searchInput}
                    // placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        //   onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        onClick={() => { confirm() }}

                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            setSelectedKeys([]); // Clear selected keys
                            confirm(); // Trigger filter confirmation
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>

                </Space>
            </div>
        )
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            filterDropdown: renderDropDown,
            ellipsis: true
        },
        {
            title: 'Email',
            dataIndex: 'emailId',
            key: 'emailId',
            width: '10%',
            // ...getColumnSearchProps('emailId'),
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNo',
            key: 'contactNo',
            width: '15%',
            // ...getColumnSearchProps('contactNo'),
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            width: '10%',
            // ...getColumnSearchProps('department'),
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            width: '10%',
            // ...getColumnSearchProps('designation'),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '10%',
            // ...getColumnSearchProps('role'),
        },
        {
            title: 'Date of joining',
            dataIndex: 'dateOfJoining',
            key: 'dateOfJoining',
            width: '15%',
            // ...getColumnSearchProps('dateOfJoining'),
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '40%',
            render: (record, rowIndex) => <Space>
                <Tooltip placement="bottom" title="Edit user details">
                    <a style={{ marginRight: 15 }} onClick={() => editUser(record)} ><EditOutlined /></a>
                </Tooltip>
                <Tooltip placement="bottom" title="Send verification email">
                    {loadingIndex === record.userId && loadingIndex ? <Spin style={{ width: 29 }} spinning={true} /> : <a onClick={_ => sendVerificationEmail(record)} style={{ marginRight: 15 }}><SendOutlined /></a>}
                </Tooltip>
                <Tooltip placement="bottom" title="Set active/inactive">
                    <Popconfirm
                        title="Are you sure to change the status of the user?"
                        description={
                            <>
                                <TextArea style={{ width: 300 }} required onChange={(e) => setRemark(e.target.value)} rows={4} placeholder="Add remark." />
                            </>
                        }
                        onConfirm={() => confirm(record)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Switch style={{ marginRight: 15 }} size="small" checked={record.active} defaultChecked />
                    </Popconfirm>
                </Tooltip>
            </Space>,
        },
    ];

    return (
        <React.Fragment>
            {contextHolder}
            <Typography.Title level={4} style={{ color: theme.token.colorPrimary, margin: 0 }}>Users</Typography.Title>

            <div className="page-header">
                <Button size='large' type="primary" icon={<PlusOutlined />} onClick={_ => navigate("/dashboard/users/create-user")}>
                    Add User
                </Button>
            </div>
            <Table
                loading={loadingDetails}
                onChange={(pagination: any, filters: any, sorter: any, extra: any) => {
                    console.log("filters", filters)
                    setFilterSearch(filters.name?.[0])
                    handleSort(pagination, filters, sorter, extra)
                }}
                rowClassName="table-row"
                columns={columns as any}
                dataSource={data?.items}
                pagination={{
                    pageSize: tableParams.size,
                    total: data?.totalItems,
                    current: tableParams.page,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    position: ["bottomCenter"],
                    showPrevNextJumpers: false,
                }}
                footer={() => <div className='footer-pagination'>{data && getPaginationCountApi(data)}</div>}
                rowKey={(record) => record.id}
                scroll={{
                    x: true
                    , y: data?.totalItems > 0 ? "70vh" : undefined
                }}
            // showHeader={!data?.items || data?.items?.length === 0 ? false : true}
            // locale={loadingDetails ? undefined : { emptyText: <EmptyList customMessage={<>You have no Enquiries</>} /> }}
            />
        </React.Fragment>
    )

};

export default UserList;