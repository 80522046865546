import { Tooltip, Typography } from "antd";
import theme from "../../utils/theme";
import { Link } from "react-router-dom";

export const columns = [
    // {
    //     title: 'Date',
    //     dataIndex: 'auditInfo',
    //     key: "auditInfo.creationDate",
    //     render: (auditInfo: any) => { return <div>{dayjs(auditInfo.creationDate).format("DD MMM YYYY")}</div> },
    //     align: "center",
    //     width: 170,
    //     sorter: true
    // },
    {
        title: 'Contract',
        dataIndex: 'url',
        key: 'url',
        align: "center",
        render: (item: any, record: any) => <Link target="_blank" to={item} style={{ display: "inline-flex", gap: 10, alignItems: "center" }}>
            <div style={{ height: 16.47 }}>
                <img src="/assets/file-icon.png" style={{ width: 14 }} />
            </div>
            <Typography.Text style={{ color: theme.token.colorPrimary, textDecoration: "underline" }}>{record?.id}
            </Typography.Text>
        </Link>,
        width: 220
    },
    {
        title: 'Remarks',
        dataIndex: 'remark',
        key: 'remark',
        align: "center",
        render: ((remark: string) => <Tooltip title={remark ? remark : ""}><Typography.Text ellipsis>{remark ? remark : ""}</Typography.Text></Tooltip>),
        ellipsis: true,
        width: 210
    },
    {
        title: <>Daily Quantity<br />(MMBTU)</>,
        dataIndex: 'dcq',
        key: 'dcq',
        render: (dcq: string) => <div style={{ display: "inline-block", width: 100 }}>{dcq}</div>,
        align: "center"
        // sorter: true
    },
    {
        title: <>Total Contract<br />Qty. (MMBTU)</>,
        dataIndex: 'totalQuantity',
        key: 'totalQuantity',
        render: (totalQuantity: string) => <div style={{display: "inline-block", width: 100}}>{totalQuantity ? totalQuantity : "----"}</div>,
        align: "center",
        // sorter: true,
        // width: 250,

    },
    {
        title: 'Update Status',
        dataIndex: 'contractUpdateStatus',
        key: 'contractUpdateStatus',
        align: "center",
        render: (contractUpdateStatus: any) => <>
            <Typography.Text>{contractUpdateStatus}</Typography.Text>
        </>,
        // sorter: true
    },
];